import { axiosInstance } from '@tgg/micro-services/axios';

import { isAxiosError } from '../axios';
import { TggLogger, TggTraceCorrelationParameters } from '../logger';
import { relativeUrlWithBasePath } from '../url';

export const getEmailIdentity = async (email: string) => {
    await axiosInstance.get(relativeUrlWithBasePath(`/api/join-now/email/`), {
        params: { email },
    });

    const { data }: { data: string } = await axiosInstance.get(
        relativeUrlWithBasePath(`/api/proxy/identity/users/`),
        { params: { email } },
    );
    return data.charAt(5);
};

export const getEmailErrorMessage = (
    error: unknown,
    appLogger: TggLogger,
    loggerParameters: TggTraceCorrelationParameters,
) => {
    const errorMessage =
        (error instanceof Error && error.message) ||
        'Unknown error occurred when calling Auth0 to check if user exists in clubware';
    appLogger?.error(
        isAxiosError(error) ? (error.response?.data as string) : errorMessage,
        loggerParameters,
    );
};

import { useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';

import {
    PinoProduct,
    TggMembershipType,
    TggPinoProductTitle,
} from '@tgg/common-types';
import { sendAnalyticsDataEvent, DataEventNames } from '@tgg/services';
import { getTestId } from '@tgg/util';

import { theme } from '../../theme';
import { ButtonBase } from '../Button';
import { PinoProductOption } from '../PinoProductOption';
import { getPinoOrientation } from '../PinoProductOptions/PinoProductOptions.helpers';

import {
    StyledButtonContainer,
    StyledCard,
    StyledFixedProductOptions,
    StyledParagraph,
} from './PinoDayPassProductOptions.styled';
import { PinoDayPassProductOptionsProperties } from './PinoDayPassProductOptions.types';

export const PinoDayPassProductOptions = ({
    gymName,
    products,
    onSelect,
    productHighlightPills,
    isRejoin = false,
}: PinoDayPassProductOptionsProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const router = useRouter();
    const handleCancelClick = async () => {
        await router.push('/rejoin-now/your-membership/');
    };
    const [optionSelected, setOptionSelected] = useState<PinoProduct>(
        products[0],
    );

    const handleSelectedProductOption = (product: PinoProduct) => {
        setOptionSelected(product);

        sendAnalyticsDataEvent({
            eventName: DataEventNames.TERM_SELECT,
            eventData: product,
            eventGymName: gymName,
        });
    };

    const handleClick = () => {
        sendAnalyticsDataEvent({
            eventName: DataEventNames.JOIN_NOW,
            eventData: optionSelected,
            eventGymName: gymName,
        });

        onSelect(optionSelected);
    };

    const orientation = getPinoOrientation(products.length, isDesktop);

    return (
        <StyledCard variant="outlined">
            {products.length > 0 && (
                <StyledFixedProductOptions>
                    {products.map((product, index) => (
                        <PinoProductOption
                            key={product.id}
                            product={product}
                            pinoProductSelected={
                                product.id === optionSelected.id
                            }
                            title={TggPinoProductTitle.DayPass}
                            numberOfElements={products.length}
                            orientation={orientation}
                            onSelectProductOption={handleSelectedProductOption}
                            pill={
                                (productHighlightPills &&
                                    productHighlightPills?.find(
                                        pill =>
                                            pill.membershipType ===
                                            product.membershipType,
                                    )) ||
                                undefined
                            }
                        />
                    ))}
                </StyledFixedProductOptions>
            )}

            <StyledParagraph>
                Unlimited access for one or more consecutive days starting on
                your chosen date.
            </StyledParagraph>

            <StyledButtonContainer isrejoin={isRejoin}>
                <ButtonBase
                    type="button"
                    text={`Choose your ${
                        optionSelected.membershipType ===
                        TggMembershipType.DayPass
                            ? ''
                            : 'start '
                    }date`}
                    onClick={handleClick}
                    data-testid={getTestId('choose-date-button')}
                />
                {isRejoin && (
                    <ButtonBase
                        type="button"
                        data-testid="choose-day-pass-term-cancel-button"
                        buttonStyle="secondary"
                        text={`cancel`}
                        onClick={handleCancelClick}
                    />
                )}
            </StyledButtonContainer>
        </StyledCard>
    );
};

import Link from 'next/link';

import { Chip } from '../Chip';

import {
    StyledContainer,
    StyledLink,
    StyledHorizontalRule,
    StyledLinkContainer,
} from './PromotionalLink.styled';
import { PromotionalLinksProperties } from './PromotionalLink.types';

/**
 * Use the `PromotionalLink` component to add top-level links in the Navigation.
 */
export function PromotionalLink({
    promotionalLinkHref,
    promotionalLinkText,
    chipText,
    chipColor,
    divider = true,
    caseSensitive = false,
    logSideNavRedirect,
}: PromotionalLinksProperties) {
    return (
        <StyledContainer>
            <StyledLinkContainer>
                <Link href={promotionalLinkHref} passHref legacyBehavior>
                    <StyledLink
                        $caseSensitive={caseSensitive}
                        onClick={() => {
                            logSideNavRedirect();
                        }}
                    >
                        {promotionalLinkText}
                    </StyledLink>
                </Link>
                {chipText && chipColor && (
                    <Chip text={chipText} tint={chipColor} />
                )}
            </StyledLinkContainer>
            {divider && <StyledHorizontalRule />}
        </StyledContainer>
    );
}

import { NavigationItem } from '../../NavigationItem';
import {
    StyledHeadingMainMenu,
    StyledList,
    StyledHorizontalRule,
} from '../SideNavigation.styled';
import { NavItemsWithSlide } from '../SideNavigation.types';

import { MemberAreaNavMenuProperties } from './MemberAreaNavMenu.types';

export function MemberAreaNavMenu({
    items,
    isMenuOpen,
    onItemOpen,
}: MemberAreaNavMenuProperties) {
    return (
        <>
            <StyledHeadingMainMenu variant="h3" gutterBottom={false}>
                Member Area
            </StyledHeadingMainMenu>
            <StyledHorizontalRule />
            <StyledList>
                {items.map(({ mainMenuItem, slideItems }, index) => {
                    const { text, leftIconName, caseSensitive, testId } =
                        mainMenuItem;
                    return (
                        slideItems.length > 0 && (
                            <NavigationItem
                                caseSensitive={caseSensitive}
                                key={`item${text}`}
                                text={text}
                                leftIconName={leftIconName}
                                onClick={() => {
                                    onItemOpen(
                                        leftIconName as keyof NavItemsWithSlide,
                                        text,
                                    );
                                }}
                                divider={index !== items.length - 1}
                                tabIndex={isMenuOpen ? -1 : 0}
                                testId={testId}
                            />
                        )
                    );
                })}
            </StyledList>
        </>
    );
}

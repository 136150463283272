import { styled } from '@mui/material/styles';

import { Theme } from '../../theme';
import { Paragraph } from '../Paragraph';

import {
    StyledListProperties,
    StyledHeadingWrapperProperties,
    StyledStyledListContentProperties,
} from './BenefitsTable.types';

export const StyledHeadingWrapper = styled(
    'div',
)<StyledHeadingWrapperProperties>`
    padding: 0 ${({ theme }) => theme.spacing(15)};
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: ${({ theme, $align }) =>
            $align === 'left'
                ? `0 ${theme.spacing(30)}`
                : `0 ${theme.spacing(30)} 0 20rem `};
    }
`;

export const StyledHeading = styled(Paragraph)`
    padding: ${({ theme }: { theme: Theme }) => `${theme.spacing(10)} 0 `};
    font-family: thegymgroupsans-Headline;
`;

export const StyledList = styled('div')<StyledListProperties>`
    & > div:nth-of-type(6n + 1),
    & > div:nth-of-type(6n + 2),
    & > div:nth-of-type(6n + 3) {
        background-color: ${({ $backgroundColor }) => $backgroundColor};
    }
`;

export const StyledListContent = styled(
    'div',
)<StyledStyledListContentProperties>`
    display: flex;
    padding: 1.5rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: ${({ $align }) =>
            $align === 'left'
                ? '1.5rem 1.5rem 1.5rem 3rem;'
                : '1.5rem 1.5rem 1.5rem 20rem;'};
    }
`;

export const StyledIconContainer = styled('span')`
    width: 2.2rem;
    height: 2.2rem;
`;

export const StyledParagraph = styled(Paragraph)`
    display: inline-block;
    padding-left: ${({ theme }: { theme: Theme }) => theme.spacing(10)};
`;

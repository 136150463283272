import getConfig from 'next/config';
import ReCAPTCHA from 'react-google-recaptcha';

import { AlertMessage } from '../AlertMessage';

import { ReCaptchaProperties } from './ReCaptcha.types';

const { GOOGLE_RECAPTCHA_API_KEY } = getConfig().publicRuntimeConfig;

export const ReCaptcha = ({
    siteKey = GOOGLE_RECAPTCHA_API_KEY,
    onChange,
}: ReCaptchaProperties) =>
    siteKey ? (
        // @ts-ignore
        <ReCAPTCHA
            id="reCAPTCHA"
            data-testid="reCAPTCHA"
            sitekey={siteKey}
            onChange={onChange}
            size="normal"
        />
    ) : (
        <AlertMessage text="Please provide an API key" type="alert" />
    );

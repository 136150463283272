import { Icon } from '../../../Icon';

import { StyledHeading, StyledPaymentIcons } from './Header.styled';

export function Header() {
    return (
        <>
            <StyledHeading variant="h2" component="h3">
                Your Debit/Credit Card
            </StyledHeading>

            <StyledPaymentIcons data-testid="paymentIcons">
                <Icon name="visa" rectangle size={50} />
                <Icon name="visaDebit" rectangle size={50} />
                <Icon name="maestro" rectangle size={50} />
                <Icon name="mastercard" rectangle size={50} />
                <Icon name="amex" rectangle size={50} />
            </StyledPaymentIcons>
        </>
    );
}

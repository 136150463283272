/* istanbul ignore file */
import { styled } from '@mui/material/styles';

import { palette } from '../../theme';

export const StyledLister = styled('ol')<{
    $isFAG?: boolean;
}>`
    > li > * {
        height: 100%;
        margin: 0 auto;
        text-align: left;
        background-color: ${palette.common.white};
        border: 2px solid
            ${palette.primary.main}${({ $isFAG }) => ($isFAG ? '50' : '40')};
        box-shadow: none;
    }

    display: grid;
    grid-auto-rows: 1fr;
    gap: 1.9rem;
    padding: ${({ $isFAG }) => ($isFAG ? '1rem 0' : '2rem 3rem 3rem')};
    margin-top: 0;
    list-style: none;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        grid-template-columns: ${({ $isFAG }) =>
            $isFAG ? 'repeat(2, 1fr)' : '1fr 1fr'};
        gap: 2.4rem;
        max-width: 112.4rem;
        padding: ${({ $isFAG }) =>
            $isFAG ? '2rem 3rem 0 3rem' : '2rem 3rem 3rem'};
        margin: 0 auto 4rem auto;
        > li > * {
            min-height: 14rem;
            margin: 0;
        }
        & > li:nth-of-type(3) {
            grid-column: ${({ $isFAG }) => ($isFAG ? '1 / span 2' : 'auto')};
            justify-self: ${({ $isFAG }) => ($isFAG ? 'center' : 'stretch')};
            width: ${({ $isFAG }) => ($isFAG ? 'calc(50% - 1.2rem)' : 'auto')};
            max-width: ${({ $isFAG }) => ($isFAG ? '100%' : 'none')};
        }
    }
`;

import { styled } from '@mui/material/styles';

import { palette } from '../../../theme';

export const StyledYourPlanChip = styled('div')`
    display: flex;
    align-items: center;
    width: 11rem;
    height: 2.8rem;
    padding: 0 1rem;

    margin-bottom: 1.5rem;
    margin-left: -0.6rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: ${palette.common.blue};
    border: solid 0.1rem ${palette.common.blue};
    border-radius: 2rem;

    span {
        margin-left: 0.5rem;
    }

    svg {
        align-self: center;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 11.8rem;
        height: 3.2rem;
        font-size: 1.6rem;
        border-radius: 2rem;
    }
`;

export const StyledYourPlanChipPlaceholder = styled('div')`
    width: 11rem;
    height: 2.8rem;
    margin-bottom: 1.5rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 12.4rem;
        height: 3.2rem;
    }
`;

/* istanbul ignore file */
import { useScrollTrigger, useMediaQuery } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { cloneElement, useEffect, useState } from 'react';

import { JoinJourneyShoppingBasketCookie } from '@tgg/common-types';
import {
    getLoggedInUserFromCookie,
    getAbsoluteURL,
    isMemberArea,
    relativeUrlWithBasePath,
    removeMemberAreaFromUrl,
    isKioskLandingPage,
    getCookie,
    composeFullCookieName,
    AVAILABLE_COOKIES,
} from '@tgg/util';

import { useFlagsContext } from '../../contexts';
import { theme, palette } from '../../theme';
import { HeaderInfoSnackBar } from '../HeaderInfoSnackBar';
import { IconLink } from '../IconLink';
import { Logo } from '../Logo';

import {
    StyledLinksContainer,
    StyledAppBar,
    StyledLogoContainer,
    StyledContainer,
    StyledSnackBarContainer,
} from './Header.styled';
import { ElevationScrollProperties, HeaderProperties } from './Header.types';

const ElevationScroll = ({ children }: ElevationScrollProperties) => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
};

const getLogoHref = (isMember: boolean, isKiosk: boolean) => {
    if (isKiosk) {
        return '/kiosk';
    }

    if (isMember) {
        return getAbsoluteURL('/');
    }

    return '/';
};

export function Header({
    logoOnly,
    onMenuIconClick,
    headerSnackBar,
    appBannerVisible = false,
    accountHref,
    showBorderBottom = false,
    logoType = 'negativeStacked',
    onGymBagClick,
}: HeaderProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const router = useRouter();
    const isInMemberArea = isMemberArea(router.basePath);
    const isKiosk = isKioskLandingPage(router.pathname);
    const {
        flags: {
            support_centre_enabled: { enabled: enabledSupportCentre },
            return_user_basket: { enabled: enabledReturnUserBasket },
        },
    } = useFlagsContext();

    const shoppingBasketCookie =
        !isInMemberArea &&
        getCookie<JoinJourneyShoppingBasketCookie>({
            cookieKey: composeFullCookieName(
                AVAILABLE_COOKIES.DEFAULT_BASKET,
                'commerce',
            ),
        });

    const LogoDimensionsProperties = {
        width: isDesktop ? 115 : 92,
        height: isDesktop ? 55 : 42,
    };

    const iconStyle = {
        iconColor: enabledReturnUserBasket
            ? palette.common.white
            : palette.secondary.main,
    };

    const iconHoverStyle = {
        iconColor: enabledReturnUserBasket
            ? palette.common.white
            : palette.secondary.main,
        background: palette.primary.dark,
    };

    const [loggedIn, setLoggedIn] = useState<string>('');

    useEffect(() => {
        const loggedInUser = getLoggedInUserFromCookie();
        setLoggedIn(loggedInUser);
    }, []);

    return (
        <ElevationScroll>
            <StyledAppBar
                $hasSnackBar={!!headerSnackBar}
                $appBannerVisible={appBannerVisible}
                $showBorderBottom={showBorderBottom}
            >
                <StyledContainer data-is-sticky={true}>
                    <StyledLogoContainer>
                        <Link
                            href={getLogoHref(isInMemberArea, isKiosk)}
                            passHref
                            legacyBehavior
                        >
                            <a
                                aria-label="The Gym Group homepage"
                                title="The Gym Group homepage"
                                href="/"
                            >
                                <Logo
                                    logoType={logoType}
                                    {...LogoDimensionsProperties}
                                />
                            </a>
                        </Link>
                    </StyledLogoContainer>
                    {!logoOnly && (
                        <StyledLinksContainer>
                            {enabledSupportCentre &&
                                !enabledReturnUserBasket && (
                                    <IconLink
                                        name="question"
                                        link="https://support.thegymgroup.com"
                                        style={iconStyle}
                                        hoverStyle={iconHoverStyle}
                                        size={32}
                                        ariaLabel="Support Centre"
                                        testId="support-centre"
                                    />
                                )}
                            <IconLink
                                name="gymLocation"
                                href={
                                    isInMemberArea
                                        ? getAbsoluteURL('/find-a-gym/')
                                        : '/find-a-gym/'
                                }
                                style={iconStyle}
                                hoverStyle={iconHoverStyle}
                                size={32}
                                ariaLabel="Find a Gym"
                                testId="find-a-gym"
                            />
                            <IconLink
                                name={loggedIn ? 'accountLoggedIn' : 'account'}
                                href={
                                    loggedIn
                                        ? accountHref
                                        : removeMemberAreaFromUrl(
                                              relativeUrlWithBasePath(
                                                  '/login/',
                                              ),
                                          )
                                }
                                style={iconStyle}
                                hoverStyle={iconHoverStyle}
                                size={32}
                                ariaLabel="Account"
                                testId="login-member-area"
                            />
                            {enabledReturnUserBasket && !isInMemberArea && (
                                <IconLink
                                    name={
                                        shoppingBasketCookie &&
                                        shoppingBasketCookie?.payments?.today
                                            ? 'gymBagPopulated'
                                            : 'gymBag'
                                    }
                                    href=""
                                    style={iconStyle}
                                    hoverStyle={iconHoverStyle}
                                    size={32}
                                    ariaLabel="Return User Basket"
                                    testId="return-user-basket"
                                    buttonOnClick={onGymBagClick}
                                    className="returnUserBasket"
                                />
                            )}
                            <IconLink
                                name="menu"
                                style={iconStyle}
                                hoverStyle={iconHoverStyle}
                                size={32}
                                buttonOnClick={onMenuIconClick}
                                ariaLabel="Site Menu"
                                testId="site-menu"
                            />
                        </StyledLinksContainer>
                    )}
                </StyledContainer>
                {headerSnackBar && (
                    <StyledSnackBarContainer>
                        <HeaderInfoSnackBar
                            message={headerSnackBar.message}
                            iconName={headerSnackBar.iconName}
                            barType={headerSnackBar.barType}
                            link={headerSnackBar.link}
                        />
                    </StyledSnackBarContainer>
                )}
            </StyledAppBar>
        </ElevationScroll>
    );
}

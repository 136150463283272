import { useRouter } from 'next/router';
import { ParsedUrlQueryInput, stringify } from 'querystring';

import { ButtonBase } from '../Button';
import { Icon } from '../Icon';
import { Overlay } from '../Overlay';

import {
    ButtonsContainer,
    IconWrapper,
    StyledContainer,
    StyledOverlayText,
} from './RejoinInfoOverlay.styled';
import { RejoinInfoOverlayProperties } from './RejoinInfoOverlay.types';

export const RejoinInfoOverlay = ({
    open,
    onClose,
    email,
}: RejoinInfoOverlayProperties) => {
    const router = useRouter();

    const handleSignIn = async () => {
        let query: ParsedUrlQueryInput = {
            returnURL: '/rejoin-now/your-membership/',
        };

        if (email) {
            query = { ...query, email: email };
        }
        await router.push(`/api/auth/login/?${stringify(query)}`);
    };

    return (
        <Overlay
            open={open}
            handleClose={onClose}
            contentContainerMaxWidth={255}
            smallScreenOptions={{ fullScreenMode: false }}
        >
            <StyledContainer>
                <IconWrapper>
                    <Icon name="information" size={40} />
                </IconWrapper>
                <StyledOverlayText>
                    If we recognise your details, we’ve sent you a PIN reminder
                    so you can easily log into your account
                </StyledOverlayText>
                <ButtonsContainer>
                    <ButtonBase
                        data-testid="continueToSignInOverlayButton"
                        text="continue to sign in"
                        onClick={handleSignIn}
                    />
                    <ButtonBase
                        text="close"
                        data-testid="closeRejoinInfoOverlayButton"
                        buttonStyle="secondary"
                        onClick={onClose}
                    />
                </ButtonsContainer>
            </StyledContainer>
        </Overlay>
    );
};

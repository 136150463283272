import { AxiosResponse } from 'axios';

import {
    ApplicationIdentifier,
    ErrorCodes,
    PaymentError,
} from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import {
    PostPaymentGatewayResponseBody,
    PostPaymentGatewayRequestBody,
} from '@tgg/services';
import {
    relativeUrlWithBasePath,
    getApiErrorResponseMessage,
    isAxiosError,
    ErrorResponse,
} from '@tgg/util';

import { getPaymentUrl } from '../../getPaymentUrls/getPaymentUrl';

export interface ProcessPaymentGatewayZeroValueArguments {
    paymentToken: string;
    termsAccepted: boolean;
    applicationIdentifier: ApplicationIdentifier;
}

export const processPaymentGatewayZeroValue = async ({
    paymentToken,
    termsAccepted,
    applicationIdentifier,
}: ProcessPaymentGatewayZeroValueArguments) => {
    const url = getPaymentUrl(applicationIdentifier);

    try {
        const { data } = await axiosInstance.post<
            PostPaymentGatewayResponseBody,
            AxiosResponse<PostPaymentGatewayResponseBody>,
            PostPaymentGatewayRequestBody
        >(
            relativeUrlWithBasePath(url),
            {
                paymentToken,
                termsAccepted,
            },
            {
                timeout: 60_000,
            },
        );

        return data;
    } catch (error) {
        const errorMessage = getApiErrorResponseMessage(error);
        const statusCode =
            (isAxiosError(error) && error.response?.status) || 500;
        const errorCode = String(
            (isAxiosError<ErrorResponse>(error) &&
                error.response?.data.error.code) ||
                '1111',
        ) as ErrorCodes;

        throw new PaymentError(
            `**${applicationIdentifier}**: Payment failed - There was an issue with the transaction when calling payment gateway api clientside: ${JSON.stringify(
                errorMessage,
            )} errorCode: ${errorCode} statusCode: ${statusCode}`,
            errorCode,
        );
    }
};

import { TggMembershipType, TggProductRange } from '@tgg/common-types';

import {
    GenericDataEvent,
    membershipTypeToMembershipRangeResponse,
} from './googleAnalytics.types';

export const sendGenericDataEvent = (event: GenericDataEvent): void => {
    if (typeof window === 'undefined') {
        return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        ...event,
        timestamp: Date.now(),
    });
};

export const membershipTypeToMembershipRange = (
    membershipType: TggMembershipType,
): membershipTypeToMembershipRangeResponse => {
    if (membershipType === TggMembershipType.Live_It_Fixed)
        return TggProductRange.Ultimate;

    const mappedRange = Object.values(TggProductRange).find(range =>
        membershipType.toLowerCase().includes(range.replace(' ', '')),
    );

    return mappedRange || 'non pino product';
};

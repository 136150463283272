import { useMemo } from 'react';

import { MainMenuNavigationLink } from '../../components/SideNavigation';
import { useFlagsContext } from '../flagsContext';

import { MenuItemsProviderProperties } from './menuItemContext.types';
import { MenuItemsContext } from './menuItemsContext';
import { commonMenuItems } from './menuItemsDefaults';

export function MenuItemsProvider({
    children,
    menuItems,
}: MenuItemsProviderProperties) {
    const {
        flags: {
            support_centre_enabled: { enabled: enabledSupportCentre },
            rejoin_journey: { enabled: rejoinJourneyEnabled },
        },
    } = useFlagsContext();
    const contextValue = useMemo(() => {
        const menu = { ...commonMenuItems, ...menuItems };
        const mainMenuWithFlags = menu.mainMenu.filter(
            ({ text }: MainMenuNavigationLink) =>
                text !== 'help & support' || enabledSupportCentre,
        );
        const myAccountMenuWithFlags = menu.myAccount?.filter(
            ({ text }: MainMenuNavigationLink) =>
                text !== 'Rejoin' || rejoinJourneyEnabled,
        );

        const flaggedMenuItems = {
            ...menu,
            mainMenu: mainMenuWithFlags,
            myAccount: myAccountMenuWithFlags,
        };
        return { menuItems: flaggedMenuItems };
    }, [enabledSupportCentre, menuItems, rejoinJourneyEnabled]);
    return (
        <MenuItemsContext.Provider value={contextValue}>
            {children}
        </MenuItemsContext.Provider>
    );
}

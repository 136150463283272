import { useState } from 'react';

import { getFormattedDate } from '@tgg/util';

import {
    StyledAvailabilityParagraph,
    StyledButton,
    StyledChevronIcon,
    StyledContainer,
    StyledDescriptionParagraph,
    StyledDot,
    StyledHeading,
    StyledHeadingContainer,
    StyledHorizontalRule,
    StyledTimeParagraph,
    StyledTrainerParagraph,
} from './TimetableClass.styled';
import { TimetableClassProperties } from './TimetableClass.types';

export const TimetableClass = ({
    className,
    duration,
    startTime,
    availability,
    trainer,
    description,
    placesRation,
}: TimetableClassProperties) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <StyledContainer>
            <StyledHeadingContainer>
                <div>
                    <StyledHeading variant="h2" gutterBottom={false}>
                        {className}
                    </StyledHeading>
                    <StyledTimeParagraph data-testid="timeParagraph">
                        {getFormattedDate(startTime, 'hh:mmaaa')}{' '}
                        <StyledDot>•</StyledDot> {duration} mins
                    </StyledTimeParagraph>
                </div>
                <div>
                    <StyledButton
                        aria-label={isOpen ? 'Hide content' : 'View content'}
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        <StyledChevronIcon
                            $rotated={isOpen}
                            size={[15, 19]}
                            name="chevronRight"
                        />
                    </StyledButton>
                </div>
            </StyledHeadingContainer>

            <StyledHorizontalRule />
            {trainer && (
                <StyledTrainerParagraph data-testid="trainerParagraph">
                    with {trainer}
                </StyledTrainerParagraph>
            )}
            <StyledAvailabilityParagraph>
                <strong>{availability}</strong>
            </StyledAvailabilityParagraph>
            {isOpen && (
                <>
                    <StyledDescriptionParagraph data-testid="descriptionParagraph">
                        {description}
                    </StyledDescriptionParagraph>
                    <StyledAvailabilityParagraph>
                        <strong>class availability: {placesRation}</strong>
                    </StyledAvailabilityParagraph>
                </>
            )}
        </StyledContainer>
    );
};

import { styled } from '@mui/material/styles';

import { theme } from '../../theme';
import { Heading } from '../Heading';

export const StyledWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 45rem;
    padding: 2.5rem;
`;

export const StyledSmallTextContent = styled('p')`
    margin-top: 3rem;

    font-size: small;
    color: ${theme.palette.common.blue};

    text-align: center;
`;

export const StyledHeading = styled(Heading)`
    font-family: thegymgroupsans-Headline, sans-serif;
    font-size: 1.9rem;
    color: ${theme.palette.primary.main};
    text-align: center;
`;

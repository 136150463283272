import { Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { Theme, palette } from '../../../theme';

import { StyledWasPriceProperties } from './WasPrice.types';

const subtleStyle = (theme: Theme) => css`
    font-size: 1rem;
    line-height: 1.6rem;
    color: ${palette.common.blue};
    ${theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }
`;

export const StyledWasPrice = styled(Typography, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledWasPriceProperties>(({ theme, $isSubtle }) => ({
    marginRight: '0.8rem',
    textDecoration: 'line-through',
    ...($isSubtle && subtleStyle(theme)),
}));

import { styled } from '@mui/material/styles';

import { palette, Theme } from '../../theme';
import { HorizontalRule } from '../HorizontalRule';

export const StyledContainer = styled('div')`
    height: 5rem;
`;

export const StyledLinkContainer = styled('div')`
    padding-bottom: ${({ theme }) => theme.spacing(15)};
    padding-left: ${({ theme }) => theme.spacing(30)};
`;

type StyledLinkProperties = {
    $caseSensitive: boolean;
};

export const StyledLink = styled('a')<StyledLinkProperties>`
    padding-right: ${({ theme }) => theme.spacing(10)};
    font-size: 1.4rem;
    font-weight: normal;
    color: ${palette.common.blue};
    text-decoration: none;
    text-transform: ${({ $caseSensitive }) =>
        $caseSensitive ? 'none' : 'lowercase'};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }

    & :hover {
        text-decoration: underline;
    }
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    width: 29rem;
    margin: 0 0 0 3rem;
    background-color: ${palette.grey['900']};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up(375)} {
        width: 34.5rem;
    }
`;

/* eslint-disable @typescript-eslint/no-floating-promises */
import { Wrapper } from '@googlemaps/react-wrapper';
import { useMediaQuery } from '@mui/material';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import {
    EventKey,
    SearchMyLocationCTAPayload,
    SearchMyLocationIconPayload,
    SeeMoreGymsLinkPayload,
    UseMyLocationPayload,
    dispatchEvent,
} from '@tgg/services';

import { palette, theme } from '../../theme';
import { render } from '../GoogleMap/GoogleMap.styled';
import { Icon } from '../Icon';
import { Lister } from '../Lister';

import { useGymsLocation } from './FindAGymComponent.hooks';
import {
    StyledAlertMessage,
    StyledButtonBase,
    StyledButtonLink,
    StyledContainer,
    StyledHeading,
    StyledIcon,
    StyledResultsParagraph,
    StyledSearchInput,
    StyledSearchParagraph,
} from './FindAGymComponent.styled';
import { FindAGymComponentProperties } from './FindAGymComponent.types';

const {
    publicRuntimeConfig: { GOOGLE_MAPS_API_KEY },
} = getConfig();

const handleCTAAction = () => {
    dispatchEvent<SearchMyLocationCTAPayload>(EventKey.CTA_BUTTON, {
        cta_name: 'find your gym',
        component_name: 'find a gym',
    });
};

export const FindAGymComponent = ({
    id,
    searchParagraph,
    gyms,
}: FindAGymComponentProperties) => {
    const router = useRouter();
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const useMyLocationQuery = useRef('');

    const {
        mappedGyms,
        handleSearch,
        searchInputReference,
        userPositionError,
        findMyLocation,
    } = useGymsLocation(gyms);

    const redirect = () =>
        router.push(
            `/find-a-gym/${
                searchInputReference.current?.value
                    ? `?s=${encodeURIComponent(
                          searchInputReference.current?.value,
                      )}`
                    : ''
            }`,
        );

    const handleGymsIconSearch = async () => {
        useMyLocationQuery.current = '';
        if (!searchInputReference.current?.value.trim()) {
            await redirect();
        } else {
            const result = await handleSearch(
                searchInputReference.current?.value,
            );
            dispatchEvent<SearchMyLocationIconPayload>(
                EventKey.FAG_LOCATION_SEARCH,
                {
                    location_search_term: searchInputReference.current?.value,
                    search_results: result.gymsCount,
                    component_name: 'find a gym',
                },
            );
            if (!result.isValidSearch) {
                await redirect();
            }
        }
    };

    const handleGymsCTASearch = async () => {
        handleCTAAction();
        if (!searchInputReference.current?.value.trim()) {
            await redirect();
        } else {
            const result = await handleSearch(
                searchInputReference.current?.value,
            );
            if (!result.isValidSearch) {
                await redirect();
            }
        }
    };

    const handleSeeMoreClick = async () => {
        const locationQuery =
            useMyLocationQuery.current ||
            encodeURIComponent(searchInputReference.current?.value || '');
        const pushURL = `/find-a-gym/?s=${locationQuery}`;
        await router.push(pushURL);
        dispatchEvent<SeeMoreGymsLinkPayload>(EventKey.LINK_CLICK, {
            link_destination: pushURL,
            link_text: 'see more gyms near you',
            component_name: 'find a gym',
        });
    };

    return (
        <StyledContainer id={id}>
            <StyledIcon
                name="gymFinder"
                size={isDesktop ? 60 : 50}
                color={palette.common.blue}
            />
            <StyledHeading variant="h2">find a gym near you</StyledHeading>
            <StyledSearchParagraph>{searchParagraph}</StyledSearchParagraph>
            <Wrapper
                apiKey={GOOGLE_MAPS_API_KEY}
                render={render}
                libraries={['geometry', 'places']}
            >
                <StyledSearchInput
                    onSubmit={handleGymsIconSearch}
                    placeholder="Street, town or postcode"
                    ref={searchInputReference}
                />
                {mappedGyms.length === 0 && userPositionError && (
                    <StyledAlertMessage
                        type="information"
                        text={userPositionError}
                    />
                )}
                {mappedGyms.length === 0 && (
                    <StyledButtonLink
                        onClick={() => {
                            findMyLocation();
                            useMyLocationQuery.current = 'Current+location';
                            dispatchEvent<UseMyLocationPayload>(
                                EventKey.LINK_CLICK,
                                {
                                    link_destination: null,
                                    link_text: 'Use my current location',
                                    component_name: 'find a gym',
                                },
                            );
                        }}
                    >
                        <Icon name="targetLocation" />
                        <span>Use my current location</span>
                    </StyledButtonLink>
                )}
                {mappedGyms.length > 0 && (
                    <>
                        <StyledResultsParagraph gutterBottom={false}>
                            top 3 results
                        </StyledResultsParagraph>
                        <Lister gyms={mappedGyms} isFAG />
                    </>
                )}
            </Wrapper>
            {mappedGyms.length === 0 ? (
                <StyledButtonBase
                    text="find your gym"
                    onClick={handleGymsCTASearch}
                />
            ) : (
                <StyledButtonLink onClick={handleSeeMoreClick}>
                    see more gyms near you
                </StyledButtonLink>
            )}
        </StyledContainer>
    );
};

/* istanbul ignore file */
import { createContext } from 'react';

import { type Promisable } from '@tgg/common-types';
import {
    type OptimizelyEvent,
    type TggFlagsObject,
    defaultFlags,
    CustomOptimizelyEventAttributes,
    FlagsConstants,
} from '@tgg/util';

export type trackFlagsEventHandler = (
    optimizelyEvent: OptimizelyEvent,
    attributes?: CustomOptimizelyEventAttributes,
) => Promisable<void>;

export type FlagsContextProperties = {
    flags: TggFlagsObject;
    flagsUserId: string;
    pathname: string;
    trackFlagsEvent: trackFlagsEventHandler;
};

export const FlagsContext = createContext<FlagsContextProperties>({
    flags: defaultFlags,
    flagsUserId: FlagsConstants.UNRESOLVED,
    trackFlagsEvent: () => {},
    pathname: FlagsConstants.UNRESOLVED,
});

import { type AxiosResponse } from 'axios';

import {
    type ApplicationIdentifier,
    type BillingAddressFields,
} from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import {
    type PostPaymentGatewayResponseBody,
    type PostPaymentGatewayRequestBody,
} from '@tgg/services';
import {
    relativeUrlWithBasePath,
    getFrontendStrongCustomerAuthentication,
    originWithBasePath,
} from '@tgg/util';

import {
    getPaymentUrl,
    getChallangeOutcomeUrl,
} from '../../getPaymentUrls/getPaymentUrl';

export type ProcessPaymentGatewayData = {
    cardIdentifier: string;
    paymentToken: string;
    sessionKey: string;
    termsAccepted: boolean;
    authorisedPayee: boolean;
    billingAddress?: BillingAddressFields;
    updateJourneyName?: string;
    applicationIdentifier: ApplicationIdentifier;
    isReoccurringCreditCard?: boolean;
};

export const processPaymentGateway = async ({
    paymentToken,
    cardIdentifier,
    sessionKey,
    termsAccepted,
    authorisedPayee,
    billingAddress,
    updateJourneyName,
    applicationIdentifier,
    isReoccurringCreditCard,
}: ProcessPaymentGatewayData) => {
    const url = getPaymentUrl(applicationIdentifier);

    const { data: paymentGatewayResponse } = await axiosInstance.post<
        PostPaymentGatewayResponseBody,
        AxiosResponse<PostPaymentGatewayResponseBody>,
        PostPaymentGatewayRequestBody
    >(
        relativeUrlWithBasePath(url),
        {
            cardIdentifier,
            paymentToken,
            sessionKey,
            strongCustomerAuthentication:
                getFrontendStrongCustomerAuthentication(
                    originWithBasePath(window.location.origin),
                    getChallangeOutcomeUrl(applicationIdentifier),
                    updateJourneyName,
                ),
            reoccurringCreditCard: isReoccurringCreditCard,
            termsAccepted,
            authorisedPayee,
            ...(billingAddress && { billingAddress }),
        },
        {
            timeout: 60_000,
        },
    );

    return paymentGatewayResponse;
};

import getConfig from 'next/config';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import {
    addressLineValidator,
    cityTownValidator,
    postcodeValidator,
} from '@tgg/form-validation';
import { getTestId } from '@tgg/util';

import { Asterisk } from '../../Asterisk';
import { TextInput } from '../../Forms/TextInput';
import { StyledLabel } from '../ControlledInputs.styled';
import { ControlledTextInput } from '../ControlledTextInput';

import { useControlledAddressInput } from './ControlledAddressInput.hooks';
import {
    StyledButton,
    StyledAddressWrapper,
    StyledManualAddress,
    StyledSearchAddress,
    StyledHeading,
    StyledAddressLabel,
} from './ControlledAddressInput.styled';
import {
    ADDRESS_FIELDS,
    ControlledAddressInputProperties,
} from './ControlledAddressInput.types';

const {
    publicRuntimeConfig: { LOQATE_API_BASE_URL },
} = getConfig();

export function ControlledAddressInput({
    defaultValues,
    resetField,
    control,
    errors,
    trigger,
    clearErrors,
    getValues,
    reset,
}: ControlledAddressInputProperties) {
    const [isManualAddressInput, setIsManualAddressInput] = useState<boolean>(
        !!defaultValues.addressLine1,
    );

    const { handleLoqateLoad, isLoqateControlVisible } =
        useControlledAddressInput({
            reset,
            getValues,
            setIsManualAddressInput,
            trigger,
        });

    const handleSelectManualInput = () => {
        resetField('searchAddress');
        clearErrors(ADDRESS_FIELDS);
        setIsManualAddressInput(true);
    };

    const handleSelectAutoInput = () => {
        reset({
            ...getValues(),
            ...defaultValues,
        });
        ADDRESS_FIELDS.forEach(field => resetField(field));
        setIsManualAddressInput(false);
    };

    useEffect(() => {
        // if the loqate script was already loaded, add search logic to the searchAddress control
        if (window.pca) {
            handleLoqateLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Script
                id="loqate"
                src={`${LOQATE_API_BASE_URL}/js/address-3.91.js`}
                onLoad={handleLoqateLoad}
            />
            <StyledAddressWrapper>
                <StyledHeading variant="h2" gutterBottom={false}>
                    YOUR HOME ADDRESS
                </StyledHeading>
                <link
                    rel="stylesheet"
                    type="text/css"
                    href={`${LOQATE_API_BASE_URL}/css/address-3.91.css`}
                />
                <StyledSearchAddress
                    className={isManualAddressInput ? 'hidden' : ''}
                    data-testid="search-address-container"
                >
                    <Controller
                        name="searchAddress"
                        control={control}
                        rules={{
                            validate: () => {
                                return (
                                    !ADDRESS_FIELDS.some(field =>
                                        Object.keys(errors).includes(field),
                                    ) ||
                                    'Please select an address or enter manually'
                                );
                            },
                        }}
                        render={({
                            field: { value, onChange, onBlur },
                            fieldState: { invalid, error },
                        }) => {
                            return (
                                <>
                                    <StyledAddressLabel htmlFor="searchAddress">
                                        Address
                                        <Asterisk spaceBefore />
                                    </StyledAddressLabel>
                                    <TextInput
                                        onBlur={() => {
                                            return (
                                                !isLoqateControlVisible() &&
                                                onBlur()
                                            );
                                        }}
                                        onClick={async () =>
                                            // eslint-disable-next-line no-void
                                            void trigger(ADDRESS_FIELDS)
                                        }
                                        value={value}
                                        id="searchAddress"
                                        autoComplete="none"
                                        onChange={onChange}
                                        isInvalid={invalid}
                                        errorMessage={error?.message}
                                        placeholder="Start typing your address..."
                                    />
                                </>
                            );
                        }}
                    />

                    <StyledButton
                        onClick={handleSelectManualInput}
                        buttonStyle="tertiary"
                        text="Enter address manually"
                        data-testid={getTestId('enter-address-manually-button')}
                    />
                </StyledSearchAddress>
                <StyledManualAddress
                    className={isManualAddressInput ? '' : 'hidden'}
                    data-testid="manual-address-container"
                >
                    <ControlledTextInput
                        rules={{
                            validate: {
                                validator: value => {
                                    if (value.trim().length === 0) {
                                        return 'Your address is required';
                                    }

                                    return addressLineValidator(value);
                                },
                            },
                        }}
                        isRequired
                        control={control}
                        id="addressLine1"
                        name="addressLine1"
                        label="Address Line 1"
                        placeholder="Address Line 1"
                    />
                    <ControlledTextInput
                        rules={{
                            validate: {
                                validator: addressLineValidator,
                            },
                        }}
                        control={control}
                        id="addressLine2"
                        name="addressLine2"
                        label="Address Line 2"
                        placeholder="Address Line 2"
                    />
                    <ControlledTextInput
                        rules={{
                            validate: {
                                validator: addressLineValidator,
                            },
                        }}
                        control={control}
                        id="addressLine3"
                        name="addressLine3"
                        label="Address Line 3"
                        placeholder="Address Line 3"
                    />

                    <ControlledTextInput
                        rules={{
                            validate: {
                                validator: cityTownValidator,
                            },
                        }}
                        isRequired
                        id="cityTown"
                        name="cityTown"
                        control={control}
                        label="City / Town"
                        placeholder="City / Town"
                    />

                    <Controller
                        rules={{
                            validate: {
                                validator: postcodeValidator,
                            },
                        }}
                        name="postcode"
                        control={control}
                        render={({
                            field: { value, onChange, onBlur },
                            fieldState: { invalid, isDirty, error },
                        }) => {
                            return (
                                <>
                                    <StyledLabel
                                        $hasPaddingTop
                                        htmlFor="postcode"
                                    >
                                        Postcode <Asterisk spaceBefore />
                                    </StyledLabel>
                                    <TextInput
                                        iconElementRight={{
                                            name:
                                                !invalid && isDirty
                                                    ? 'tick'
                                                    : 'blank',
                                        }}
                                        id="postcode"
                                        value={value}
                                        onBlur={onBlur}
                                        isInvalid={invalid}
                                        onChange={onChange}
                                        placeholder="Postcode"
                                        errorMessage={error?.message}
                                    />
                                </>
                            );
                        }}
                    />
                    <StyledButton
                        onClick={handleSelectAutoInput}
                        buttonStyle="tertiary"
                        text="Search new address"
                    />
                </StyledManualAddress>
            </StyledAddressWrapper>
        </>
    );
}

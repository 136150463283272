/* eslint-disable import/no-extraneous-dependencies */
import 'react-phone-number-input/style.css';

import { TextInput } from '../TextInput';

import { StyledPhoneInput } from './TelephoneInput.styled';
import { TelephoneInputProperties } from './TelephoneInput.types';

/**
 * A telephone number input supporting country codes
 *
 * The number defaults to the +44 country code for GB
 */
export const TelephoneInput = ({
    value,
    hasMarginTop,
    placeholder = 'Enter phone number',
    onChange,
    ...rest
}: TelephoneInputProperties) => {
    return (
        <StyledPhoneInput
            defaultCountry="GB"
            international
            placeholder={placeholder}
            value={value}
            inputComponent={TextInput}
            $hasMarginTop={hasMarginTop}
            focusInputOnCountrySelection={false}
            onChange={(inputValue?: string) => {
                onChange(inputValue || '');
            }}
            {...rest}
        />
    );
};

export default TelephoneInput;

import { palette } from '../../theme';
import { getPromoTimes } from '../../utils';
import { CountdownTimer } from '../CountdownTimer';
import { Paragraph } from '../Paragraph';
import { PromoCode, PromoCodeSize, PromoCodeVariant } from '../PromoCode';
import { Tile } from '../Tile';

import {
    StyledContentContainer,
    StyledWrapper,
    StyledParagraph,
    StyledDealIcon,
    StyledRule,
} from './PromoCountdownTile.styled';
import { PromoCountdownTileProperties } from './PromoCountdownTile.types';

export const PromoCountdownTile = ({
    id,
    title,
    message,
    promoCode = '',
    promoStartDate,
    promoEndDate,
    className,
}: PromoCountdownTileProperties) => {
    const now = Date.now();
    const { startDate, endDate } = getPromoTimes(promoStartDate, promoEndDate);

    const copyPromoCode = () => {
        // Ignoring as not supporting IE.
        // eslint-disable-next-line compat/compat
        return navigator.clipboard.writeText(promoCode);
    };

    const getDuration = () => {
        // If end date is in the past just show 00's
        if (endDate < now) return 0;

        // If start date is in the future, show diff between
        if (startDate > now) return (endDate - startDate) / 1000;

        // If start date is in the past, countdown to end date
        return (endDate - now) / 1000;
    };

    const duration = getDuration();
    // If start date is in the past and duration is not 0, play.
    const isPlaying = !!startDate && startDate < now && duration !== 0;

    return (
        <StyledWrapper id={id}>
            <Tile title={title} className={className} barType="promo">
                <StyledContentContainer>
                    {message && <Paragraph>{message}</Paragraph>}
                    <CountdownTimer isPlaying={isPlaying} duration={duration} />
                    {promoCode && (
                        <>
                            <StyledRule />
                            <StyledDealIcon
                                name="deals"
                                color={palette.primary.main}
                            />
                            <StyledParagraph>Use code:</StyledParagraph>
                            <PromoCode
                                promoCode={promoCode}
                                onClick={copyPromoCode}
                                variant={PromoCodeVariant.NAVY}
                                size={PromoCodeSize.LARGE}
                            />
                        </>
                    )}
                </StyledContentContainer>
            </Tile>
        </StyledWrapper>
    );
};

export default PromoCountdownTile;

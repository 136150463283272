import { dispatchEvent, EventKey } from '@tgg/services';
import { isFutureDate } from '@tgg/util';

import { Icon } from '../Icon';

import { useGetAnchorLinksNavigation } from './AnchorLinks.hooks';
import {
    StyledAnchorLink,
    StyledAnchorWrapper,
    StyledBottomLine,
    StyledButton,
    StyledContainer,
    StyledScrollContainer,
} from './AnchorLinks.styled';
import { AnchorLink, AnchorLinksProperties } from './AnchorLinks.types';

export const AnchorLinks = ({
    id,
    links,
    promoSnackBarOptions,
}: AnchorLinksProperties) => {
    const {
        showChevrons,
        scrollContainer,
        containerReference,
        handleClick,
        activeLink,
    } = useGetAnchorLinksNavigation(links);

    const isFuturePromoStartDate = isFutureDate(
        promoSnackBarOptions?.promoStartDate || '',
    );

    const topHeightMobile = promoSnackBarOptions?.isSticky
        ? promoSnackBarOptions?.promoStartDate && !isFuturePromoStartDate
            ? '14rem'
            : '9.5rem'
        : '5.9rem';

    const topHeightDesktop = promoSnackBarOptions?.isSticky
        ? promoSnackBarOptions?.promoStartDate && !isFuturePromoStartDate
            ? '16.15rem'
            : '11.8rem'
        : '7.1rem';

    return (
        <StyledContainer
            id={id}
            data-testid="anchor-links"
            $topHeightDesktop={topHeightDesktop}
            $topHeightMobile={topHeightMobile}
            data-is-sticky={true}
        >
            {showChevrons && (
                <StyledButton onClick={() => scrollContainer('left')}>
                    <Icon name="chevronLeft" />
                </StyledButton>
            )}
            <StyledScrollContainer ref={containerReference}>
                <StyledBottomLine />
                {links.map((link: AnchorLink) => (
                    <StyledAnchorWrapper
                        key={link.title}
                        $activeState={activeLink === link.path}
                    >
                        <StyledAnchorLink
                            href={link.path}
                            onClick={(event: {
                                preventDefault: () => void;
                            }) => {
                                event.preventDefault();
                                handleClick(link.path);
                                void dispatchEvent(EventKey.ANCHOR_LINK_CLICK, {
                                    anchor_link: link.title,
                                });
                            }}
                        >
                            {link.title}
                        </StyledAnchorLink>
                    </StyledAnchorWrapper>
                ))}
            </StyledScrollContainer>
            {showChevrons && (
                <StyledButton onClick={() => scrollContainer('right')}>
                    <Icon name="chevronRight" />
                </StyledButton>
            )}
        </StyledContainer>
    );
};

import { styled } from '@mui/material/styles';

import { Heading } from '../Heading';

import { StyledSnackBarProperties } from './SnackBar.types';

export const StyledSnackBar = styled('div')<StyledSnackBarProperties>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3.6rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        min-height: 4.6rem;
    }
    color: ${({ $colors }) => $colors.textColor};
    text-align: center;
    background-color: ${({ $colors }) => $colors.background};

    svg {
        margin-right: ${({ theme }) => theme.spacing(10)};
    }
`;

export const StyledHeading = styled(Heading, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledSnackBarProperties>`
    color: ${({ $colors }) => $colors.textColor};
`;

import { Svg } from '../Svg';

import { logoPaths } from './Logo.paths';
import { LogoProperties } from './Logo.types';

/**
 * Use the `Logo` component whenever you need to display the Gym Group logo.
 * There are multiple variations of the Logo so you may want to consider which is most appropriate for each use case.
 */
export const Logo = ({ width, height, viewBox, logoType }: LogoProperties) => {
    const dimensions = {
        negative: {
            width: width || 270,
            height: height || 42,
            viewBox: viewBox || '0 0 270 42',
        },
        positive: {
            width: width || 270,
            height: height || 42,
            viewBox: viewBox || '0 0 270 42',
        },
        positiveStacked: {
            width: width || 297,
            height: height || 136,
            viewBox: viewBox || '0 0 297 136',
        },
        negativeStacked: {
            width: width || 297,
            height: height || 136,
            viewBox: viewBox || '0 0 297 136',
        },
        blackStacked: {
            width: width || 299,
            height: height || 137,
            viewBox: viewBox || '0 0 299 137',
        },
        positiveNoText: {
            width: width || 200,
            height: height || 200,
            viewBox: viewBox || '0 0 900 900',
        },
    };

    return (
        <Svg
            width={dimensions[logoType].width}
            height={dimensions[logoType].height}
            viewBox={dimensions[logoType].viewBox}
            data-testid="logo"
        >
            {logoPaths[logoType]}
        </Svg>
    );
};

import useMediaQuery from '@mui/material/useMediaQuery';

import { relativeUrlWithBasePath } from '@tgg/util';

import { palette, theme } from '../../theme';
import { Heading } from '../Heading';
import { Icon } from '../Icon';

import {
    StyledContainer,
    StyledIcon,
    StyledDescription,
    StyledText,
    StyledAppStoreBadges,
} from './DownloadApp.styled';
import { DownloadAppProperties } from './DownloadApp.types';

export const DownloadApp = ({
    customSubTitle = 'unlock all features',
    isShowTextContent = true,
    showBorderRadius = true,
    appStoreLinkProps,
    googlePlayLinkProps,
}: DownloadAppProperties) => {
    const { primary } = palette;
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const iconSize = isDesktop ? 40 : 36;
    const iconColor = primary.main;

    return (
        <StyledContainer
            $useAsTile={isShowTextContent}
            $showBorderRadius={showBorderRadius}
        >
            {isShowTextContent && (
                <StyledText>
                    <StyledIcon>
                        <Icon name="mobile" size={iconSize} color={iconColor} />
                    </StyledIcon>
                    <div>
                        <Heading variant="h4" gutterBottom={false}>
                            {customSubTitle}
                        </Heading>

                        <StyledDescription>
                            Download the gym group app
                        </StyledDescription>
                    </div>
                </StyledText>
            )}
            <StyledAppStoreBadges>
                <a
                    href="https://apps.apple.com/gb/app/the-gym/id1444707310"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="App Store app link"
                    {...appStoreLinkProps}
                >
                    <img
                        src={relativeUrlWithBasePath(
                            '/ywnqh/images/app_store_badge.svg',
                        )}
                        alt="App Store badge"
                    />
                </a>
                <a
                    href="https://play.google.com/store/apps/details?id=com.netpulse.mobile.thegymgroup&amp;hl=en_GB&amp;gl=US"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Play Store app link"
                    {...googlePlayLinkProps}
                >
                    <img
                        src={relativeUrlWithBasePath(
                            '/ywnqh/images/google_play_badge.svg',
                        )}
                        alt="Play Store badge"
                    />
                </a>
            </StyledAppStoreBadges>
        </StyledContainer>
    );
};

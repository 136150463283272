import { ControlledCheckboxInput } from '../../ControlledInputs/ControlledCheckboxInput';
import { Heading } from '../../Heading';

import {
    StyledTickboxContainer,
    StyledErrorMessage,
    StyledContainer,
    StyledParagraph,
} from './StayInTouch.styled';
import { StayInTouchProperties } from './StayInTouch.types';
import { stayInTouchValidation } from './utils';

const defaultHeading = 'Stay in Touch';
const defaultDescription =
    'Keep up to date with information about your membership, relevant offers and news. Choose how you would like to be contacted. You can always update your preferences in the Member Area.';

export function StayInTouch({
    control,
    emailFieldName = 'emailOptIn',
    emailLabel = 'Email',
    smsFieldName = 'smsOptIn',
    smsLabel = 'SMS',
    rules,
    heading,
    description,
    getValues,
    getFieldState,
}: StayInTouchProperties) {
    const emailState = getFieldState(emailFieldName);
    const smsState = getFieldState(smsFieldName);
    const checkboxError = emailState.error ?? smsState.error;
    const validator = stayInTouchValidation({
        getValues,
        emailFieldName,
        smsFieldName,
    });
    const defaultRules = {
        validate: { validator },
    };

    return (
        <StyledContainer>
            <Heading variant="h2" alignOnDesktop="center">
                {heading ?? defaultHeading}
            </Heading>
            <StyledParagraph>
                {description ?? defaultDescription}
            </StyledParagraph>
            <StyledTickboxContainer>
                <ControlledCheckboxInput
                    control={control}
                    name={emailFieldName}
                    rules={rules ?? defaultRules}
                    label={emailLabel}
                />
                <ControlledCheckboxInput
                    control={control}
                    name={smsFieldName}
                    rules={rules ?? defaultRules}
                    label={smsLabel}
                />
            </StyledTickboxContainer>
            {checkboxError && (
                <StyledErrorMessage>
                    Please select at least one option
                </StyledErrorMessage>
            )}
        </StyledContainer>
    );
}

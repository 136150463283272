import useMediaQuery from '@mui/material/useMediaQuery';
import React, { forwardRef } from 'react';

import { theme } from '../../../theme';

import {
    StyledSmallButton,
    StyledPrimaryButton,
    SecondaryStyledButton,
    StyledTertiaryButton,
} from './Button.styled';
import { ButtonProperties } from './Button.types';

/**
 * Use the `Button` component for any call to action.
 */
export const ButtonBase = forwardRef(
    (
        {
            id,
            text,
            buttonStyle = 'primary',
            size = 'medium',
            onClick,
            fullWidth,
            className,
            ...otherParameters
        }: ButtonProperties,
        reference?: React.ForwardedRef<HTMLButtonElement>,
    ) => {
        const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

        const clickHandler = (
            event: React.MouseEvent<
                HTMLAnchorElement | HTMLButtonElement,
                MouseEvent
            >,
        ) => {
            if (onClick) {
                onClick(event);
            }
        };

        const commonButtonProperties = {
            $buttonStyle: buttonStyle,
            $size: size,
            id,
            onClick: clickHandler,
            ref: reference,
            fullWidth: fullWidth ?? !isDesktop,
            className,
            ...otherParameters,
        };

        if (size.includes('small')) {
            return (
                <StyledSmallButton {...commonButtonProperties}>
                    {text}
                </StyledSmallButton>
            );
        }

        if (buttonStyle.includes('primary')) {
            return (
                <StyledPrimaryButton {...commonButtonProperties}>
                    {text}
                </StyledPrimaryButton>
            );
        }

        if (buttonStyle.includes('secondary')) {
            return (
                <SecondaryStyledButton
                    {...commonButtonProperties}
                    variant="outlined"
                >
                    {text}
                </SecondaryStyledButton>
            );
        }

        return (
            <StyledTertiaryButton {...commonButtonProperties} variant="text">
                {text}
            </StyledTertiaryButton>
        );
    },
);

ButtonBase.displayName = 'Button';

export default ButtonBase;

import { useMediaQuery } from '@mui/material';
import { PayPalButtons, PayPalMessages } from '@paypal/react-paypal-js';
import { useRef, useState } from 'react';

import { theme } from '@tgg/ui';

import { StyledContainer } from './PayPal.styled';
import { PayPalProperties } from './PayPal.types';
import {
    handleApprove,
    handleCreateOrder,
    handleError,
} from './utils/paypalHelpers';

export function PayPal({
    isDisabled,
    wref,
    paymentToken,
    termsAccepted,
    authorisedPayee,
    onErrorCallback,
    loggerMetaData,
    showPayPalPayIn3,
    paypalCaptureOrder,
    paypalCreateOrder,
    updatePaymentReference,
    logger,
    payPalPaymentPayload,
    returnUrl,
    confirmationUrl,
}: PayPalProperties) {
    const orderReference = useRef('');
    const isAuthorised = termsAccepted && authorisedPayee;
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const defaultMessageTextAlign = isDesktop ? 'center' : 'left';
    const [updatedPaymentToken, setUpdatedPaymentToken] =
        useState(paymentToken);

    return (
        <>
            <PayPalButtons
                disabled={isDisabled}
                forceReRender={[isAuthorised]}
                fundingSource={showPayPalPayIn3 ? 'paylater' : 'paypal'}
                style={{
                    layout: 'vertical',
                    color: 'gold',
                    shape: 'pill',
                    label: 'paypal',
                    tagline: false,
                }}
                createOrder={async () => {
                    const { orderId, orderReference: reference } =
                        await handleCreateOrder({
                            wref,
                            updatedPaymentToken,
                            updatePaymentReference,
                            logger,
                            loggerMetaData,
                            payPalPaymentPayload,
                            paypalCreateOrder,
                            returnUrl,
                            setUpdatedPaymentToken,
                        });
                    orderReference.current = reference;
                    return orderId;
                }}
                onApprove={async (data, _actions) => {
                    await handleApprove({
                        data,
                        orderReference: orderReference.current,
                        paypalCaptureOrder,
                        paymentInfo: {
                            authorisedPayee,
                            termsAccepted,
                            token: updatedPaymentToken,
                        },
                        logger,
                        loggerMetaData,
                        confirmationUrl,
                    });
                }}
                onError={error =>
                    handleError(error, logger, loggerMetaData, onErrorCallback)
                }
            />
            {showPayPalPayIn3 && (
                <StyledContainer>
                    <PayPalMessages
                        style={{
                            layout: 'text',
                            logo: {
                                type: 'alternative',
                                position: 'left',
                            },
                            text: {
                                size: 13,
                                align: defaultMessageTextAlign,
                            },
                        }}
                        amount={Number(
                            payPalPaymentPayload?.payments?.today?.total,
                        )}
                        currency="GBP"
                        forceReRender={[isAuthorised]}
                    />
                </StyledContainer>
            )}
        </>
    );
}

export default PayPal;

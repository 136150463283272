import { styled } from '@mui/material/styles';

import { palette } from '../../theme';

export const StyledLinkWrapper = styled('a')`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
`;

export const StyledIconWrapper = styled('div')`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${({ theme }) => `${theme.spacing(42)}`};
    height: ${({ theme }) => `${theme.spacing(42)}`};
    background-color: ${palette.primary.main};
    border-radius: 50%;

    &:hover {
        background-color: ${palette.primary.dark};
    }
`;

export const StyledSpan = styled('span')`
    margin-top: 1.2rem;
    font-size: 1rem;
    line-height: 1.4;
    color: ${palette.common.blue};
`;

/* eslint-disable react/sort-comp */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppProps } from 'next/app';
import * as React from 'react';

import { createTraceParameters } from '@tgg/util';

import {
    WithApplicationInsightsConfig,
    WithApplicationInsightsProperties,
} from './applicationInsights.types';

export function withApplicationInsights(config: WithApplicationInsightsConfig) {
    return (App: any) => {
        return class WithApplicationInsights extends React.Component<
            WithApplicationInsightsProperties & AppProps
        > {
            public appInsights?: ApplicationInsights;

            public override componentDidMount() {
                this.initializeAppInsights();
                this.trackPageView();
            }

            public override componentDidCatch(error: Error) {
                if (this.appInsights) {
                    const loggerParameters = createTraceParameters({
                        context: null,
                        parameters: {
                            operation: 'Root Error Boundary',
                        },
                    });

                    this.setState(null);
                    this.appInsights.trackException(
                        { exception: error },
                        loggerParameters,
                    );
                }
            }

            public initializeAppInsights() {
                if (config.isEnabled && !this.appInsights) {
                    this.appInsights = new ApplicationInsights({ config });
                    this.appInsights.loadAppInsights();
                    window.appInsights = this.appInsights;
                }
            }

            public trackPageView() {
                if (this.appInsights) {
                    const {
                        Component,
                        router: { route },
                    } = this.props;
                    const { displayName, name } = Component;
                    // eslint-disable-next-line no-restricted-globals
                    const pathName = location.pathname;
                    const documentTitle = displayName || name || pathName;
                    const properties = { route };

                    this.appInsights.trackPageView({
                        name: documentTitle,
                        properties,
                    });
                }
            }

            public override render() {
                this.trackPageView();
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return React.createElement(App, this.props);
            }
        };
    };
}

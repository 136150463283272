import { styled } from '@mui/material';

import { palette, Theme } from '../../theme';

export const StyledContainer = styled('div')<{
    $topHeightMobile: string;
    $topHeightDesktop: string;
}>`
    position: sticky;
    top: ${({ $topHeightMobile }) => $topHeightMobile};
    z-index: 2;
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 1.5rem 0.8rem 1.7rem;
    overflow: hidden;
    background-color: ${palette.common.white};
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        top: ${({ $topHeightDesktop }) => $topHeightDesktop};
        justify-content: center;
    }
`;

export const StyledScrollContainer = styled('div')`
    display: flex;
    gap: 3rem;
    align-items: center;
    padding: 0 0.8rem;
    overflow-x: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar: {
        display: none;
    }
    ${({ theme }: { theme: Theme }) => theme.breakpoints.down('desktop')} {
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
    }
`;

export const StyledAnchorLink = styled('a')`
    min-width: fit-content;
    max-width: 100%;
    padding: 0.8rem 0;
    font-size: 1.6rem;
    font-weight: 500;
    color: ${palette.common.blue};
    text-decoration: none;
    text-transform: none;
    white-space: nowrap;
    cursor: pointer;
    background-color: ${palette.common.white};
    border: none;
`;

export const StyledBottomLine = styled('div')`
    position: absolute;
    bottom: 1.7rem;
    left: 0;
    width: 100%;
    height: 0.6rem;
    background-color: ${palette.primary.main}20;
`;

export const StyledAnchorWrapper = styled('div')<{ $activeState: boolean }>`
    position: relative;
    display: flex;
    padding-bottom: 0.9rem;
    border-bottom: 0.6rem solid
        ${({ $activeState }) =>
            $activeState ? palette.primary.main : 'transparent'};
`;

export const StyledButton = styled('button')`
    display: flex;
    align-items: center;
    padding: 0.6rem 2rem;
    cursor: pointer;
    background: none;
    border: none;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down('desktop')} {
        display: none;
    }
`;

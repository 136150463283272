import { ButtonBase } from '../../Button';

import {
    StyledWrapperButton,
    StyledContainerButton,
} from './ButtonStandalone.styled';
import { ButtonStandaloneProperties } from './ButtonStandalone.types';

/**
 * Use the `ButtonStandalone` component for any call to action.
 */
export const ButtonStandaloneBase = ({
    text,
    href,
    buttonStyle = 'primary',
    fullWidth = false,
    onClick,
}: ButtonStandaloneProperties) => (
    <StyledContainerButton
        $fullWidth={fullWidth}
        data-testid="standaloneButtonStyledWrapper"
    >
        <StyledWrapperButton>
            <ButtonBase
                text={text}
                href={href}
                buttonStyle={buttonStyle}
                onClick={onClick}
            />
        </StyledWrapperButton>
    </StyledContainerButton>
);

ButtonStandaloneBase.displayName = 'ButtonStandalone';

export default ButtonStandaloneBase;

import { PinoProductTitle } from '@tgg/common-types';

import { Palette, palette } from '../theme';

export type PinoColors = {
    [key in PinoProductTitle]: PinoColor;
};

type PinoColor = {
    primary: Palette;
    alternate: string;
    contentColor: Palette;
};

export const getPinoColor = (
    productTitle: PinoProductTitle,
    isDesktop?: boolean,
): PinoColor => {
    const { common, pino } = palette;

    const colors: PinoColors = {
        ultimate: {
            primary: pino.ultimate,
            alternate: pino.ultimateAlternate,
            contentColor: pino.ultimate,
        },
        standard: {
            primary: pino.standard,
            alternate: pino.standardAlternate,
            contentColor: pino.standard,
        },
        'off-peak': {
            primary: pino.offPeak,
            alternate: pino.offPeakAlternate,
            contentColor: common.blue,
        },
        membershipStudent: {
            primary: common.blue,
            alternate: pino.standardAlternate,
            contentColor: common.blue,
        },
        dayPass: {
            primary: common.blue,
            alternate: isDesktop ? common.white : pino.standardAlternate,
            contentColor: common.blue,
        },
    };

    return colors[productTitle];
};

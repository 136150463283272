import { styled } from '@mui/material/styles';

export const StyledContainer = styled('div')`
    display: flex;
    flex-flow: column;
    gap: 2rem;
    align-items: center;
    max-width: 25.5rem;
`;

export const StyledButtonsContainer = styled('div')`
    display: flex;
    flex-flow: column;
`;

export const StyledOverlayText = styled('div')`
    text-align: center;
`;

export const IconWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 8rem;
    background-color: #e5effa;
    border-radius: 100%;
`;

export const ButtonsContainer = styled('div')`
    display: flex;
    flex-flow: column;
    gap: 0.8rem;
    width: 100%;
`;

import { styled } from '@mui/material/styles';

import { Icon } from '../Icon';

import { StyledLinkProperties, StyledButtonProperties } from './IconLink.types';

export const StyledLink = styled('a')<StyledLinkProperties>`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 6.4rem;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    background: ${({ $background }) => $background};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 7.2rem;
    }
`;

export const StyledButton = styled('button')<StyledButtonProperties>`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 6.4rem;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    background: transparent;
    border: none;

    &:hover {
        background: ${({ $background }) => $background};
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 7.2rem;
        padding-top: 1.4rem;
        padding-bottom: 1.5rem;
    }
`;

export const StyledIcon = styled(Icon)`
    && {
        cursor: pointer;
    }
`;

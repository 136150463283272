import { Card } from './Card';
import { ImageCard } from './ImageCard';
import { StyledPill } from './MembershipInfo.styled';
import { MembershipInfoProperties } from './MembershipInfo.types';
import { MiniCard } from './MiniCard';

export const MembershipInfo = ({
    title,
    subTitle,
    paragraph,
    additionalGyms,
    addonCards,
    iconRight,
    accessibleTitleForIconRight,
    iconRightLink,
    pillText,
}: MembershipInfoProperties) => {
    const isAddonCards = addonCards && addonCards.length > 0;
    return (
        <Card
            title={title}
            subTitle={subTitle}
            paragraph={paragraph}
            iconRight={iconRight}
            iconRightLink={iconRightLink}
            $hasExtraMarginTop={pillText ? true : false}
            accessibleTitleForIconRight={accessibleTitleForIconRight}
        >
            {additionalGyms && additionalGyms.length > 0 && (
                <MiniCard titles={additionalGyms} />
            )}
            {pillText && <StyledPill aria-hidden>{pillText}</StyledPill>}

            {isAddonCards && (
                <ImageCard addons={addonCards} pillText={pillText} />
            )}
        </Card>
    );
};

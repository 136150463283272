export const getPaymentGatewayCardIdentifier = async ({
    merchantSessionKey,
    nameOnCard,
    cardNumber,
    expiryDate,
    securityCode,
}: {
    merchantSessionKey: string;
    nameOnCard: string;
    cardNumber: string;
    expiryDate: string;
    securityCode: string;
}): Promise<string> =>
    new Promise(
        (
            resolve,
            reject: (reason?: {
                [index: number]: {
                    code: number;
                    message: string;
                };
            }) => void,
        ) => {
            window
                .sagepayOwnForm({
                    merchantSessionKey,
                })
                .tokeniseCardDetails({
                    cardDetails: {
                        cardholderName: nameOnCard,
                        cardNumber: cardNumber.replaceAll(' ', ''), // Clear spaces
                        expiryDate: expiryDate.replaceAll(/\/| /g, ''), // Clear spaces and remove "/"
                        securityCode,
                    },
                    onTokenised: result => {
                        if (result.success === false) {
                            reject(result.errors);
                        } else {
                            resolve(result.cardIdentifier);
                        }
                    },
                });
        },
    );

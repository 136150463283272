import { useMediaQuery } from '@mui/material';

import { theme } from '../../theme';

import {
    StyledAdditionalContent,
    StyledContent,
    StyledHeading,
    StyledImageStandalone,
    StyledPill,
    StyledRichTextContent,
    StyledWrapper,
    ToggleButton,
} from './AddOnBox.styled';
import { AddOnBoxProperties } from './AddOnBox.types';
import { AddOnPriceInfo } from './AddOnPriceInfo';
import { StyledDescriptionContent } from './AddOnPriceInfo.styled';

export const AddOnBox = ({
    addOn,
    pill,
    onToggle,
    isChecked = false,
    disabled = false,
}: AddOnBoxProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const handleClick = () => {
        onToggle({ isChecked: !isChecked, addOn });
    };

    return (
        <StyledWrapper>
            {addOn.image?.image && (
                <StyledImageStandalone
                    {...addOn.image}
                    $size="small"
                    data-testid="add-on-image"
                />
            )}
            <StyledContent>
                <StyledDescriptionContent>
                    <StyledHeading variant="h3">{addOn.title}</StyledHeading>
                    <StyledRichTextContent text={addOn.description} fullWidth />

                    {pill && <StyledPill>{pill}</StyledPill>}

                    <StyledAdditionalContent>
                        {<AddOnPriceInfo addOn={addOn} />}
                        {!isDesktop && (
                            <ToggleButton
                                disabled={disabled}
                                data-testid={`add-on-switch-button-${addOn.tggMembershipType}`}
                                onClick={handleClick}
                                checked={isChecked}
                            />
                        )}
                    </StyledAdditionalContent>
                </StyledDescriptionContent>
                {isDesktop && (
                    <ToggleButton
                        disabled={disabled}
                        data-testid={`add-on-switch-button-${addOn.tggMembershipType}`}
                        onClick={handleClick}
                        checked={isChecked}
                    />
                )}
                {pill && <StyledPill $vertical={true}>{pill}</StyledPill>}
            </StyledContent>
        </StyledWrapper>
    );
};

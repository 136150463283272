import { useState } from 'react';

import { PaymentMethod } from '@tgg/common-types';
import { MembershipAgreement } from '@tgg/ui';

import { PayPal } from './PayPal';
import { StyledPaypalWrapper } from './PayPalPayment.styled';
import { PayPalPaymentProperties } from './PayPalPayment.types';

export function PayPalPayment({
    expectedPaymentFrequency,
    wref,
    accountData,
    paymentToken,
    agreementTypes,
    onErrorCallback,
    loggerMetaData,
    showPayPalPayIn3,
    paypalCaptureOrder,
    paypalCreateOrder,
    updatePaymentReference,
    logger,
    payPalPaymentPayload,
    returnUrl,
    confirmationUrl,
}: PayPalPaymentProperties) {
    const [
        hasAcceptedAllTermsAndConditions,
        setHasAcceptedAllTermsAndConditions,
    ] = useState<boolean>(false);

    return (
        <>
            <MembershipAgreement
                agreementTypes={agreementTypes}
                selectedPaymentMethod={
                    showPayPalPayIn3
                        ? PaymentMethod.PaypalPayIn3
                        : PaymentMethod.Paypal
                }
                accountData={accountData}
                expectedPaymentFrequency={expectedPaymentFrequency}
                wref={wref}
                onTermsAndConditionsAccepted={
                    setHasAcceptedAllTermsAndConditions
                }
            />
            <StyledPaypalWrapper>
                <PayPal
                    isDisabled={!hasAcceptedAllTermsAndConditions}
                    wref={wref}
                    paymentToken={paymentToken}
                    termsAccepted={hasAcceptedAllTermsAndConditions}
                    authorisedPayee={hasAcceptedAllTermsAndConditions}
                    onErrorCallback={onErrorCallback}
                    loggerMetaData={loggerMetaData}
                    showPayPalPayIn3={showPayPalPayIn3}
                    paypalCaptureOrder={paypalCaptureOrder}
                    paypalCreateOrder={paypalCreateOrder}
                    updatePaymentReference={updatePaymentReference}
                    logger={logger}
                    payPalPaymentPayload={payPalPaymentPayload}
                    returnUrl={returnUrl}
                    confirmationUrl={confirmationUrl}
                />
            </StyledPaypalWrapper>
        </>
    );
}

import { useEffect, useState } from 'react';

import { Nullable } from '@tgg/common-types';
import {
    AVAILABLE_COOKIES,
    FlagsConstants,
    deleteCookie,
    getCookie,
    getOptimizelyUserIdClientSide,
    userIdentifierCookieApi,
} from '@tgg/util';

export const useUserIdentification = (config: {
    flagsUserId: Nullable<string>;
    pathname: string;
    useOptimizelyProxy: boolean;
}) => {
    const { flagsUserId, pathname, useOptimizelyProxy } = config;
    const [resolvedUserId, setResolvedUserId] = useState(flagsUserId);
    const [isUserInitialized, setIsUserInitialized] = useState(false);

    useEffect(() => {
        if (!isUserInitialized) {
            if (
                getCookie<string>({
                    cookieKey: AVAILABLE_COOKIES.USER_IDENTIFIER_DEPRECATED,
                }) !== null
            )
                deleteCookie({
                    cookieKey: AVAILABLE_COOKIES.USER_IDENTIFIER_DEPRECATED,
                    cookieParameters: {},
                });

            const { resolvedId: userIdFromCookie } =
                getOptimizelyUserIdClientSide({
                    shouldReset: useOptimizelyProxy,
                });

            // resolve cookie id when be id not present
            if (resolvedUserId === FlagsConstants.UNRESOLVED || !resolvedUserId)
                setResolvedUserId(userIdFromCookie);

            // prioritize cookie id when using OptimizelyReactSdk
            if (
                useOptimizelyProxy &&
                resolvedUserId !== userIdFromCookie &&
                userIdFromCookie !== FlagsConstants.UNRESOLVED
            )
                setResolvedUserId(userIdFromCookie);

            // prioritize be id when not using OptimizelyReactSdk
            if (
                !useOptimizelyProxy &&
                resolvedUserId &&
                resolvedUserId !== userIdFromCookie &&
                resolvedUserId !== FlagsConstants.UNRESOLVED
            )
                userIdentifierCookieApi.browser.setCookie(resolvedUserId);

            setIsUserInitialized(true);
        }
    }, [resolvedUserId, isUserInitialized, pathname, useOptimizelyProxy]);

    return {
        isUserInitialized,
        resolvedUserId: resolvedUserId as string,
    };
};

import { css, styled } from '@mui/material/styles';
import Image from 'next/legacy/image';

import { Theme, palette, containerLargeWidth } from '../../theme';
import { Heading } from '../Heading';

export const StyledWrapper = styled('div')`
    padding-top: 5rem;
    padding-bottom: 3rem;
    margin-bottom: 0.8rem;
    text-align: center;
    background-color: ${palette.common.white};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding-top: 10rem;
        padding-bottom: 8rem;
    }
`;

export const StyledImageContainer = styled('div')`
    position: relative;
    width: 100%;
    height: 23.6rem;
    margin: 1rem 0 2rem 0;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        height: 38.2rem;
    }
`;

export const StyledImage: any = styled(Image)`
    object-fit: contain;
`;

export const StyledContainer = styled('div')`
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        max-width: ${containerLargeWidth};
        margin: 0 auto;
    }
`;

const contentStyles = css`
    padding-right: 3rem;
    padding-left: 3rem;
`;

export const StyledContent = styled('div')`
    ${contentStyles};
`;

export const StyledMainHeading = styled(Heading)`
    ${contentStyles};
    font-size: 2.8rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 3.6rem;
    }
`;

export const StyledHeading = styled(Heading)`
    margin-right: 0;
    margin-left: 0;
    font-size: 2.5rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 3.1rem;
    }
`;

export const StyledButtonsContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('mobile')} {
        display: inline-flex;
    }
`;

import { Status, Wrapper, WrapperProps } from '@googlemaps/react-wrapper';
import { useMediaQuery } from '@mui/material';
import getConfig from 'next/config';
import { ReactNode, useState } from 'react';

import { sortGymsByOptions } from '@tgg/common-types';
import { dispatchEvent, EventKey, FindAGymSortBy } from '@tgg/services';

import { palette, theme } from '../../theme';
import { AlertMessage } from '../AlertMessage';
import { TextInput } from '../Forms';
import { Icon } from '../Icon';
import { Picture } from '../ImageStandalone/Picture';
import { Lister } from '../Lister';
import { Paragraph } from '../Paragraph';

import { useGoogleMap } from './GoogleMap.hooks';
import {
    StyledMapContainer,
    StyledAlertContainer,
    StyledFindMyLocationButton,
    StyledSearchContainer,
    StyledSearchInput,
    StyledBackToSearch,
    StyledButtonLink,
    StyledFilterLabelWrapper,
    StyledListerActionsContainer,
    StyledRegionalPreference,
    StyledRegionalPreferenceHeading,
    StyledSearchDropdown,
    TilesWrapper,
    PlaceholderMapContainer,
    StyledMapPlaceholder,
    getOverrideSizes,
} from './GoogleMap.styled';
import { GoogleMapProperties, MapContainerProperties } from './GoogleMap.types';
import { Clusterer } from './components';

const selectItems = {
    [sortGymsByOptions.DISTANCE_UP]: 'distance (default)',
    [sortGymsByOptions.LOWEST_PRICE_UP]: 'price lowest to highest',
    [sortGymsByOptions.LOWEST_PRICE_DOWN]: 'price highest to lowest',
};

const maximumMobileImageHeight = 400;

const {
    publicRuntimeConfig: { GOOGLE_MAPS_API_KEY },
} = getConfig();

export const renderMapPlaceholder = (
    withSearch: boolean,
    placeholderImageName: string,
    mapHeight: number,
): ((status: Status) => ReactNode) =>
    function MapPlaceholder(status: Status) {
        if (status === Status.LOADING) {
            return (
                <PlaceholderMapContainer
                    data-testid="mapPlaceholder"
                    variant={!withSearch ? 'short' : 'tall'}
                >
                    <StyledMapPlaceholder visible={true}>
                        <Picture
                            image={{
                                name: placeholderImageName,
                                endpoint: 'thegymgroup',
                                defaultHost: 'cdn.media.amplience.net',
                            }}
                            height={mapHeight}
                            maximumMobileImageHeight={maximumMobileImageHeight}
                            overrideSizes={getOverrideSizes(
                                mapHeight,
                                maximumMobileImageHeight,
                            )}
                            alt="The Gym Group asset-google map placeholder"
                        />
                    </StyledMapPlaceholder>
                </PlaceholderMapContainer>
            );
        }

        return null;
    };

/**
 * The GoogleMap component renders an instance of Google Maps onto the page with a list of gym markers. Most
 * of the rendering is done by Google Maps itself; our components are mainly containers for the various Maps
 * instances.
 */
export function GoogleMap({
    id,
    apiKey,
    markerOptions,
    withSearch = false,
    regionName,
    regionId,
    onGymClick,
    draggable = true,
    disableControlZoom = false,
    draggableCursor,
    initialQuery,
}: GoogleMapProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const placeholderImageName = !isDesktop
        ? 'The_Gym_Group_Asset-Generic-Google_Maps_Placeholder_Mobile_Version'
        : 'The_Gym_Group_Asset-Generic-Google_Maps_Placeholder_Desktop_Version';

    const mapHeight = !withSearch ? 400 : 800;

    return (
        <div id={id}>
            <Wrapper
                apiKey={GOOGLE_MAPS_API_KEY}
                render={
                    renderMapPlaceholder(
                        withSearch,
                        placeholderImageName,
                        mapHeight,
                    ) as WrapperProps['render']
                }
                libraries={['geometry', 'places']}
            >
                <MapContainer
                    markerOptions={markerOptions}
                    withSearch={withSearch}
                    regionName={regionName}
                    regionId={regionId}
                    onGymClick={onGymClick}
                    isDesktop={isDesktop}
                    draggable={draggable}
                    disableControlZoom={disableControlZoom}
                    draggableCursor={draggableCursor}
                    initialQuery={initialQuery}
                    placeholderImageName={placeholderImageName}
                />
            </Wrapper>
        </div>
    );
}
export default GoogleMap;

/**
 * MapContainer acts as a bridge between the Wrapper component and its sub components. The Map and Marker instances
 * must be created within the Wrapper component boundary, and then passed down into each of the sub components.
 */
function MapContainer({
    markerOptions,
    withSearch,
    regionName,
    regionId,
    onGymClick,
    isDesktop,
    draggable,
    disableControlZoom,
    draggableCursor,
    initialQuery,
    placeholderImageName,
}: MapContainerProperties) {
    const {
        loaded,
        map,
        markers,
        visibleMarkers,
        mapReference,
        searchTerm,
        onSearch,
        haveSearched,
        onClearSearch,
        onFindMyLocation,
        searchInputReference,
        userPositionError,
    } = useGoogleMap(
        markerOptions,
        withSearch,
        isDesktop,
        draggable,
        disableControlZoom,
        draggableCursor,
        onGymClick,
        initialQuery,
    );
    const [sortByValue, setSortByValue] = useState<sortGymsByOptions>(
        sortGymsByOptions.DISTANCE_UP,
    );

    const mapHeight = !withSearch || haveSearched ? 400 : 800;

    const clearSearchLinkText = 'back to all gyms';

    return (
        <>
            {withSearch && (
                <>
                    <StyledSearchContainer>
                        <StyledSearchInput
                            ref={searchInputReference}
                            onSubmit={onSearch}
                            placeholder="Street, town or postcode"
                            value={searchTerm}
                        />
                        <StyledFindMyLocationButton
                            text="Find my location"
                            buttonStyle="secondary"
                            onClick={onFindMyLocation}
                            startIcon={
                                <Icon
                                    name="targetLocation"
                                    color={palette.primary.main}
                                    size={24}
                                />
                            }
                        />
                    </StyledSearchContainer>
                    {userPositionError && (
                        <StyledAlertContainer>
                            <AlertMessage
                                type="information"
                                text="Sorry there is a problem retrieving your location, please search for a location instead"
                            />
                        </StyledAlertContainer>
                    )}
                </>
            )}
            <PlaceholderMapContainer
                variant={!withSearch || haveSearched ? 'short' : 'tall'}
            >
                <StyledMapPlaceholder visible={!loaded}>
                    <Picture
                        image={{
                            name: placeholderImageName,
                            endpoint: 'thegymgroup',
                            defaultHost: 'cdn.media.amplience.net',
                        }}
                        height={mapHeight}
                        maximumMobileImageHeight={maximumMobileImageHeight}
                        overrideSizes={getOverrideSizes(
                            mapHeight,
                            maximumMobileImageHeight,
                        )}
                        alt="The Gym Group asset-google map placeholder"
                        scale=""
                    />
                </StyledMapPlaceholder>
                <StyledMapContainer ref={mapReference} />
            </PlaceholderMapContainer>
            <Clusterer map={map} markers={markers} />
            {withSearch && haveSearched && (
                <>
                    <StyledListerActionsContainer>
                        <StyledBackToSearch>
                            <Icon
                                name="chevronLeft"
                                color={palette.primary.main}
                                size={12}
                            />{' '}
                            <StyledButtonLink
                                onClick={() =>
                                    onClearSearch(clearSearchLinkText)
                                }
                            >
                                {clearSearchLinkText}
                            </StyledButtonLink>
                        </StyledBackToSearch>

                        <StyledSearchDropdown>
                            <TextInput
                                id="sort-by-select"
                                select
                                value={sortByValue}
                                onChange={event => {
                                    const eventValue = event.target
                                        .value as sortGymsByOptions;
                                    setSortByValue(eventValue);
                                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                    dispatchEvent<FindAGymSortBy>(
                                        EventKey.GYM_SORT,
                                        {
                                            sort_by: selectItems[eventValue],
                                        },
                                    );
                                }}
                                iconElementRight={{
                                    name: 'tick',
                                }}
                                label={
                                    <StyledFilterLabelWrapper>
                                        <strong>sort by:</strong>
                                        {selectItems[sortByValue]}
                                    </StyledFilterLabelWrapper>
                                }
                            >
                                {Object.entries(selectItems).map(
                                    ([key, value]) => {
                                        return (
                                            <option key={key} value={key}>
                                                {value}
                                            </option>
                                        );
                                    },
                                )}
                            </TextInput>
                        </StyledSearchDropdown>
                    </StyledListerActionsContainer>
                    <TilesWrapper>
                        <Lister
                            gyms={visibleMarkers}
                            sortByValue={sortByValue}
                        />
                    </TilesWrapper>
                </>
            )}
            {!withSearch && regionName && (
                <>
                    <StyledRegionalPreference>
                        <StyledRegionalPreferenceHeading
                            id={regionId}
                            variant="h2"
                        >{`our ${regionName}`}</StyledRegionalPreferenceHeading>
                        <Paragraph gutterBottom={false}>
                            Choose your preferred gym to view
                        </Paragraph>
                    </StyledRegionalPreference>
                    <TilesWrapper>
                        <Lister gyms={visibleMarkers} regionName={regionName} />
                    </TilesWrapper>
                </>
            )}
        </>
    );
}

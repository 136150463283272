import { Heading } from '../Heading';

import { StyledContainer } from './TitleBox.styled';
import { TitleBoxProperties } from './TitleBox.types';
/**
 * Use the `Heading` component to communicate the organisation of the content of the page.
 */
export const TitleBox = ({
    id,
    text,
    isLowercase = true,
}: TitleBoxProperties) => {
    if (!text) {
        return null;
    }

    return (
        <StyledContainer id={id}>
            <Heading gutterBottom={false} isLowercase={isLowercase}>
                {text}
            </Heading>
        </StyledContainer>
    );
};

export default TitleBox;

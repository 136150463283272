import { palette } from '../../../theme';
import { Icon } from '../../Icon';

import {
    StyledDetails,
    StyledHeading,
    StyledSummary,
    StyledContainer,
} from './ControlledCollapsible.styled';
import { ControlledCollapsibleProperties } from './ControlledCollapsible.types';

/**
 * Component to allow content to be collapsed behind a header. Controlled from the outside for use e.g. as part of an Accordion.
 */
export function ControlledCollapsible({
    children,
    heading,
    onClick,
    open,
    className,
}: ControlledCollapsibleProperties) {
    const iconName = open ? 'close' : 'plus';

    return (
        <StyledDetails className={className} onClick={onClick} open={open}>
            <StyledSummary>
                <StyledContainer>
                    <StyledHeading>{heading}</StyledHeading>
                    <Icon
                        name={iconName}
                        color={palette.primary.main}
                        size={16}
                    />
                </StyledContainer>
            </StyledSummary>
            {children}
        </StyledDetails>
    );
}

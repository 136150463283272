import { useState } from 'react';

import { BillingAddressFields } from '@tgg/common-types';

import {
    UseBillingAddressArguments,
    HandleSubmitArguments,
} from './useBillingAddress.types';

export const useBillingAddress = ({ onSubmit }: UseBillingAddressArguments) => {
    const [isOverlayOpen, setOverlayOpen] = useState(false);

    return {
        isOverlayOpen,
        handleSubmit: handleSubmit({ onSubmit, setOverlayOpen }),
        onOverlayClose: () => setOverlayOpen(false),
        onOverlayOpen: () => setOverlayOpen(true),
    };
};

export const handleSubmit =
    ({ onSubmit, setOverlayOpen }: HandleSubmitArguments) =>
    async (address: BillingAddressFields) => {
        await onSubmit(address);

        setOverlayOpen(false);
    };

import { styled } from '@mui/material/styles';

import { PromoColors, promoPalette } from '../../theme';

interface StyledCardProperties {
    $paletteColor: PromoColors;
    $squeeze?: boolean;
}

export const StyledCard = styled('div')<StyledCardProperties>`
    position: absolute;
    top: 0;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.4rem 1rem;
    font-size: 1rem;
    line-height: 1.2rem;
    color: ${({ $paletteColor }) => promoPalette[$paletteColor].text};
    background-color: ${({ $paletteColor }) =>
        promoPalette[$paletteColor].background};
    border-radius: 1rem;
    transform: translateY(-50%);

    ${({ theme }) => theme.breakpoints.down(468)} {
        svg {
            flex: 1;
        }

        b {
            flex: 2;
            white-space: nowrap;
        }

        ${({ $squeeze }) => {
            /* istanbul ignore if - can't get these css values for test */
            if ($squeeze) {
                return `
                    left: 0;

                    b {
                        white-space: wrap;
                    }
                `;
            }

            return '';
        }}
    }
`;

export const StyledBoldText = styled('b')`
    font-family: thegymgroupsans-Headline, sans-serif;
    font-weight: 400;
`;

import { alpha, Typography as MaterialTypography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { palette, Theme } from '../../theme';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { Tile } from '../Tile';

export const StyledTile = styled(Tile)`
    display: grid;
    grid-template-columns: 1fr 11.8rem;
    max-width: 52rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        grid-template-columns: 1fr 13.5rem;
    }

    border: 2px solid ${alpha(palette.primary.main, 0.2)};
    box-shadow: none;
`;

export const StyledIconContainer = styled('div')`
    margin-right: 0.3rem;
    margin-left: -0.3rem;
`;

export const StyledGymName = styled('a')`
    padding: 0;
    font-family: thegymgroupsans-Headline;
    font-size: 1.9rem;
    line-height: 2.2rem;
    color: ${palette.primary.main};
    text-align: inherit;
    text-decoration: underline;
    text-transform: lowercase;
    letter-spacing: -0.03rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 2.1rem;
        line-height: 2.5rem;
    }
`;

export const StyledAddressSection = styled('div')`
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing(15)};
`;

export const StyledHeading = styled(Heading)`
    padding-right: 0.1rem;
    padding-left: 0.1rem;
`;

export const StyledAddressInformation = styled('div')`
    display: flex;
    margin-top: 1.2rem;
`;

export const StyledAddressLink = styled('a')`
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${palette.common.blue};
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
`;

export const StyledDistanceText = styled(Paragraph)`
    padding-top: ${({ theme }: { theme: Theme }) => theme.spacing(15)};
`;

export const StyledAddressDistance = styled('span')<{ $color: any }>`
    padding: 0.4rem;
    margin-top: 0.4rem;
    font-size: 1rem;
    background-color: ${({ $color }) => $color[100]};
    border-radius: 0.7rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
    }
`;

export const StyledPricingSection = styled('a')<{ $color: any }>`
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(15)};
    color: inherit;
    text-align: right;
    text-decoration: none;
    background-color: ${({ $color }) => $color[100]};
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
`;

export const StyledPrice = styled('div')`
    line-height: 2.5rem;
    text-align: right;

    * {
        vertical-align: top;
    }

    *:last-child {
        line-height: 2.4rem;
    }
`;

export const StyledPriceText = styled(MaterialTypography)`
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${palette.common.blue};
    white-space: nowrap;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
`;

import useMediaQuery from '@mui/material/useMediaQuery';
import { MouseEvent } from 'react';

import { ClickEvent, dispatchEvent } from '@tgg/services';
import { isSupportCentre } from '@tgg/util';

import { useFlagsContext } from '../../contexts';
import { theme, Palette, palette } from '../../theme';
import { Accordion } from '../Accordion';
import { LinksList } from '../LinksList';
import { SocialIcon } from '../SocialIcon';

import {
    StyledAppStoreBadgesSection,
    StyledAppStoreBadges,
    StyledAwardsContainer,
    StyledFooterHeading,
    StyledFooterWrapper,
    StyledHorizontalRuleContainer,
    StyledSiteLinksSection,
    StyledTopSection,
    StyledSocialIconsList,
    StyledSocialIconsSection,
    StyledSiteLinkColumn,
    StyledHorizontalRule,
} from './Footer.styled';
import { FooterProperties } from './Footer.types';
import { siteLinks } from './constants';

const handleFooterLinksClick =
    (footerSection: string) => (event: MouseEvent<HTMLAnchorElement>) => {
        const { href, dataset } = event.currentTarget;

        dispatchEvent(ClickEvent.FOOTER_LINK, {
            footer_section: footerSection.toLowerCase(),
            link_text: dataset.linkName,
            link_destination: href,
        });
    };

const handleSocialLinksClick = handleFooterLinksClick('get social');

/**
 * Footer component containing non-page-specific functionality such as site links, social links and site search.
 * Awards badges and app store badges must be passed in by the consumer.
 */
export function Footer({ appStoreBadges, awardsBadges }: FooterProperties) {
    const {
        flags: {
            support_centre_enabled: { enabled: enabledSupportCentre },
        },
    } = useFlagsContext();
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const subTitleColor = palette.common.blue as Palette;

    const aboutGymWithoutSC = siteLinks.aboutTheGymGroup.filter(
        ({ href }: { name: string; href: string }) => !isSupportCentre(href),
    );
    const flaggedAboutGymItems = enabledSupportCentre
        ? siteLinks.aboutTheGymGroup
        : aboutGymWithoutSC;

    return (
        <StyledFooterWrapper>
            <StyledTopSection>
                <StyledSocialIconsSection>
                    <StyledFooterHeading
                        color={subTitleColor}
                        variant="h2"
                        gutterBottom={false}
                    >
                        Get Social
                    </StyledFooterHeading>
                    <StyledSocialIconsList>
                        <li>
                            <SocialIcon
                                variant="tiktok"
                                ariaLabel="Follow us on TikTok"
                                href="https://www.tiktok.com/@thegymgroup"
                                onClick={handleSocialLinksClick}
                            />
                        </li>
                        <li>
                            <SocialIcon
                                variant="instagram"
                                ariaLabel="Visit our instagram page"
                                href="https://www.instagram.com/thegymgroup/?hl=en"
                                onClick={handleSocialLinksClick}
                            />
                        </li>
                        <li>
                            <SocialIcon
                                variant="youtube"
                                ariaLabel="Visit our youtube page"
                                href="https://www.youtube.com/user/TheGymGroupUK"
                                onClick={handleSocialLinksClick}
                            />
                        </li>
                        <li>
                            <SocialIcon
                                variant="X"
                                ariaLabel="Visit our X page"
                                href="https://twitter.com/TheGymGroup"
                                onClick={handleSocialLinksClick}
                            />
                        </li>
                    </StyledSocialIconsList>
                </StyledSocialIconsSection>
            </StyledTopSection>
            {isDesktop && (
                <StyledHorizontalRuleContainer>
                    <StyledHorizontalRule variant="middle" />
                </StyledHorizontalRuleContainer>
            )}
            <StyledSiteLinksSection>
                {isDesktop ? (
                    <>
                        <StyledSiteLinkColumn>
                            <StyledFooterHeading
                                color={subTitleColor}
                                variant="h2"
                                gutterBottom={false}
                            >
                                About The Gym Group
                            </StyledFooterHeading>
                            <LinksList
                                onClick={handleFooterLinksClick(
                                    'About The Gym Group',
                                )}
                                links={flaggedAboutGymItems}
                            />
                        </StyledSiteLinkColumn>
                        <StyledSiteLinkColumn>
                            <StyledFooterHeading
                                color={subTitleColor}
                                variant="h2"
                                gutterBottom={false}
                            >
                                The Gyms
                            </StyledFooterHeading>
                            <LinksList
                                onClick={handleFooterLinksClick('The Gyms')}
                                links={siteLinks.theGyms}
                            />
                        </StyledSiteLinkColumn>
                        <StyledSiteLinkColumn>
                            <StyledFooterHeading
                                color={subTitleColor}
                                variant="h2"
                                gutterBottom={false}
                            >
                                Corporate
                            </StyledFooterHeading>
                            <LinksList
                                onClick={handleFooterLinksClick('Corporate')}
                                links={siteLinks.corporate}
                            />
                        </StyledSiteLinkColumn>
                    </>
                ) : (
                    <Accordion
                        onExpand={section => {
                            dispatchEvent(ClickEvent.FOOTER_SECTION_EXPAND, {
                                footer_section: section.toLowerCase(),
                            });
                        }}
                        items={[
                            {
                                heading: 'About The Gym Group',
                                content: (
                                    <LinksList
                                        onClick={handleFooterLinksClick(
                                            'About The Gym Group',
                                        )}
                                        links={flaggedAboutGymItems}
                                    />
                                ),
                            },
                            {
                                heading: 'The Gyms',
                                content: (
                                    <LinksList
                                        onClick={handleFooterLinksClick(
                                            'The Gyms',
                                        )}
                                        links={siteLinks.theGyms}
                                    />
                                ),
                            },
                            {
                                heading: 'Corporate',
                                content: (
                                    <LinksList
                                        onClick={handleFooterLinksClick(
                                            'Corporate',
                                        )}
                                        links={siteLinks.corporate}
                                    />
                                ),
                            },
                            {
                                heading: 'Legal',
                                content: (
                                    <LinksList
                                        onClick={handleFooterLinksClick(
                                            'Legal',
                                        )}
                                        links={siteLinks.legal}
                                    />
                                ),
                            },
                        ]}
                    />
                )}

                <StyledSiteLinkColumn>
                    {isDesktop && (
                        <>
                            <StyledFooterHeading
                                color={subTitleColor}
                                variant="h2"
                                gutterBottom={false}
                            >
                                Legal
                            </StyledFooterHeading>
                            <LinksList
                                onClick={handleFooterLinksClick('Legal')}
                                links={siteLinks.legal}
                            />
                        </>
                    )}

                    <StyledAppStoreBadgesSection>
                        <StyledFooterHeading
                            color={subTitleColor}
                            variant="h2"
                            gutterBottom
                        >
                            Get The Gym Group App
                        </StyledFooterHeading>
                        <StyledAppStoreBadges>
                            {appStoreBadges}
                        </StyledAppStoreBadges>
                    </StyledAppStoreBadgesSection>
                </StyledSiteLinkColumn>
            </StyledSiteLinksSection>

            <StyledAwardsContainer>{awardsBadges}</StyledAwardsContainer>
        </StyledFooterWrapper>
    );
}

import {
    PaymentCategories,
    JoinJourneyShoppingBasketCookie,
    Nullable,
    PaymentItem,
} from '@tgg/common-types';
import { getYourPaymentDiscountMessage } from '@tgg/util';

import { getDiscountDuration } from '../Payments/PaymentSummary/PaymentSummary.helpers';

export function checkNoJoiningFeePromo(paymentItems?: PaymentItem[]) {
    if (paymentItems) {
        const joiningFeeItem = paymentItems.find(
            element => element.category === PaymentCategories.JoiningFee,
        );

        return joiningFeeItem?.cost === 0;
    }

    return false;
}

export function getMappedTodayItems(
    basketData: Nullable<JoinJourneyShoppingBasketCookie>,
) {
    const steppedKickerDiscountDuration = getDiscountDuration(
        basketData?.payments.steppedKicker,
    );

    const mappedTodayItems =
        steppedKickerDiscountDuration && basketData?.payments.today?.items
            ? basketData.payments.today.items.map(item => {
                  if (item.category === PaymentCategories.MonthlyMembership) {
                      return {
                          ...item,
                          discountMessage: getYourPaymentDiscountMessage(
                              steppedKickerDiscountDuration,
                              false,
                          ),
                      };
                  }

                  return item;
              })
            : (basketData?.payments.today?.items ?? []);

    return mappedTodayItems;
}

export function checkFixedProductSelected(
    basketData: Nullable<JoinJourneyShoppingBasketCookie>,
) {
    const paymentItems = basketData?.payments.today?.items;
    const membershipType = basketData?.basketItems.product.membershipType;

    if (
        paymentItems &&
        membershipType &&
        !membershipType.startsWith('DayPass')
    ) {
        const fixedProduct = paymentItems.find(
            item => item.category === PaymentCategories.OneOff,
        );

        return !!fixedProduct;
    }

    return false;
}

import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../../../theme';
import { Paragraph } from '../../../Paragraph';

export const StyledPaymentItemList = styled('ul')<{
    $gutterBottom?: boolean;
    $hasSavingsItem?: boolean;
}>`
    padding-inline-start: 0;
    padding-top: ${({ theme, $hasSavingsItem }) =>
        !$hasSavingsItem && theme.spacing(10)};
    padding-bottom: ${({ theme, $gutterBottom, $hasSavingsItem }) =>
        $gutterBottom && !$hasSavingsItem && theme.spacing(10)};
    margin-block-start: 0;
    margin-block-end: 0;
    list-style-type: none;
`;

export const StyledPaymentItem = styled('li')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.spacing(5)};
`;

export const StyledAmounts = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledOriginalPrice = styled(Paragraph)`
    text-decoration: line-through;
    text-transform: lowercase;
`;

export const StyledDiscountedMessage = styled(Paragraph)`
    font-size: 1rem;
    text-transform: lowercase;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
        text-align: left;
    }
`;

export const StyledLabel = styled(Paragraph, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<{
    $applyStrikeThrough: boolean;
    $hasDiscountPriceInRed?: boolean;
    $hasSavingsInRed?: boolean;
}>`
    ${properties =>
        properties.$applyStrikeThrough && 'text-decoration: line-through;'}
    ${properties =>
        (properties.$hasDiscountPriceInRed || properties.$hasSavingsInRed) &&
        `color: ${palette.common.red};`}
    text-transform: lowercase;
    white-space: pre;
`;

import { useEffect, useRef } from 'react';

import { useBackToTopContext } from '../../../contexts';

import { StyledHideBackToTopButton } from './HideBackToTopButtonToThisPlace.styled';
import { BackToTopContextProperties } from './HideBackToTopButtonToThisPlace.types';

/**
 * HideBackToTopButtonToThisPlace component hides the BackToTop button until this place of the page got scrolled past.
 */
export function HideBackToTopButtonToThisPlace({
    additionalOffset,
}: BackToTopContextProperties) {
    const { setElementReference, setAdditionalOffset } = useBackToTopContext();
    const backToTopPlaceReference = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setElementReference(backToTopPlaceReference);
        setAdditionalOffset(additionalOffset ?? 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledHideBackToTopButton
            data-testid="hide-back-to-top-button"
            ref={backToTopPlaceReference}
        />
    );
}

import { promoPalette } from '../../theme';
import { Icon } from '../Icon';

import { StyledBoldText, StyledCard } from './ProductHighlightPill.styled';
import { ProductHighlightPillProperties } from './ProductHighlightPill.types';

export function ProductHighlightPill({
    text,
    iconName,
    color,
    squeeze,
}: ProductHighlightPillProperties) {
    return (
        <StyledCard $squeeze={squeeze} $paletteColor={color}>
            {iconName && (
                <Icon
                    name={iconName}
                    color={promoPalette[color].text}
                    size={12}
                />
            )}
            <StyledBoldText>{text}</StyledBoldText>
        </StyledCard>
    );
}

import {
    Client,
    OptimizelyUserContext,
    enums,
} from '@optimizely/optimizely-sdk';
import getConfig from 'next/config';

import { Nullable, Promisable } from '@tgg/common-types';

import { TggLogger } from '../../logger';

const {
    serverRuntimeConfig: { optimizelyService },
} = getConfig();

export type OptimizelyClient = {
    client: OptimizelyUserContext;
    pid: string;
    error: Nullable<Error>;
};

type OptimizelyService = {
    getOptimizelyClient: (logger: TggLogger) => Promise<OptimizelyProcess>;
    resetOptimizelyService: () => void;
};

export type OptimizelyProcess = {
    client: Nullable<Client>;
    pid: Nullable<string>;
    error: Nullable<unknown>;
    initialized?: boolean;
    init?: (config: {
        events?: Partial<{
            [key in enums.NOTIFICATION_TYPES]: (event: any) => Promisable<void>;
        }>;
    }) => void;
};

export type OptimizelyUserAttributes = {
    pathname: string;
    $opt_user_agent: string;
};

export const initOptimizelyClient = async (
    userId: string,
    logger: TggLogger,
    userAttributes?: Partial<OptimizelyUserAttributes>,
): Promise<OptimizelyClient> => {
    const { client, error, pid, init, initialized } = await (
        optimizelyService as OptimizelyService
    ).getOptimizelyClient(logger);
    if (error) throw new Error((error as Error).message);
    if (!client)
        throw new Error('Optimizely client not initialized, reason: unknown');

    const userContext = await client.createUserContext(userId, userAttributes);

    if (!userContext)
        throw new Error(
            `Optimizely not able to create context for user: ${userId}`,
        );

    return {
        client: userContext,
        pid: pid as string,
        error: null,
    };
};

import { Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { Icon } from '../Icon';

import {
    StyledIconProperties,
    StyledCollapsibleProperties,
} from './PartialCollapsible.types';

export const StyledCollapsible = styled(Collapse, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledCollapsibleProperties>`
    min-height: ${({ $minHeightMobile }) => $minHeightMobile}rem !important;
    margin: 0 auto;
    line-height: 2.1rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        min-height: ${({ $minHeightDesktop }) =>
            $minHeightDesktop}rem !important;
    }

    ${({ $isOpen }) =>
        $isOpen
            ? 'mask-image: none'
            : 'mask-image: linear-gradient(to bottom, black 60%, transparent 100%)'};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        ${({ $alignOnDesktop }) => `text-align: ${$alignOnDesktop}`};
    }

    &.MuiCollapse-hidden {
        visibility: visible;
    }
`;

export const StyledCollapsibleControl = styled('div')<{
    $fullWidth?: boolean;
}>`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${({ $fullWidth }) => ($fullWidth ? '0' : '0 3rem')};
    margin: 1rem auto 0;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 0;
    }
`;

export const StyledDivider = styled('div')<{ $variant?: boolean }>`
    flex-grow: 1;
    height: 0.1rem;
    background: ${({ theme, $variant }) =>
        $variant ? palette.common.white : palette.primary.main};
`;

export const StyledButton = styled('button')<{ $variant?: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    cursor: pointer;
    background: none;
    border: 0.2rem solid
        ${({ theme, $variant }) =>
            $variant ? palette.common.white : palette.primary.main};
    border-radius: 50%;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 4.5rem;
        height: 4.5rem;
        margin-right: 3rem;
        margin-left: 3rem;
    }
`;

export const StyledChevronIcon = styled(Icon, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledIconProperties>`
    cursor: pointer;
    transition: all 0.3s;
    transform: ${({ $rotated }) =>
        $rotated ? 'rotate(-90deg);' : 'rotate(90deg)'};
`;

/* eslint-disable @typescript-eslint/no-floating-promises */
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { Nullable } from '@tgg/common-types';
import { TggFlagsObject, createTraceParameters, defaultFlags } from '@tgg/util';

import { FlagsContext, FlagsContextProperties } from './flagsContext';
import { useOptimizelyBeClient } from './hooks/useOptimizelyBeClient';
import {
    useOptimizelyProxyClient,
    OptimizelyClientHookConfig,
} from './hooks/useOptimizelyProxyClient';
import { useOptimizelyTracking } from './hooks/useOptimizelyTracking';
import { useUserIdentification } from './hooks/useUserIdentification';

export type FlagsProviderProperties = {
    children: ReactNode;
    flagsContextData: {
        flags: Nullable<TggFlagsObject>;
        flagsUserId: string;
        pathname: string;
        useOptimizelyProxy: boolean;
    };
};

export function FlagsProvider({
    children,
    flagsContextData,
}: FlagsProviderProperties) {
    const { flags, flagsUserId, pathname, useOptimizelyProxy } =
        flagsContextData ?? /* istanbul ignore next */ {};
    const [render, setRender] = useState(false);
    const [resolvedFlags, setResolvedFlags] = useState({
        ...defaultFlags,
        ...flags,
    });

    const userConfig = useMemo(
        () => ({
            flagsUserId,
            pathname,
            useOptimizelyProxy,
        }),
        [flagsUserId, pathname, useOptimizelyProxy],
    );

    const { isUserInitialized, resolvedUserId } =
        useUserIdentification(userConfig);

    const loggerParameters = useMemo(
        () =>
            createTraceParameters({
                context: null,
                parameters: {
                    operation: `optimizelyClient ${{
                        useOptimizelyProxy,
                    }}`,
                },
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isUserInitialized],
    );

    const baseClientConfig = useMemo(
        () =>
            ({
                pathname,
                resolvedUserId,
                setFlags: setResolvedFlags,
                loggerParameters,
            }) as Omit<OptimizelyClientHookConfig, 'useClient'>,
        [pathname, resolvedUserId, loggerParameters],
    );

    const { dispatchGaEvents, eventsSent } = useOptimizelyBeClient({
        ...baseClientConfig,
        resolvedFlags,
        useClient: !useOptimizelyProxy,
    });

    const { resolveFlags, flagsLoaded } = useOptimizelyProxyClient({
        ...baseClientConfig,
        useClient: useOptimizelyProxy,
    });

    const { trackFlagsEvent } = useOptimizelyTracking({
        pathname,
        resolvedUserId,
    });

    // main logic
    useEffect(() => {
        // send GA events when proxy is disabled based on ssr flags
        if (isUserInitialized && !useOptimizelyProxy && !eventsSent) {
            dispatchGaEvents();
        }

        // resolve flags using proxy when enabled
        if (isUserInitialized && useOptimizelyProxy && !flagsLoaded) {
            setRender(false);
            resolveFlags();
        }

        // render
        if (flagsLoaded || !useOptimizelyProxy) setRender(true);
    }, [
        dispatchGaEvents,
        eventsSent,
        flagsLoaded,
        isUserInitialized,
        resolveFlags,
        useOptimizelyProxy,
    ]);

    const contextValue: FlagsContextProperties = useMemo(() => {
        return {
            flags: resolvedFlags,
            flagsUserId: resolvedUserId,
            pathname,
            trackFlagsEvent,
        };
    }, [resolvedFlags, resolvedUserId, pathname, trackFlagsEvent]);

    return (
        <FlagsContext.Provider value={contextValue}>
            {render && children}
        </FlagsContext.Provider>
    );
}

import { Controller, FieldValues, Path } from 'react-hook-form';

import { emailConfirmationValidator } from '@tgg/form-validation';

import { Asterisk } from '../../Asterisk';
import { TextInput } from '../../Forms';
import { ControlledEmailInput } from '../ControlledEmailInput';
import { StyledLabel } from '../ControlledInputs.styled';

import { ControlledConfirmationEmailInputProperties } from './ControlledConfirmationEmailInput.types';

export type EmailFieldValues = FieldValues & {
    email: Path<string>;
};

export function ControlledConfirmationEmailInput<T extends EmailFieldValues>({
    getValues,
    label = 'email',
    control,
    placeholder = 'enter email address',
}: ControlledConfirmationEmailInputProperties<T>) {
    return (
        <>
            <ControlledEmailInput
                label={label}
                control={control}
                placeholder="enter email address"
                dependentInputs={['emailConfirmation']}
            />
            <Controller
                name="emailConfirmation"
                control={control}
                rules={{
                    validate: {
                        validator: value => {
                            return emailConfirmationValidator(
                                getValues('email' as any),
                                value,
                            );
                        },
                    },
                }}
                render={({
                    field: { value, onChange, onBlur },
                    fieldState: { invalid, isDirty, error },
                }) => {
                    return (
                        <>
                            <StyledLabel
                                $hasPaddingTop
                                data-testid="email-confirmation-label"
                                htmlFor="emailConfirmation"
                            >
                                confirm email address
                                <Asterisk spaceBefore />
                            </StyledLabel>
                            <TextInput
                                value={value}
                                errorMessage={error?.message}
                                isInvalid={invalid}
                                placeholder={placeholder}
                                id="emailConfirmation"
                                onChange={onChange}
                                onBlur={onBlur}
                                iconElementRight={{
                                    name:
                                        !invalid && isDirty ? 'tick' : 'blank',
                                }}
                            />
                        </>
                    );
                }}
            />
        </>
    );
}

export default ControlledConfirmationEmailInput;

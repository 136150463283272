import cookie from 'cookie';
import jsCookie from 'js-cookie';
import { GetServerSidePropsContext } from 'next/types';

import { ApplicationIdentifier } from '@tgg/common-types';

import {
    DeleteCookieSsr,
    GetCookieSsr,
    UpdateCookieSsr,
    SetCookieSsr,
    ExpireCookieSsr,
    AVAILABLE_COOKIES,
    AnyContext,
    NonPageContext,
} from './cookies.types';
import {
    decodeCookieValue,
    parseCookieUpsert,
    stringifyCookieValue,
    serializeCookieForServerside,
    convertCookieOptionsToSsr,
} from './utils/cookies.utils';

export const getLoggedInUserFromCookie = () => {
    const statusCookie = jsCookie.get('loggedInUser');
    if (!statusCookie) return '';
    return statusCookie;
};

function isNonPageContext(context: AnyContext): context is NonPageContext {
    return (context as NonPageContext).pathname !== undefined;
}

/**
 * grab all request cookies
 * req.cookies - next cookie object
 * req.headers.cookie - native node cookie string
 */
export const getCookiesSsr = (context: AnyContext) => {
    if (!isNonPageContext(context) && context.req?.cookies)
        return context.req.cookies;
    if (context.req?.headers?.cookie)
        return cookie.parse(context.req.headers.cookie);
    return {};
};

/**
 * replace cookie in next cookie object
 */
const replaceCookieSSr = (
    cookiesObject: Partial<Record<string, string>>,
    cookieKey: string,
    value: any,
) => {
    const newCookiesObject = { ...cookiesObject };
    newCookiesObject[cookieKey] = stringifyCookieValue(value);
    return newCookiesObject;
};

/*
 * response cookies are always string[]
 */
const standariseCookies = (cookiesHeader: any): string[] => {
    let responseCookies = cookiesHeader;
    if (!Array.isArray(responseCookies)) {
        responseCookies = responseCookies
            ? [stringifyCookieValue(responseCookies)]
            : [];
    }
    return responseCookies;
};

/*
 * remove cookie from response header
 */
const filterOutCookieString = (cookies: string[], cookieKey: string) =>
    cookies.filter(cookieString => cookieString.split('=')[0] !== cookieKey);

/**
 * add cookie to res.headers
 * preserve current headers
 * removes previous cookie if present not to duplicate entries
 */
export const addSerialisedCookieSsr = (
    context: AnyContext,
    serializedCookie: string,
) => {
    const cookieKey = serializedCookie.split('=')[0];
    const responseCookies = standariseCookies(
        context.res?.getHeader('Set-Cookie'),
    );

    const filteredResponseCookies = filterOutCookieString(
        responseCookies,
        cookieKey,
    );

    context.res?.setHeader('Set-Cookie', [
        ...filteredResponseCookies,
        serializedCookie,
    ]);
};

export const getCookieSsr = <C>({
    cookieKey,
    context,
}: GetCookieSsr): C | null => {
    const cookies = getCookiesSsr(context);

    const value = cookies[cookieKey];
    if (value === undefined || value === null) return null;
    const decodedValue = decodeCookieValue(value);

    /**
     * parse if decodedValue is an object, array or stringified string
     */
    return /^["[{]/.test(decodedValue)
        ? JSON.parse(decodedValue)
        : decodedValue;
};

export const setCookieSsr = <C>({
    cookieKey,
    context,
    value,
    cookieParameters,
    environment,
}: SetCookieSsr<C>) => {
    /**
     * create cookie string
     */
    const serializedCookie = serializeCookieForServerside(cookieKey, value, {
        ...convertCookieOptionsToSsr(cookieParameters, cookieKey, environment),
    });

    /**
     * add cookie string to context.res
     */
    addSerialisedCookieSsr(context, serializedCookie);

    /**
     * add cookie string to context.req.cookies - next
     */
    /* istanbul ignore else */
    if (!isNonPageContext(context)) {
        const requestCookies = context.req?.cookies;
        /* istanbul ignore else */
        if (requestCookies) {
            context.req.cookies = replaceCookieSSr(
                requestCookies,
                cookieKey,
                value,
            );
        }
    }

    /**
     * add cookie string to context.req.cookies - node
     */
    const requestHeaderCookies = context.req?.headers.cookie;
    /* istanbul ignore else */
    if (context.req && typeof requestHeaderCookies === 'string') {
        const parsedRequestHeaderCookies = cookie.parse(requestHeaderCookies);

        const newRequestHeaderCookies = replaceCookieSSr(
            parsedRequestHeaderCookies,
            cookieKey,
            value,
        );

        context.req.headers.cookie = Object.entries(
            newRequestHeaderCookies,
        ).reduce((accum, item) => {
            return `${accum}${item[0]}=${item[1]};`;
        }, '');
    }
};

export const updateCookieSsr = <C>({
    cookieKey,
    context,
    value,
    cookieParameters,
    environment,
}: UpdateCookieSsr<C>) => {
    const currentValue = getCookieSsr<C>({
        cookieKey,
        context,
    });
    const newValue = parseCookieUpsert(currentValue, value);

    setCookieSsr<C>({
        cookieKey,
        context,
        value: newValue,
        cookieParameters,
        environment,
    });
};

export const expireCookieSsr = <C>({
    cookieKey,
    cookieParameters,
    context,
    environment,
    emptyValue,
}: ExpireCookieSsr<C>) => {
    setCookieSsr<any>({
        cookieKey,
        context,
        value: emptyValue,
        cookieParameters: {
            ...cookieParameters,
            maxAge: 0,
            expires: new Date(),
        },
        environment,
    });
};

export const deleteCookieSsr = <C>({ cookieKey, context }: DeleteCookieSsr) => {
    const responseCookies = standariseCookies(
        context.res?.getHeader('Set-Cookie'),
    );

    /**
     * remove cookie from response header
     */
    const filteredResponseCookies = filterOutCookieString(
        responseCookies,
        cookieKey,
    );

    /**
     * set new response header
     */
    context.res?.setHeader('Set-Cookie', [...filteredResponseCookies]);

    /**
     * clear next request cookie object
     */
    /* istanbul ignore else */
    if (!isNonPageContext(context)) {
        const requestCookies = context.req?.cookies;
        /* istanbul ignore else */
        if (requestCookies) {
            delete context.req.cookies[cookieKey];
        }
    }

    /**
     * clear node request cookie header
     */
    const requestHeaderCookies = context.req?.headers.cookie;
    /* istanbul ignore else */
    if (context.req && typeof requestHeaderCookies === 'string') {
        const parsedRequestHeaderCookies = cookie.parse(requestHeaderCookies);

        delete parsedRequestHeaderCookies[cookieKey];

        context.req.headers.cookie = Object.entries(
            parsedRequestHeaderCookies,
        ).reduce((accum, item) => {
            return `${accum}${item[0]}=${item[1]};`;
        }, '');
    }
};

export const clearApplicationCookiesSsr = (
    applicationIdentifier: ApplicationIdentifier,
    context: GetServerSidePropsContext,
) => {
    Object.values(AVAILABLE_COOKIES).forEach(cookieToDelete => {
        deleteCookieSsr({
            cookieKey: `${cookieToDelete}-${applicationIdentifier}`,
            context,
        });
    });
};

import getConfig from 'next/config';
import { useRouter } from 'next/router';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { optimizelyFlagsService } from '@tgg/services';
import {
    TggTraceCorrelationParameters,
    defaultFlags,
    type TggFlagsObject,
    type TggLogger,
} from '@tgg/util';

import { useAppConfigContext } from '../../appConfigContext';
import { dispatchOptimizelyGaDecisionEvent } from '../helpers/dispatchOptimizelyGaEvent';

const { publicRuntimeConfig } = getConfig();

export type OptimizelyClientHookConfig = {
    resolvedUserId: string;
    pathname: string;
    useClient: boolean;
    setFlags: Dispatch<SetStateAction<TggFlagsObject>>;
    loggerParameters: TggTraceCorrelationParameters;
};

export const getOptimizelyFlags = async (
    userId: string,
    pathname: string,
    options: {
        logger: TggLogger;
        loggerParameters: TggTraceCorrelationParameters;
    },
) => {
    const { logger, loggerParameters } = options;

    try {
        const { data: flags } = await optimizelyFlagsService({
            apiConfig: {
                subscriptionKey: '',
                url: `${publicRuntimeConfig?.APP_BASE_URL}/${publicRuntimeConfig?.APP_BASE_PATH}`,
            },
            data: {
                overrideUserId: userId,
                pathname,
            },
        });

        return { flags, error: null };
    } catch (error_) {
        const error = error_ as Error;
        logger.error(
            `api/proxy/optimizely/flags failed: ${error.message}`,
            loggerParameters,
        );

        return { flags: defaultFlags, error };
    }
};

export const useOptimizelyProxyClient = (
    config: OptimizelyClientHookConfig,
) => {
    const [flagsLoaded, setFlagsLoaded] = useState(false);
    const [isFlagsError, setIsFlagsError] = useState(false);
    const { resolvedUserId, useClient, pathname, setFlags, loggerParameters } =
        config;

    const {
        appConfig: { appLogger },
    } = useAppConfigContext();

    const router = useRouter();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (useClient) {
            const eventHandler = () => {
                setFlagsLoaded(false);
            };

            router.events.on('routeChangeComplete', eventHandler);

            return () => {
                router.events.off('routeChangeComplete', eventHandler);
            };
        }
    }, [router.events]);

    const resolveFlags = useCallback(() => {
        if (!flagsLoaded) {
            setIsFlagsError(false);

            getOptimizelyFlags(resolvedUserId, pathname, {
                logger: appLogger,
                loggerParameters,
            })
                .then(result => {
                    const { flags, error } = result;
                    // dirty types hack
                    const resolvedFlags = flags as TggFlagsObject;
                    setFlags({ ...defaultFlags, ...resolvedFlags });

                    // GA events when no error
                    if (!error) {
                        Object.keys(resolvedFlags).forEach(flagKey => {
                            const flag =
                                resolvedFlags[flagKey as keyof TggFlagsObject];
                            // @ts-ignore
                            if (
                                flag.enabled &&
                                flag.variables?.enableDataLayer === true
                            ) {
                                dispatchOptimizelyGaDecisionEvent({
                                    flagKey,
                                    flagsUserId: resolvedUserId,
                                    pathname,
                                    ruleKey: flag.ruleKey,
                                    variationKey: flag.variationKey,
                                });
                            }
                        });
                    } else {
                        setIsFlagsError(true);
                    }
                })
                .finally(() => {
                    setFlagsLoaded(true);
                });
        }
    }, [
        appLogger,
        flagsLoaded,
        loggerParameters,
        pathname,
        resolvedUserId,
        setFlags,
    ]);

    return { resolveFlags, flagsLoaded, isFlagsError };
};

import { styled } from '@mui/material/styles';

import { Theme } from '../../theme';
import { Icon } from '../Icon';

export const StyledPayPalMessage = styled('section')`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
`;

export const StyledPayPalMessageIframe = styled('iframe')`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const StyledCloseButton = styled('button')`
    position: absolute;
    top: 2rem;
    right: 3rem;
    display: flex;
    height: fit-content;
    margin: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    svg {
        margin: auto;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        top: 3rem;
    }
`;

export const StyledIcon = styled(Icon)`
    cursor: pointer;
`;

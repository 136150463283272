import { Dialog, useMediaQuery } from '@mui/material';

import { palette, theme } from '../../theme';

import {
    StyledDialogTitle,
    StyledDialogContent,
    StyledCloseButton,
    StyledIcon,
    StyledHeading,
} from './Overlay.styled';
import { OverlayProperties } from './Overlay.types';

export const Overlay = ({
    title,
    children,
    open,
    contentContainerHasPadding = true,
    contentContainerisFullHeight = false,
    titleVariant = 'h2',
    handleClose,
    centerTitleOnDesktop,
    contentContainerMaxWidth,
    smallScreenOptions: { fullScreenMode = true } = {},
}: OverlayProperties) => {
    const isSmallScreen = useMediaQuery('(max-width:767px)');
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    let borderRadius = '20px';

    // in case of mobile version we want to have fullscreen mode for some overlays,
    // so we don't need the borderRadius
    if (!isDesktop && fullScreenMode) {
        borderRadius = 'unset';
    }

    return (
        <Dialog
            open={open}
            fullScreen={fullScreenMode && isSmallScreen}
            onClose={handleClose}
            aria-labelledby="overlay"
            aria-describedby="overlay-modal"
            PaperProps={{
                style: {
                    borderRadius,
                    overflowX: 'hidden',
                },
            }}
        >
            {title && (
                <StyledDialogTitle data-testid="overlayTitle">
                    <StyledHeading
                        variant={titleVariant}
                        component="h1"
                        gutterBottom={false}
                        alignOnDesktop={
                            centerTitleOnDesktop ? 'center' : undefined
                        }
                        {...(contentContainerMaxWidth
                            ? { $maxWidth: contentContainerMaxWidth }
                            : {})}
                    >
                        {title}
                    </StyledHeading>

                    <StyledCloseButton
                        onClick={handleClose}
                        aria-label="Close"
                        name="Close"
                        data-testid="close-button"
                    >
                        <StyledIcon
                            size={18}
                            name="close"
                            color={palette.common.white}
                        />
                    </StyledCloseButton>
                </StyledDialogTitle>
            )}
            <StyledDialogContent
                $hasPadding={contentContainerHasPadding}
                $isFullHeight={contentContainerisFullHeight}
                {...(contentContainerMaxWidth
                    ? { $maxWidth: contentContainerMaxWidth }
                    : {})}
            >
                {children}
            </StyledDialogContent>
        </Dialog>
    );
};

export default Overlay;

import { PaymentError, type ErrorCodes } from '@tgg/common-types';

import { CardPaymentFormLogging } from '../CardPayment.types';
import { isThreeDSPayentEvent } from '../ChallengeOutcome/ChallengeOutcome.types';

import { logPaymentIssue } from './logPaymentIssue';

export interface ThreeDSResponseListenerArguments {
    onErrorCallback: (code: ErrorCodes, fullMessage?: string | Error) => void;
    resetPaymentFlow: () => void;
    onThreeDsSuccess: () => void | Promise<void>;
    log: CardPaymentFormLogging;
}

export const threeDSResponseListener =
    ({
        onErrorCallback,
        resetPaymentFlow,
        onThreeDsSuccess,
        log,
    }: ThreeDSResponseListenerArguments) =>
    async (event: MessageEvent) => {
        if (event.origin !== window.location.origin) return;
        const { logger, loggerParameters } = log;

        /* istanbul ignore else */
        if (isThreeDSPayentEvent(event.data)) {
            const paymentEvent = event.data;
            resetPaymentFlow();

            logger.info(
                `3DS - Payment Event was triggered by 3DS check: ${JSON.stringify(
                    {
                        data: event.data,
                        origin: event.origin,
                    },
                )}`,
                loggerParameters,
            );

            if (paymentEvent.code === 'OK') {
                await onThreeDsSuccess();
            }

            const isCodeKnownError = ['2003', '2002', '2001', '2000'].includes(
                paymentEvent.code,
            );

            if (paymentEvent.error || isCodeKnownError) {
                const errorCode = isCodeKnownError
                    ? (paymentEvent.code as ErrorCodes)
                    : '2001';

                logPaymentIssue({
                    error: new PaymentError(
                        `3DS - Error event recieved from Iframe`,
                        errorCode,
                        {
                            originalError: paymentEvent.error,
                        },
                    ),
                    log,
                });

                onErrorCallback(errorCode as ErrorCodes);

                window.scroll({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }
    };

import { decimalToPoundsAndPence } from '@tgg/util';

import {
    StyledFinalCostsWrapper,
    StyledFinalCostsText,
} from './TotalCost.styled';
import { TotalCostProperties } from './TotalCost.types';

export const TotalCost = ({
    title,
    totalText = 'total',
    totalCost,
    gutterBottom = true,
}: TotalCostProperties) => (
    <StyledFinalCostsWrapper $gutterBottom={gutterBottom}>
        <StyledFinalCostsText gutterBottom={false}>
            {totalText}
        </StyledFinalCostsText>
        <StyledFinalCostsText
            gutterBottom={false}
            data-testid={`${title} total`}
        >
            {decimalToPoundsAndPence(totalCost)}
        </StyledFinalCostsText>
    </StyledFinalCostsWrapper>
);

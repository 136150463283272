import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';

export const StyledPanel = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding-top: ${({ theme }) => theme.spacing(30)};
    overflow: auto;
    background-color: ${palette.grey[50]};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('mobile')} {
        width: 37.5rem;
    }
`;

export const StyledHeader = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: ${({ theme }) => theme.spacing(30)};
    margin-bottom: 3rem;
`;

export const StyledHeading = styled(Heading)`
    && {
        margin-bottom: 0;
    }

    color: ${palette.common.blue};
`;

export const StyledCloseButton = styled('button')`
    background-color: #cce3fa;
`;

export const StyledForm = styled('form')`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StyledFiltersContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-inline: ${({ theme }) => theme.spacing(30)};
    padding-bottom: 3rem;
`;

export const StyledLabel = styled('label')`
    width: 100%;
`;

export const StyledLabelText = styled('span')`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.4rem;
    padding: 1rem 1.5rem;
    font-family: thegymgroupsans-headline;
    font-size: 1.8rem;
    line-height: 4.4rem;
    color: ${palette.common.blue};
    text-align: center;
    text-transform: lowercase;
    cursor: pointer;
    background-color: ${palette.common.white};
    border: 1px solid ${palette.common.blue};
    border-radius: 36rem;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        content: '';
        border-radius: inherit;
    }

    &:hover {
        &::before {
            display: block;
            background-color: rgba(0, 0, 0, 0.09);
        }
    }
`;

export const StyledInput = styled('input')`
    &:checked + .text-label {
        background-color: ${palette.secondary.main};
        border: 3px solid ${palette.common.blue};
    }

    &:focus + .text-label {
        outline: solid 0.2rem ${palette.common.blue};
        outline-offset: 0.2rem;
    }
`;

export const StyledCTAs = styled('div')`
    position: sticky;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
    padding: 1rem 3rem 3rem;
    margin-top: auto;
    background-color: ${palette.grey[50]};

    &::before {
        position: absolute;
        top: -3rem;
        left: 0;
        width: 100%;
        height: 3rem;
        pointer-events: none;
        content: '';
        background: linear-gradient(
            to bottom,
            rgba(238, 243, 249, 0.0001) 0%,
            ${palette.grey[50]} 100%
        );
    }
`;

export const StyledSecondaryBaseButton = styled(ButtonBase)`
    background-color: transparent;
`;

export const calculateScrollPadding = () => {
    const stickyElements =
        document.querySelectorAll<HTMLElement>('[data-is-sticky]');

    let totalHeight = 0;

    stickyElements.forEach(element => {
        totalHeight += element.offsetHeight;
    });

    return totalHeight + 15;
};

import { styled } from '@mui/material/styles';

export const StyledDetails = styled('details')`
    width: 100%;
    padding: 2.4rem 2rem 2rem;
    cursor: pointer;
    & ::-webkit-details-marker {
        display: none;
    }
`;

export const StyledSummary = styled('summary')`
    list-style: none;
`;

export const StyledContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledHeading = styled('p')`
    margin: 0;
    font-family: thegymgroupsans-Headline;
    font-size: 1.4rem;

    text-transform: lowercase;
`;

import { OptimizelyFeatureFlags } from '@tgg/common-types';

import { AnyContext } from '../cookies';

import { getOptimizelyUserId } from './cookie/featureFlagsCookie';
import {
    FlagsConstants,
    TggFlagsObject,
    type FeatureFlagsRequestOptions,
} from './featureFlags.types';
import { getFeatureFlagRequestOptions } from './helpers/featureFlagsHelpers';
import { optimizelyFlagsGetterTggWrapper } from './optimizely/optimizelyApi';

type FeatureFlagsRequestOverrides = {
    overrideUserId: string;
    pathname: string;
};

export const getAllFeatureFlagsSsr = async (
    context: AnyContext,
    options: FeatureFlagsRequestOptions & {
        overrides?: Partial<FeatureFlagsRequestOverrides>;
        isSysCall?: boolean;
    },
) => {
    const { logger, loggerMetaData, overrides, isSysCall } = options;
    const { pathname } = getFeatureFlagRequestOptions(context);
    const { resolvedId: userId } = await getOptimizelyUserId(context);
    const loggerParameters = {
        ...loggerMetaData,
        operation: 'getAllFeatureFlagsSsr',
    };

    const flagsUserId = isSysCall
        ? FlagsConstants.ROOT
        : overrides?.overrideUserId || userId;

    const flagsPathname = overrides?.pathname || pathname;

    const userAgent = isSysCall
        ? FlagsConstants.TGG_USER_AGENT
        : context.req?.headers['user-agent'];

    const flagsObject = await optimizelyFlagsGetterTggWrapper(
        'ALL',
        flagsUserId,
        {
            logger,
            loggerParameters,
            userAttributes: {
                pathname: flagsPathname,
                $opt_user_agent: userAgent,
            },
        },
    );

    return {
        flags: flagsObject.result,
        pathname: flagsPathname,
        userId: flagsUserId,
        error: flagsObject.error,
    };
};

export const getFeatureFlagSsr = async <T extends OptimizelyFeatureFlags>(
    context: AnyContext,
    key: T,
    options: FeatureFlagsRequestOptions & {
        overrides?: Partial<FeatureFlagsRequestOverrides>;
        isSysCall?: boolean;
    },
) => {
    const { pathname } = getFeatureFlagRequestOptions(context);
    const { resolvedId: userId } = await getOptimizelyUserId(context);

    const { loggerMetaData, overrides, logger, isSysCall } = options;
    const loggerParameters = {
        ...loggerMetaData,
        operation: 'getFeatureFlagSsr',
    };
    const flagsUserId = isSysCall
        ? FlagsConstants.ROOT
        : overrides?.overrideUserId || userId;

    const flagsPathname = overrides?.pathname || pathname;

    const userAgent = isSysCall
        ? FlagsConstants.TGG_USER_AGENT
        : context.req?.headers['user-agent'];

    const flagResult = await optimizelyFlagsGetterTggWrapper(key, flagsUserId, {
        logger,
        loggerParameters,
        userAttributes: {
            pathname: flagsPathname,
            $opt_user_agent: userAgent,
        },
    });

    return {
        flag: flagResult.result as TggFlagsObject[T],
        pathname: flagsPathname,
        userId: flagsUserId,
        error: flagResult.error,
    };
};

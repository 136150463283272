import { styled } from '@mui/material';

import { palette } from '../../theme';
import { Heading } from '../Heading';

export const StyledInfoContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1.9rem;
    background-color: ${palette.grey[50]};
    border-radius: 0.8rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 2rem;
    }
`;

export const StyledHeaderSection = styled('div')<{ $isClosed?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${({ $isClosed }) => ($isClosed ? '0' : '0.4rem')};
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: ${({ $isClosed }) => ($isClosed ? '0' : '0.8rem')};
    }
`;

export const StyledHeader = styled(Heading)`
    font-size: 1.4rem;
    color: ${palette.primary.main};
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;

export const StyledIconButton = styled('button')`
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
`;

import { ErrorCodes, Nullable, PaymentCategory } from '@tgg/common-types';

export enum ClickEvent {
    ACCORDION_CLICK = 'accordionClick',
    CTA_BUTTON = 'cta_button',
    CTA_BUTTON_CLICK = 'ctaButton',
    DASHBOARD_JOIN_NOW = 'dashboardJoinNow',
    FOOTER_LINK = 'footer_link',
    FOOTER_SECTION_EXPAND = 'footer_section_expand',
    GYM_PAGE_JOIN_NOW = 'gymPageJoinNow',
    GYM_SORT = 'gym_sort',
    LINK_CLICK = 'link_click',
    MENU_CLOSE = 'menuClose',
    MENU_LEVEL_DOWN = 'menuLevelDown',
    MENU_LEVEL_UP = 'menuLevelUp',
    MENU_LINK = 'menuLink',
    MENU_OPEN = 'menuOpen',
    PROPOSITION_THUMBNAIL_LINK = 'proposition_thumbnail_link',
    SELECT_CONTENT = 'select_content',
    TEXT_LINK = 'textLink',
    THINGS_TO_CONSIDER_CTA = 'things_to_consider_cta',
    TRANSPORT_SELECTION = 'transportSelection',
    ANCHOR_LINK_CLICK = 'anchor_link_click',
}

export enum ScrollEvent {
    TESTIMONIAL_SCROLL = 'testimonialScroll',
    IMAGE_SCROLL = 'imageScroll',
    ANCHOR_LINK_SCROLL = 'anchor_link_scroll',
}

export enum SyntheticEvent {
    CHECKOUT_STEP = 'checkout-step',
    DISCOUNT_CODE_SUCCESS = 'discountCodeSuccess',
    ERROR = 'errorTracking',
    VIRTUAL_PAGE_VIEW = 'virtualPageView',
    PURCHASE = 'purchase',
    ADD_TO_BASKET = 'add-to-basket',
    REMOVE_FROM_BASKET = 'remove-from-basket',
    PRODUCT_DETAIL = 'product-detail',
    PROMO_CODE_COPY = 'promoCodeCopy',
    '3DS_challenge' = '3DS_challenge',
    REJOIN_LOGIN = 'rejoin_login',
    REJOIN_LOGIN_SUCCESS = 'rejoin_login_success',
    FORM_SUBMIT = 'form_submit',
    OPEN_FILTERS = 'open_filters',
    APPLY_FILTER = 'apply_filter',
    REMOVE_FILTER = 'remove_filter',
    CLEAR_FILTERS = 'clear_filters',
    VIEW_ITEM = 'view_item',
    REMOVE_FROM_CART = 'remove_from_cart',
    ADD_TO_CART = 'add_to_cart',
}

export enum MapEvent {
    MAP_PIN = 'mapPin',
    MAP_PIN_CTA = 'mapPinCTA',
    MAP_PIN_NAME = 'mapPinName',
    MAP_LOCATION_SEARCH = 'locationSearch',
    FAG_LOCATION_SEARCH = 'location_search',
}

export enum GymTileEvent {
    GYM_TILE = 'location_list',
    GYM_LIST = 'gymList',
}

export enum OptimizelyDataLayerEvent {
    FLAG_DECISION_FX = 'optimizely-decision-fx',
    FLAG_TRACKING_FX = 'optimizely-tracking-fx',
}

export const EventKey = {
    ...ClickEvent,
    ...ScrollEvent,
    ...SyntheticEvent,
    ...MapEvent,
    ...GymTileEvent,
    ...OptimizelyDataLayerEvent,
};

export type EventKeyType = keyof typeof EventKey;
export type EventType = (typeof EventKey)[EventKeyType];

export type TargetedElement = {
    dataset: {
        analyticsEvent: EventType;
        analyticsPayload?: string;
    };
};

export type AnalyticsHTMLParameters = Partial<{
    'data-analytics-event': EventType;
    'data-analytics-payload': string;
}>;

export type ClickableElement = HTMLElement | SVGElement;
export type AnalyticsElement = TargetedElement & ClickableElement;

export type ProductDetails = {
    name: string;
    price: number;
    brand: string;
    category: 'OneOff' | 'Monthly' | 'Add Ons';
    variant: string;
    quantity: number;
};

export type ProductViewEvent = {
    event: 'add-to-basket' | 'product-detail';
    ecommerce: {
        [key: string]: {
            products: ProductDetails[];
        };
    };
};

export type ProductRemoveEvent = {
    event: 'remove-from-basket';
    ecommerce: {
        [key: string]: {
            products: ProductDetails[];
        };
    };
};

export type ViewItemEvent = {
    event: 'add_to_cart' | 'view_item' | 'remove_from_cart';
    items: {
        item_name: string;
        price: number;
        item_brand: string; //Dynamic - Collected from the member info//
        item_category: string;
        item_variant: string; //flag which area of the site they are in//
        toggle_state: boolean; //Boolean - true = on & false = no //
        quantity: number;
    }[];

    journey_type: string;
    current_membership_product: string;
};

export type GenericEvent = {
    event: EventType;
    [key: string]: any;
};

export type EventsMap = {
    [key in EventType]?: (
        payload: string,
        elementReference: TargetedElement,
    ) => GenericEvent;
};

export interface CtaButtonClickPayload {
    ctaPosition: string;
    ctaName: string;
}

export interface AccordionClickPayload {
    accordionContent: string;
}
export interface PaymentsType {
    event: PaymentsEventTypes;
    paymentType: 'directDebit' | 'card' | 'paypal';
}

export interface RenewalPaymentsType {
    event: PaymentsEventTypes;
    payment_type: 'directDebit' | 'card' | 'paypal';
    journey_type: 'renew membership';
}

export type PaymentsEventTypes =
    | 'monthly_payments_type'
    | 'your_payments_type'
    | 'changeReccuringCardType';

export interface GlobalPayload {
    loggedInStatus: 'member logged in' | 'not logged in';
    gaClientId: string;
    gaSessionId: string;
    adBlock: string;
    memberId: string;
    memberStatus: string;
}

interface YourMembershipPayload {
    products?: ProductCheckoutStep1[]; // TODO: Implement Add-ons
    joiningFee?: number | '';
    monthlyFee?: number | '';
    oneOffFee?: number | '';
}
export interface CheckoutStepPayload<T = void> {
    ecommerce: {
        checkout: {
            actionField: { step: number };
        } & T;
    };
}

export interface UserInformation {
    fn?: string;
    ln?: string;
    em?: string;
    ph?: string;
    ph_plus?: string;
    ct?: string;
    zp?: string;
    db?: string;
    ge?: string;
}

export type CheckoutSteps =
    | CheckoutStepPayload
    | CheckoutStepPayload<YourMembershipPayload>
    | CheckoutStepPayload<UserInformation>;

export interface CheckoutEventDiscount {
    discountCode: string;
}

export interface AnalyticsErrorEvent {
    [key: string]: unknown;
    category: ErrorCodes;
    details: {
        title: string;
        description: string;
    };
}

export type ProductCheckoutStep1 = {
    name: string;
    price: number | '';
    brand: string;
    category: PaymentCategory;
    variant: 'Create';
    quantity: number | '';
};

export interface PlayStoreBadgeClickPayload {
    appPlatform: 'play' | 'app';
}

export interface GymPageJoinNowPayload {
    ctaPosition: string;
    membershipType: string;
    membershipProduct: string;
    joinNowGym: string;
    joiningFee: number | string;
    monthlyFee: number | string;
    oneOffFee: number | string;
    promoJoiningFee: string;
    promoMonthlyFee: string;
    promoOneOffFee: string;
    promo: string;
}

export interface VirtualPageViewPayload {
    virtualPageURL: string;
    virtualPageTitle: string;
}

export interface ErrorTrackingAnalyticsData {
    errorCategory: string;
    errorDetail?: string;
}

export interface PurchasePayload {
    ecommerce: {
        purchase: {
            actionField: { id: string; revenue: string; coupon?: string };
            products?: ProductCheckoutStep1[];
        };
    };
}

export interface VideoAnalyticsData {
    event: string;
    videoName: string;
    videoDuration: number;
    videoCurrentTime?: number;
    videoPercentage?: number;
}

export interface StoryBoxCTAAnalyticsData {
    event: 'cta_button';
    component_name: 'story box';
    content_title: string;
    cta_destination: string;
    cta_text: string;
    cta_position: string;
}

export type AnalyticsDataTypes =
    | VideoAnalyticsData
    | VirtualPageViewPayload
    | ErrorTrackingAnalyticsData;

export interface MenuLevelActionPayload {
    menuText: string;
    menuParent: string;
    menuContext: string;
}

export interface SideNavMenuStatePayload {
    menuContext: string;
}

export interface FindAGymSortBy {
    sort_by: string;
}

export interface SideNavRedirectionData {
    menuText: string;
    menuParent: string;
    menuContext: string;
    menuDestination: string;
}

export interface PromoCodeCopyPayload {
    promoCode: string;
    branchName: string;
}

export interface ImageScrollPayload {
    scrollDirection: string;
    imagePosition: string;
    branchName: string;
}

export interface CtaButtonClickWithBranchPayload {
    ctaPosition: string;
    ctaName: string;
    branchName: string;
}

export interface CtaButtonCancelMembershipPayload {
    cta_position: string;
    cta_name: string;
    cancel_category: string;
    cancel_reason: string;
    current_home_gym: string;
    current_membership_product: string;
    current_monthly_payment_type: string;
}

export interface CtaFindMyLocationButtonClick {
    ctaName: string;
}

export interface MapLocationSearch {
    locationSearchTerm: string;
}

export interface TextLinkPayload {
    linkText: string;
}

export interface PropositionThumbnailLinkPayload {
    title: string;
    link_destination: string;
    position: string;
    link_text: string;
}

export interface CtaButtonClick {
    cta_position: string;
    cta_name: string;
    page_name: string;
}

export interface RegisterYourInterestFormSubmit {
    form_id: string;
    form_name: string;
    form_submit_text: string;
    interested_gym: string;
    product_interested_in: string;
    email_optin: boolean;
    sms_optin: boolean;
}

export interface RegisterPTsInterestFormSubmit {
    form_id: string;
    form_name: string;
    form_submit_text: string;
    is_member: boolean;
    gym: string;
}

export interface CtaButtonRenewMembershipPayload {
    cta_name: string;
    current_home_gym: string;
    cta_position: Nullable<string>;
    current_membership_product?: string;
    days_left?: number;
    renewal_price?: number;
    renewal_discount_percent?: number;
}

export interface ManagePaymentsTabPayload {
    content_type: 'tab';
    item_id: 'payment details' | 'payment history';
    current_home_gym: string;
    current_membership_product: string;
    current_monthly_payment_type: string;
}

export interface BenefitsOverlayPayload {
    content_type: 'info icon';
    content_id: string;
    cta_text: 'Benefits' | 'More about benefits';
}

export interface SearchMyLocationIconPayload {
    location_search_term: string;
    search_results: number;
    component_name: 'find a gym';
}

export interface UseMyLocationPayload {
    link_destination: null;
    link_text: 'Use my current location';
    component_name: 'find a gym';
}

export interface SearchMyLocationCTAPayload {
    cta_name: 'find your gym';
    component_name: 'find a gym';
}

export interface SeeMoreGymsLinkPayload {
    link_destination: string;
    link_text: 'see more gyms near you';
    component_name: 'find a gym';
}

import { alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Palette, palette, theme } from '../../theme';
import { Icon } from '../Icon';

import {
    StyledList,
    StyledListContent,
    StyledHeading,
    StyledHeadingWrapper,
    StyledIconContainer,
    StyledParagraph,
} from './BenefitsTable.styled';
import { BenefitsTableProperties } from './BenefitsTable.types';

interface BenefitRowProperties {
    children: string;
    iconColor: Palette;
    align: 'left' | 'center';
}

export const BenefitRow = ({
    children,
    iconColor,
    align,
}: BenefitRowProperties) => {
    const benefitContainsLetters = /[A-Za-z]/.test(children);
    const iconName = benefitContainsLetters ? 'tick' : 'blank';

    return (
        <>
            <div />
            <StyledListContent $align={align} aria-label="Benefit description">
                <StyledIconContainer>
                    <Icon name={iconName} color={iconColor} size={22} />
                </StyledIconContainer>

                <StyledParagraph gutterBottom={false}>
                    {children}
                </StyledParagraph>
            </StyledListContent>
            <div />
        </>
    );
};

/**
 * list of items with alternating colour for each row.
 */
export function BenefitsTable({
    title = '',
    benefits,
    variant,
    align = 'left',
}: BenefitsTableProperties) {
    const styleMap = {
        green: alpha(palette.secondary.main, 0.1),
        blue: alpha(palette.primary.main, 0.1),
        'alternative-green': alpha(palette.secondary.main, 0.1),
    };

    const iconColorsMap = {
        green: palette.secondary.main,
        blue: palette.primary.main,
        'alternative-green': palette.secondary.main,
    };

    const titleForId = title.split(' ').join('-');
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const backgroundColor = styleMap[variant];
    const iconColor = iconColorsMap[variant];

    return (
        <>
            {title && (
                <StyledHeadingWrapper
                    data-testid="benefits-table-label"
                    id={`benefits-table-label-${titleForId}`}
                    $align={isDesktop ? align : 'left'}
                >
                    <StyledHeading gutterBottom={false}>{title}</StyledHeading>
                </StyledHeadingWrapper>
            )}
            <StyledList
                role="list"
                $backgroundColor={backgroundColor as Palette}
                {...(title && {
                    'aria-describedby': `benefits-table-label-${titleForId}`,
                })}
            >
                {benefits.map((benefit, index) => {
                    return (
                        <BenefitRow
                            key={`${title}-${benefit}-${index}`}
                            iconColor={iconColor}
                            align={align}
                        >
                            {benefit}
                        </BenefitRow>
                    );
                })}
            </StyledList>
        </>
    );
}

export default BenefitsTable;

import { palette } from '../../theme';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';

import {
    StyledAddonImageContainer,
    StyledContainer,
    StyledIconContainer,
    StyledImageCard,
    StyledImageStandalone,
    StyledSlotText,
    StyledTextContainer,
} from './MembershipInfo.styled';
import { AddonCardProperties } from './MembershipInfo.types';

export const ImageCard = ({
    addons,
    pillText,
}: {
    addons: AddonCardProperties[];
    pillText?: string;
}) => {
    return (
        <StyledContainer>
            {addons.map(
                ({ title, subTitle, image, showPadlockIcon }, index) => {
                    const imageProperties = {
                        ...image,
                        width: 96,
                        height: 80,
                    };
                    return (
                        <StyledImageCard key={`$addon-${title}-${index}`}>
                            <StyledAddonImageContainer>
                                <StyledImageStandalone {...imageProperties} />
                                <StyledTextContainer>
                                    <StyledSlotText
                                        $textColor="primary"
                                        gutterBottom={false}
                                        variant="h4"
                                    >
                                        {title}
                                    </StyledSlotText>
                                    <Paragraph gutterBottom={false}>
                                        {subTitle}
                                    </Paragraph>
                                </StyledTextContainer>
                            </StyledAddonImageContainer>
                            {showPadlockIcon && (
                                <StyledIconContainer>
                                    <Icon
                                        ariaHidden={true}
                                        name={'padlock'}
                                        color={palette.common.blue}
                                        size={16}
                                    />
                                </StyledIconContainer>
                            )}
                        </StyledImageCard>
                    );
                },
            )}
        </StyledContainer>
    );
};

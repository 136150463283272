import { GenericErrorMessage, Layout } from '@tgg/ui';

import image from '../../public/ywnqh/images/skipping-rope.png';

export function FiveHundred() {
    return (
        <Layout>
            <GenericErrorMessage
                mainHeading="Uh-Oh!"
                heading="Something must be tangled"
                contactLink="/contact-us/"
                primaryButtonText="go to homepage"
                primaryLink="/"
                secondaryButtonText="find a gym"
                secondaryLink="/find-a-gym"
                imageSourceDesktop={image.src}
                imageSourceMobile={image.src}
            />
        </Layout>
    );
}

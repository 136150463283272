import { PaymentError } from '@tgg/common-types';

import { CardPaymentFormLogging } from '../../CardPayment.types';

export type LogPaymentIssue = {
    error: unknown;
    log: CardPaymentFormLogging;
};

export const logPaymentIssue = ({ error, log }: LogPaymentIssue) => {
    const { logger, loggerParameters } = log;

    /* istanbul ignore next */
    if (!error) return;

    if (error instanceof PaymentError) {
        const { code, message, originalError } = error;
        const parsedError = originalError
            ? JSON.stringify(originalError)
            : 'No origin error data present';

        logger.error(
            `Payment failed: 
            code: ${code},
            message: ${message},
            error: ${parsedError}`,
            loggerParameters,
        );
    } else {
        logger.error(
            `Payment failed:
            message: ${(error as Error).message}
            error: ${JSON.stringify(error)}`,
            loggerParameters,
        );
    }
};

import { styled } from '@mui/material/styles';

import { palette } from '../../theme';

export const StyledUl = styled('ul')`
    padding-left: 1.8rem;

    li::marker {
        color: ${palette.primary.main};
    }

    li {
        padding-left: 0.8rem;
        margin-bottom: 1rem;

        p {
            display: inline-block;
            vertical-align: text-top;
        }

        ul {
            margin: 2rem 0 2rem 2.7rem;
            color: ${palette.common.blue};

            li::marker {
                color: ${palette.primary.main};
            }

            li {
                margin-bottom: 0;
            }
        }
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 2rem;
    }
`;

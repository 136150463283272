import { EventKey, CtaButtonClickPayload } from '@tgg/services';

import { Paragraph } from '../Paragraph';

import {
    StyledButton,
    StyledCTASection,
    StyledHeadingContent,
    StyledHeading,
    StyledWrapper,
    StyledImageStandalone,
    StyledParagraph,
    StyledGymLocationHeading,
} from './ContentTile.styled';
import { ContentTileProperties } from './ContentTile.types';

/**
 * The `ContentTile` component contains an image, title, label above the title, as well as up to two CTAs (primary and secondary style)
 */
export function ContentTile({
    id,
    title,
    description,
    image,
    buttons = [],
    children,
    alignText = 'center',
    fullWidth = false,
    gymLocation,
    headingVariant = 'h4',
    extraPadding = false,
}: ContentTileProperties) {
    const [firstButtonProperties, secondButtonProperties] = buttons;

    return (
        <StyledWrapper
            id={id}
            data-testid="contentTileWrapper"
            $fullWidth={fullWidth}
        >
            <StyledImageStandalone {...image} />
            <StyledHeadingContent
                data-testid="headingContent"
                $extraPadding={extraPadding}
                $alignText={alignText}
            >
                {title && (
                    <StyledHeading
                        variant={headingVariant}
                        gutterBottom={false}
                    >
                        {title}
                    </StyledHeading>
                )}
                {description && (
                    <StyledParagraph gutterBottom={!!gymLocation}>
                        {description}
                    </StyledParagraph>
                )}
                {gymLocation && (
                    <>
                        <StyledGymLocationHeading
                            variant="body2"
                            gutterBottom={false}
                        >
                            From The Gym Group Team
                        </StyledGymLocationHeading>
                        <Paragraph variant="subtitle1" gutterBottom={false}>
                            {gymLocation}
                        </Paragraph>
                    </>
                )}
                {(firstButtonProperties || secondButtonProperties) && (
                    <StyledCTASection>
                        {firstButtonProperties && (
                            <StyledButton
                                size="medium"
                                fullWidth
                                buttonStyle="secondary-blue-white"
                                {...firstButtonProperties}
                                data-analytics-event={EventKey.CTA_BUTTON_CLICK}
                                data-analytics-payload={JSON.stringify({
                                    ctaName: firstButtonProperties.text,
                                    ctaPosition: 'ContentTile',
                                } as CtaButtonClickPayload)}
                            />
                        )}
                        {secondButtonProperties && (
                            <StyledButton
                                size="medium"
                                fullWidth
                                buttonStyle="secondary-blue-white"
                                {...secondButtonProperties}
                                data-analytics-event={EventKey.CTA_BUTTON_CLICK}
                                data-analytics-payload={JSON.stringify({
                                    ctaName: secondButtonProperties.text,
                                    ctaPosition: 'ContentTile',
                                } as CtaButtonClickPayload)}
                            />
                        )}
                    </StyledCTASection>
                )}
                {children}
            </StyledHeadingContent>
        </StyledWrapper>
    );
}

export default ContentTile;

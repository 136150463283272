import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';

import { Asterisk } from '../../Asterisk';

import {
    StyledFormLabel,
    StyledRadio,
    StyledWrapper,
} from './ControlledRadioGroup.styled';
import { ControlledRadioGroupProperties } from './ControlledRadioGroup.types';

export const ControlledRadioGroup = ({
    control,
    radioGroupFieldName,
    radioGroupAriaLabel,
    rules,
    isRow,
    isRequired,
    items,
}: ControlledRadioGroupProperties) => {
    const { radioGroupLabel, radioGroupItems } = items;
    return (
        <StyledWrapper>
            <FormControl component="fieldset">
                <StyledFormLabel>
                    {radioGroupLabel} {isRequired && <Asterisk spaceBefore />}
                </StyledFormLabel>
                <Controller
                    name={radioGroupFieldName}
                    control={control}
                    rules={rules}
                    render={({ field }) => {
                        return (
                            <RadioGroup
                                row={isRow}
                                data-testid={`radio-group-${radioGroupFieldName}`}
                                placeholder="select either option"
                                aria-label={radioGroupAriaLabel}
                                {...field}
                            >
                                {radioGroupItems.map(({ value, label }) => (
                                    <FormControlLabel
                                        value={value}
                                        key={value}
                                        control={<StyledRadio />}
                                        label={label}
                                    />
                                ))}
                            </RadioGroup>
                        );
                    }}
                />
            </FormControl>
        </StyledWrapper>
    );
};

import { palette } from '../../theme';
import { Icon } from '../Icon';

import {
    StyledLinkWrapper,
    StyledIconWrapper,
    StyledSpan,
} from './SocialIcon.styled';
import { SocialIconProperties } from './SocialIcon.types';

/**
 * Use the SocialIcon component to display a clickable social media icon.
 */
export const SocialIcon = ({
    variant,
    href,
    fullSize = false,
    ariaLabel,
    linkName,
    className,
    label,
    onClick,
    ...rest
}: SocialIconProperties) => {
    const iconSize = fullSize ? 42 : 20;

    return (
        <StyledLinkWrapper
            onClick={onClick}
            href={href}
            rel="noreferrer noopener"
            target="_blank"
            aria-label={ariaLabel}
            data-link-name={linkName}
            className={className}
        >
            <StyledIconWrapper>
                <Icon
                    name={variant}
                    size={iconSize}
                    color={palette.common.white}
                    {...rest}
                />
            </StyledIconWrapper>

            {label && <StyledSpan>{label}</StyledSpan>}
        </StyledLinkWrapper>
    );
};

import {
    StyledMiniCard,
    StyledSlot,
    StyledSlotText,
} from './MembershipInfo.styled';

export const MiniCard = ({ titles }: { titles: string[] }) => {
    return (
        <StyledMiniCard>
            {titles.map((title, index) => (
                <StyledSlot key={index}>
                    <StyledSlotText gutterBottom={false} variant="h4">
                        {title}
                    </StyledSlotText>
                </StyledSlot>
            ))}
        </StyledMiniCard>
    );
};

import { useState, useEffect } from 'react';

import { axiosInstance } from '@tgg/micro-services/axios';
import { relativeUrlWithBasePath } from '@tgg/util';

export function useGymLocationMap(
    latitude: number | undefined,
    longitude: number | undefined,
    isDesktop: boolean,
) {
    const [mapWidth, setMapWidth] = useState(0);
    const [signedUrl, setSignedUrl] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const height = isDesktop ? 400 : 192;
                const response = await axiosInstance.get(
                    relativeUrlWithBasePath(
                        `/api/proxy/sign-static-map?latitude=${latitude}&longitude=${longitude}&width=${mapWidth}&height=${height}`,
                    ),
                );
                const { data } = response;
                setSignedUrl(data.signedUrl);
            } catch (error) {
                console.error(
                    'Error fetching signed URL in GymLocation',
                    error,
                );
            }
        };

        if (mapWidth > 0) {
            // eslint-disable-next-line no-void
            void fetchData();
        }
    }, [latitude, longitude, mapWidth, isDesktop]);

    const handleResize = () => {
        setMapWidth(Math.min(window.innerWidth, 640));
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return signedUrl;
}

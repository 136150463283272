import { DayOffPeakTimes } from '@tgg/common-types';
import { dateStringTo12HourTime } from '@tgg/util';

import {
    StyledOffPeakTimesText,
    StyledRenderOffPeakTimesContainer,
} from '../GymLocation.styled';

interface RenderOffPeakTimesProperties {
    time: DayOffPeakTimes;
}

export const RenderOffPeakTimes = ({ time }: RenderOffPeakTimesProperties) => {
    return (
        <StyledRenderOffPeakTimesContainer key={time.period}>
            <StyledOffPeakTimesText>
                {dateStringTo12HourTime(new Date(time.openingTime))}
            </StyledOffPeakTimesText>
            <div>-</div>
            <StyledOffPeakTimesText>
                {dateStringTo12HourTime(new Date(time.closingTime))}
            </StyledOffPeakTimesText>
        </StyledRenderOffPeakTimesContainer>
    );
};

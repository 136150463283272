/* eslint-disable consistent-return */
/* eslint-disable default-case */
import { alpha, styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { Heading } from '../Heading';
import { Icon } from '../Icon';

export const StyledCarousel = styled('div')`
    position: relative;
    display: flex;
    width: 100%;
    height: 25rem;
    overflow-x: hidden;
    touch-action: pinch-zoom;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        height: 54.4rem;
    }
`;

interface TileProperties {
    $position:
        | 'previous'
        | 'current'
        | 'next'
        | 'offscreen-right'
        | 'offscreen-left';
    $addOffset?: boolean;
}

export const StyledTile = styled('div')<TileProperties>`
    position: absolute;
    display: flex;
    flex-direction: column;
    visibility: ${properties =>
        (properties.$position === 'offscreen-left' ||
            properties.$position === 'offscreen-right') &&
        'hidden'};
    transition: all 0.3s ease-in-out;
    transform: translateX(
        ${properties => {
            switch (properties.$position) {
                case 'previous': {
                    return '-100%';
                }
                case 'next': {
                    return '100%';
                }
                case 'current': {
                    return '0';
                }
                case 'offscreen-left': {
                    return '-200%';
                }
                case 'offscreen-right': {
                    return '200%';
                }
            }
        }}
    );

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        border-right: 0.2rem solid ${palette.common.white};
        border-left: 0.2rem solid ${palette.common.white};

        transform: translateX(
            ${properties => {
                if (!properties.$addOffset) return '';

                const adjacentTilePreviewWidth = '7.6rem';
                switch (properties.$position) {
                    case 'previous': {
                        return `calc(-100% + ${adjacentTilePreviewWidth})`;
                    }
                    case 'next': {
                        return `calc(100% + ${adjacentTilePreviewWidth})`;
                    }
                    case 'current': {
                        return adjacentTilePreviewWidth;
                    }
                    case 'offscreen-left': {
                        return `calc(-200% + ${adjacentTilePreviewWidth})`;
                    }
                    case 'offscreen-right': {
                        return `calc(200% + ${adjacentTilePreviewWidth})`;
                    }
                }
            }}
        );
    }
`;

const StyledSubtitle = styled(Heading)`
    width: fit-content;
    padding: 0.2rem 0.5rem;
    color: ${palette.common.blue};
    background-color: ${({ theme }) => alpha(palette.common.white, 0.5)};
    border-radius: 0.4rem;
`;

export const OverlayedStyledSubtitle = styled(StyledSubtitle)`
    position: absolute;
    top: 3rem;
    right: 50%;
    display: inline-block;
    margin-right: 0;
    margin-left: 0;
    transform: translateX(50%);
`;

const StyledPagination = styled('span')`
    padding: 0.1rem 1rem;
    font-size: 1rem;
    /* TODO add this to theme under Text Small? */
    color: ${palette.common.blue};
    text-align: center;
    text-transform: uppercase;
    background-color: ${palette.common.white};
    border-radius: 0.4rem;
    opacity: 0.75;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 1rem 2rem;
        font-size: 1.6rem;
        opacity: 1;
    }
`;

export const OverlayedStyledPagination = styled(StyledPagination)`
    position: absolute;
    right: 50%;
    bottom: 2rem;
    transform: translateX(50%);
`;

interface ButtonProperties {
    disabled: boolean;
}

export const StyledIcon = styled(Icon)`
    cursor: inherit;
`;

const StyledPaginationButton = styled('button')<ButtonProperties>`
    position: absolute;
    bottom: calc(50% - 3rem);
    width: 4rem;
    height: 6rem;
    padding: 1.5rem 0.3rem 1.3rem 0.5rem;
    background-color: ${palette.common.white};
    border: none;
    border-radius: 0.4rem;
    ${properties => !properties.disabled && 'cursor: pointer;'}
`;
export const StyledNextButton = styled(StyledPaginationButton)`
    right: 5rem;
`;
export const StyledPreviousButton = styled(StyledPaginationButton)`
    left: 5rem;
`;

import { v4 as uuidv4 } from 'uuid';

import { palette } from '../../theme';
import { Icon } from '../Icon';
import { UnorderedList } from '../UnorderedList';

import {
    StyledAlertMessageContainer,
    StyledContentContainer,
    Styledlink,
} from './AlertMessage.styled';
import { AlertMessageProperties } from './AlertMessage.types';
/**
 * An Alert Message component to display an alert icon, alert message, an optional link and text in bullet point format.
 */
export const AlertMessage = ({
    type,
    text,
    bulletPoints,
    linkText,
    linkUrl,
    children,
    ...otherProperties
}: AlertMessageProperties) => {
    return (
        <StyledAlertMessageContainer
            type={type}
            {...otherProperties}
            data-testid="alert-container"
        >
            <div>
                {type === 'information' && (
                    <Icon
                        name="information"
                        color={palette.primary.main}
                        size={24}
                    />
                )}
                {type === 'alert' && (
                    <Icon name="alert" color={palette.error.main} size={24} />
                )}
            </div>
            <StyledContentContainer>
                {text && (
                    <>
                        {text}
                        {linkText && (
                            <>
                                ,
                                <Styledlink href={linkUrl}>
                                    {linkText}
                                </Styledlink>
                            </>
                        )}
                        .
                    </>
                )}
                {bulletPoints && Array.isArray(bulletPoints) && (
                    <UnorderedList>
                        {bulletPoints.map(item => (
                            <li key={uuidv4()}>{item}</li>
                        ))}
                    </UnorderedList>
                )}
                {children}
            </StyledContentContainer>
        </StyledAlertMessageContainer>
    );
};

export default AlertMessage;

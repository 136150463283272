/* istanbul ignore file */
import { styled } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers';

export const StyledDateInputContainer = styled('div')`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 2.5rem;
`;

export const StyledDateInput = styled(MobileDatePicker)`
    margin-bottom: 3rem;
    font-size: 1.6rem;

    fieldset {
        border-style: solid;
        border-width: 0.1rem;
        border-radius: 2.5rem;
    }

    input {
        padding: 1.5rem;
    }

    svg,
    input,
    fieldset {
        cursor: pointer;
    }
`;

export const StyledHelperText = styled('span')`
    font-size: 1.2rem;
    line-height: 1.8rem;
`;

export const StyledDialogContainer = styled('div')`
    .MuiDialog-paper {
        border-radius: 1.6rem;
    }

    .MuiTypography-overline {
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: ${({ theme }) => theme.palette.common.blue};
        text-transform: initial;
    }

    .MuiDatePickerToolbar-title {
        margin-top: 0.5rem;
        font-size: 3.6rem;
        line-height: 4rem;
        letter-spacing: -0.005rem;
    }

    .MuiPickersToolbar-penIconButton {
        display: none;
    }

    .MuiPickersCalendarHeader-label {
        font-family: thegymgroupsans-Headline;
        font-size: 1.6rem;
        line-height: 2rem;
        letter-spacing: -0.001rem;
        cursor: text;
    }

    .MuiPickersArrowSwitcher-button {
        color: ${({ theme }) => theme.palette.common.blue};
    }

    .MuiPickersArrowSwitcher-button:last-child {
        margin-left: 2rem;
    }

    .MuiPickersArrowSwitcher-button.Mui-disabled {
        color: ${({ theme }) => theme.palette.common.blue};
        opacity: 0.38;
    }

    .MuiDayPicker-weekDayLabel {
        font-size: 1.4rem;
        color: ${({ theme }) => theme.palette.common.blue};
        opacity: 0.38;
    }

    .MuiPickersDay-root {
        font-size: 1.4rem;
        line-height: normal;
        color: ${({ theme }) => theme.palette.common.blue};
    }

    .MuiPickersDay-root.MuiPickersDay-today {
        border: 1px solid ${({ theme }) => theme.palette.common.blue};
    }

    .MuiPickersDay-root.Mui-disabled {
        color: ${({ theme }) => theme.palette.common.blue};
        opacity: 0.38;
    }

    .MuiPickersDay-root.Mui-selected {
        color: ${({ theme }) => theme.palette.common.white};
    }

    .MuiPickersToolbar-root {
        padding: 2rem 2rem 0;
    }

    .MuiDialogActions-root {
        .MuiButton-root {
            display: inline-block;
            min-width: auto;
            padding: 0.4rem 1.6rem;
            font-family: thegymgroupsans-Headline;
            font-size: 1.6rem;
            text-transform: lowercase;
            border-radius: 36rem;
        }

        .MuiButton-root:first-of-type {
            color: ${({ theme }) => theme.palette.common.blue};
        }

        .MuiButton-root:last-of-type {
            color: ${({ theme }) => theme.palette.common.white};
            background-color: ${({ theme }) => theme.palette.primary.main};
        }

        .MuiButton-root:first-of-type:focus,
        .MuiButton-root:first-of-type:hover {
            outline: 0.25rem solid ${({ theme }) => theme.palette.common.blue};
            outline-offset: 0.25rem;
        }

        .MuiButton-root:last-of-type:focus {
            outline: 0.25rem solid ${({ theme }) => theme.palette.primary.dark};
            outline-offset: 0.25rem;
        }

        .MuiButton-root:last-of-type:hover {
            background-color: ${({ theme }) => theme.palette.primary.dark};
        }
    }
`;

import { styled } from '@mui/material/styles';

import { palette } from '../../theme';

export const StyledLinkText = styled('a')`
    padding-top: 1.5rem;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.8;
    color: ${palette.common.blue};
    text-decoration: none;
    text-transform: lowercase;
`;

export const StyledList = styled('ul')`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    padding: 0;
    margin: 0;
    list-style: none;
`;

export const StyledListItem = styled('li')`
    padding-top: 1.5rem;
`;

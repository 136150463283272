import { useRouter } from 'next/router';
import { ErrorBoundary } from 'react-error-boundary';

import { createTraceParameters } from '@tgg/util';

import { FiveHundred } from 'apps/commerce/compositions';
import { appLogger } from 'apps/commerce/core/appLogger';

import { RootErrorBoundaryProperties } from './RootErrorBoundary.types';

const errorHandler = (error: Error, info: { componentStack: string }) => {
    const loggerParameters = createTraceParameters({
        context: null,
        parameters: {
            operation: `root-error-boundary`,
        },
    });
    appLogger.error(error.message, loggerParameters);

    if (typeof window !== 'undefined' && window.appInsights) {
        const { appInsights } = window;

        appInsights.trackException({
            exception: error,
            properties: {
                componentStack: info.componentStack,
            },
        });
    }
};

export default function RootErrorBoundary({
    children,
}: RootErrorBoundaryProperties) {
    const router = useRouter();

    return (
        <ErrorBoundary
            FallbackComponent={FiveHundred}
            onError={errorHandler}
            resetKeys={[router.route]}
        >
            {children}
        </ErrorBoundary>
    );
}

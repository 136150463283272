import { styled } from '@mui/material/styles';

import { palette } from '../../theme';

export const StyledBreadcrumbs = styled('nav')`
    box-sizing: content-box;
    max-width: 128rem;
    padding: 2rem 3rem 0;
    margin: 0 auto 3rem;
    font-size: 1.4rem;
    font-weight: 700;
`;
export const StyledList = styled('ol')`
    padding-left: 0;
    margin: 0;
    line-height: 2rem;
    list-style: none;
`;

export const StyledListItem = styled('li')`
    display: inline;

    + li::before {
        display: inline-block;
        margin: 0 1rem;
        font-size: inherit;
        color: ${palette.primary.main};
        content: '/';
    }

    a {
        color: ${palette.common.blue};
        text-decoration: none;
    }
`;

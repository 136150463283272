import {
    CtaButtonClickPayload,
    CtaButtonClickWithBranchPayload,
    EventKey,
    dispatchEvent,
} from '@tgg/services';

import { ButtonBase } from '../base/ButtonBase';

import { ButtonCmsProperties } from './ButtonCms.types';

export enum CmsButtonEvents {
    CLASS_TIMETABLE = 'class timetable',
    JOIN_NOW = 'join now',
}

export const ButtonCms = ({
    gymName,
    ctaPosition,
    ...baseProperties
}: ButtonCmsProperties) => {
    const onClick = () => {
        const toDispatch = Object.values(CmsButtonEvents).find(entry =>
            baseProperties.text.toLowerCase().includes(entry),
        );

        if (toDispatch) {
            if (gymName) {
                dispatchEvent<CtaButtonClickWithBranchPayload>(
                    EventKey.CTA_BUTTON_CLICK,
                    {
                        branchName: gymName,
                        ctaName: toDispatch,
                        // TODO replace enum with a map with all event props
                        ctaPosition: 'gym page body',
                    },
                );
            }
            if (ctaPosition) {
                dispatchEvent<CtaButtonClickPayload>(
                    EventKey.CTA_BUTTON_CLICK,
                    {
                        ctaName: toDispatch,
                        ctaPosition,
                    },
                );
            }
        }
    };
    return <ButtonBase {...baseProperties} onClick={onClick} />;
};

ButtonCms.displayName = 'Button';

export default ButtonCms;

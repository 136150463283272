import { AddOn } from '@tgg/common-types';
import { decimalToPoundsAndPence } from '@tgg/util';

import {
    StyledDiscountedPrice,
    StyledText,
    StyledDuration,
    StyledPrice,
    StyledPriceAndDuration,
    StyledPriceContainer,
    StyledTCLink,
} from './AddOnPriceInfo.styled';

function getDurationText(kickerPriceDuration: number) {
    return kickerPriceDuration === 2
        ? '/2nd month'
        : ` for first ${kickerPriceDuration} months`;
}
/**
 *
 * @param isFirstMonthFree from addOn data
 * @param isKickerInForce from addOn data
 * @param kickerPrice from addOn data but converted to string with £ symbol
 * @param expectedPayment from addOn data but converted to string with £ symbol
 * @param kickerPriceDuration from addOn data
 * @returns appropriate JSX.Element - based on conditions
 */
const renderPriceInfo = ({
    isFirstMonthFree,
    isKickerInForce,
    kickerPrice,
    expectedPayment,
    kickerPriceDuration,
}: {
    isFirstMonthFree: boolean;
    isKickerInForce: boolean;
    kickerPrice: string | null;
    expectedPayment: string | null;
    kickerPriceDuration: number | null;
}) => {
    if (isFirstMonthFree) {
        return (
            <>
                <StyledPriceAndDuration>
                    <StyledPrice>
                        <StyledDiscountedPrice>
                            {expectedPayment}
                        </StyledDiscountedPrice>{' '}
                        {decimalToPoundsAndPence(0)}
                    </StyledPrice>
                    <StyledDuration>/1st month</StyledDuration>
                </StyledPriceAndDuration>
                <StyledText>{`Then ${expectedPayment}/month.`}</StyledText>
            </>
        );
    }

    if (
        isKickerInForce &&
        kickerPrice !== null &&
        kickerPriceDuration !== null &&
        kickerPriceDuration >= 1
    ) {
        return (
            <>
                <StyledPriceAndDuration>
                    <StyledPrice>
                        <StyledDiscountedPrice>
                            {kickerPrice}
                        </StyledDiscountedPrice>{' '}
                        {expectedPayment}
                    </StyledPrice>
                    <StyledDuration>
                        {kickerPriceDuration > 2
                            ? `${getDurationText(kickerPriceDuration)}.`
                            : '/1st month'}
                    </StyledDuration>
                </StyledPriceAndDuration>
                <StyledText>
                    {kickerPriceDuration === 2
                        ? `${expectedPayment}${getDurationText(kickerPriceDuration)}. Then ${kickerPrice}/month.`
                        : ` Then ${kickerPrice}/month.`}
                </StyledText>
            </>
        );
    }

    return (
        <StyledPriceAndDuration>
            <StyledPrice>{expectedPayment}</StyledPrice>
            <StyledDuration>/month</StyledDuration>
        </StyledPriceAndDuration>
    );
};

export const AddOnPriceInfo = ({ addOn }: { addOn: AddOn }) => {
    const expectedPayment = decimalToPoundsAndPence(
        addOn.expectedPaymentAmount,
    );
    const kickerPrice =
        addOn.kickerPriceMonthlyFee !== null
            ? decimalToPoundsAndPence(addOn.kickerPriceMonthlyFee)
            : null;

    return (
        <StyledPriceContainer>
            {renderPriceInfo({
                isFirstMonthFree: addOn.isFirstMonthFree,
                isKickerInForce: addOn.isKickerPriceInForce,
                kickerPrice,
                expectedPayment,
                kickerPriceDuration: addOn.kickerPriceDuration,
            })}
            <StyledTCLink href="https://www.thegymgroup.com/legal/membership-agreement/">
                Cancel anytime, <p>T&Cs apply.</p>
            </StyledTCLink>
        </StyledPriceContainer>
    );
};

import { palette } from '../../theme';
import { Icon } from '../Icon';

import {
    StyledCard,
    StyledLink,
    StyledParagraph,
    StyledSection,
    StyledSubtitle,
    StyledTitle,
} from './MembershipInfo.styled';
import { MembershipInfoProperties } from './MembershipInfo.types';

export const Card = ({
    title,
    subTitle,
    paragraph,
    iconRight,
    iconRightLink,
    $hasExtraMarginTop,
    accessibleTitleForIconRight,
    children,
}: MembershipInfoProperties) => {
    return (
        <StyledCard $hasExtraMarginTop={$hasExtraMarginTop}>
            <StyledSection>
                <StyledTitle variant={'h3'} gutterBottom={false}>
                    {title}
                </StyledTitle>
                {iconRight && iconRightLink && (
                    <StyledLink
                        href={iconRightLink}
                        aria-label={accessibleTitleForIconRight}
                    >
                        <Icon
                            ariaHidden={true}
                            name={iconRight}
                            color={palette.primary.main}
                            size={16}
                        />
                    </StyledLink>
                )}
            </StyledSection>
            {subTitle && (
                <StyledSubtitle variant="body2">{subTitle}</StyledSubtitle>
            )}
            {paragraph && (
                <StyledParagraph
                    data-testid="member-card-paragraph"
                    variant="body2"
                >
                    {paragraph}
                </StyledParagraph>
            )}
            {children}
        </StyledCard>
    );
};

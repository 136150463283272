import { IconName } from '../../Icon';
import { NavigationItem } from '../../NavigationItem';
import {
    StyledHeadingMainMenu,
    StyledHorizontalRule,
    StyledList,
} from '../SideNavigation.styled';

import { MyAccountCommerceMenuProperties } from './MyAccountCommerceMenu.types';

export function MyAccountCommerceMenu({
    items,
    isMenuOpen,
    header,
    logSideNavRedirect,
}: MyAccountCommerceMenuProperties) {
    return items ? (
        <>
            <StyledHeadingMainMenu variant="h3" gutterBottom={false}>
                {header}
            </StyledHeadingMainMenu>
            <StyledHorizontalRule />
            <StyledList>
                {items.map(
                    (
                        {
                            text,
                            promotionalVILinkText,
                            href,
                            leftIconName,
                            caseSensitive,
                            chipText,
                            chipColor,
                        },
                        index,
                    ) => (
                        <NavigationItem
                            caseSensitive={caseSensitive}
                            key={`item${text}`}
                            href={href}
                            {...(href && {
                                onClick: () =>
                                    logSideNavRedirect({
                                        menuParentText: header,
                                        menuItemText: text,
                                        redirectUrl: href,
                                    }),
                            })}
                            text={promotionalVILinkText || text}
                            leftIconName={leftIconName as IconName}
                            divider={index !== items.length - 1}
                            tabIndex={isMenuOpen ? -1 : 0}
                            chipText={chipText}
                            chipColor={chipColor}
                        />
                    ),
                )}
            </StyledList>
        </>
    ) : null;
}

import { StepConnector } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';

import { palette, theme } from '../../theme';

export const StyledProgressBarContainer = styled('div')`
    background-color: ${palette.common.white};
    border-bottom: 0.1rem solid ${palette.grey[200]};
`;

export const StyledStep = styled(Step)``;

export const StyledStepper = styled(Stepper)`
    padding: 1rem 2.1rem;
    margin: auto;
    background-color: ${palette.common.white};

    ${theme.breakpoints.up('desktop')} {
        width: 100%;
        max-width: 98rem;
    }
`;

export const StyledStepLabel = styled(StepLabel)`
    text-transform: lowercase;

    &.Mui-disabled {
        svg {
            opacity: 50%;
        }
    }

    & .MuiStepLabel-label {
        margin-top: 0;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.6rem;
        color: ${palette.common.blue};

        ${theme.breakpoints.up('desktop')} {
            font-size: 1.2rem;
            line-height: 1.8rem;
        }
    }

    & .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 0;
    }

    a {
        color: inherit;
        text-decoration: inherit;
    }
`;

export const StyledStepConnector = styled(StepConnector)`
    &.MuiStepConnector-alternativeLabel {
        top: 1rem;
        right: calc(50% + 3rem);
        left: calc(-50% + 3rem);
        ${theme.breakpoints.up('desktop')} {
            top: 1.6rem;
        }
    }

    & .MuiStepConnector-lineHorizontal {
        border-color: ${palette.grey[200]};
        border-radius: 0.05rem;
    }

    &.MuiStepConnector-active {
        & .MuiStepConnector-lineHorizontal {
            border-color: ${palette.primary.main};
        }
    }

    &.MuiStepConnector-completed {
        & .MuiStepConnector-lineHorizontal {
            border-color: ${palette.primary.main};
        }
    }
`;

export const StyledLabelContent = styled('span')<{
    $isActive?: boolean;
}>`
    color: ${palette.common.blue};
    ${({ $isActive }) =>
        $isActive ? 'font-family:thegymgroupsans-headline, sans-serif;' : ''};
`;

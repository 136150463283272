import Link from 'next/link';

import { palette } from '../../theme';
import { Icon } from '../Icon';

import {
    StyledAddressSection,
    StyledAddressLink,
    StyledAddressDistance,
    StyledAddressInformation,
    StyledDistanceText,
    StyledHeading,
    StyledIconContainer,
    StyledGymName,
    StyledPricingSection,
    StyledPrice,
    StyledPriceText,
    StyledTile,
} from './GymAddressTile.styled';
import { GymAddressTileProperties } from './GymAddressTile.types';

/**
 * GymAddressTile to display information with link for accessing gym page or google maps link
 */
export function GymAddressTile({
    gymName = '',
    gymAddress = '',
    gymPageURL = '',
    distance = 0,
    lowestPrice = 0,
    joiningFee = 0,
    gutterBottom = false,
    latitude,
    longitude,
    onTileClick,
    regionName,
}: GymAddressTileProperties) {
    const [pounds, pennies] = lowestPrice.toString().split('.');

    const joiningFeeText =
        joiningFee > 0 ? `£${joiningFee} joining fee` : 'no joining fee';

    const hasDistance = distance || distance === 0;

    return (
        <StyledTile gutterBottom={gutterBottom}>
            <StyledAddressSection>
                <Link href={gymPageURL} passHref legacyBehavior>
                    <StyledGymName onClick={onTileClick}>
                        {gymName}
                    </StyledGymName>
                </Link>
                <StyledAddressInformation>
                    <StyledIconContainer>
                        <Icon
                            name="gymLocation"
                            color={palette.primary.main}
                            size={22}
                        />
                    </StyledIconContainer>
                    <div>
                        <StyledAddressLink
                            href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                            target="_blank"
                        >
                            {gymAddress}
                        </StyledAddressLink>
                        {hasDistance && !regionName && (
                            <StyledDistanceText gutterBottom={false}>
                                <StyledAddressDistance $color={palette.grey}>
                                    {`${distance}mi`}
                                </StyledAddressDistance>
                            </StyledDistanceText>
                        )}
                    </div>
                </StyledAddressInformation>
            </StyledAddressSection>
            <Link href={gymPageURL} passHref legacyBehavior>
                <StyledPricingSection
                    onClick={onTileClick}
                    data-testid="price-section"
                    $color={palette.grey}
                >
                    <div>
                        {!!lowestPrice && (
                            <>
                                <StyledPriceText gutterBottom={false}>
                                    from
                                </StyledPriceText>

                                <StyledPrice>
                                    <span>£</span>
                                    <StyledHeading
                                        variant="h1"
                                        component="span"
                                        gutterBottom={false}
                                        color={palette.common.blue}
                                    >
                                        {pounds}
                                    </StyledHeading>
                                    <StyledHeading
                                        variant="h4"
                                        component="span"
                                        gutterBottom={false}
                                        color={palette.common.blue}
                                    >
                                        {pennies}
                                    </StyledHeading>
                                </StyledPrice>
                                <StyledPriceText>per month</StyledPriceText>
                                <StyledPriceText>+</StyledPriceText>
                                <StyledPriceText>
                                    {joiningFeeText}
                                </StyledPriceText>
                            </>
                        )}
                    </div>
                </StyledPricingSection>
            </Link>
        </StyledTile>
    );
}

export default GymAddressTile;

import { EventKey } from '@tgg/services';
import { encodeAsBase64 } from '@tgg/util';

import { SimpleCarousel } from '../SimpleCarousel';

import { Testimonial } from './Testimonial';
import { StyledHeading } from './TestimonialsGallery.styled';
import { TestimonialsGalleryProperties } from './TestimonialsGallery.types';

/**
 * Use the `TestimonialsGallery` component to display a carousel of client Testimonials.
 */
export const TestimonialsGallery = ({
    id,
    heading,
    data,
}: TestimonialsGalleryProperties) => (
    <>
        <StyledHeading
            id={id}
            variant="h3"
            gutterBottom
            alignOnDesktop="center"
        >
            {heading}
        </StyledHeading>
        <SimpleCarousel
            galleryElements={data.map((item, index) => {
                return (
                    <Testimonial
                        key={encodeAsBase64(`${item.customerName}${index}`)}
                        {...item}
                    />
                );
            })}
            analyticsEvent={EventKey.TESTIMONIAL_SCROLL}
        />
    </>
);

export default TestimonialsGallery;

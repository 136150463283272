import { styled } from '@mui/material/styles';

import { SpacingProperties, SpacingSize } from './Spacing.types';

const sizes = {
    mobile: {
        [SpacingSize.ExtraSmall]: 8,
        [SpacingSize.Small]: 10,
        [SpacingSize.Medium]: 20,
        [SpacingSize.Large]: 30,
        [SpacingSize.ExtraLarge]: 120,
    },
    desktop: {
        [SpacingSize.ExtraSmall]: 8,
        [SpacingSize.Small]: 15,
        [SpacingSize.Medium]: 20,
        [SpacingSize.Large]: 40,
        [SpacingSize.ExtraLarge]: 120,
    },
};

export const StyledSpacing = styled('div')<SpacingProperties>`
    width: ${({ size, theme }) =>
        theme.typography.pxToRem(sizes.mobile[size as SpacingSize])};
    height: ${({ size, theme }) =>
        theme.typography.pxToRem(sizes.mobile[size as SpacingSize])};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: ${({ size, theme }) =>
            theme.typography.pxToRem(sizes.desktop[size as SpacingSize])};
        height: ${({ size, theme }) =>
            theme.typography.pxToRem(sizes.desktop[size as SpacingSize])};
    }
`;

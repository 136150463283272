import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { AlertMessage } from '../AlertMessage';
import { ButtonBase } from '../Button';
import { ButtonLink } from '../ButtonLink';
import { SearchInput } from '../Forms';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';

export const StyledContainer = styled('div')`
    width: 100%;
    padding: 3rem;
    margin: 0 auto;
    text-align: center;
    background-color: ${palette.grey[900]};
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        max-width: 128rem;
        padding: 3rem 0;
    }
`;

export const StyledIcon = styled(Icon)`
    && {
        margin-bottom: 1rem;
    }
`;

export const StyledHeading = styled(Heading)`
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: ${palette.common.blue};
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 3.1rem;
    }
`;

export const StyledSearchParagraph = styled(Paragraph)`
    color: ${palette.common.blue};
`;

export const StyledResultsParagraph = styled(Paragraph)`
    margin-top: 1.5rem;
    font-size: 1.2rem;
    text-align: left;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.4rem;
        text-align: center;
    }
`;

export const StyledAlertMessage = styled(AlertMessage)`
    margin-bottom: 2rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        max-width: 70.8rem;
        margin: 0 auto 2rem;
    }
`;

export const StyledSearchInput = styled(SearchInput)`
    width: 100%;
    margin: 0 auto 2rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        max-width: 70.8rem;
    }
`;

export const StyledButtonLink = styled(ButtonLink)`
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
`;

export const StyledButtonBase = styled(ButtonBase)`
    width: 100%;
    max-width: 33.5rem;
    margin-top: 2rem;
`;

import Link from 'next/link';

import { palette } from '../../theme';
import { Icon } from '../Icon';
import { IconComplete } from '../IconComplete';

import {
    StyledStepper,
    StyledStep,
    StyledStepLabel,
    StyledStepConnector,
    StyledProgressBarContainer,
    StyledLabelContent,
} from './ProgressBar.styled';
import { ProgressBarProperties } from './ProgressBar.types';

/**
 * Use the `ProgressBar` component to display current progress along a sequence of steps.
 */
export function ProgressBar({ activeStep, steps }: ProgressBarProperties) {
    return (
        <StyledProgressBarContainer>
            <StyledStepper
                alternativeLabel
                activeStep={activeStep}
                connector={<StyledStepConnector />}
            >
                {steps.map(({ label, icon }, stepIndex) => {
                    const href = steps[stepIndex].routes[0]; // First route for the available step should be fine
                    return (
                        <StyledStep key={label}>
                            <StyledStepLabel
                                StepIconComponent={
                                    /* eslint-disable-next-line react/no-unstable-nested-components */
                                    ({ active, completed }) =>
                                        completed ? (
                                            <Link
                                                href={href}
                                                passHref
                                                legacyBehavior
                                            >
                                                <a
                                                    href={href}
                                                    tabIndex={-1}
                                                    aria-hidden="true"
                                                >
                                                    <IconComplete
                                                        name={icon}
                                                        color={
                                                            palette.primary.main
                                                        }
                                                        size={[22, 32]}
                                                    />
                                                </a>
                                            </Link>
                                        ) : (
                                            <Icon
                                                name={icon}
                                                color={palette.primary.main}
                                                size={[22, 32]}
                                                viewBox="0 0 28 28"
                                            />
                                        )
                                }
                                data-testid={label}
                            >
                                {stepIndex < activeStep ? (
                                    <Link href={href}>{label}</Link>
                                ) : (
                                    <StyledLabelContent
                                        $isActive={activeStep === stepIndex}
                                    >
                                        {label}
                                    </StyledLabelContent>
                                )}
                            </StyledStepLabel>
                        </StyledStep>
                    );
                })}
            </StyledStepper>
        </StyledProgressBarContainer>
    );
}

import { Dialog } from '@mui/material';

import { ButtonBase } from '../Button';
import { Spacing, SpacingSize } from '../Spacing';

import {
    StyledHeading,
    StyledSmallTextContent,
    StyledWrapper,
} from './ConfirmationDialog.styled';
import { ConfirmationDialogProperties } from './ConfirmationDialog.types';

export const ConfirmationDialog = ({
    title,
    isOpen,
    children,
    footnote,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryClick,
    onSecondaryClick,
    onClose,
}: ConfirmationDialogProperties) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                style: {
                    overflowX: 'hidden',
                    borderRadius: '1rem',
                },
            }}
        >
            <StyledWrapper>
                <StyledHeading
                    component="h1"
                    gutterBottom={false}
                    alignOnDesktop="center"
                >
                    {title}
                </StyledHeading>
                <Spacing size={SpacingSize.Small} />

                {children}

                <ButtonBase
                    text={primaryButtonText}
                    fullWidth
                    onClick={onPrimaryClick}
                />
                <Spacing size={SpacingSize.ExtraSmall} />
                <ButtonBase
                    text={secondaryButtonText}
                    buttonStyle="secondary"
                    fullWidth
                    onClick={onSecondaryClick}
                />

                {footnote && (
                    <StyledSmallTextContent>{footnote}</StyledSmallTextContent>
                )}
            </StyledWrapper>
        </Dialog>
    );
};

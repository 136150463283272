export function calculateDistance(
    latitudeOfFirstGym: number,
    longitudeOfFirstGym: number,
    latitudeOfSecondGym: number,
    longitudeOfSecondGym: number,
) {
    const latLng1 = new google.maps.LatLng(
        latitudeOfFirstGym,
        longitudeOfFirstGym,
    );
    const latLng2 = new google.maps.LatLng(
        latitudeOfSecondGym,
        longitudeOfSecondGym,
    );

    const distanceInMeters =
        google.maps.geometry.spherical.computeDistanceBetween(latLng1, latLng2);

    return distanceInMeters / 1609;
}

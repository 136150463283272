import {
    MonthlyPaymentFrequency,
    OneOffPaymentFrequency,
} from '@tgg/common-types';

import { Paragraph } from '../../Paragraph';

import { StyledPrice } from './Price.styled';
import { PriceProperties } from './Price.types';

const renderPaymentFrequency = (
    termOfPayment: MonthlyPaymentFrequency | OneOffPaymentFrequency,
) => {
    return (
        <>
            {termOfPayment.paymentFrequency === 'Monthly' ? (
                'per month'
            ) : (
                <>
                    for <strong>{termOfPayment.term}</strong>{' '}
                    {termOfPayment.termType.toLocaleLowerCase()}
                </>
            )}
            <br />
        </>
    );
};

export const Price = ({
    color,
    price,
    frequency,
    discountMessage,
}: PriceProperties) => {
    return (
        <>
            <StyledPrice
                variant="h3"
                component="span"
                $color={color}
                data-testid="price"
            >
                £{price.toFixed(2)}
            </StyledPrice>
            <Paragraph
                variant="subtitle1"
                gutterBottom={false}
                data-testid="discount-message"
            >
                {discountMessage || renderPaymentFrequency(frequency)}
            </Paragraph>
        </>
    );
};

export default Price;

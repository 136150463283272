/* eslint-disable @typescript-eslint/no-floating-promises */
import { useCallback } from 'react';

import { CustomOptimizelyEventAttributes, OptimizelyEvent } from '@tgg/util';

import { useAppConfigContext } from '../../appConfigContext';
import { dispatchOptimizelyGaTrackingEvent } from '../helpers/dispatchOptimizelyGaEvent';
import { trackOptimizelyEventClient } from '../helpers/optimizelyTrackEvent';

export const useOptimizelyTracking = (config: {
    resolvedUserId: string;
    pathname: string;
}) => {
    const { resolvedUserId, pathname } = config;

    const {
        appConfig: { appLogger },
    } = useAppConfigContext();

    const trackFlagsEvent = useCallback(
        (
            optimizelyEvent: OptimizelyEvent,
            attributes?: CustomOptimizelyEventAttributes,
        ) => {
            trackOptimizelyEventClient(
                optimizelyEvent,
                {
                    customAttributes:
                        attributes ?? /* istanbul ignore next */ {},
                    userId: resolvedUserId,
                    pathname,
                },
                { logger: appLogger },
            );
            dispatchOptimizelyGaTrackingEvent({
                flagsUserId: resolvedUserId,
                pathname,
            });
        },

        [appLogger, resolvedUserId, pathname],
    );

    return { trackFlagsEvent };
};

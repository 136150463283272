import useMediaQuery from '@mui/material/useMediaQuery';
import React, { forwardRef } from 'react';

import { theme } from '../../theme';

import { StyledPinoButton } from './PinoButton.styled';
import { ButtonProperties } from './PinoButton.types';

/**
 * Use the `Button` component for any call to action.
 */
export const PinoButton = forwardRef(
    (
        {
            size = 'medium',
            onClick,
            fullWidth,
            buttonLocation = 'table',
            ...otherParameters
        }: ButtonProperties,
        reference?: React.ForwardedRef<HTMLButtonElement>,
    ) => {
        const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

        const commonButtonProperties = {
            $size: size,
            onClick,
            ref: reference,
            fullWidth: fullWidth ?? !isDesktop,
            $buttonLocation: buttonLocation,
            ...otherParameters,
        };

        return (
            <StyledPinoButton {...commonButtonProperties} theme={theme}>
                {commonButtonProperties.children}
            </StyledPinoButton>
        );
    },
);

PinoButton.displayName = 'Button';

export default PinoButton;

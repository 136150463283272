import { type AxiosResponse } from 'axios';
import { useCallback, useEffect, useMemo } from 'react';

import { PaymentError } from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import {
    relativeUrlWithBasePath,
    originWithBasePath,
    getAppLogger,
} from '@tgg/util';

import { logPaymentIssue } from '../utils';
import { getPaymentOutcomeUrl } from '../utils/getPaymentUrls';

import {
    ThreeDSPayentEvent,
    type ChallengeOutcomeProperties,
} from './ChallengeOutcome.types';

export function ChallengeOutcome(pageProperties: ChallengeOutcomeProperties) {
    const {
        encodedPaymentResult,
        applicationIdentifier,
        paymentTokenPersistanceLayer,
        loggerParameters,
    } = pageProperties;

    const logger = useMemo(
        () => getAppLogger({ applicationIdentifier }),
        [applicationIdentifier],
    );

    const requestOutcome = useCallback(
        async (paymentToken: string | null) => {
            const domain = originWithBasePath(window.location.origin);

            try {
                if (!paymentToken) {
                    const errorMessage = `3DS - No payment token - Challenge Outcome,
                    token: ${paymentToken}`;

                    const paymentError = new PaymentError(errorMessage, '2000');

                    const message: ThreeDSPayentEvent = {
                        code: '2000',
                        type: 'TggPayment',
                        error: paymentError,
                    };

                    logPaymentIssue({
                        error: paymentError,
                        log: { logger, loggerParameters },
                    });

                    window.top?.postMessage(message, domain);
                    return;
                }

                const url = getPaymentOutcomeUrl(applicationIdentifier);

                const response: AxiosResponse<any> = await axiosInstance.post(
                    relativeUrlWithBasePath(url),
                    {
                        token: paymentToken,
                        res: encodedPaymentResult.value,
                        authType: encodedPaymentResult.type,
                    },
                    {
                        timeout: 60_000,
                    },
                );

                if (response.status === 202 && window && window.top) {
                    logger.info(`3DS - Challenge Successful`, loggerParameters);

                    const message: ThreeDSPayentEvent = {
                        code: 'OK',
                        type: 'TggPayment',
                    };

                    window.top.postMessage(message, domain); // Payment Authorized
                } else {
                    /* istanbul ignore next */
                    const errorMessage = response
                        ? `Response Error - status ${response.status} 
                        response: ${response}`
                        : `Client Browser Error,
                        client window exist: ${!!window},
                        client window.top exist: ${!!window.top}`;

                    /* istanbul ignore next */
                    const errorCode = response ? '2002' : '2001';

                    const paymentError = new PaymentError(
                        `3DS - ${errorMessage}`,
                        errorCode,
                    );

                    logPaymentIssue({
                        error: paymentError,
                        log: { logger, loggerParameters },
                    });

                    const message: ThreeDSPayentEvent = {
                        code: errorCode,
                        type: 'TggPayment',
                        error: paymentError,
                    };

                    window.top?.postMessage(message, domain);
                }
            } catch (error) {
                logPaymentIssue({ error, log: { logger, loggerParameters } });

                const message: ThreeDSPayentEvent = {
                    code: '2001',
                    type: 'TggPayment',
                    error: new Error((error as Error).message),
                };

                window.top?.postMessage(message, domain); // Payment not Authorized
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            applicationIdentifier,
            encodedPaymentResult.type,
            encodedPaymentResult.value,
        ],
    );

    useEffect(() => {
        // eslint-disable-next-line no-void
        const paymentToken =
            paymentTokenPersistanceLayer.retrievePaymentToken(sessionStorage);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        requestOutcome(paymentToken);
    }, [requestOutcome, paymentTokenPersistanceLayer]);

    return <>Please wait. Do not refresh the page.</>;
}

import { Collapse, Divider } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';

import {
    DataEventNames,
    EventKey,
    ProductViewEvent,
    sendAnalyticsDataEvent,
    dispatchEvent,
} from '@tgg/services';
import { monthlyProductTitleMap } from '@tgg/util';

import { HideBackToTopButtonToThisPlace } from '../BackToTopButton';
import { ButtonBase } from '../Button';
import { PinoProductOptions } from '../PinoProductOptions';
import { PinoTable } from '../PinoTable';

import {
    createMembershipTermsForSelectedProduct,
    isGymOpen24Hours,
} from './pino-products.helpers';
import {
    StyledDescription,
    StyledDivider,
    StyledHeading,
    StyledOptionButton,
    StyledPinoSectionHeader,
    StyledProductOptionWrapper,
} from './pino-products.styled';
import { PinoProductsProperties } from './pino-products.types';

const buttonMarginBottom = 40;

export const PinoProducts = ({
    products,
    onSelect,
    gymName,
    ctaHref,
    productsAvailableAddons,
    openingHours,
    isKickerOffersRibbonEnabled,
    areTermsVisible,
    productHighlightPills,
    isBenefitsShortened,
    isGuestPassEnabled,
    ctaName,
    isPaypalIframeVisible,
    isPaypalPayIn3Visible,
    isFreeFreezeNoticeVisible,
    isMBTOptimizationEnabled = false,
    isMultipleAddonsEnabled,
    isRejoin = false,
}: PinoProductsProperties) => {
    const isOpen24Hours = isGymOpen24Hours(openingHours);
    const [selectedProduct, setSelectedProduct] = useState(products.Monthly[0]);
    const [showPinoProductOptions, setShowPinoProductOptions] =
        useState<boolean>(areTermsVisible || false);
    const router = useRouter();

    const selectedProductTitle =
        monthlyProductTitleMap[selectedProduct.membershipType];

    const membershipTerms = createMembershipTermsForSelectedProduct(
        selectedProduct,
        products.Fixed,
    );

    const handleCancelClick = async () => {
        await router.push('/rejoin-now/your-membership/');
    };

    const handleProductTableSelection = (product: any) => {
        setSelectedProduct(product);

        if (!areTermsVisible) {
            setShowPinoProductOptions(false);
        }
    };

    const handleOnOptionButton = () => {
        setShowPinoProductOptions(true);

        sendAnalyticsDataEvent({
            eventName: DataEventNames.CHOOSE_TERM,
            eventData: selectedProduct,
            eventGymName: gymName,
        });

        const listOfProducts = [
            membershipTerms.Monthly,
            ...membershipTerms.Fixed,
        ].map(product => {
            const { description, expectedPaymentFrequency, price } = product;
            return {
                name: description,
                price: price.value,
                brand: gymName,
                category: expectedPaymentFrequency,
                variant: 'Create',
                quantity: 1,
            };
        });

        dispatchEvent<ProductViewEvent>(EventKey.PRODUCT_DETAIL, {
            event: EventKey.PRODUCT_DETAIL,
            ecommerce: {
                detail: {
                    products: listOfProducts,
                },
            },
        });
    };

    const buttonWording = isRejoin
        ? 'Choose membership term'
        : `Choose your ${selectedProductTitle} term`;

    return (
        <div>
            {!isRejoin && (
                <StyledPinoSectionHeader>
                    <StyledHeading variant="h2">our memberships</StyledHeading>
                    <StyledDescription>
                        We want our memberships to work as hard as you do. Thats
                        why we offer flexible packages and payment terms, so you
                        can create a membership that provides exactly what you
                        need.
                    </StyledDescription>
                    <Divider />
                </StyledPinoSectionHeader>
            )}
            <PinoTable
                pinoMonthlyProducts={products.Monthly}
                onSelectedProduct={handleProductTableSelection}
                tableCaption={!isRejoin ? '1. Select your plan' : ''}
                disabledProduct={undefined}
                gymName={gymName}
                isOpen24Hours={isOpen24Hours}
                isKickerOffersRibbonEnabled={isKickerOffersRibbonEnabled}
                isBenefitsShortened={isBenefitsShortened}
                isGuestPassEnabled={isGuestPassEnabled}
                isFreeFreezeNoticeVisible={isFreeFreezeNoticeVisible}
                isMultipleAddonsEnabled={isMultipleAddonsEnabled}
            />
            {!showPinoProductOptions && (
                <StyledOptionButton isrejoin={isRejoin}>
                    <ButtonBase
                        type="button"
                        data-testid="choose-term-button"
                        buttonStyle="primary"
                        text={buttonWording}
                        onClick={handleOnOptionButton}
                    />
                    {isRejoin && (
                        <ButtonBase
                            type="button"
                            data-testid="pino-products-choose-term-cancel-button"
                            buttonStyle="secondary"
                            text={`cancel`}
                            onClick={handleCancelClick}
                        />
                    )}
                </StyledOptionButton>
            )}
            <div>
                <Collapse in={showPinoProductOptions}>
                    <StyledProductOptionWrapper
                        $visible={showPinoProductOptions}
                    >
                        {!isMBTOptimizationEnabled && !isRejoin && (
                            <StyledDivider />
                        )}
                        <PinoProductOptions
                            products={membershipTerms}
                            ctaHref={ctaHref}
                            gymName={gymName}
                            isRejoin={isRejoin}
                            onSelect={onSelect}
                            productsAvailableAddons={productsAvailableAddons}
                            productHighlightPills={productHighlightPills}
                            ctaName={
                                isRejoin ? 'Choose membership term' : ctaName
                            }
                            isPaypalIframeVisible={isPaypalIframeVisible}
                            isPaypalPayIn3Visible={isPaypalPayIn3Visible}
                            isFreeFreezeNoticeVisible={
                                isFreeFreezeNoticeVisible
                            }
                            isMBTOptimizationEnabled={isMBTOptimizationEnabled}
                        />
                    </StyledProductOptionWrapper>
                </Collapse>
            </div>
            <HideBackToTopButtonToThisPlace
                additionalOffset={buttonMarginBottom}
            />
            {!showPinoProductOptions && <Divider data-testid="divider" />}
        </div>
    );
};

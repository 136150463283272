import {
    StyledWrapper,
    StyledButtonGroup,
    StyledButton,
    StyledInput,
    StyledErrorMessage,
} from './ToggleButton.styled';
import { ToggleButtonProperties } from './ToggleButton.types';

/**
 * Allow a user to select between two or more options.
 */
export function ToggleButton({
    name,
    options,
    selectedOptionId,
    errorMessage,
    onChange,
    center,
    layoutVariant = 'vertical',
    disabled,
}: ToggleButtonProperties) {
    return (
        <>
            <StyledWrapper
                $center={center}
                $layoutVariant={layoutVariant}
                data-testid="toggleButtonContainer"
            >
                {options.map(({ label, id }) => (
                    <StyledButtonGroup key={id}>
                        <StyledInput
                            disabled={disabled}
                            id={id}
                            type="radio"
                            name={name}
                            value={id}
                            onChange={onChange}
                            defaultChecked={selectedOptionId === id}
                        />
                        <StyledButton
                            $disabled={disabled}
                            $error={errorMessage !== undefined}
                            htmlFor={id}
                        >
                            {label}
                        </StyledButton>
                    </StyledButtonGroup>
                ))}
            </StyledWrapper>
            {errorMessage && (
                <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
            )}
        </>
    );
}

export default ToggleButton;

import useMediaQuery from '@mui/material/useMediaQuery';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { theme, palette } from '../../theme';

import {
    StyledWrapper,
    StyledParagraph,
    StyledProgressBar,
    StyledHeading,
} from './CountdownTimer.styled';
import { CountdownTimerProperties } from './CountdownTimer.types';

/**
 * Countdown timer component to showcase the timer
 */

const addZero = (time: number) => `0${time}`.slice(-2);

export const CountdownTimer = ({
    duration,
    isPlaying,
    color = palette.secondary.main,
    secondaryColor = palette.grey[900],
}: CountdownTimerProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const transparentColour = 'rgba(0,0,0,0)' as any;
    const zeroSeconds = 0;
    const minuteInSeconds = 60;
    const hourInSeconds = 3600;
    const daysInSeconds = 86_400;

    const timerProperties = {
        isPlaying: !!isPlaying,
        size: isDesktop ? 88 : 43,
        strokeWidth: isDesktop ? 5 : 3,
        trailStrokeWidth: 1,
        isSmoothColorTransition: false,
        trailColor: secondaryColor,
    };

    const days = Math.trunc(duration / daysInSeconds);
    const daysDuration = days * daysInSeconds;

    const getTimeSeconds = (time: number) => Math.trunc(minuteInSeconds - time);
    const getTimeMinutes = (time: number) =>
        Math.trunc((time % hourInSeconds) / minuteInSeconds);

    const getTimeHours = (time: number) =>
        Math.trunc((time % daysInSeconds) / hourInSeconds);
    const getTimeDays = (time: number) => Math.trunc(time / daysInSeconds);

    return (
        <StyledWrapper>
            <StyledProgressBar>
                <CountdownCircleTimer
                    {...timerProperties}
                    rotation="counterclockwise"
                    duration={daysDuration}
                    initialRemainingTime={duration}
                    colors={[color, transparentColour, transparentColour]}
                    colorsTime={[duration, daysInSeconds, zeroSeconds]}
                >
                    {({ elapsedTime }) => (
                        <StyledHeading
                            gutterBottom={false}
                            variant="h2"
                            component="span"
                        >
                            {addZero(getTimeDays(daysDuration - elapsedTime))}
                        </StyledHeading>
                    )}
                </CountdownCircleTimer>

                <StyledParagraph gutterBottom={false}>Days</StyledParagraph>
            </StyledProgressBar>
            <StyledProgressBar>
                <CountdownCircleTimer
                    {...timerProperties}
                    duration={daysInSeconds}
                    initialRemainingTime={duration % daysInSeconds}
                    colors={[color, transparentColour, transparentColour]}
                    colorsTime={[duration, hourInSeconds, zeroSeconds]}
                    onComplete={
                        /* istanbul ignore next */
                        totalElapsedTime => ({
                            shouldRepeat:
                                duration - totalElapsedTime > hourInSeconds,
                        })
                    }
                >
                    {({ elapsedTime }) => (
                        <StyledHeading
                            variant="h2"
                            component="span"
                            gutterBottom={false}
                        >
                            {addZero(getTimeHours(daysInSeconds - elapsedTime))}
                        </StyledHeading>
                    )}
                </CountdownCircleTimer>

                <StyledParagraph gutterBottom={false}>Hours</StyledParagraph>
            </StyledProgressBar>

            <StyledProgressBar>
                <CountdownCircleTimer
                    {...timerProperties}
                    rotation="counterclockwise"
                    duration={hourInSeconds}
                    initialRemainingTime={duration % hourInSeconds}
                    onComplete={totalElapsedTime => ({
                        shouldRepeat:
                            duration - totalElapsedTime > minuteInSeconds,
                    })}
                    colors={[color, transparentColour, transparentColour]}
                    colorsTime={[duration, minuteInSeconds, zeroSeconds]}
                >
                    {({ elapsedTime }) => (
                        <StyledHeading
                            variant="h2"
                            component="span"
                            gutterBottom={false}
                        >
                            {addZero(
                                getTimeMinutes(hourInSeconds - elapsedTime),
                            )}
                        </StyledHeading>
                    )}
                </CountdownCircleTimer>

                <StyledParagraph gutterBottom={false}>Minutes</StyledParagraph>
            </StyledProgressBar>
            <StyledProgressBar>
                <CountdownCircleTimer
                    {...timerProperties}
                    rotation="counterclockwise"
                    duration={minuteInSeconds}
                    initialRemainingTime={duration % minuteInSeconds}
                    onComplete={totalElapsedTime => ({
                        shouldRepeat: duration - totalElapsedTime > 0,
                    })}
                    colors={[color, transparentColour]}
                    colorsTime={[duration, zeroSeconds]}
                >
                    {({ elapsedTime }) => (
                        <StyledHeading
                            variant="h2"
                            component="span"
                            gutterBottom={false}
                        >
                            {addZero(getTimeSeconds(elapsedTime))}
                        </StyledHeading>
                    )}
                </CountdownCircleTimer>

                <StyledParagraph gutterBottom={false}>Seconds</StyledParagraph>
            </StyledProgressBar>
        </StyledWrapper>
    );
};

import * as z from 'zod';

import { sortCodeSchema } from '@tgg/form-validation';

import { validationErrorMessages } from './ChangeDirectDebitForm.constants';

export const directDebitSchema = z.object({
    accountName: z
        .string()
        .trim()
        .min(1, validationErrorMessages.accountNameRequired)
        .min(6, validationErrorMessages.accountNameMinChars)
        .max(18, validationErrorMessages.accountNameMaxChars)
        .regex(/^[A-Za-z]+[ A-Za-z-]*$/, {
            message: validationErrorMessages.accountNameOnlyLetters,
        }),
    sortCode: sortCodeSchema,
    accountNumber: z
        .string()
        .trim()
        .min(1, validationErrorMessages.accountNumberRequired)
        .length(8, validationErrorMessages.accountNumberLength)
        .regex(/^\d*$/, {
            message: validationErrorMessages.accountNumberOnlyDigits,
        }),
});

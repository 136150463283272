import { Controller } from 'react-hook-form';

import { expiryDateValidator } from '@tgg/form-validation';

import { Asterisk } from '../../../Asterisk';
import { DateInput } from '../../../Forms/DateInput';
import { StyledInput, StyledLabel } from '../InputFields.styled';
import { ControlInputProperties } from '../InputFields.types';

export function ExpiryDate({ control }: { control: ControlInputProperties }) {
    return (
        <StyledInput>
            <Controller
                name="expiryDate"
                control={control}
                rules={{
                    validate: {
                        validator: value =>
                            value ? expiryDateValidator(value) : false,
                    },
                }}
                render={({
                    field: { value, onChange, onBlur },
                    fieldState: { invalid, error, isDirty },
                }) => {
                    return (
                        <>
                            <StyledLabel htmlFor="expiry-date">
                                Expiry Date
                                <Asterisk spaceBefore />
                            </StyledLabel>
                            <DateInput
                                format="short"
                                id="expiry-date"
                                onBlur={onBlur}
                                onChange={onChange}
                                errorMessage={error?.message}
                                isInvalid={invalid}
                                value={value}
                                iconElementRight={{
                                    name:
                                        !invalid && isDirty ? 'tick' : 'blank',
                                }}
                                autoComplete="cc-exp"
                            />
                        </>
                    );
                }}
            />
        </StyledInput>
    );
}

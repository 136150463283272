import { styled } from '@mui/material/styles';

import { palette } from '../../theme';

import { StyledChipWrapperProperties } from './Chip.types';

export const ChipWrapper = styled('span')<StyledChipWrapperProperties>`
    box-sizing: border-box;
    display: inline-block;
    height: 2rem;
    padding: 0 0.7rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: ${palette.common.blue};
    text-transform: lowercase;
    vertical-align: middle;
    border: 1px solid;
    border-color: ${palette.common.blue};
    border-radius: 1rem;
`;

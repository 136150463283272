import { styled } from '@mui/material/styles';

import { Theme, lightGreyBoxShadow, palette } from '../../theme';
import { ButtonLink } from '../ButtonLink';
import { Heading } from '../Heading';

type StyledContainerProperties = {
    $disabled: boolean;
};

type StyledNameContainerProperties = {
    $listPadding: boolean;
};

type StyledNameProperties = {
    $isSummary: boolean;
};

export const StyledContainer = styled('div')<StyledContainerProperties>`
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
    border-radius: 0.5rem;
    box-shadow: ${lightGreyBoxShadow};
    opacity: ${({ $disabled }) => ($disabled ? '0.5' : '1')};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin: 2rem 0;
    }
`;

export const StyledNameContainer = styled('div')<StyledNameContainerProperties>`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    justify-content: center;
    padding-top: ${({ $listPadding }) => ($listPadding ? '2.1rem' : '0')};
    padding-bottom: ${({ $listPadding }) => ($listPadding ? '1.5rem' : '0')};
    padding-left: 1.5rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 0 0 0 3rem;
    }
`;

export const StyledName = styled(Heading)<StyledNameProperties>`
    display: flex;
    align-items: center;
    font-size: ${properties => (properties.$isSummary ? '1.6rem' : '1.9rem')};
    color: ${properties =>
        properties.$isSummary ? palette.common.blue : palette.primary.main};
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 2.1rem;
        line-height: 2.5rem;
    }
`;

export const StyledIcon = styled('div')`
    display: flex;
    align-items: center;
    padding: 1.5rem;
    background: ${palette.grey[100]};
    border-radius: 0.5rem 0 0 0.5rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 2rem 4rem;
    }
`;

export const StyledList = styled('ul')`
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
`;

export const StyledListItem = styled('li')`
    display: inline;
    &:not(:first-of-type) {
        padding-left: 0.4rem;
        margin-left: 0.4rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            padding-left: 0.6rem;
            margin-left: 0.6rem;
        }
    }
`;

export const StyledButtonLink = styled(ButtonLink)`
    font-size: 1.4rem;
    color: ${palette.common.blue};
`;

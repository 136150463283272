import { useEffect, useState } from 'react';

import {
    Nullable,
    type ApplicationIdentifier,
    type CardPaymentInfo,
    type ErrorCodes,
    PaymentError,
} from '@tgg/common-types';
import { EventKey, dispatchEvent } from '@tgg/services';
import { getErrorFromErrorCode } from '@tgg/util';

import {
    type SetUpCardPaymentFlow,
    type OnCardPaymentSuccess,
    CardPaymentFormLogging,
} from '../CardPayment.types';

import { logPaymentIssue } from './logPaymentIssue';
import { threeDSResponseListener } from './threeDSResponseListener';

export interface UseThreeDSParameters {
    applicationIdentifier: ApplicationIdentifier;
    log: CardPaymentFormLogging;
    transactionId?: string;
    onErrorCallback: (code: ErrorCodes, fullMessage?: string | Error) => void;
    onShowThreeDSCallback?: (isActive: boolean) => void;
    onSuccess: OnCardPaymentSuccess;
}

export const useThreeDS = ({
    applicationIdentifier,
    transactionId,
    log,
    onErrorCallback,
    onShowThreeDSCallback,
    onSuccess,
}: UseThreeDSParameters) => {
    const [secureRedirectUrl, setSecureRedirectUrl] = useState<string>('');
    const [threeDSRequest, setThreeDSRequest] = useState<string>('');
    const [uniqueTransactionId, setUniqueTransactionId] = useState<string>(
        transactionId ?? '',
    );
    const [updateJourneyName, setUpdateJourneyName] = useState<string>();
    const [threeDSType, setThreeDSType] = useState<
        'ThreeDSOne' | 'ThreeDSTwo' | null
    >(null);
    const [hasThreeDSError, setHasThreeDSError] = useState<boolean>(false);
    const [formData, setFormData] = useState<Nullable<CardPaymentInfo>>(null);

    const resetPaymentFlow = () => {
        setSecureRedirectUrl('');
        setThreeDSType(null);
        setThreeDSRequest('');
        setHasThreeDSError(false);
        setFormData(null);
    };

    const setUpThreeDSFlow: SetUpCardPaymentFlow = ({
        paymentGatewayResponse,
        journeyName,
        formInfo,
    }) => {
        setSecureRedirectUrl(paymentGatewayResponse.redirectUrl);
        setThreeDSRequest(paymentGatewayResponse.req);
        setThreeDSType(paymentGatewayResponse.type);
        setUniqueTransactionId(paymentGatewayResponse.uniqueId);
        setUpdateJourneyName(journeyName);
        setFormData(formInfo);
    };

    const triggerTimer = (timeout = 600_000) => {
        setTimeout(() => {
            setHasThreeDSError(true);

            const error = new PaymentError(
                `**${applicationIdentifier}**: ${
                    getErrorFromErrorCode('2001').title
                }`,
                '2001',
            ); // PAYMENT NOT AUTHORISED

            logPaymentIssue({ error, log });
            onErrorCallback('2001', 'internal');
        }, timeout);
    };

    const onThreeDsSuccess = () =>
        onSuccess({ formInfo: formData as CardPaymentInfo });

    const isThreeDSSecured = !!(
        secureRedirectUrl &&
        threeDSType &&
        threeDSRequest &&
        !hasThreeDSError
    );

    useEffect(() => {
        /* istanbul ignore else */
        if (onShowThreeDSCallback) {
            onShowThreeDSCallback(isThreeDSSecured);
        }

        if (isThreeDSSecured) {
            dispatchEvent(EventKey['3DS_challenge']);
        }
    }, [onShowThreeDSCallback, isThreeDSSecured]);

    useEffect(() => {
        const listener = threeDSResponseListener({
            onErrorCallback,
            resetPaymentFlow,
            onThreeDsSuccess,
            log,
        });

        window.addEventListener('message', listener, false);

        return () => {
            window.removeEventListener('message', listener, false);
        };
    }, [log]);

    return {
        isThreeDSSecured,
        secureRedirectUrl,
        threeDSRequest,
        uniqueTransactionId,
        threeDSType,
        updateJourneyName,
        setUpThreeDSFlow,
        triggerTimer,
        resetPaymentFlow,
        onThreeDsSuccess,
    };
};

import { styled } from '@mui/material/styles';

import { Theme, containerSmallWidth } from '../../../theme';
import { Heading } from '../../Heading';
import { Paragraph } from '../../Paragraph';

export const StyledHeading = styled(Heading)`
    margin-right: 0;
    margin-left: 0;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

export const StyledDescription = styled(Paragraph)`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        max-width: ${containerSmallWidth};
        margin: 0 auto;
        text-align: center;
    }
`;

export const StyledPinoSectionHeader = styled('section')`
    padding: ${({ theme }) => theme.spacing(30)};
    margin: 0;
`;

export const StyledOptionButton = styled('div')<{
    isrejoin?: boolean;
}>`
    display: flex;
    flex-direction: ${({ isrejoin }) => (isrejoin ? 'column' : 'row')};
    gap: 1rem;
    justify-content: center;
    max-width: ${({ isrejoin }) => isrejoin && '50%'};
    margin: ${({ isrejoin, theme }) =>
        isrejoin
            ? '3rem auto'
            : `${theme.spacing(30)} ${theme.spacing(30)} ${theme.spacing(40)} ${theme.spacing(30)}`};
    ${({ theme }) => theme.breakpoints.down('desktop')} {
        max-width: ${({ isrejoin }) => isrejoin && '100%'};
    }
`;

interface StyledProductOptionWrapperProperties {
    $visible: boolean;
}

export const StyledProductOptionWrapper = styled(
    'div',
)<StyledProductOptionWrapperProperties>`
    width: 100%;
    visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`;

import { styled } from '@mui/material/styles';

import { Heading } from '../../Heading';

export const StyledHeadingSubMenu = styled(Heading)`
    margin-bottom: 1rem;
    margin-left: 3rem;
    font-size: 1.9rem;
`;

export const StyledListSubMenuTop = styled('ul')`
    padding-left: 0;
    margin: 0 0 2rem 0;
    list-style: none;
`;

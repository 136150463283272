import { styled } from '@mui/material/styles';

import { palette, Theme } from '../../../theme';

export const StyledContainer = styled('div')`
    margin-bottom: 2.4rem;
`;

export const StyledHeading = styled('div')`
    display: flex;
    padding: 0.8rem 2rem;
    font-size: 1.6rem;
    font-weight: 800;
    background-color: ${palette.common.blue};
`;

export const StyledLink = styled('a')`
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 2rem;
    color: ${palette.common.white};
    text-decoration: none;
`;

export const StyledBody = styled('div')`
    padding: 1.8rem;

    ul {
        padding: 0;

        li {
            padding-top: 0.8rem;
        }

        p {
            font-size: 1.4rem;
            font-weight: 700;

            ${({ theme }: { theme: Theme }) =>
                theme.breakpoints.up('desktop')} {
                font-size: 1.6rem;
            }
        }
    }

    .discount-message {
        font-size: 1rem;
        line-height: 1.4rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            font-size: 1.2rem;
            line-height: 1.8rem;
        }
    }
`;

export const StyledTotalCostWrapper = styled('div')<{
    $gutterBottom?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5.6rem;
    padding: 1rem 1.8rem;
    background-color: ${palette.grey[50]};
    border-bottom-right-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
`;

export const StyledTotalCostText = styled('div')`
    font-family: thegymgroupsans-Headline;
    font-size: 1.4rem;
    font-weight: 800;
    line-height: 2rem;
    color: ${palette.common.blue};

    /* stylelint-disable-next-line no-descending-specificity */
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
`;

import Script from 'next/script';
import { useMemo, useState } from 'react';

import { type BillingAddressFields, PaymentMethod } from '@tgg/common-types';
import { useFormValidation } from '@tgg/form-validation';
import { AnalyticsErrorEvent, dispatchEvent, EventKey } from '@tgg/services';
import {
    getAppLogger,
    getErrorFromErrorCode,
    mapBillingAddressFromCustomerAddress,
} from '@tgg/util';

import { AuthenticationIframe } from './AuthenticationIframe';
import {
    type CardPaymentErrorCallback,
    type CardPaymentFormContainerProperties,
} from './CardPayment.types';
import { CardPaymentForm } from './CardPaymentForm';
import { MembershipAgreement } from './MembershipAgreement';
import { PaymentCta } from './PaymentCta';
import { useThreeDS, usePayment } from './utils';

export function CardPayment({
    ui: { agreementTypes, showHeader, submitButtonIconName, submitText },
    paymentData: {
        applicationIdentifier,
        eventType,
        eventPayload,
        expectedPaymentFrequency,
        totalCost,
        accountData,
        customerAddress,
        threeDSChallengePath,
        transactionId,
        wref,
        isReoccurringCreditCard,
    },
    paymentProccesor: { paymentHandler, paymentGatewayUrl },
    callbacks: {
        onErrorCallback,
        onSuccess,
        onBeforeSubmit,
        onCancel,
        onShowThreeDSCallback,
        onValidate,
    },
    loggerParameters,
}: CardPaymentFormContainerProperties) {
    const { control, formState, handleSubmit, getValues } = useFormValidation({
        formProps: {
            mode: 'onBlur',
            defaultValues: {
                nameOnCard: '',
                cardNumber: '',
                expiryDate: '',
                securityCode: '',
            },
        },
        onValidate,
    });
    const { isSubmitting, isValid } = formState;

    const log = useMemo(
        () => ({
            logger: getAppLogger({ applicationIdentifier }),
            loggerParameters,
        }),
        [applicationIdentifier, loggerParameters],
    );

    const internalErrorCallback =
        createCardPaymentInternalErrorCallback(onErrorCallback);

    const [cardIdentifier, setCardIdentifier] = useState<string>('');
    const [billingAddress, setBillingAddress] = useState<BillingAddressFields>(
        mapBillingAddressFromCustomerAddress(customerAddress),
    );
    const [
        hasAcceptedAllTermsAndConditions,
        setHasAcceptedAllTermsAndConditions,
    ] = useState<boolean>(false);

    const {
        isThreeDSSecured,
        secureRedirectUrl,
        threeDSRequest,
        uniqueTransactionId,
        threeDSType,
        updateJourneyName,
        triggerTimer,
        setUpThreeDSFlow,
    } = useThreeDS({
        applicationIdentifier,
        transactionId,
        log,
        onErrorCallback: internalErrorCallback,
        onShowThreeDSCallback,
        onSuccess,
    });

    const onFormSubmit = usePayment({
        termsAccepted: hasAcceptedAllTermsAndConditions,
        wref,
        billingAddress,
        isReoccurringCreditCard,
        totalCost,
        log,
        onErrorCallback: internalErrorCallback,
        setCardIdentifier,
        setUpThreeDSFlow,
        paymentHandler,
        onBeforeSubmit,
        onSuccess,
    });

    const submitButtonDisabled =
        (totalCost > 0 && !isValid) ||
        !hasAcceptedAllTermsAndConditions ||
        isSubmitting;

    if (isThreeDSSecured) {
        return (
            <AuthenticationIframe
                redirectUrl={secureRedirectUrl}
                type={threeDSType as 'ThreeDSOne' | 'ThreeDSTwo'}
                req={threeDSRequest}
                transactionId={uniqueTransactionId}
                triggerTimerCallback={triggerTimer}
                applicationIdentifier={applicationIdentifier}
                threeDSChallengePath={threeDSChallengePath}
                updateJourneyName={updateJourneyName}
            />
        );
    }

    return (
        <>
            <Script src={paymentGatewayUrl} />
            <form onSubmit={handleSubmit(onFormSubmit)}>
                {totalCost > 0 && (
                    <CardPaymentForm
                        billingAddress={billingAddress}
                        cardIdentifier={cardIdentifier}
                        control={control}
                        showHeader={showHeader}
                        getValues={getValues}
                        setBillingAddress={setBillingAddress}
                    />
                )}

                <MembershipAgreement
                    agreementTypes={agreementTypes}
                    selectedPaymentMethod={PaymentMethod.Card}
                    accountData={accountData}
                    expectedPaymentFrequency={expectedPaymentFrequency}
                    wref={wref}
                    onTermsAndConditionsAccepted={
                        setHasAcceptedAllTermsAndConditions
                    }
                />

                <PaymentCta
                    eventType={eventType}
                    eventPayload={eventPayload}
                    disabled={submitButtonDisabled}
                    submitText={submitText}
                    submitButtonIconName={submitButtonIconName}
                    onCancel={onCancel}
                />
            </form>
        </>
    );
}

export const createCardPaymentInternalErrorCallback =
    (onErrorCallback: CardPaymentErrorCallback): CardPaymentErrorCallback =>
    (code, fullMessage) => {
        dispatchEvent<AnalyticsErrorEvent>(EventKey.ERROR, {
            category: code,
            details: getErrorFromErrorCode(code),
        });

        onErrorCallback(code, fullMessage);
    };

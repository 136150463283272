import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { ImageStandalone } from '../ImageStandalone';
import { Paragraph } from '../Paragraph';

export const StyledWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    max-width: 128rem;
    height: auto;
    margin: 0 3rem;
    overflow: hidden;
    border-radius: 1rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
        align-items: stretch;
    }
    ${({ theme }) => theme.breakpoints.up(1024)} {
        margin: 0 3rem;
    }
    ${({ theme }) => theme.breakpoints.up('largeDesktop')} {
        margin: 0 auto;
    }
`;

export const StyledImageContainer = styled('div')`
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: auto;
    min-height: 20rem;
    overflow: hidden;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex: 1 1 30%;
        width: 30%;
    }
`;

export const StyledImageStandalone = styled(ImageStandalone)`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const StyledTextContainer = styled('div')`
    position: relative;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 3rem 2rem 2rem;
    background-color: ${palette.grey[900]};
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 70%;
        padding: 2.2rem 4rem;
        margin: 0 auto;
    }
`;

export const StyledHeader = styled('div')`
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 2rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        gap: 1.5rem;
        margin-bottom: 3rem;
    }
`;

export const StyledHeading = styled(Heading)`
    font-size: 2.5rem;
    color: ${palette.common.blue};
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 3.1rem;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    margin-bottom: 2rem;
    font-size: 1.6rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 3rem;
        font-size: 1.8rem;
    }
`;

export const StyledButton = styled(ButtonBase)`
    width: auto;
    padding: 0.6rem 1.5rem;
    font-size: 1.8rem;
`;

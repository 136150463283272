import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { Heading } from '../Heading';

export const StyledCard = styled(Card)`
    padding: 3.2rem;
    margin: 3rem 0.2rem 0;
    border-radius: 1.6rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 4rem;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 3rem 0 0;
    }

    ${({ theme }) => theme.breakpoints.down(468)} {
        padding: 2rem;
    }

    ${({ theme }) => theme.breakpoints.down(405)} {
        padding: 1.5rem;
    }
`;

export const StyledButtonContainer = styled('div')<{
    isrejoin?: boolean;
}>`
    display: flex;
    flex-direction: ${({ isrejoin }) => (isrejoin ? 'column' : 'row')};
    gap: 1rem;
    justify-content: center;
    max-width: ${({ isrejoin }) => isrejoin && '50%'};
    padding: ${({ isrejoin, theme }) => isrejoin && `0 ${theme.spacing(30)}`};
    margin: ${({ isrejoin, theme }) =>
        isrejoin
            ? ` ${theme.spacing(40)} auto`
            : `${theme.spacing(20)} ${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(0)}`};

    ${({ theme }) => theme.breakpoints.down('desktop')} {
        max-width: ${({ isrejoin }) => isrejoin && '100%'};
        margin-top: ${({ theme }) => theme.spacing(20)};
    }
`;

export const StyledHeader = styled('header')`
    display: flex;
    align-content: center;
    justify-content: space-between;

    margin-bottom: 3rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 4rem;
    }
`;

export const StyledFixedProductOptions = styled('div')`
    display: flex;
    gap: 0.3rem;

    & > article {
        flex: 1;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        gap: 1.3rem;
    }
`;

export const StyledSectionHeading = styled(Heading)`
    margin: 3.2rem 3rem 0 3rem;
    font-family: thegymgroupsans-Headline, sans-serif;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: ${palette.common.blue};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
        line-height: 1.6rem;
        text-align: center;
    }
`;

import { useEffect, useState } from 'react';

import { getTestId, originWithBasePath } from '@tgg/util';

import { Overlay } from '../Overlay';

import {
    StyledLink,
    StyledOverlayHeading,
    StyledParagraph,
    StyledQRBorder,
    StyledQRCode,
} from './GymTourOverlay.styled';
import { GymTourOverlayProperties } from './GymTourOverlay.types';
import { useOverlay } from './useOverlay/useOverlay';

export const GymTourOverlay = ({ setMenuOpen }: GymTourOverlayProperties) => {
    const [gymTourPath, setUrl] = useState('');
    useEffect(() => {
        if (!gymTourPath) {
            setUrl(`${originWithBasePath(window.location.origin)}/gym-tour/`);
        }
    }, [gymTourPath]);

    const { handleClose, isOpen } = useOverlay({
        setMenuOpen,
    });

    return (
        <Overlay
            title="&nbsp;&nbsp;interested in a gym tour?&nbsp;&nbsp;"
            open={isOpen}
            handleClose={handleClose}
            centerTitleOnDesktop
            contentContainerMaxWidth={70}
            contentContainerisFullHeight
        >
            <StyledOverlayHeading>
                scan qr code for details
            </StyledOverlayHeading>
            <StyledQRBorder>
                <StyledQRCode
                    size={100}
                    value={gymTourPath}
                    viewBox="0 0 256 256"
                />
            </StyledQRBorder>
            <StyledParagraph>
                Unable to scan/access QR code, please click{' '}
                <StyledLink
                    href={gymTourPath}
                    data-testid={getTestId('gym-tour-details-link')}
                >
                    <strong>here</strong>
                </StyledLink>
            </StyledParagraph>
        </Overlay>
    );
};

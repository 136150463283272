import { Dispatch, DispatchWithoutAction, useEffect } from 'react';
import {
    FieldErrors,
    FieldValues,
    UseFormProps,
    useForm,
} from 'react-hook-form';

import { type Promisable } from '@tgg/common-types';

import { SnackBarActions } from 'libs/ui/src/lib/contexts/snackBarContext/snackBarContext.types';

export type UseFormValidationArguments<T extends FieldValues> = {
    formProps: UseFormProps<T>;
    snackBarErrorMessage?: string;
    onValidate?: (errors: FieldErrors) => Promisable<void>;
    updateSnackBar?: DispatchWithoutAction | Dispatch<SnackBarActions>;
};

export const useFormValidation = <
    TFieldValues extends FieldValues = FieldValues,
>({
    formProps,
    snackBarErrorMessage,
    onValidate,
    updateSnackBar,
}: UseFormValidationArguments<TFieldValues>) => {
    const form = useForm<TFieldValues>(formProps);

    const {
        formState: { errors },
    } = form;

    const numberOfErrors = Object.keys(errors).length;

    useEffect(() => {
        if (onValidate) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onValidate(errors);
        }

        if (!onValidate && updateSnackBar) {
            if (numberOfErrors > 0) {
                updateSnackBar({
                    barType: 'error',
                    content: snackBarErrorMessage ?? 'check your details',
                    iconName: 'alert',
                });
            } else {
                updateSnackBar({ barType: 'none' });
            }
        }

        // Excluded onValidate from deps as if it triggers a re-render in the parent
        // this effect will also trigger, creating an infinite loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfErrors]);

    return form;
};

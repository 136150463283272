export enum CmsPage {
    HomePage = 'HomePage',
    GymPage = 'GymPage',
    RegionPage = 'RegionPage',
}

export interface PromoSnackBarOptions {
    isSticky: boolean;
    promoStartDate: string | null;
}

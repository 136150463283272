import { Asterisk } from '../../Asterisk';
import { BillingAddress } from '../../BillingAddress';
import { StyledHorizontalRule } from '../CardPayment.styled';
import {
    CardholderNameInput,
    CardNumberInput,
    ExpiryDate,
    SecurityCode,
} from '../InputFields';

import {
    StyledInputFieldsSection,
    StyledInstructionText,
    StyledNarrowInputFieldsWrapper,
} from './CardPaymentForm.styled';
import { CardPaymentFormProperties } from './CardPaymentForm.types';
import { Header } from './Header';

export function CardPaymentForm({
    cardIdentifier,
    control,
    billingAddress,
    showHeader = true,
    getValues,
    setBillingAddress,
}: CardPaymentFormProperties) {
    return (
        <>
            {showHeader && <Header />}

            {/* Used by payment gateway */}
            <input
                data-testid="cardId"
                type="hidden"
                name="cardIdentifier"
                value={cardIdentifier}
            />

            <StyledInputFieldsSection>
                <StyledInstructionText gutterBottom={false}>
                    <Asterisk spaceAfter />
                    Required fields
                </StyledInstructionText>
                <CardholderNameInput control={control} />
                <CardNumberInput control={control} />
                <StyledNarrowInputFieldsWrapper>
                    <ExpiryDate control={control} />
                    <SecurityCode control={control} getValues={getValues} />
                </StyledNarrowInputFieldsWrapper>
            </StyledInputFieldsSection>

            <StyledHorizontalRule />

            <BillingAddress {...billingAddress} onSubmit={setBillingAddress} />
        </>
    );
}

import Link from 'next/link';
import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';

import { getAbsoluteURL, isMemberArea, isSupportCentre } from '@tgg/util';

import { StyledLinkText, StyledList, StyledListItem } from './LinksList.styled';
import { LinksListProperties } from './LinksList.types';

export function LinksList({ links, onClick }: LinksListProperties) {
    const router = useRouter();
    const isInMemberArea = isMemberArea(router.basePath);

    return (
        <StyledList>
            {links.map(link => {
                const isSupportCentreLink = isSupportCentre(link.href);
                return (
                    <StyledListItem key={uuidv4()}>
                        {link.external ? (
                            <StyledLinkText
                                data-testid={`external${link.href}`}
                                href={link.href}
                                data-link-name={link.name}
                                onClick={onClick}
                                rel={
                                    isSupportCentreLink
                                        ? 'noopener noreferrer'
                                        : ''
                                }
                            >
                                {link.name}
                            </StyledLinkText>
                        ) : (
                            <Link
                                href={
                                    isInMemberArea
                                        ? getAbsoluteURL(link.href)
                                        : link.href
                                }
                                passHref
                                legacyBehavior
                            >
                                <StyledLinkText
                                    onClick={onClick}
                                    data-testid={`internal${link.href}`}
                                    data-link-name={link.name}
                                >
                                    {link.name}
                                </StyledLinkText>
                            </Link>
                        )}
                    </StyledListItem>
                );
            })}
        </StyledList>
    );
}

import { Divider, Collapse } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { PinoMonthlyProduct, PinoProduct } from '@tgg/common-types';
import { DataEventNames, sendAnalyticsDataEvent } from '@tgg/services';

import { ButtonBase } from '../../Button';
import { Icon } from '../../Icon';
import { isGymOpen24Hours } from '../../PinoProducts';
import { PinoStudentProductOptions } from '../../PinoStudentProductOptions';
import { PinoTable } from '../../PinoTable';

import {
    StyledDescription,
    StyledHeading,
    StyledOptionButton,
    StyledPinoSectionHeader,
    StyledProductOptionWrapper,
} from './StudentPanel.styled';
import { StudentPanelProperties } from './StudentPanel.types';

export function StudentPanel({
    products,
    onSelect,
    gymName,
    ctaHref,
    productsAvailableAddons,
    openingHours,
    areTermsVisible,
    productHighlightPills,
    isBenefitsShortened,
    ctaName,
    isPaypalIframeVisible,
    isPaypalPayIn3Visible,
    isMBTOptimizationEnabled = false,
    isRejoin = false,
}: StudentPanelProperties) {
    const isOpen24Hours = isGymOpen24Hours(openingHours);
    const cheapestProduct = [...products.Student].sort(
        (a, b) => a.price.value - b.price.value,
    )[0];
    const [selectedProduct] = useState<PinoMonthlyProduct | PinoProduct>(
        cheapestProduct,
    );
    const router = useRouter();

    const [showPinoProductOptions, setShowPinoProductOptions] =
        useState<boolean>(areTermsVisible || false);

    const pinoStudentProducts = [cheapestProduct];

    const handleCancelClick = async () => {
        await router.push('/rejoin-now/your-membership/');
    };

    const handleOnOptionButton = () => {
        setShowPinoProductOptions(true);

        sendAnalyticsDataEvent({
            eventName: DataEventNames.CHOOSE_TERM,
            eventData: selectedProduct,
            eventGymName: gymName,
        });
    };

    return (
        <div>
            {!isRejoin && (
                <StyledPinoSectionHeader>
                    <StyledHeading variant="h2">
                        <Icon name="membershipStudent" viewBox="0 2 22 22" />{' '}
                        student membership
                    </StyledHeading>
                    <StyledDescription>
                        Everything you need to stay fit while studying
                    </StyledDescription>
                    <Divider />
                </StyledPinoSectionHeader>
            )}

            <PinoTable
                pinoMonthlyProducts={pinoStudentProducts}
                tableCaption={!isRejoin ? '1. Select your plan' : ''}
                disabledProduct={undefined}
                gymName={gymName}
                isOpen24Hours={isOpen24Hours}
                isStudentPinoTable
                isBenefitsShortened={isBenefitsShortened}
                showFooter={false}
            />
            {!showPinoProductOptions && (
                <StyledOptionButton isrejoin={isRejoin}>
                    <ButtonBase
                        type="button"
                        data-testid="choose-term-button"
                        buttonStyle="primary"
                        text={`Choose ${isRejoin ? 'membership' : 'your student'} term`}
                        onClick={handleOnOptionButton}
                    />
                    {isRejoin && (
                        <ButtonBase
                            type="button"
                            data-testid="student-panel-term-cancel-button"
                            buttonStyle="secondary"
                            text={`cancel`}
                            onClick={handleCancelClick}
                        />
                    )}
                </StyledOptionButton>
            )}
            <div>
                <Collapse in={showPinoProductOptions}>
                    <StyledProductOptionWrapper
                        $visible={showPinoProductOptions}
                    >
                        <PinoStudentProductOptions
                            products={products.Student}
                            isRejoin={isRejoin}
                            ctaHref={ctaHref}
                            gymName={gymName}
                            onSelect={onSelect}
                            productsAvailableAddons={productsAvailableAddons}
                            ctaName={
                                isRejoin ? 'Choose membership term' : ctaName
                            }
                            productHighlightPills={productHighlightPills}
                            isPaypalIframeVisible={isPaypalIframeVisible}
                            isPaypalPayIn3Visible={isPaypalPayIn3Visible}
                            isMBTOptimizationEnabled={isMBTOptimizationEnabled}
                        />
                    </StyledProductOptionWrapper>
                </Collapse>
            </div>
            {!showPinoProductOptions && <Divider />}
        </div>
    );
}

import { getTestId } from '@tgg/util';

import {
    iso3166CountriesList,
    iso3166UsStatesList,
} from './BillingAddress.constants';
import {
    StyledBillingAddressWrapper,
    StyledButton,
    StyledHeading,
    StyledInputInnerWrapper,
    StyledParagraph,
} from './BillingAddress.styled';
import { BillingAddressProperties } from './BillingAddress.types';
import { BillingAddressOverlay } from './BillingAddressOverlay';
import { useBillingAddress } from './utils';

export function BillingAddress({
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    postCode,
    country,
    state,
    onSubmit,
}: BillingAddressProperties) {
    const { isOverlayOpen, handleSubmit, onOverlayOpen, onOverlayClose } =
        useBillingAddress({
            onSubmit,
        });

    return (
        <>
            <StyledHeading variant="h2" component="h3" gutterBottom={false}>
                Billing Address
            </StyledHeading>
            <StyledParagraph>
                Please make sure your credit/debit card billing address is as it
                appears on your card statement.
            </StyledParagraph>
            <StyledBillingAddressWrapper data-testid="billing-address-wrapper">
                <StyledInputInnerWrapper>
                    <span>{addressLine1}</span>
                    <span>{addressLine2}</span>
                    <span>{addressLine3}</span>
                    <span>{city}</span>
                    <span>{postCode}</span>
                    {country === 'US' && state && (
                        <span>{iso3166UsStatesList[state]}</span>
                    )}

                    <span>{iso3166CountriesList[country]}</span>
                </StyledInputInnerWrapper>
                <StyledButton
                    text="Change"
                    data-testid={getTestId('change-billing-address-button')}
                    buttonStyle="tertiary"
                    fullWidth={false}
                    onClick={onOverlayOpen}
                />
            </StyledBillingAddressWrapper>

            <BillingAddressOverlay
                isOpen={isOverlayOpen}
                onClose={onOverlayClose}
                onSubmit={handleSubmit}
            />
        </>
    );
}

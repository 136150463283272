/* eslint-disable react/display-name */
import { ReactNode } from 'react';

import { Heading } from '../Heading';
import { OrderedList } from '../OrderedList';
import { Paragraph } from '../Paragraph';
import { PartialCollapsible } from '../PartialCollapsible';
import { UnorderedList } from '../UnorderedList';

import { StyledContainer, StyledReactMarkdown } from './RichTextContent.styled';
import { RichTextContentProperties } from './RichTextContent.types';

function p({ children }: { children: ReactNode }) {
    return (
        <Paragraph variant="body1" gutterBottom={false}>
            {children}
        </Paragraph>
    );
}

function ul({ children }: { children: ReactNode }) {
    return <UnorderedList>{children}</UnorderedList>;
}

function ol({ children }: { children: ReactNode }) {
    return <OrderedList>{children}</OrderedList>;
}

function h1({ children }: { children: ReactNode }) {
    return <Heading variant="h1">{children as string}</Heading>;
}

function h2({ children }: { children: ReactNode }) {
    return <Heading variant="h2">{children as string}</Heading>;
}

function h3({ children }: { children: ReactNode }) {
    return <Heading variant="h3">{children as string}</Heading>;
}

function h4({ children }: { children: ReactNode }) {
    return <Heading variant="h4">{children as string}</Heading>;
}
/**
 * The `RichTextContent` component is used to render Markdown content as React components.
 *
 * It is used to parse content from Amplience to other components as listed below.
 */
export function RichTextContent({
    id,
    className,
    text,
    collapsible,
    fullWidth = false,
    alignOnDesktop,
}: RichTextContentProperties) {
    const markdownComponent = (
        <div id={id} data-testid="rich-text-content">
            <StyledReactMarkdown
                className={className}
                $fullWidth={fullWidth}
                $alignOnDesktop={alignOnDesktop}
                components={{
                    p,
                    ul,
                    ol,
                    h1,
                    h2,
                    h3,
                    h4,
                }}
            >
                {text}
            </StyledReactMarkdown>
        </div>
    );

    if (collapsible) {
        const lineHeightDesktop = 2.6;
        const lineHeightMobile = 2.4;
        const minHeightDesktop = 3 * lineHeightDesktop;
        const minHeightMobile = 4 * lineHeightMobile;

        return (
            <StyledContainer
                id={id}
                $fullWidth={fullWidth}
                $alignOnDesktop={alignOnDesktop}
            >
                <PartialCollapsible
                    fullWidth={fullWidth}
                    minHeightDesktop={minHeightDesktop}
                    minHeightMobile={minHeightMobile}
                >
                    {markdownComponent}
                </PartialCollapsible>
            </StyledContainer>
        );
    }

    return markdownComponent;
}

export default RichTextContent;

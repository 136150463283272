import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';

export const StyledContainer = styled('div')<{
    $useAsTile: boolean;
    $showBorderRadius: boolean;
}>`
    display: flex;
    flex-grow: 0;
    flex-basis: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;

    ${({ $useAsTile, $showBorderRadius }) =>
        $useAsTile
            ? `
                max-width: 80rem;
                padding: 1.5rem;
                ${
                    $showBorderRadius
                        ? `
                            border: 0.3rem solid ${palette.grey[50]};
                            margin: 3rem auto;
                        `
                        : ''
                }
                border-radius: 1.5rem;
            `
            : ''};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;

        ${({ $useAsTile }) =>
            $useAsTile ? `padding: 1.5rem 4rem 0.5rem;` : ''};
    }
`;

export const StyledAppStoreBadges = styled('div')`
    display: flex;
    gap: 1rem;

    img {
        width: 100%;
    }
`;

export const StyledText = styled('section')`
    display: flex;
    margin-bottom: 1rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-right: 2rem;
        margin-bottom: 0;
    }
`;

export const StyledIcon = styled('div')`
    height: 5rem;
    padding: ${({ theme }) => theme.spacing(3)};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        height: 6rem;
        padding: ${({ theme }) => theme.spacing(5)};
    }
`;

export const StyledDescription = styled('div')`
    padding-bottom: ${({ theme }) => theme.spacing(5)};
    margin-bottom: revert;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 0.5rem;
    }
`;

import { Controller } from 'react-hook-form';

import { cvvNumberSchema, validation } from '@tgg/form-validation';

import { Asterisk } from '../../../Asterisk';
import { TextInput } from '../../../Forms/TextInput';
import { StyledInput, StyledLabel } from '../InputFields.styled';
import { ControlInputProperties } from '../InputFields.types';

export function SecurityCode({
    control,
    getValues,
}: {
    control: ControlInputProperties;
    getValues: () => { cardNumber: string };
}) {
    return (
        <StyledInput>
            <Controller
                name="securityCode"
                control={control}
                rules={{
                    required: 'Enter a valid security code',
                    validate: value => {
                        const { cardNumber } = getValues();
                        return (
                            validation(cvvNumberSchema(cardNumber), value)
                                .errors?.[0] ?? true
                        );
                    },
                }}
                render={({
                    field: { value, onChange, onBlur },
                    fieldState: { invalid, isDirty, error },
                }) => {
                    return (
                        <>
                            <StyledLabel htmlFor="security-code">
                                Security Code
                                <Asterisk spaceBefore />
                            </StyledLabel>
                            <TextInput
                                value={value}
                                errorMessage={error?.message}
                                isInvalid={invalid}
                                onChange={onChange}
                                onBlur={onBlur}
                                placeholder="XXX"
                                id="security-code"
                                inputProps={{
                                    inputMode: 'numeric',
                                }}
                                iconElementRight={{
                                    name:
                                        !invalid && isDirty ? 'tick' : 'blank',
                                }}
                                autoComplete="cc-csc"
                            />
                        </>
                    );
                }}
            />
        </StyledInput>
    );
}

import { AxiosResponse } from 'axios';
import { differenceInHours } from 'date-fns';
import { useEffect, useLayoutEffect, useState } from 'react';

import { JoinJourneyShoppingBasketCookie, MappedGym } from '@tgg/common-types';
import { gymBagShoppingBasketSchema, validation } from '@tgg/form-validation';
import { axiosInstance } from '@tgg/micro-services/axios';
import {
    getMembershipOptionData,
    mapPaymentSummary,
    ShoppingBasketDto,
} from '@tgg/services';
import {
    AVAILABLE_COOKIES,
    composeFullCookieName,
    createTraceParameters,
    deleteCookie,
    getApiErrorResponseMessage,
    getCookie,
    getFormattedDate,
    isDayPassMembership,
    setCookie,
} from '@tgg/util';

import { useAppConfigContext } from '../../contexts';

export function useBasketData({ domain }: { domain: string }) {
    const {
        appConfig: { appLogger },
    } = useAppConfigContext();

    const cookieKey = composeFullCookieName(
        AVAILABLE_COOKIES.DEFAULT_BASKET,
        'commerce',
    );

    const [basketData, setBasketData] =
        useState<null | JoinJourneyShoppingBasketCookie>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const shoppingBasketCookie = getCookie<JoinJourneyShoppingBasketCookie>({
        cookieKey,
    });

    const loggerParameters = createTraceParameters({
        context: null,
        parameters: { operation: 'useBasketData' },
    });
    const url = `${domain}/api/proxy/join/options/membership/`;

    useLayoutEffect(() => {
        if (shoppingBasketCookie) {
            const { isValid, errors } = validation(gymBagShoppingBasketSchema, {
                ...shoppingBasketCookie,
                isDayPass: isDayPassMembership(
                    shoppingBasketCookie?.basketItems?.product?.membershipType,
                ),
            });

            if (isValid) {
                const gymBagTimestamp = sessionStorage.getItem('gymBag');
                const formattedDateNow = getFormattedDate(
                    new Date(),
                    'yyyy-MM-dd',
                );

                if (
                    !gymBagTimestamp ||
                    (gymBagTimestamp &&
                        differenceInHours(
                            new Date(),
                            new Date(gymBagTimestamp),
                        ) >= 24)
                ) {
                    appLogger?.info(
                        `GymBag GET ${url} - about to get membership option data`,
                        loggerParameters,
                    );

                    getMembershipOptionData({
                        url,
                        accountTemplateIds: [
                            shoppingBasketCookie.basketItems.product.id,
                            ...shoppingBasketCookie.basketItems.addOns.map(
                                ({ id }) => id,
                            ),
                        ],
                        branchId: shoppingBasketCookie.branchId,
                        promoCode: shoppingBasketCookie.payments.promoCode,
                    })
                        .then(({ data }: AxiosResponse<any>) => {
                            const paymentSummary = {
                                ...shoppingBasketCookie?.payments,
                                ...mapPaymentSummary(
                                    data.shoppingBasket as ShoppingBasketDto,
                                ),
                            };
                            setBasketData({
                                ...shoppingBasketCookie,
                                payments: paymentSummary,
                            });
                            sessionStorage.setItem(
                                'gymBag',
                                new Date().toISOString(),
                            );
                            setCookie<JoinJourneyShoppingBasketCookie>({
                                cookieKey,
                                cookieParameters: {},
                                value: {
                                    ...shoppingBasketCookie,
                                    membershipStartDate: formattedDateNow,
                                    payments: paymentSummary,
                                },
                            });
                        })
                        .catch(error => {
                            const errorMessage =
                                getApiErrorResponseMessage(error);
                            appLogger?.error(
                                `GymBag GET ${url}- error occurred: ${errorMessage}`,
                                loggerParameters,
                            );
                            setBasketData(null);
                            deleteCookie({
                                cookieKey,
                                cookieParameters: {},
                            });
                        })
                        .finally(() => {
                            setIsLoaded(true);
                        });
                } else {
                    setBasketData(shoppingBasketCookie);
                    setIsLoaded(true);
                }
            }

            if (!isValid) {
                appLogger?.error(
                    `GymBag commerce: Corrupted basket cookie: ${errors}`,
                    loggerParameters,
                );
                setIsLoaded(true);
            }
        } else {
            setIsLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { basketData, isLoaded };
}

export function useGymPageUrl({
    branchId,
    branchName,
    domain,
}: {
    branchId?: string;
    branchName?: string;
    domain: string;
}) {
    const {
        appConfig: { appLogger },
    } = useAppConfigContext();

    const [gymPageUrl, setGymPageUrl] = useState<string | undefined>();

    const loggerParameters = createTraceParameters({
        context: null,
        parameters: { operation: 'useGymPageUrl' },
    });

    useEffect(() => {
        const fetchGymData = async () => {
            const gymCmsData: AxiosResponse<MappedGym[]> =
                await axiosInstance.get(`${domain}/api/cms/gyms/${branchId}/`);

            const availableGym = gymCmsData.data.find(
                (gym: MappedGym) =>
                    gym.isAvailableOnGymList && gym.gymName === branchName,
            );

            if (availableGym) {
                setGymPageUrl(availableGym.gymPageURL);
            } else {
                appLogger?.error(
                    `GymBag GET gym page URL - gym ${branchId} not found`,
                    loggerParameters,
                );
            }
        };

        if (branchId && branchName) {
            fetchGymData().catch(error => {
                const errorMessage = getApiErrorResponseMessage(error);
                appLogger?.error(
                    `GymBag GET gym page URL - error occurred: ${errorMessage}`,
                    loggerParameters,
                );
            });
        }
    }, [appLogger, branchId, branchName, domain, loggerParameters]);

    return gymPageUrl;
}

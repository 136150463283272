import {
    format,
    isExists,
    differenceInYears,
    differenceInDays,
    isBefore,
    roundToNearestMinutes,
} from 'date-fns';
import * as z from 'zod';

import { validation } from '@tgg/form-validation';

import { DatePartsFormat, DayMonthYear, InvalidDate } from './date.types';
import { dateOrdinal } from './dateOrdinal';

export const getDayMonthYearFromIsoDate = (
    isoDate: string,
): DayMonthYear | InvalidDate => {
    const date = new Date(isoDate);
    const validationSchema = z.object({
        date: z.date(),
    });
    const response = validation(validationSchema, { date });

    if (response.errors) {
        return { isValid: false, message: 'Invalid Date' };
    }

    return {
        isValid: true,
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
    };
};

/**
 * checks whether a given date is valid. e.g. 31/02/1994, 13/34/1900 etc.. should be invalid.
 * @param input - the date inputted by user in the UI in the format dd/mm/yyyy.
 * @returns boolean - true is given date is valid and in correct format.
 */
export const isDateValid = (input: string): boolean => {
    const inputSplitted = input.split('/');
    if (inputSplitted.length !== 3) return false;
    const [dd, mm, yyyy] = inputSplitted.map(Number);
    return isExists(yyyy, mm - 1, dd);
};

/**
 * gets the difference in years between 2 dates.
 * @param firstDate date on the left side of the subtraction.
 * @param secondDate date on the right side of the subtraction.
 * @returns number of full years between the two dates.
 */
export const getYearsDifference = (
    firstDate: DatePartsFormat,
    secondDate: DatePartsFormat,
): number => {
    const dateLeft = new Date(firstDate[2], firstDate[1] - 1, firstDate[0]);
    const dateRight = new Date(secondDate[2], secondDate[1] - 1, secondDate[0]);
    return differenceInYears(dateLeft, dateRight);
};

/**
 * does a given date occur before another given date.
 * @param firstDate the date that should be before the other one to return true.
 * @param secondDate the date to compare with.
 * @returns boolean - true if first date is before second date.
 */
export const isFirstDateBeforeSecond = (
    firstDate: DatePartsFormat,
    secondDate: DatePartsFormat,
): boolean => {
    const dateLeft = new Date(firstDate[2], firstDate[1] - 1, firstDate[0]);
    const dateRight = new Date(secondDate[2], secondDate[1] - 1, secondDate[0]);
    return isBefore(dateLeft, dateRight);
};

/**
 * @param time Time in the format 'hh:mm:ss'
 * @return string '(h)h(:mm)[am|pm]
 */
export const getMeridiemTimeFromIsoTime = (time: string): string => {
    const date = timeStringToDateObject(time);

    return dateStringTo12HourTime(date);
};

/**
 * Get 3-letter WeekDay name from a given date
 * @param date string date.
 * @return string day name.
 */
export const getWeekDayFromDate = (date: string): string => {
    const inputDate: Date = new Date(date);
    const [weekday] = inputDate.toDateString().split(' ');
    return weekday.toUpperCase();
};

/**
 * Get day, month from date.
 * @param date string date.
 * @param includeYear boolean. If true, the year will also be returned from the input date
 * @return string day & month in 'ordinal day month' format e.g. 3rd Jun
 */
export const getOrdinalDateFromDate = (
    date: string,
    options?: { returnYear?: boolean; returnNominativeMonth?: boolean },
): string => {
    const inputDate: Date = new Date(date);
    const dateString = inputDate.toDateString();
    const [, month, day, year] = dateString.split(' ');
    const dayParsed = Number.parseInt(day, 10);
    const ordinalOfDay = dateOrdinal(dayParsed);
    const monthFormatted = options?.returnNominativeMonth
        ? format(inputDate, 'MMMM')
        : month;
    return `${dayParsed}${ordinalOfDay} ${monthFormatted}${
        options?.returnYear ? ` ${year}` : ''
    }`;
};

/**
 * Get today's date as string.
 * Will return date in the format 'Weekday Month Day Year' e.g. Thu Jan 13 2022
 */
export const getTodaysDate = () => {
    return new Date().toDateString();
};

/**
 * Create a JavaScript date object from a time string.
 *
 * To use date-fns to process times, a date object is required, however it doesn't matter what the date actually is.
 *
 * @param time Time in the format 'hh:mm:ss'
 * @return Date object with hard-coded date.
 */
const timeStringToDateObject = (time: string): Date => {
    const [hh, mm, ss] = time.split(':');

    return new Date(2020, 11, 26, Number(hh), Number(mm), Number(ss));
};

/**
 * Get today's date as timestamp of format YYMMDDhhmmssSSSS
 */
export const getCurrentTimestamp = () => {
    return format(new Date(), 'yyMMddHHmmss0SSS');
};

/**
 * Checks if the date is a future date
 * @param date string date "2018-12-06T00:00:00"
 */
export const isFutureDate = (date: string): boolean => {
    return isBefore(new Date(), new Date(date));
};

/**
 * Checks the date difference between passing the date and todays date
 * @param date string date "2018-12-06T00:00:00"
 */
export const getDaysDifference = (date: string): number => {
    return differenceInDays(new Date(), new Date(date));
};

/**
 * Formats the date to dd/MM/yyyy
 */
export const getFormattedDate = (
    date: string | Date,
    formatString = 'dd/MM/yyyy',
) => {
    return date === '' ? '' : format(new Date(date), formatString);
};

/**
 * Formats the date to dd MMMM yyyy
 */
export const getViewFormattedDate = (date: string | Date) => {
    return getFormattedDate(date, 'dd MMMM yyyy');
};

/**
 * Formats the date to yyyymmdd
 * @param ddmmyyyy
 */
export const ddmmyyyyToyyyymmdd = (ddmmyyyy: string) => {
    return ddmmyyyy.replace(/T.*/, '').split('/').reverse().join('-');
};

/**
 * Formats the date to ddmmyyyy
 * @param yyyymmdd
 */
export const yyyymmddToddmmyyyy = (yyyymmdd: string) => {
    return yyyymmdd.split('-').reverse().join('/');
};

/**
 * Outputs a unix timestamp for a date provided in yyyy-mm-dd format and can add days
 * @param dateTime string date "2018-12-06"
 * @param addedDays number integer > 0
 */
export const getUnixTimestampAndAddDays = (
    dateTime: string | undefined,
    addedDays: number,
) => {
    if (!dateTime) return 0;
    if (!Number.isInteger(addedDays)) return 0;
    if (addedDays < 0) return 0;

    const date = new Date(dateTime);
    if (date.toString() === 'Invalid Date') return 0;
    date.setDate(date.getDate() + addedDays);
    // JS tries to adjust the hours to the current timezone so we need to set it to 0
    // https://stackoverflow.com/questions/32469269/javascript-date-give-wrong-date-off-by-one-hour
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    return date.getTime();
};

/**
 * Outputs a 12 hour time (eg 4pm) from a date string
 * @param date string date "2018-12-06T00:00:00"
 */
export const dateStringTo12HourTime = (date: Date) => {
    return format(
        roundToNearestMinutes(date, { nearestTo: 15 }),
        "hh:mm aaaaa'm'",
    );
};

/**
 * Checks if the user is under a certain age
 * @param dob string date in ddmmyyyy format
 * @param maxAge number integer > 0
 */
export const isUnderAge = (dob: string, maxAge: number) => {
    const todaysDate = new Date();
    const dateParts = dob.split('/').map(Number);
    const [dd, mm, yyyy] = dateParts;
    const differenceInYearsFromToday = getYearsDifference(
        [
            todaysDate.getDate(),
            todaysDate.getMonth() + 1,
            todaysDate.getFullYear(),
        ],
        [dd, mm, yyyy],
    );

    return differenceInYearsFromToday < maxAge;
};

import { useMediaQuery } from '@mui/material';
import Image from 'next/legacy/image';

import { TggMembershipType } from '@tgg/common-types';
import { relativeUrlWithBasePath } from '@tgg/util';

import { palette, theme } from '../../theme';
import { DownloadApp } from '../DownloadApp';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';

import {
    DownloadOurAppSection,
    StyledCheckList,
    StyledContent,
    StyledHeading,
} from './DownloadAppWithBenefitsList.styled';
import { getAddOnMessage } from './utils';

export interface DownloadAppWithBenefitsListProperTies {
    membershipType: TggMembershipType;
    isMultipleAddonsEnabled?: boolean;
    heading?: string;
    subTitle?: string;
}

export function DownloadAppWithBenefitsList({
    membershipType,
    isMultipleAddonsEnabled,
    heading = 'maximise your membership with our app',
    subTitle,
}: DownloadAppWithBenefitsListProperTies) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    /* istanbul ignore next */
    const imageHeight = isDesktop ? 144 : 79;
    /* istanbul ignore next */
    const imageWidth = isDesktop ? 515 : 325;
    const addOnsItemText = getAddOnMessage({
        membershipType,
        isMultipleAddonsEnabled,
    });

    return (
        <DownloadOurAppSection>
            <Image
                src={relativeUrlWithBasePath(
                    '/ywnqh/images/download-our-app.jpg',
                )}
                alt="two gym-goers have a friendly fist bump"
                height={imageHeight}
                width={imageWidth}
                objectFit="cover"
            />
            <StyledContent>
                <StyledHeading
                    variant="h3"
                    component="h2"
                    alignOnDesktop="center"
                >
                    {heading}
                </StyledHeading>
                {subTitle && <Paragraph>{subTitle}</Paragraph>}
                <StyledCheckList>
                    <li>
                        <Icon
                            name="tick"
                            color={palette.primary.main}
                            size={16}
                        />
                        Check how busy the gym is
                    </li>
                    <li>
                        <Icon
                            name="tick"
                            color={palette.primary.main}
                            size={16}
                        />
                        Contactless entry
                    </li>
                    <li>
                        <Icon
                            name="tick"
                            color={palette.primary.main}
                            size={16}
                        />
                        Track your activity
                    </li>
                    {addOnsItemText && (
                        <li>
                            <Icon
                                name="tick"
                                color={palette.primary.main}
                                size={16}
                            />
                            {addOnsItemText}
                        </li>
                    )}
                    <li>
                        <Icon
                            name="tick"
                            color={palette.primary.main}
                            size={16}
                        />
                        Book classes
                    </li>
                    <li>
                        <Icon
                            name="tick"
                            color={palette.primary.main}
                            size={16}
                        />
                        Create and follow workouts
                    </li>
                </StyledCheckList>
                <DownloadApp isShowTextContent={false} />
            </StyledContent>
        </DownloadOurAppSection>
    );
}

import { useCallback, useState } from 'react';

import { dispatchEvent, EventKey } from '@tgg/services';

import { palette } from '../../theme';
import { ButtonBase } from '../Button';
import { FiltersOverlay } from '../FiltersOverlay';
import { Icon } from '../Icon';

import { StyledButton, StyledList, StyledWrapper } from './BlogFilters.styled';
import { BlogFiltersProperties } from './BlogFilters.types';

export function BlogFilters({
    categoryName,
    filters,
    currentFilters,
    onRemoveFilter,
    handleSubmit,
}: BlogFiltersProperties) {
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);

    const handleFiltersOverlayClose = useCallback(
        () => setIsFiltersOpen(false),
        [],
    );

    const handleFiltersOverlayOpen = useCallback(async () => {
        setIsFiltersOpen(true);
        void dispatchEvent(EventKey.OPEN_FILTERS, {
            event: EventKey.OPEN_FILTERS,
            filter_category: categoryName.toLowerCase(),
        });
    }, [categoryName]);

    const handleOnClear = useCallback(async () => {
        await handleSubmit([]);
        void dispatchEvent(EventKey.CLEAR_FILTERS, {
            event: EventKey.CLEAR_FILTERS,
            filter_category: categoryName.toLowerCase(),
            filter_sub_category: currentFilters.join('|').toLowerCase(),
        });
    }, [categoryName, handleSubmit, currentFilters]);

    const handleOnSubmit = useCallback(
        async (newFilters: string[]) => {
            handleFiltersOverlayClose();
            await handleSubmit(newFilters);
            void dispatchEvent(EventKey.APPLY_FILTER, {
                event: EventKey.APPLY_FILTER,
                filter_category: categoryName.toLowerCase(),
                filter_sub_category: newFilters.join('|').toLowerCase(),
            });
        },
        [categoryName, handleSubmit, handleFiltersOverlayClose],
    );

    return (
        <>
            <StyledWrapper>
                <StyledList>
                    <li>
                        <ButtonBase
                            type="button"
                            onClick={handleFiltersOverlayOpen}
                            text="Filter"
                            buttonStyle="primary-navy"
                            startIcon={
                                <Icon
                                    name="filters"
                                    color={palette.common.white}
                                />
                            }
                        />
                    </li>
                    {currentFilters.length > 0 && (
                        <>
                            {currentFilters.map(filter => (
                                <li key={filter}>
                                    <StyledButton
                                        onClick={() => {
                                            onRemoveFilter(filter);
                                            void dispatchEvent(
                                                EventKey.REMOVE_FILTER,
                                                {
                                                    event: EventKey.REMOVE_FILTER,
                                                    filter_category:
                                                        categoryName.toLowerCase(),
                                                    filter_sub_category:
                                                        filter.toLowerCase(),
                                                },
                                            );
                                        }}
                                        endIcon={
                                            <Icon
                                                name="close"
                                                size={12}
                                                color={palette.common.blue}
                                            />
                                        }
                                        type="button"
                                        text={filter}
                                    />
                                </li>
                            ))}
                        </>
                    )}
                </StyledList>
            </StyledWrapper>
            <FiltersOverlay
                category={categoryName}
                isOpen={isFiltersOpen}
                onClose={handleFiltersOverlayClose}
                onClear={handleOnClear}
                onSubmit={handleOnSubmit}
                filters={filters}
                currentFilters={currentFilters}
            />
        </>
    );
}

export default BlogFilters;

import { Controller } from 'react-hook-form';

import { Asterisk } from '../../Asterisk';
import { ToggleButton } from '../../Forms/ToggleButton';
import { StyledLabel } from '../ControlledInputs.styled';

import { ControlledGenderInputProperties } from './ControlledGenderInput.types';

const maleFemaleOptions = [
    { id: 'Female', label: 'Female' },
    { id: 'Male', label: 'Male' },
];

export function ControlledGenderInput({
    control,
    disabled,
}: ControlledGenderInputProperties) {
    return (
        <Controller
            name="maleFemale"
            control={control}
            rules={{ required: 'Select your gender' }}
            render={({ field: { name, value, onChange } }) => {
                return (
                    <>
                        <StyledLabel $hasPaddingTop htmlFor="maleFemale">
                            Sex
                            <Asterisk spaceBefore />
                        </StyledLabel>
                        <ToggleButton
                            disabled={disabled}
                            name={name}
                            selectedOptionId={value}
                            onChange={onChange}
                            options={maleFemaleOptions}
                            layoutVariant="horizontal"
                        />
                    </>
                );
            }}
        />
    );
}

export default ControlledGenderInput;

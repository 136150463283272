import { AxiosError } from 'axios';

import { isAxiosError } from '../../../axios';
import { ApiError, RLErrorResponse } from '../../../errors';
import {
    TggLogger,
    TggTraceCorrelationParameters,
} from '../../../logger/logger.types';
import {
    ApiHandlerWithLoggerErrorHandler,
    ErrorResponse,
    GsspWithLoggerErrorHandler,
} from '../../next.types';

export const getApiErrorResponseMessage = (error: unknown) => {
    return (
        ((isAxiosError<ErrorResponse>(error) &&
            error.response?.data?.error?.message) ||
            (error as Error)?.message) ??
        'unknown error'
    );
};

export const logDefaultMessage = (handlerData: {
    error: unknown;
    logger: TggLogger;
    loggerParameters: TggTraceCorrelationParameters;
}) => {
    const { error, logger, loggerParameters } = handlerData;
    const { operation, resolvedPath } = loggerParameters;
    logger.debug(
        `${operation} in ${resolvedPath} page failed`,
        loggerParameters,
    );

    const message = getApiErrorResponseMessage(error);

    if (isAxiosError(error)) {
        logger.error(
            `${operation} in ${resolvedPath} - there was an error when calling APIM - error: ${JSON.stringify(
                error.response?.data,
            )} - request url: ${
                error.response?.config?.url
            } - response status: ${error.response?.status || error.status}
             - message: ${message}`,
            loggerParameters,
        );
    } else {
        logger.error(
            `${operation} in ${resolvedPath} failed - error unrelated to APIM - error: ${message}`,
            loggerParameters,
        );
    }
};

export const createGsspErrorHandler =
    <T>(
        handler: GsspWithLoggerErrorHandler<T>,
    ): GsspWithLoggerErrorHandler<T> =>
    handlerData => {
        logDefaultMessage(handlerData);
        return handler(handlerData);
    };

export const createNextApiHandlerErrorHandler =
    (
        handler: ApiHandlerWithLoggerErrorHandler,
    ): ApiHandlerWithLoggerErrorHandler =>
    handlerData => {
        logDefaultMessage(handlerData);
        return handler(handlerData);
    };

export const gsspErrorHandler = createGsspErrorHandler<never>(() => ({
    redirect: {
        destination: '/500/',
        permanent: false,
    },
}));

export const nextApiErrorHandler = createNextApiHandlerErrorHandler(input => {
    const { response, error, loggerParameters } = input;

    let errorStatusCode: number | undefined = (error as ApiError)?.status;
    const dataCode =
        (error as ApiError)?.code ?? (error as any)?.response?.data?.code;

    let code = dataCode ? String(dataCode) : dataCode;

    if (isAxiosError(error)) {
        errorStatusCode = (error as AxiosError).response?.status;
    }

    /* istanbul ignore next */
    if ((error as AxiosError<RLErrorResponse>).response?.data?.Code) {
        code = String(
            (error as AxiosError<RLErrorResponse>).response?.data.Code,
        );
        errorStatusCode =
            typeof code === 'string' ? Number.parseInt(code, 10) : undefined;
    }

    /* istanbul ignore next */
    // eslint-disable-next-line prefer-destructuring
    const message: string | undefined =
        (error as any)?.response?.data?.message ||
        (error as any)?.response?.data?.Message ||
        (error as Error).message ||
        (error as ApiError)?.detail;

    response.status(errorStatusCode || 500).json({
        error: {
            message: message || /* istanbul ignore next */ '',
            code,
            loggerParameters,
        },
    });
});

import { Controller } from 'react-hook-form';

import {
    getYearsDifference,
    isFirstDateBeforeSecond,
    isDateValid,
} from '@tgg/util';

import { Asterisk } from '../../Asterisk';
import { DateInput } from '../../Forms/DateInput';
import { StyledLabel, StyledSmallText } from '../ControlledInputs.styled';

import { ControlledBirthDateInputProperties } from './ControlledBirthDate.types';

export const validateDateOfBirth = (value: string): string | boolean => {
    if (!isDateValid(value)) {
        return 'Enter a valid date in DD/MM/YYYY format';
    }

    const valueSplitted = value.split('/');
    const dateParts = valueSplitted.map(Number);
    const [dd, mm, yyyy] = dateParts;

    const todaysDate = new Date();

    const differenceInYearsFromToday = getYearsDifference(
        [
            todaysDate.getDate(),
            todaysDate.getMonth() + 1,
            todaysDate.getFullYear(),
        ],
        [dd, mm, yyyy],
    );
    const isGivenDateBefore1900 = isFirstDateBeforeSecond(
        [dd, mm, yyyy],
        [1, 1, 1900],
    );

    if (isGivenDateBefore1900) {
        return 'Enter a valid year of birth';
    }

    if (differenceInYearsFromToday < 16) {
        return 'You must be 16 or older to join';
    }

    return true;
};

export function ControlledBirthDateInput({
    control,
    disabled,
    showMinAgeMessage = true,
    isRejoin = false,
    placeholder,
}: ControlledBirthDateInputProperties) {
    return (
        <>
            <Controller
                name="dob"
                control={control}
                rules={{
                    required: 'Date of Birth is required',
                    validate: validateDateOfBirth,
                }}
                render={({
                    field: { value, onBlur, onChange },
                    fieldState: { error, invalid, isDirty },
                }) => {
                    return (
                        <>
                            <StyledLabel $hasPaddingTop htmlFor="dob">
                                Date of Birth
                                <Asterisk spaceBefore />
                            </StyledLabel>
                            <DateInput
                                id="dob"
                                disabled={disabled}
                                placeholder={placeholder}
                                onBlur={onBlur}
                                onChange={onChange}
                                errorMessage={error?.message}
                                isInvalid={invalid}
                                value={value}
                                iconElementRight={{
                                    name:
                                        !invalid && isDirty ? 'tick' : 'blank',
                                }}
                            />
                        </>
                    );
                }}
            />

            {showMinAgeMessage && !isRejoin && (
                <StyledSmallText gutterBottom={false}>
                    Members must be 16 or over. If under 18 you will need to
                    complete a compulsory online Kickstart Session, and may be
                    asked for valid proof of age.
                </StyledSmallText>
            )}
        </>
    );
}

export default ControlledBirthDateInput;

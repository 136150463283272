import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { type TggFlagsObject } from '@tgg/util';

import { dispatchOptimizelyGaDecisionEvent } from '../helpers/dispatchOptimizelyGaEvent';

export const useOptimizelyBeClient = (config: {
    resolvedFlags: TggFlagsObject;
    resolvedUserId: string;
    pathname: string;
    useClient: boolean;
}) => {
    const { resolvedFlags, resolvedUserId, pathname, useClient } = config;

    const [eventsSent, setEventsSent] = useState(false);

    const router = useRouter();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (useClient) {
            const eventHandler = () => {
                setEventsSent(false);
            };

            router.events.on('routeChangeComplete', eventHandler);

            return () => {
                router.events.off('routeChangeComplete', eventHandler);
            };
        }
    }, [router.events]);

    const dispatchGaEvents = useCallback(() => {
        if (!eventsSent) {
            Object.keys(resolvedFlags).forEach(flagKey => {
                const flag = resolvedFlags[flagKey as keyof TggFlagsObject];
                // @ts-ignore
                if (flag.enabled && flag.variables?.enableDataLayer === true) {
                    dispatchOptimizelyGaDecisionEvent({
                        flagKey,
                        flagsUserId: resolvedUserId,
                        pathname,
                        ruleKey: flag.ruleKey,
                        variationKey: flag.variationKey,
                    });
                }
            });
            setEventsSent(true);
        }
    }, [eventsSent, resolvedFlags, resolvedUserId, pathname]);

    return { dispatchGaEvents, eventsSent };
};

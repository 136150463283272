export enum GymStatus {
    ComingSoon = 'Opening Soon',
    Open = 'Open',
    ClosedForMaintenance = 'Closed for Maintenance',
    Closed = 'Closed',
    Shutdown = 'Shutdown',
    Deleted = 'Deleted',
}
interface OpeningHours {
    OpeningTime: string;
    ClosingTime: string;
}

export interface AllDaysOpeningHours {
    Sunday: OpeningHours;
    Monday: OpeningHours;
    Tuesday: OpeningHours;
    Wednesday: OpeningHours;
    Thursday: OpeningHours;
    Friday: OpeningHours;
    Saturday: OpeningHours;
}

export interface UserDefinedFieldModelsDetails {
    Group: string;
    Name: string;
    Value: string;
}

export interface UDFKeyValueDetails {
    Group: string;
    Key: string;
    Value: string;
}

export interface OffPeakHoursData {
    Group: string;
    UdfKeyValues?: UDFKeyValueDetails[];
    UserDefinedFieldModels?: UserDefinedFieldModelsDetails[];
}

export interface AddressDto {
    address1: string;
    address2: string | null;
    address3: string | null;
    city: string | null;
    postcode: string;
}

export type DaysOfTheWeek =
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday';

export interface DayOffPeakTimes {
    period: number;
    openingTime: string;
    closingTime: string;
}

export type OffPeakTimes = {
    [key in DaysOfTheWeek]: DayOffPeakTimes[];
};

// type for getCmsGymList
export interface MappedGym {
    branchId: string;
    gymName: string;
    gymPageURL: string;
    isAvailableOnGymList?: boolean;
}

export type GymWithBranchIdAndName = {
    branchId: string;
    branchName: string;
};

export interface GymReference {
    gymName: string;
    gymPageURL: string;
    branchId: string;
}

export type SortedGym = {
    distance?: number;
    gym: {
        gymName: string;
        branchId: string;
        gymAddress: string;
        lowestPrice: number;
        joiningFee: number;
        gymPageURL: string;
    };
    position: { lat: number; lng: number };
};

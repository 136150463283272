import { styled } from '@mui/material/styles';

import { palette } from '../../../theme';
import { RichTextContent } from '../../RichTextContent';

export const StyledRichTextContent = styled(RichTextContent)`
    padding: 0;

    p {
        margin: 0;
        font-size: 1.4rem;
        color: ${palette.common.blue};
    }

    ul {
        margin: -0.5rem 0 0;

        li {
            padding-left: 0;
            font-size: 1.4rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

import { useMediaQuery } from '@mui/material';
import { PayPalMessages } from '@paypal/react-paypal-js';

import { theme } from '../../../theme';

import { StyledContainer } from './PayPalMessage.styled';
import { PayPalMessageProperties } from './PayPalMessage.types';

export const PayPalMessage = ({
    clientId,
    amount,
    style,
    noGutter,
}: PayPalMessageProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const defaultTextAlign = isDesktop ? 'center' : 'left';
    const defaultTextSize = 13;

    return (
        <StyledContainer data-testid="PayPalMessage" $noGutter={noGutter}>
            <PayPalMessages
                style={{
                    layout: 'text',
                    logo: {
                        type: 'alternative',
                        position: 'left',
                    },
                    text: {
                        size: style?.textSize ?? defaultTextSize,
                        align: style?.textAlign ?? defaultTextAlign,
                    },
                }}
                amount={amount}
                currency="GBP"
                forceReRender={[amount, isDesktop]}
            />
        </StyledContainer>
    );
};

import { Dialog, useMediaQuery } from '@mui/material';
import { useState } from 'react';

import { relativeUrlWithBasePath } from '@tgg/util';

import { palette, theme } from '../../theme';
import { ButtonLink } from '../ButtonLink';
import { Paragraph } from '../Paragraph';

import {
    StyledCloseButton,
    StyledIcon,
    StyledPayPalMessage,
    StyledPayPalMessageIframe,
} from './PayPalPayLaterMessage.styled';
import { PayPalPayLaterMessageProperties } from './PayPalPayLaterMessage.types';

export function PayPalPayLaterMessage({
    payLaterMessage,
}: PayPalPayLaterMessageProperties) {
    const [open, setOpen] = useState(false);
    const isLargeDesktop = useMediaQuery(theme.breakpoints.up('largeDesktop'));
    const handleClose = () => setOpen(false);
    return (
        <>
            <StyledPayPalMessage>
                <img
                    src={relativeUrlWithBasePath(
                        '/ywnqh/images/paypal_badge.svg',
                    )}
                    alt="PayPal badge"
                />
                <Paragraph gutterBottom={false}>
                    Pay in 3 interest-free payments with PayPal on all saver
                    memberships.{' '}
                    {payLaterMessage && (
                        <>
                            <ButtonLink onClick={() => setOpen(true)}>
                                Learn more
                            </ButtonLink>
                            <img
                                style={{ display: 'none' }}
                                alt="pay later"
                                src={payLaterMessage.impression_url}
                                aria-hidden="true"
                            />
                        </>
                    )}
                </Paragraph>
            </StyledPayPalMessage>
            {payLaterMessage && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="overlay"
                    aria-describedby="overlay-modal"
                    PaperProps={{
                        style: {
                            borderRadius: '20px',
                            overflow: 'hidden',
                            position: 'relative',
                            width: isLargeDesktop ? '40%' : '90%',
                            height: isLargeDesktop ? 'auto' : '75%',
                            paddingTop: '62.5%',
                        },
                    }}
                >
                    <StyledPayPalMessageIframe
                        title="PayPal Pay Later Further Information"
                        src={payLaterMessage.click_url}
                        allow="none"
                    />
                    <StyledCloseButton
                        onClick={handleClose}
                        aria-label="Close"
                        name="Close"
                        data-testid="close-button"
                    >
                        <StyledIcon
                            size={18}
                            name="close"
                            color={palette.primary.main}
                        />
                    </StyledCloseButton>
                </Dialog>
            )}
        </>
    );
}

import getConfig from 'next/config';

import { createCookieApi } from './cookies';
import { AVAILABLE_COOKIES } from './cookies.types';

export const createUserIdentifierCookieApi = () => {
    const APP_ENVIRONMENT =
        typeof getConfig === 'function'
            ? (getConfig()?.publicRuntimeConfig?.APP_ENVIRONMENT ??
              /* istanbul ignore next */ 'unresolved')
            : /* istanbul ignore next */ 'non-next';

    return createCookieApi<string>(
        'common',
        AVAILABLE_COOKIES.USER_IDENTIFIER,
        '',
        {
            expires: 365, // 365 days - When cookie is expired, the browser should take care of cookie cleanup.
            sameSite: 'strict',
            secure: true,
            httpOnly: false,
        },
        APP_ENVIRONMENT,
    );
};
export const userIdentifierCookieApi = createUserIdentifierCookieApi();

export const setUserIdentifierCookieSsr =
    userIdentifierCookieApi.ssr.setCookieSsr;

export const getUserIdentifierCookieSsr =
    userIdentifierCookieApi.ssr.getCookieSsr;

import { ReactNode, useMemo } from 'react';

import { ApplicationIdentifier } from '@tgg/common-types';
import { FlagsConstants, getAppLogger } from '@tgg/util';

import { AppConfigContext } from './appConfigContext';

export type AppConfigProperties = {
    children: ReactNode;
    appConfig: {
        appIdentifier: ApplicationIdentifier | FlagsConstants.UNRESOLVED;
    };
};

export function AppConfigProvider({
    children,
    appConfig,
}: AppConfigProperties) {
    const appLogger = useMemo(() => {
        const { appIdentifier: applicationIdentifier } = appConfig;
        return getAppLogger({
            applicationIdentifier,
        });
    }, [appConfig]);

    const contextValue = useMemo(() => {
        return { appConfig: { ...appConfig, appLogger } };
    }, [appConfig, appLogger]);

    return (
        <AppConfigContext.Provider value={contextValue}>
            {children}
        </AppConfigContext.Provider>
    );
}

import { styled } from '@mui/material/styles';

export const StyledLabel = styled('label')`
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
    font-weight: normal;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;

export const StyledInput = styled('div')`
    margin-bottom: ${({ theme }) => theme.spacing(20)};
`;

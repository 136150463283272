import { useMediaQuery } from '@mui/material';

import { palette, theme } from '../../../theme';
import { Icon } from '../../Icon';

import {
    HeaderWithIcon,
    StyledCloseButton,
    StyledHeadingHamBurger,
    StyledMenuHeader,
    StyledMenuIcon,
} from './Header.styled';
import { HeaderProperties } from './Header.types';

export function Header({ id, icon, text, onClose }: HeaderProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const { primary } = palette;

    return (
        <StyledMenuHeader id={id}>
            <HeaderWithIcon>
                <StyledHeadingHamBurger variant="h2" gutterBottom={false}>
                    <StyledMenuIcon
                        name={icon}
                        color={primary.main}
                        size={isDesktop ? 26 : 22}
                    />
                    {text}
                </StyledHeadingHamBurger>
            </HeaderWithIcon>
            <StyledCloseButton
                aria-label="close the side menu"
                onClick={onClose}
            >
                <Icon name="close" size={22} color={primary.main} />
            </StyledCloseButton>
        </StyledMenuHeader>
    );
}

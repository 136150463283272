import { useState } from 'react';

import { useFormValidation } from '@tgg/form-validation';
import { axiosInstance } from '@tgg/micro-services/axios';
import { ClickEvent, dispatchEvent } from '@tgg/services';
import {
    createTraceParameters,
    getEmailErrorMessage,
    getAbsoluteURL,
} from '@tgg/util';

import { useAppConfigContext } from '../../contexts';
import { ControlledEmailInput } from '../ControlledInputs';
import { RejoinInfoOverlay } from '../RejoinInfoOverlay';

import {
    StyledForm,
    StyledHeading,
    StyledImageContainer,
    StyledImageStandalone,
    StyledParagraph,
    StyledTextContainer,
    StyledWrapper,
} from './RejoinEmailComponent.styled';
import { RejoinEmailComponentProperties } from './RejoinEmailComponent.types';

export function RejoinEmailComponent({
    id,
    title,
    image,
    description,
}: RejoinEmailComponentProperties) {
    const loggerParameters = createTraceParameters({
        context: null,
        parameters: {
            operation: `rejoin-email-component`,
        },
    });
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
    const [overlayEmail, setOverlayEmail] = useState<string | undefined>();

    const { getFieldState, control, handleSubmit } = useFormValidation({
        formProps: {
            mode: 'onBlur',
            defaultValues: {
                email: '',
            },
        },
    });

    const {
        appConfig: { appLogger },
    } = useAppConfigContext();

    const onSubmit = async ({ email }: { email: string }) => {
        void dispatchEvent(ClickEvent.CTA_BUTTON_CLICK, {
            ctaName: 'emailSubmit',
        });

        setIsOverlayOpen(true);
        setOverlayEmail(email);

        try {
            const getSendPinNumberRouteUrl = `${getAbsoluteURL()}/api/proxy/join/sendPinNumber`;

            await axiosInstance.post(getSendPinNumberRouteUrl, {
                email: email,
            });
        } catch (error) {
            getEmailErrorMessage(error, appLogger, loggerParameters);
        }
    };

    return (
        <StyledWrapper id={id}>
            <StyledImageContainer>
                <StyledImageStandalone {...{ ...image }} />
            </StyledImageContainer>
            <StyledTextContainer>
                <StyledHeading gutterBottom={false} variant="h2">
                    {title}
                </StyledHeading>
                <StyledParagraph gutterBottom={false}>
                    {description}
                </StyledParagraph>
                <StyledForm
                    onSubmit={handleSubmit(onSubmit)}
                    aria-label="email address form"
                >
                    <ControlledEmailInput
                        control={control}
                        iconElementRight={{
                            name: getFieldState('email').invalid
                                ? 'information'
                                : 'arrowRight',
                            accessibleTitle: 'email address',
                            onClick: handleSubmit(onSubmit),
                        }}
                        placeholder="Enter your email address"
                        inputProps={{ maxLength: 50, inputMode: 'email' }}
                    />
                </StyledForm>
                {isOverlayOpen && (
                    <RejoinInfoOverlay
                        open={isOverlayOpen}
                        email={overlayEmail}
                        onClose={() => {
                            setIsOverlayOpen(false);
                            setOverlayEmail(undefined);
                        }}
                    />
                )}
            </StyledTextContainer>
        </StyledWrapper>
    );
}

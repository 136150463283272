import { upperFirst, lowerFirst } from 'lodash';

export type UnwrapArray<T> = T extends Array<infer R> ? R : T;

/**
 * type equivalent of unCapitalizeObjectProperties
 * traverses object type applying UnCapitalizedProperties to any obect type found
 * will try to infer type from arrays and apply UnCapitalizedProperties if object
 */
export type UnwrapUnCapitalizedObjct<T> =
    T extends Array<infer R>
        ? Array<R extends Record<any, any> ? UnCapitalizedProperties<R> : R>
        : T extends Record<any, any>
          ? /**
             * we need that to prevent stacking UnCapitalizedProperties -
             * UnCapitalizedProperties<UnCapitalizedProperties<UnCapitalizedProperties<type>>>
             * */
            T extends UnCapitalizedProperties<T>
              ? T
              : UnCapitalizedProperties<T>
          : T;

/**
 * type equivalent of capitalizeObjectProperties
 * traverses object type applying CapitalizedProperties to any obect type found
 * will try to infer type from arrays and apply CapitalizedProperties if object
 */
export type UnwrapCapitalizedObjct<T> =
    T extends Array<infer R>
        ? Array<R extends Record<any, any> ? CapitalizedProperties<R> : R>
        : T extends Record<any, any>
          ? /**
             * we need that to prevent stacking UnCapitalizedProperties -
             * UnCapitalizedProperties<UnCapitalizedProperties<UnCapitalizedProperties<type>>>
             * */
            T extends CapitalizedProperties<T>
              ? T
              : CapitalizedProperties<T>
          : T;

export type CapitalizedProperties<T> = {
    [key in keyof T as Capitalize<string & key>]: UnwrapCapitalizedObjct<
        T[key]
    >;
};

export type UnCapitalizedProperties<T> = {
    [key in keyof T as Uncapitalize<string & key>]: UnwrapUnCapitalizedObjct<
        T[key]
    >;
};

export const isObject = (input: any): boolean =>
    input && typeof input === 'object' && !Array.isArray(input);

/**
 * crawls recursively through object appling changes to property names
 * WARNING - will go inside arrays and apply changes on array elements (if objects)
 */
export const transformObjectProperties = <T extends Record<string, any>, O>(
    data: T,
    transformFuction: (property: string) => string,
): O => {
    if (typeof data !== 'object' || Array.isArray(data)) return data as O;

    return Object.keys(data).reduce((aggregate, currentProperty) => {
        const outputObject = { ...aggregate };
        if (
            data[currentProperty] &&
            typeof data[currentProperty] === 'object' &&
            !Array.isArray(data[currentProperty])
        ) {
            outputObject[`${transformFuction(currentProperty)}`] =
                transformObjectProperties(
                    data[currentProperty],
                    transformFuction,
                );
        } else if (
            typeof data[currentProperty] === 'object' &&
            Array.isArray(data[currentProperty])
        ) {
            outputObject[`${transformFuction(currentProperty)}`] = data[
                currentProperty
            ].map((value: any) =>
                transformObjectProperties(value, transformFuction),
            );
        } else {
            outputObject[`${transformFuction(currentProperty)}`] =
                data[currentProperty];
        }

        return outputObject;
    }, {} as any);
};

export const capitalizeObjectProperties = <T extends Record<string, any>>(
    data: T,
) => transformObjectProperties<T, CapitalizedProperties<T>>(data, upperFirst);

export const unCapitalizeObjectProperties = <T extends Record<string, any>>(
    data: T,
) => transformObjectProperties<T, UnCapitalizedProperties<T>>(data, lowerFirst);

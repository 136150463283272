import { palette } from '../../theme';

import { StyledHorizontalRule } from './HorizontalRule.styled';
import { HorizontalRuleProperties } from './HorizontalRule.types';

/**
 * Use the `HorizontalRule` component for any dividers.
 */
export function HorizontalRule({
    variant = 'middle',
    className,
    color = palette.grey[200],
}: HorizontalRuleProperties) {
    return (
        <StyledHorizontalRule
            variant={variant}
            color={color}
            className={className}
        />
    );
}

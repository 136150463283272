import { ButtonBase } from '../Button';

import {
    StyledAvatar,
    StyledButtonWrapper,
    StyledContainer,
    StyledContentContainer,
    StyledHeading,
    StyledHeadingBg,
    StyledImageContainer,
    StyledInfoContainer,
    StyledListItem,
    StyledSecondHeading,
    StyledUnorderedList,
} from './TrainerBox.styled';
import { TrainerBoxProperties } from './TrainerBox.types';

export const TrainerBox = ({
    imageSrc,
    trainerName,
    specialistAreas,
    staffMemberId,
    handleClick,
}: TrainerBoxProperties) => {
    return (
        <StyledContainer>
            <StyledHeadingBg>
                <StyledHeading variant="h2">{trainerName}</StyledHeading>
            </StyledHeadingBg>
            <StyledContentContainer>
                <StyledInfoContainer>
                    <StyledImageContainer>
                        <StyledAvatar src={imageSrc} alt={trainerName} />
                    </StyledImageContainer>
                    <StyledSecondHeading variant="h3">
                        specialist areas:
                    </StyledSecondHeading>
                    <StyledUnorderedList>
                        {specialistAreas &&
                            specialistAreas.map(area => (
                                <StyledListItem key={area}>
                                    {area}
                                </StyledListItem>
                            ))}
                    </StyledUnorderedList>
                </StyledInfoContainer>
                <StyledButtonWrapper>
                    <ButtonBase
                        text="more info"
                        data-testid="trainer-btn"
                        fullWidth
                        onClick={() => handleClick(staffMemberId)}
                    />
                </StyledButtonWrapper>
            </StyledContentContainer>
        </StyledContainer>
    );
};

import { styled } from '@mui/material/styles';

import { palette } from '../../../theme';

export const StyledBestDealChip = styled('div')<{
    $isMarginBottom?: boolean;
    $isHeightAuto?: boolean;
}>`
    display: flex;
    align-items: center;
    width: 11rem;
    height: ${({ $isHeightAuto }) => ($isHeightAuto ? `auto` : '3.1rem')};
    padding: 0 1rem;

    margin-bottom: ${({ $isMarginBottom }) =>
        $isMarginBottom ? `1.5rem;` : '0px'};
    margin-left: -0.6rem;
    font-family: thegymgroupsans-Headline;
    color: ${palette.common.white};
    background: ${palette.common.blue};
    border-radius: 2rem;

    span {
        margin-left: 0.5rem;
    }

    svg {
        align-self: center;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 11.7rem;
        height: ${({ $isHeightAuto }) => ($isHeightAuto ? `auto` : ' 3.2rem')};
        border-radius: 2rem;
    }
`;

export const StyledBestDealChipPlaceholder = styled('div')`
    width: 11rem;
    height: 3.1rem;
    margin-bottom: 1.5rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 12.4rem;
        height: 3.4rem;
    }
`;

import Link from 'next/link';

import { getAbsoluteURL } from '@tgg/util';

import {
    StyledAlertMessage,
    StyledBoldText,
} from './InductionInformationMessage.styled';

export function InductionInformationMessage() {
    return (
        <StyledAlertMessage type="information">
            <StyledBoldText>
                Please attend the mandatory Kickstart Session with a fitness
                expert
            </StyledBoldText>
            Your PIN will only grant you access to the gym after completing your
            Kickstart Session.
            <ul>
                <li>
                    The gym manager will contact you by email to arrange a date
                    and time for your Kickstart Session.
                </li>
                <li>
                    Our{' '}
                    <Link href={getAbsoluteURL('contact-us')}>
                        customer support team
                    </Link>{' '}
                    can help if you haven&apos;t received the email within 3
                    working days.
                </li>
                <li>
                    You won&apos;t be able to book additional guest passes until
                    you have attended a Kickstart Session with a fitness expert.
                </li>
            </ul>
        </StyledAlertMessage>
    );
}

import { styled } from '@mui/material/styles';

import { Theme } from '../../theme';

export const GridContainer = styled('div')<{ $height?: number }>`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    width: 100%;
    margin-bottom: 1.5rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
        column-gap: 1.5rem;
        height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
    }
    & img,
    & a {
        display: block;
        flex: 1 1 0;
        width: 100%;
        height: 100%;
    }

    img {
        display: block;
        width: 100%;
        height: ${({ $height }) => ($height ? `${$height}px` : '100%')};
        object-fit: cover;
    }
`;

export const StyledImageWrapper = styled('div')`
    flex: 1;
`;

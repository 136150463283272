import { RefObject, useEffect, useMemo, useRef, useState } from 'react';

import { palette } from '../../theme';

import { IconLinkStyle, IconLinkStyleComplete } from './IconLink.types';

type UseHoverType<T extends HTMLElement> = [RefObject<T>, boolean];

export function useHover<T extends HTMLElement>(): UseHoverType<T> {
    const [value, setValue] = useState(false);
    const reference = useRef<T>(null);
    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);

    useEffect(() => {
        const node = reference.current;

        if (!node) {
            return;
        }

        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mousedown', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        // eslint-disable-next-line consistent-return
        return () => {
            node.removeEventListener('mouseover', handleMouseOver);
            node.removeEventListener('mousedown', handleMouseOver);
            node.removeEventListener('mouseout', handleMouseOut);
        };
    }, []);

    return [reference, value];
}

export function useDynamicsStyle(
    regularStyle: IconLinkStyle,
    dynamicStyle: IconLinkStyle | undefined,
    isHovering: boolean,
): IconLinkStyleComplete {
    return useMemo(() => {
        const safeRegularStyle = {
            ...regularStyle,
            textColor: regularStyle.textColor || palette.common.blue,
        };

        if (!isHovering) {
            return safeRegularStyle;
        }

        return {
            ...safeRegularStyle,
            ...dynamicStyle,
        };
    }, [isHovering, regularStyle, dynamicStyle]);
}

import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';

export const StyledPriceContainer = styled('div')`
    display: flex;
    flex-direction: column;
`;

export const StyledPrice = styled('div')`
    align-self: center;
    margin-right: 0.5rem;
    font-family: thegymgroupsans-headline;
    font-size: 1.5rem;
    line-height: 2.1rem;
    color: ${palette.primary.main};
    letter-spacing: -0.05rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.7rem;
        letter-spacing: -0.03rem;
    }
`;

export const StyledDiscountedPrice = styled('span')`
    text-decoration: line-through;
`;

export const StyledDuration = styled('span')`
    font-family: thegymgroupsans-bold;
    font-size: 1.5rem;
    line-height: 2.4rem;
    color: ${palette.common.blue};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.8rem;
        line-height: 2.6rem;
    }
`;

export const StyledText = styled('span')`
    font-family: thegymgroupsans-headline, sans-serif;
`;

export const StyledPriceAndDuration = styled('div')`
    display: flex;
`;

export const StyledTCLink = styled('a')`
    display: flex;
    font-family: thegymgroupsans-headline, sans-serif;
    color: ${palette.common.blue};
    text-decoration: none;
    p {
        margin: 0;
        text-decoration: underline;
    }
`;

export const StyledDescriptionContent = styled('div')`
    max-width: 35rem;
`;

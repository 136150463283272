import { styled } from '@mui/material/styles';
import Link from 'next/link';

import { Heading } from '../Heading';
import { ImageStandalone } from '../ImageStandalone';
import { Paragraph } from '../Paragraph';

export const StyledCard = styled('div')<{ $hasExtraMarginTop?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    align-self: stretch;
    padding: 1.5rem;
    margin-top: ${({ $hasExtraMarginTop }) =>
        $hasExtraMarginTop ? '2rem' : '1rem'};
    background-color: ${({ theme }) => theme.palette.grey[50]};
    border-radius: 0.8rem;
`;

export const StyledSlot = styled('li')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 4rem;
    padding: 1rem;
    background-color: ${({ theme }) => theme.palette.grey[900]};
    border-radius: 0.8rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-basis: 49%;
    }
`;

export const StyledSection = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const StyledTitle = styled(Heading)`
    font-family: thegymgroupsans-Headline;
    font-size: 1.4rem;
    font-style: normal;
    line-height: 2rem;
    color: ${({ theme }) => theme.palette.primary.main};
    text-transform: lowercase;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;

export const StyledSubtitle = styled(Paragraph)`
    font-family: thegymgroupsans-Headline;
    font-size: 1.4rem;
    font-style: normal;
    line-height: 2rem;
    text-transform: lowercase;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;

export const StyledSlotText = styled(Heading, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<{
    $textColor?: string;
}>`
    font-family: thegymgroupsans-Headline;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 800;
    line-height: 2rem;
    color: ${({ $textColor, theme }) =>
        $textColor === 'primary'
            ? theme.palette.primary.main
            : theme.palette.common.blue};
    text-transform: lowercase;
`;

export const StyledParagraph = styled(Paragraph)`
    font-family: thegymgroupsans-Bold;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 800;
    line-height: 2rem;
    & b {
        font-family: thegymgroupsans-Headline;
    }
`;

export const StyledMiniCard = styled('ul')`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    width: 100%;
    padding-left: 0;
    margin: 0;
    list-style-type: none;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-flow: row wrap;
        gap: 1rem 1.5rem;
    }
`;

export const StyledImageCard = styled('li')`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 0.8rem;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05);
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        height: 8rem;
    }
`;

export const StyledAddonImageContainer = styled('div')`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

export const StyledImageStandalone = styled(ImageStandalone)`
    width: 6rem;
    height: 5rem;
    border-radius: 0.8rem 0 0 0.8rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 9.6rem;
        height: 8rem;
    }
`;

export const StyledTextContainer = styled('div')`
    display: flex;
    flex-direction: column;
    min-width: 60%;
`;

export const StyledPill = styled('div')`
    position: absolute;
    top: 0;
    padding: 0.3rem 1rem;
    font-family: thegymgroupsans-headline;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.palette.common.blue};
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-radius: 2rem;
    transform: translateY(-50%);
`;

export const StyledContainer = styled('ul')`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
    }
`;

export const StyledIconContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-radius: 50%;
`;

export const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 4rem;
    height: 2rem;
    text-align: center;
`;

import { useEffect, useRef, useState } from 'react';

import { dispatchEvent, EventKey } from '@tgg/services';

import { calculateScrollPadding } from '../../utils';

import { AnchorLink } from './AnchorLinks.types';

export function useGetAnchorLinksNavigation(links: AnchorLink[]) {
    const [activeLink, setActiveLink] = useState<string | null>(null);
    const [showChevrons, setShowChevrons] = useState(false);
    const containerReference = useRef<HTMLDivElement>(null);
    const isUserScrolled = useRef(true);
    const isUserScrolledAnchorLinks = useRef(true);
    const wasScrollEventFired = useRef(false);
    const offset = useRef(158);

    const handleScroll = () => {
        let currentSection = null;
        links.forEach((link: AnchorLink) => {
            const section = document.querySelector(link.path);
            if (section) {
                const { top } = section.getBoundingClientRect();
                if (top <= offset.current + 10 && top > 0)
                    currentSection = link.path;
            }
        });
        /* istanbul ignore next */
        if (
            currentSection &&
            currentSection !== activeLink &&
            isUserScrolled.current
        ) {
            setActiveLink(currentSection);

            makeActiveLinkVisible(currentSection);
        }

        /* istanbul ignore next */
        if (activeLink && isUserScrolled.current === false) {
            const section = document.querySelector(activeLink);
            if (section) {
                const isAtTarget =
                    section.getBoundingClientRect().top < offset.current + 10 &&
                    section.getBoundingClientRect().top > 0;

                if (isAtTarget) {
                    isUserScrolled.current = true;
                }
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            offset.current = calculateScrollPadding();

            if (containerReference.current) {
                const { scrollWidth, clientWidth } = containerReference.current;
                setShowChevrons(
                    window.innerWidth >= 768 &&
                        window.innerWidth < 1440 &&
                        scrollWidth > clientWidth,
                );
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [links]);

    const handleClick = (path: string) => {
        const section = document.querySelector(path);

        if (section) {
            isUserScrolled.current = false;
            setActiveLink(path);

            makeActiveLinkVisible(path);

            section.scrollIntoView({ behavior: 'smooth' });

            window.history.replaceState(null, '', path);
        }
    };

    const makeActiveLinkVisible = (link: string) => {
        const activeLinkElement = document.querySelector(`[href="${link}"]`);

        /* istanbul ignore if */
        if (activeLinkElement && containerReference.current) {
            const container = containerReference.current;
            const linkRect = activeLinkElement.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();

            const linkLeft = Math.floor(linkRect.left);
            const linkRight = Math.floor(linkRect.right);
            const containerLeft = Math.floor(containerRect.left);
            const containerRight = Math.floor(containerRect.right);

            if (linkRight > containerRight || linkLeft < containerLeft) {
                let scrollDistance = 0;

                if (linkLeft < containerLeft) {
                    scrollDistance = linkLeft - containerLeft;
                } else if (linkRight > containerRight) {
                    scrollDistance = linkRight - containerRight;
                }

                isUserScrolledAnchorLinks.current = false;
                container.scrollTo({
                    left: container.scrollLeft + scrollDistance,
                    behavior: 'smooth',
                });

                setTimeout(() => {
                    isUserScrolledAnchorLinks.current = true;
                }, 500);
            }
        }
    };

    const handleAnchorLinksScroll = () => {
        if (!wasScrollEventFired.current && isUserScrolledAnchorLinks.current) {
            void dispatchEvent(EventKey.ANCHOR_LINK_SCROLL);

            wasScrollEventFired.current = true;
        }
    };

    useEffect(() => {
        offset.current = calculateScrollPadding();

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeLink]);

    useEffect(() => {
        const container = containerReference.current;
        if (!container) return;

        container.addEventListener('scroll', handleAnchorLinksScroll);

        return () =>
            container.removeEventListener('scroll', handleAnchorLinksScroll);
    }, []);

    const scrollContainer = (direction: string) => {
        /* istanbul ignore next */
        if (containerReference.current) {
            const { scrollWidth, clientWidth } = containerReference.current;
            const newScrollX =
                direction === 'left' ? 0 : scrollWidth - clientWidth;

            containerReference.current.scrollTo({
                left: newScrollX,
                behavior: 'smooth',
            });
        }
    };

    return {
        activeLink,
        containerReference,
        handleClick,
        scrollContainer,
        showChevrons,
    };
}

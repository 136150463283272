import { styled } from '@mui/material/styles';

import { Theme } from '@tgg/ui';

import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const StyledParagraph = styled(Paragraph)`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 2rem;
        text-align: center;
    }
`;

export const StyledWayOfPaymentContainer = styled('div')`
    margin-bottom: 1rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 6rem;
    }
`;

export const StyledCreditCardButton = styled(ButtonBase)`
    margin-bottom: 2rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        max-width: 31.5rem;
        height: 4.5rem;
        margin-right: 2rem;
        margin-bottom: 0;
    }
`;

export const StyledOptionsWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
        justify-content: center;
    }
`;

export const StyledHeading = styled(Heading)`
    margin-bottom: 2rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

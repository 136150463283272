import { Dispatch, Touch, TouchEventHandler, useReducer } from 'react';

import { Direction } from './MediaGallery.types';

interface GalleryState {
    totalItems: number;
    selectedIndex: number;
    swipeDirection: Direction;
    lastTouchX?: number;
}

type GalleryAction =
    | {
          type:
              | 'SWIPE_ENDED'
              | 'SWIPE_CANCELLED'
              | 'NEXT_CLICKED'
              | 'PREVIOUS_CLICKED';
      }
    | {
          type: 'SWIPE_STARTED';
          touch: Touch;
      }
    | {
          type: 'SWIPE_MOVE';
          touch: Touch;
      };

function reducer(state: GalleryState, action: GalleryAction): GalleryState {
    switch (action.type) {
        case 'SWIPE_STARTED': {
            if (state.swipeDirection !== Direction.None) {
                return state;
            }
            return {
                ...state,
                lastTouchX: action.touch.clientX,
            };
        }

        case 'SWIPE_MOVE': {
            if (state.lastTouchX === undefined) {
                return state;
            }
            const { clientX } = action.touch;

            const swipeDirection =
                clientX < state.lastTouchX ? Direction.Left : Direction.Right;

            return {
                ...state,
                swipeDirection,
            };
        }

        case 'SWIPE_ENDED': {
            const newSelectedIndex = {
                left: state.selectedIndex + 1,
                none: state.selectedIndex,
                right: state.selectedIndex - 1,
            }[state.swipeDirection];

            const selectedIndex =
                newSelectedIndex < 0 || newSelectedIndex >= state.totalItems
                    ? state.selectedIndex
                    : newSelectedIndex;

            return {
                ...state,
                selectedIndex,
                swipeDirection: Direction.None,
            };
        }

        case 'SWIPE_CANCELLED': {
            return {
                ...state,
                swipeDirection: Direction.None,
            };
        }

        case 'NEXT_CLICKED': {
            const newSelectedIndex = state.selectedIndex + 1;
            const selectedIndex =
                newSelectedIndex < 0 || newSelectedIndex >= state.totalItems
                    ? state.selectedIndex
                    : newSelectedIndex;
            return {
                ...state,
                selectedIndex,
            };
        }

        case 'PREVIOUS_CLICKED': {
            const newSelectedIndex = state.selectedIndex - 1;
            const selectedIndex =
                newSelectedIndex < 0 || newSelectedIndex >= state.totalItems
                    ? state.selectedIndex
                    : newSelectedIndex;
            return {
                ...state,
                selectedIndex,
            };
        }

        default: {
            return state;
        }
    }
}

type DispatchedTouchEventHandler = (
    dispatch: Dispatch<GalleryAction>,
) => TouchEventHandler<HTMLDivElement>;

const onTouchStart: DispatchedTouchEventHandler = dispatch => event => {
    event.preventDefault();
    dispatch({ type: 'SWIPE_STARTED', touch: event.touches.item(0) });
};
const onTouchMove: DispatchedTouchEventHandler = dispatch => event => {
    event.preventDefault();
    dispatch({ type: 'SWIPE_MOVE', touch: event.touches.item(0) });
};
const onTouchEnd: DispatchedTouchEventHandler = dispatch => event => {
    event.preventDefault();
    dispatch({ type: 'SWIPE_ENDED' });
};
const onTouchCancel: DispatchedTouchEventHandler = dispatch => event => {
    event.preventDefault();
    dispatch({ type: 'SWIPE_CANCELLED' });
};

const onNextClick = (dispatch: Dispatch<GalleryAction>) => () => {
    dispatch({ type: 'NEXT_CLICKED' });
};
const onPreviousClick = (dispatch: Dispatch<GalleryAction>) => () => {
    dispatch({ type: 'PREVIOUS_CLICKED' });
};

export function useGallery<T>(items: T[]) {
    const [state, dispatch] = useReducer(reducer, {
        totalItems: items.length,
        selectedIndex: 0,
        swipeDirection: Direction.None,
    });

    return {
        selectedIndex: state.selectedIndex,
        nextButtonDisabled: state.selectedIndex === items.length - 1,
        previousButtonDisabled: state.selectedIndex === 0,
        swipeDirection: state.swipeDirection,
        onTouchStart: onTouchStart(dispatch),
        onTouchMove: onTouchMove(dispatch),
        onTouchEnd: onTouchEnd(dispatch),
        onTouchCancel: onTouchCancel(dispatch),
        onNextClick: onNextClick(dispatch),
        onPreviousClick: onPreviousClick(dispatch),
    };
}

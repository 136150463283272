import { styled } from '@mui/material/styles';

import { palette, lightGreyBoxShadow } from '../../theme';

import { PromoTileVIColor } from './PromoTile.types';

export const promoTileColorsMap = {
    [PromoTileVIColor.Blue]: palette.primary.main,
    [PromoTileVIColor.Green]: palette.secondary.main,
    [PromoTileVIColor.DarkBlue]: palette.common.blue,
    [PromoTileVIColor.Ultimate]: palette.pino.ultimate,
    [PromoTileVIColor.Standard]: palette.pino.standard,
    [PromoTileVIColor.OffPeak]: palette.pino.offPeak,
};

export const StyledPromoTile = styled('div')`
    ${({ color }) => `
        border-left: 0.8rem solid ${color};
        border-radius: 0.8rem;
        box-shadow: ${lightGreyBoxShadow}

        hr {
            border-top: 0.2rem solid ${color};
            margin-left: 0;
            margin-right: 0;
        }
    `};
`;

export const StyledContainer = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem 3rem 1.8rem 3rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 1.4rem 3rem 1.8rem 3rem;
    }
`;

export const StyledTitle = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: thegymgroupsans-Headline;
    font-size: 1.9rem;
    line-height: 2.1rem;
    text-transform: lowercase;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 2.1rem;
        line-height: 2.5rem;
    }
`;

export const StyledDetails = styled('div')`
    flex-grow: 1;
    margin-bottom: 0.8rem;
    font-size: 1rem;
    line-height: 1.6rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }
`;

export const StyledPromoCodeContainer = styled('div')`
    font-family: thegymgroupsans-Headline;
    font-size: 1.2rem;
`;

export const StyledPromoCode = styled('div')`
    text-transform: none;
    white-space: nowrap;
`;

import { IncomingMessage } from 'http';
import { NextApiRequest } from 'next';
import getConfig from 'next/config';

export const relativeUrlWithBasePath = (path: string) => {
    const { APP_BASE_PATH } = getConfig().publicRuntimeConfig;
    return path.startsWith('/') ? `${APP_BASE_PATH || ''}${path}` : path;
};

export const originWithBasePath = (origin: string) => {
    const { APP_BASE_PATH } = getConfig().publicRuntimeConfig;
    return APP_BASE_PATH ? `${origin}${APP_BASE_PATH}/` : origin;
};

/**
 * builds a url from the headers in the request AND adds the application base-path
 * can only be used serverside
 * @param request the serverside nextjs Request
 * @returns baseurl built from headers in the request
 */
export const getBaseURLWithBasePathFromRequest = (
    request: NextApiRequest | IncomingMessage,
): string => {
    const { APP_BASE_PATH } = getConfig().publicRuntimeConfig;
    return `${getBaseURLFromRequest(request)}${APP_BASE_PATH}/`;
};

export const getHostFromRequest = (
    request: NextApiRequest | IncomingMessage,
): URL => {
    const { headers } = request;
    const { referer, host } = headers;

    // referer 'should' always have a value regardless of environement
    // but this is a fallback
    if (!referer) {
        const { NODE_ENV } = getConfig().serverRuntimeConfig;
        const protocol = `${NODE_ENV === 'production' ? 'https' : 'http'}`;
        return new URL(`${protocol}://${host}`);
    }

    // referer should have a full url e.g. 'https://dev-www.tggdev.com/join-now/email/' so will need to extract just the origin from that.
    // ignoring the browser compat issue with URL as this is serverside
    // eslint-disable-next-line compat/compat
    const baseUrl = new URL(referer);

    return baseUrl;
};

/**
 * builds a url from the headers in the request
 * can only be used serverside
 * @param request the serverside nextjs Request
 * @returns baseurl built from headers in the request
 */
export const getBaseURLFromRequest = (
    request: NextApiRequest | IncomingMessage,
): string => {
    // check for the custom header first which will only be set if request is routed via application Gateway
    if (request.headers['x-original-host']) {
        return `https://${request.headers['x-original-host']}`;
    }

    const baseUrl = getHostFromRequest(request);
    return baseUrl.origin;
};

export const getFullUrlFromRequest = (
    request: NextApiRequest | IncomingMessage,
) => {
    const { APP_BASE_PATH } = getConfig().publicRuntimeConfig;
    const base = getBaseURLFromRequest(request);
    const urlString = `${base}${APP_BASE_PATH}${
        request.url || /* istanbul ignore next */ ''
    }`;

    // eslint-disable-next-line compat/compat
    return new URL(urlString);
};

export const addTrailingSlash = (pathname: string) => {
    return pathname && !pathname.endsWith('/') ? `${pathname}/` : pathname;
};

export const parseNextCachePathname = (pathname: string) => {
    const parsedPathname = pathname
        .replace(/(_next\/data\/.*?\/)/, '')
        .replace('.json', '/');
    return addTrailingSlash(parsedPathname);
};

export const resolvePathnameFromRequest = (
    request: NextApiRequest | IncomingMessage,
) => {
    const pathnameFromRequest = getFullUrlFromRequest(request).pathname;
    const resolvedPathname = addTrailingSlash(pathnameFromRequest);

    return pathnameFromRequest.includes('_next/data')
        ? parseNextCachePathname(pathnameFromRequest)
        : resolvedPathname;
};

export const getDomainUrl = (request: NextApiRequest): string => {
    const originalHost = request.headers['x-original-host'];
    const { NODE_ENV } = getConfig().serverRuntimeConfig;

    if (originalHost) {
        const protocol = `${NODE_ENV === 'production' ? 'https' : 'http'}`;
        const url = `${protocol}://${originalHost}`;
        return NODE_ENV === 'production'
            ? envReplacement(url)
            : localEnvReplacement(url);
    }

    const { referer, host } = request.headers;
    // referer 'should' always have a value regardless of environement
    // but this is a fallback
    if (!referer) {
        const protocol = `${NODE_ENV === 'production' ? 'https' : 'http'}`;
        const url = `${protocol}://${host}`;
        return NODE_ENV === 'production'
            ? envReplacement(url)
            : localEnvReplacement(url);
    }

    return NODE_ENV === 'production'
        ? envReplacement(referer)
        : localEnvReplacement(referer);
};

export const getCommerceApplicationHomepageURL = (origin: string) => {
    // eslint-disable-next-line compat/compat
    const originURL = new URL(origin);
    const { APP_BASE_PATH } = getConfig().publicRuntimeConfig;
    if (originURL.port === '4201') {
        // TODO revisit this in https://tggitdev.atlassian.net/browse/MAUI-1652
        // this will only be true when the function gets called from member_area AND if env is local
        // putting a clause specifically for local env is a bad practice
        originURL.port = '4200';
        return `${originURL.href}`;
    }
    return removeMemberAreaFromUrl(`${origin}${APP_BASE_PATH}/`);
};
// Remove instances of /member-area/ in urls.
// This is needed so we can remove the member area base path in "/member-area/find-a-gym" and link to "/find-a-gym".
export const removeMemberAreaFromUrl = (url: string) =>
    url.replace('/member-area/', '/');

export const isMemberArea = (path: string) => path.includes('/member-area');

export const isKioskLandingPage = (path: string) => path.includes('kiosk');

export const isSupportCentre = (path: string) =>
    path.includes('support.thegymgroup.com');

const localEnvReplacement = (url: string): string => {
    // eslint-disable-next-line compat/compat
    return new URL(url).hostname.replaceAll(/^[^:]+:/g, '');
};
const envReplacement = (url: string): string => {
    // eslint-disable-next-line compat/compat
    return new URL(url).hostname.replaceAll(/^[^.]+\./g, '.');
};

export const getAbsoluteURL = (url?: string): string => {
    const { APP_BASE_URL } = getConfig().publicRuntimeConfig;
    if (!url) {
        return APP_BASE_URL;
    }
    // removes duplicate slashes and returns an absolute dynamic path depending on the environment
    return `${APP_BASE_URL}/${url}/`.replaceAll(/([^:]\/)\/+/g, '$1');
};

export const isQueryParameterValid = (queryParameter: string): boolean => {
    // be sure that the query parameter does not contain any forbidden characters
    return !/[/<>[\\\]^{|}~]/.test(queryParameter);
};

import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Paragraph } from '../Paragraph';

export const StyledCard = styled(Card)`
    padding: 3.2rem;
    margin: 3rem 0.2rem 0;
    border: none;
    border-radius: 1.6rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 4rem;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 3rem 0 0;
    }

    ${({ theme }) => theme.breakpoints.down(468)} {
        padding: 2rem;
    }

    ${({ theme }) => theme.breakpoints.down(405)} {
        padding: 1.5rem;
    }
`;

export const StyledButtonContainer = styled('div')<{
    isrejoin?: boolean;
}>`
    display: flex;
    flex-direction: ${({ isrejoin }) => (isrejoin ? 'column' : 'row')};
    gap: 1rem;
    justify-content: center;
    max-width: ${({ isrejoin }) => isrejoin && '50%'};
    margin: ${({ isrejoin, theme }) =>
        isrejoin
            ? '3rem auto'
            : `${theme.spacing(20)} ${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(0)}`};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 3rem;
    }
    ${({ theme }) => theme.breakpoints.down('desktop')} {
        max-width: ${({ isrejoin }) => isrejoin && '100%'};
    }
`;

export const StyledFixedProductOptions = styled('div')`
    display: flex;
    gap: 0.3rem;

    & > article {
        flex: 1;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        gap: 1.3rem;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    && {
        margin-top: 3rem;
        margin-bottom: 4rem;
        text-align: center;
    }
`;

import { Drawer } from '@mui/material';
import getConfig from 'next/config';

import { OptimizelyFeatureFlags } from '@tgg/common-types';
import { decimalToPoundsAndPence } from '@tgg/util';

import { useFlagsContext } from '../../contexts';
import { palette } from '../../theme';
import { Icon } from '../Icon';
import { PayPalMessage } from '../PinoProductOptions/PayPalMessage';
import { Header } from '../SideNavigation/Header';
import { StyledPaper } from '../SideNavigation/SideNavigation.styled';
import { StickyNavigationButton } from '../StickyNavigationButton';

import {
    checkFixedProductSelected,
    checkNoJoiningFeePromo,
    getMappedTodayItems,
} from './GymBag.helpers';
import { useBasketData, useGymPageUrl } from './GymBag.hook';
import {
    StyledBenefitItem,
    StyledBenefits,
    StyledContainer,
    StyledFindAGymIcon,
    StyledFindAGymLink,
    StyledHeading,
    StyledNavigationButtonWrapper,
    StyledNavigationLink,
    StyledNavigationList,
    StyledParagraph,
    StyledPaymentIcons,
    StyledPayPalWrapper,
    StyledTotalCost,
    StyledTotalCostText,
} from './GymBag.styled';
import { GymBagProperties } from './GymBag.types';
import { GymBagTile } from './GymBagTile/GymBagTile';

const findAGymUrl = '/find-a-gym/';
const joinNowMembershipUrl = '/join-now/your-membership/';
const joinNowNoFeeButtonText = 'join now with no joining fee';
const joinNowButtonText = 'join now';
const {
    publicRuntimeConfig: { PAYPAL_CLIENT_ID },
} = getConfig();

export function GymBag({
    open,
    onClose,
    onCtaClick,
    domain = '',
}: GymBagProperties) {
    const { basketData, isLoaded } = useBasketData({
        domain,
    });

    const gymPageUrl = useGymPageUrl({
        branchId: basketData?.branchId,
        branchName: basketData?.branchName,
        domain,
    });

    const {
        flags: {
            [OptimizelyFeatureFlags.PAYPAL_PAY_IN_3_GYM_BAG_IFRAME]: {
                enabled: isPaypalIframeEnabled,
                variationKey: isPaypalIframeEnabledVariationKey,
            },
        },
    } = useFlagsContext();

    const isPaypalIframeVisible =
        isPaypalIframeEnabled && isPaypalIframeEnabledVariationKey === 'on';
    const isJoiningFeePromoApplied = checkNoJoiningFeePromo(
        basketData?.payments.today?.items,
    );
    const tileItems = getMappedTodayItems(basketData);
    const isFixedProductSelected = checkFixedProductSelected(basketData);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            elevation={0}
            PaperProps={{ component: StyledPaper }}
        >
            <Header
                id="headerId"
                icon="gymBag"
                text="Your gym bag"
                onClose={onClose}
            />
            <StyledContainer>
                {isLoaded &&
                    (basketData ? (
                        <GymBagTile
                            items={tileItems}
                            title={basketData?.branchName ?? ''}
                            total={basketData.payments.today?.total}
                            gymPageUrl={gymPageUrl ?? findAGymUrl}
                            onClose={onClose}
                        />
                    ) : (
                        <>
                            <StyledParagraph>
                                Your gym bag is empty.
                            </StyledParagraph>
                            <StyledFindAGymLink href={findAGymUrl}>
                                <StyledFindAGymIcon
                                    name="gymFinder"
                                    size={32}
                                    color={palette.common.white}
                                />
                                find a gym near you
                            </StyledFindAGymLink>
                            <StyledNavigationList>
                                <li>
                                    <StyledNavigationLink href="/gym-membership-deals-and-offers/">
                                        membership deals and offers
                                    </StyledNavigationLink>
                                </li>
                                <li>
                                    <StyledNavigationLink href="/student-membership/">
                                        student membership
                                    </StyledNavigationLink>
                                </li>
                            </StyledNavigationList>
                            <StyledHeading variant="h3">
                                all of our members benefit from:
                            </StyledHeading>
                            <StyledBenefits>
                                <StyledBenefitItem>
                                    <Icon name="dumbells" size={24} />
                                    Top Kit
                                </StyledBenefitItem>
                                <StyledBenefitItem>
                                    <Icon name="noContract" size={24} />
                                    No Contract
                                </StyledBenefitItem>
                                <StyledBenefitItem>
                                    <Icon name="cost" size={24} />
                                    Low Cost
                                </StyledBenefitItem>
                                <StyledBenefitItem>
                                    <Icon name="twentyFourHours" size={24} />
                                    24 Hour Opening
                                </StyledBenefitItem>
                            </StyledBenefits>
                        </>
                    ))}
                <StyledHeading variant="h3">pay your way</StyledHeading>
                <StyledPaymentIcons data-testid="paymentIcons">
                    <li>
                        <Icon
                            name="payPal"
                            aria-label="pay with PayPal"
                            size={60}
                            viewBox="0 0 61 16"
                        />
                    </li>
                    <li>
                        <Icon
                            name="directDebit"
                            aria-label="pay with Direct Debit"
                            viewBox="8.5 67.94 175.75 56.88"
                            size={46}
                        />
                    </li>
                    <li>
                        <Icon
                            name="visa"
                            aria-label="pay with visa"
                            rectangle
                            size={33}
                        />
                    </li>
                    <li>
                        <Icon
                            name="mastercard"
                            aria-label="pay with MasterCard"
                            rectangle
                            size={33}
                        />
                    </li>
                    <li>
                        <Icon
                            name="amex"
                            aria-label="pay with American Express"
                            rectangle
                            size={33}
                        />
                    </li>
                </StyledPaymentIcons>
            </StyledContainer>
            {isLoaded && basketData?.payments.today?.total && (
                <StyledTotalCost>
                    <StyledTotalCostText>payment due today</StyledTotalCostText>
                    <StyledTotalCostText data-testid="total-cost">
                        {decimalToPoundsAndPence(
                            basketData?.payments.today?.total,
                        )}
                    </StyledTotalCostText>
                </StyledTotalCost>
            )}
            {isLoaded &&
                basketData?.payments.today?.total &&
                isPaypalIframeVisible &&
                isFixedProductSelected && (
                    <StyledPayPalWrapper>
                        <PayPalMessage
                            clientId={PAYPAL_CLIENT_ID}
                            amount={basketData.payments.today.total}
                            style={{ textAlign: 'left' }}
                        />
                    </StyledPayPalWrapper>
                )}
            <StyledNavigationButtonWrapper>
                <StickyNavigationButton
                    buttonText={
                        isJoiningFeePromoApplied
                            ? joinNowNoFeeButtonText
                            : joinNowButtonText
                    }
                    href={
                        isLoaded && basketData
                            ? joinNowMembershipUrl
                            : findAGymUrl
                    }
                    testId="join-now"
                    onClick={onCtaClick}
                />
            </StyledNavigationButtonWrapper>
        </Drawer>
    );
}

import { useState } from 'react';

import { Icon } from '../Icon';

import {
    StyledHeader,
    StyledHeaderSection,
    StyledIconButton,
    StyledInfoContainer,
} from './SummaryExpander.styled';
import { SummaryExpanderProperties } from './SummaryExpander.types';

export const SummaryExpander = ({
    children,
    title,
    ariaLabelText,
    defaultIsVisible = false,
}: SummaryExpanderProperties) => {
    const [isVisible, setIsVisible] = useState(defaultIsVisible);
    return (
        <StyledInfoContainer>
            <StyledHeaderSection $isClosed={!isVisible}>
                <StyledHeader variant="h3" gutterBottom={false}>
                    {title}
                </StyledHeader>
                <StyledIconButton
                    aria-label={`${!isVisible ? 'show' : 'hide'} ${ariaLabelText}`}
                    onClick={() => setIsVisible(!isVisible)}
                >
                    <Icon
                        name={isVisible ? 'chevronUp' : 'chevronDown'}
                        size={20}
                    />
                </StyledIconButton>
            </StyledHeaderSection>
            {isVisible && children}
        </StyledInfoContainer>
    );
};

import { withLinkedImages } from '../../hocs/withLinkedImages/withLinkedImages';

import { Image } from './Image';
import { ImageStandaloneProperties } from './ImageStandalone.types';
import { Picture } from './Picture';

/**
 * Show an image at the full width of its container.
 */
function ImageStandaloneComponent({
    id,
    image,
    alt,
    width,
    height,
    maximumMobileImageHeight,
    className,
    lazy,
    prioritiseImageLoading = false,
    overrideSizes,
    objectPosition,
    alignCenter,
}: ImageStandaloneProperties) {
    if (prioritiseImageLoading) {
        return (
            <Picture
                id={id}
                image={image}
                alt={alt}
                height={height}
                maximumMobileImageHeight={maximumMobileImageHeight}
                lazy={lazy}
                overrideSizes={overrideSizes}
                objectPosition={objectPosition}
                className={className}
            />
        );
    }

    return (
        <Image
            id={id}
            image={image}
            alt={alt}
            height={height}
            width={width}
            className={className}
            lazy={lazy}
            objectPosition={objectPosition}
            alignCenter={alignCenter}
        />
    );
}

export const ImageStandalone = withLinkedImages(ImageStandaloneComponent);

import { Nullable, TggMembershipType } from '@tgg/common-types';

interface GetAddOnMessageArguments {
    membershipType: TggMembershipType;
    isMultipleAddonsEnabled?: boolean;
}

export const addOnsMessages = {
    manageAddons: 'Manage your add-ons',
    manageAndAccessAddons: 'Manage and access your add-ons',
};

export const addOnsMessageMap: Record<string, string> = {
    [TggMembershipType.UltimateMonthly]: addOnsMessages.manageAddons,
    [TggMembershipType.StandardMonthly]: addOnsMessages.manageAndAccessAddons,
    [TggMembershipType.OffPeakMonthly]: addOnsMessages.manageAddons,
};

// This fuctions will be removed when multiple add-ons feature is released
export const getAddOnMessage = ({
    membershipType,
    isMultipleAddonsEnabled,
}: GetAddOnMessageArguments): Nullable<string> => {
    if (
        membershipType === TggMembershipType.StandardMonthly &&
        isMultipleAddonsEnabled
    ) {
        return addOnsMessageMap[TggMembershipType.StandardMonthly];
    }

    if (
        membershipType === TggMembershipType.StandardMonthly &&
        !isMultipleAddonsEnabled
    ) {
        return addOnsMessages.manageAddons;
    }

    return addOnsMessageMap[membershipType] ?? null;
};

import { objectPositionDevices } from '@tgg/common-types';

import { theme } from '../../../theme';
import { getObjectPosition } from '../ImageStandalone.utils';

import { StyledImg } from './Picture.styled';
import { PictureProperties } from './Picture.types';

function amplienceUrl(
    defaultHost: string,
    endpoint: string,
    name: string,
    size: Record<string, number | 'auto'>,
    imgScale = '&sm=c',
): string {
    const imgWidth = size.width === 'auto' ? '' : `&w=${size.width}`;

    return `https://${defaultHost}/i/${endpoint}/${encodeURIComponent(
        name,
    )}?fmt=auto&h=${size.height}${imgWidth}${imgScale}&qlt=default&$qlt$&$poi$`;
}

export function Picture({
    id,
    image,
    alt = '',
    height = 545,
    maximumMobileImageHeight = 192,
    lazy = false,
    overrideSizes,
    objectPosition = {},
    className,
    scale,
}: PictureProperties) {
    const loading = lazy ? 'lazy' : 'eager';

    const DEFAULT_SIZES = {
        default: { height, width: theme.breakpoints.values.largeDesktop },
        smallMobile: {
            height: maximumMobileImageHeight,
            width: theme.breakpoints.values.mobile,
        },
        mobile: {
            height: maximumMobileImageHeight,
            width: theme.breakpoints.values.desktop,
        },
        desktop: { height, width: theme.breakpoints.values.largeDesktop },
        largeDesktop: {
            height,
            width: theme.breakpoints.values.extraLargeDesktop,
        },
    };
    const sizes = overrideSizes || DEFAULT_SIZES;

    const objectPositionDesktop = getObjectPosition(
        objectPosition,
        objectPositionDevices.DESKTOP,
    );
    const objectPositionMobile = getObjectPosition(
        objectPosition,
        objectPositionDevices.MOBILE,
    );

    if (typeof image === 'string') {
        return (
            <picture id={id}>
                <StyledImg
                    src={image}
                    alt={alt}
                    $height={height}
                    $maximumMobileImageHeight={maximumMobileImageHeight}
                    $objectPositionDesktop={objectPositionDesktop}
                    $objectPositionMobile={objectPositionMobile}
                    loading={loading}
                    className={className}
                />
            </picture>
        );
    }

    const { name, endpoint, defaultHost } = image;

    const defaultImageUrl = amplienceUrl(
        defaultHost,
        endpoint,
        name,
        sizes.default,
        scale,
    );

    const smallMobileImageUrl = amplienceUrl(
        defaultHost,
        endpoint,
        name,
        sizes.smallMobile,
        scale,
    );

    const mobileImageUrl = amplienceUrl(
        defaultHost,
        endpoint,
        name,
        sizes.mobile,
        scale,
    );

    const desktopImageUrl = amplienceUrl(
        defaultHost,
        endpoint,
        name,
        sizes.desktop,
        scale,
    );

    const largeDesktopImageUrl = amplienceUrl(
        defaultHost,
        endpoint,
        name,
        sizes.largeDesktop,
        scale,
    );

    return (
        <picture id={id}>
            <source
                srcSet={largeDesktopImageUrl}
                media={`(min-width: ${theme.breakpoints.values.largeDesktop}px)`}
            />
            <source
                srcSet={desktopImageUrl}
                media={`(min-width: ${theme.breakpoints.values.desktop}px)`}
            />
            <source
                srcSet={mobileImageUrl}
                media={`(min-width: ${theme.breakpoints.values.mobile}px)`}
            />
            <source srcSet={smallMobileImageUrl} />
            <StyledImg
                src={defaultImageUrl}
                alt={alt}
                $height={height}
                $maximumMobileImageHeight={maximumMobileImageHeight}
                $objectPositionDesktop={objectPositionDesktop}
                $objectPositionMobile={objectPositionMobile}
                loading={loading}
                className={className}
            />
        </picture>
    );
}

/* istanbul ignore file */
import { createContext } from 'react';

import { ApplicationIdentifier } from '@tgg/common-types';
import { FlagsConstants, TggLogger } from '@tgg/util';

import { appConfig } from './appConfigDefaults';

export type AppConfigContextProperties = {
    appConfig: {
        appIdentifier: ApplicationIdentifier | FlagsConstants.UNRESOLVED;
        appLogger: TggLogger;
    };
};

export const AppConfigContext = createContext<AppConfigContextProperties>({
    appConfig,
});

import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';
import { Paragraph } from '../Paragraph';

export const StyledContent = styled('div')`
    flex-direction: column;
    justify-content: flex-start;
    padding: ${({ theme }) => theme.spacing(10)};
    text-align: left;
`;

export const StyledDescription = styled(Paragraph)`
    padding-top: ${({ theme }: { theme: Theme }) => theme.spacing(10)};
    padding-bottom: ${({ theme }: { theme: Theme }) => theme.spacing(10)};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-top: ${({ theme }: { theme: Theme }) => theme.spacing(20)};
        padding-bottom: ${({ theme }: { theme: Theme }) => theme.spacing(30)};
    }
`;

export const StyledAddOnTileItem = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
        display: flex;
    }
`;

export const StyledPrice = styled('div')`
    align-self: center;
    margin-right: 0.5rem;
    font-family: thegymgroupsans-headline;
    font-size: 1.5rem;
    line-height: 2.1rem;
    color: ${palette.primary.main};
    letter-spacing: -0.05rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.7rem;
        letter-spacing: -0.03rem;
    }
`;

export const StyledDiscountedPrice = styled('span')`
    text-decoration: line-through;
`;

export const StyledDuration = styled('span')`
    font-family: thegymgroupsans-bold;
    font-size: 1.5rem;
    line-height: 2.4rem;
    color: ${palette.common.blue};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.8rem;
        line-height: 2.6rem;
    }
`;

export const StyledDiscountMessage = styled('div')`
    font-family: thegymgroupsans-Headline;
    font-size: 1rem;
    line-height: 1.6rem;
    color: ${palette.common.blue};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-family: thegymgroupsans-Bold;
        font-size: 1.2rem;
        font-weight: normal;
        line-height: 1.8rem;
    }
`;

export const StyledPriceContainer = styled('div')`
    display: flex;
    flex-direction: column;
`;

import { Paragraph } from '../../Paragraph';

import {
    StyledHeading,
    StyledPaymentInformationSection,
    StyledWrapper,
} from './PaymentConfirmation.styled';
import { PaymentConfirmationProperties } from './PaymentConfirmation.types';

export const PaymentConfirmation = ({
    email,
    wref,
    headContent,
    additionalPaymentInfo,
    bottomContent = null,
    ...baseProperties
}: PaymentConfirmationProperties) => {
    return (
        <div data-testid={baseProperties['data-testid']}>
            {headContent}
            <StyledWrapper>
                <StyledPaymentInformationSection>
                    <StyledHeading variant="h2">
                        Payment Information
                    </StyledHeading>
                    {additionalPaymentInfo && (
                        <Paragraph>{additionalPaymentInfo}</Paragraph>
                    )}
                    <Paragraph>
                        Please note your unique reference number:{' '}
                        <strong>{wref}</strong>
                    </Paragraph>
                    <Paragraph>
                        Email confirmation has been sent to{' '}
                        <strong>{email}</strong>
                    </Paragraph>
                </StyledPaymentInformationSection>
                {bottomContent}
            </StyledWrapper>
        </div>
    );
};

import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';

export const StyledAsterisk = styled('span')<{ $color?: string }>`
    font-size: 1.4rem;
    color: ${({ $color }) => $color ?? palette.primary.main};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;

import { styled } from '@mui/material/styles';

import { palette } from '../../../../theme';

export const StyledGroupedUnorderedListSection = styled('ul')`
    padding: 0;
    margin: 0;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-top: ${({ theme }) => theme.spacing(20)};
    }
`;

export const ListItemWrapper = styled('li')``;

export const StyledChildrenWrapper = styled('ul')`
    display: grid;
    padding: 0 0 0 3rem;
    margin: 0;
    li:not(:last-child) {
        border-bottom: 0.1rem solid ${palette.grey[100]};
    }
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        grid-template-columns: repeat(3, 1fr);
        gap: 0 5rem;
        padding: 0 3rem;
        li {
            border-bottom: 0.1rem solid ${palette.grey[100]};
        }
        /*
        * Targets the last line of items in the grid
        */
        li:nth-child(3n + 1):nth-last-child(-n + 3),
        li:nth-child(3n + 1):nth-last-child(-n + 3) ~ li {
            border-bottom: none;
        }
    }
`;

export const StyledHeading = styled('li')`
    display: flex;
    align-items: center;
    height: 4.2rem;
    padding-left: 3rem;
    text-transform: lowercase;
    background-color: ${palette.grey[50]};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        height: 5.2rem;
    }
`;

export const StyledTitle = styled('span')`
    margin-bottom: 0;
    font-family: thegymgroupsans-Headline;
`;

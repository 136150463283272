import { ButtonLink } from '../ButtonLink';
import { Paragraph } from '../Paragraph';

import { StyledCard, StyledParagraph, StyledSection } from './Card.styled';
import { CardProperties } from './Card.types';

export const Card = ({
    title,
    changeButtonText = 'change',
    onChangeButtonClick,
    children,
}: CardProperties) => {
    return (
        <StyledCard>
            <Paragraph variant="body2">{title}</Paragraph>
            <StyledParagraph variant="body2" gutterBottom={false}>
                <StyledSection>{children}</StyledSection>
                {onChangeButtonClick && (
                    <ButtonLink onClick={onChangeButtonClick}>
                        {changeButtonText}
                    </ButtonLink>
                )}
            </StyledParagraph>
        </StyledCard>
    );
};

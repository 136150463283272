import { clientLogger } from '../clientLogger';

import { dataEventMapper } from './googleAnalytics.mapper';
import { dataEventState } from './googleAnalytics.state';
import { UIDataEvent } from './googleAnalytics.types';

export const sendAnalyticsDataEvent = (dataEvent: UIDataEvent) => {
    const logger = clientLogger;
    const applicationIdentifier = 'TGG Browser Clientside';

    const { key, data } = dataEventMapper(dataEvent);

    if (!key) {
        logger.error(
            'sendAnalyticsDataEvent => no key found',
            applicationIdentifier,
        );
    }

    if (!data) {
        logger.error(
            'sendAnalyticsDataEvent => no data found',
            applicationIdentifier,
        );
    }

    dataEventState(key, data);
};

import { GymStatus } from '@tgg/common-types';

import { palette } from '../../../theme';
import { AlertMessage } from '../../AlertMessage';
import { Asterisk } from '../../Asterisk';
import { Paragraph } from '../../Paragraph';
import { Spacing, SpacingSize } from '../../Spacing';
import { PaymentTile } from '../PaymentTile';

import { MonthlyPaymentTileDescription } from './MonthlyPaymentTileDescription';
import {
    getDiscountDuration,
    mapSteppedKickerForPaymentSummary,
} from './PaymentSummary.helpers';
import { PaymentSummaryProperties } from './PaymentSummary.types';

const getMonthlyDescription = (
    isOpeningSoon: boolean,
    jjAdvisoryAlertMessageEnabled: boolean,
    hasJJAdvisoryAlertMessageVariationKey: boolean,
) => {
    const isShowAsterisk =
        jjAdvisoryAlertMessageEnabled && hasJJAdvisoryAlertMessageVariationKey;
    return (
        <Paragraph gutterBottom={false}>
            Subsequent monthly payments will{' '}
            {isOpeningSoon ? 'start' : 'commence'} one month from{' '}
            {isOpeningSoon ? 'the gym opening date' : 'today'}
            {isShowAsterisk && <Asterisk color={palette.common.blue} />}.
            Payments will then continue on or just after the same day of each
            month thereafter.
        </Paragraph>
    );
};

const getJjAdvisoryAlertMessage = (
    jjAdvisoryAlertMessageEnabled: boolean,
    hasJJAdvisoryAlertMessageVariationKey: boolean,
) =>
    jjAdvisoryAlertMessageEnabled &&
    hasJJAdvisoryAlertMessageVariationKey && (
        <>
            <Spacing size={SpacingSize.Medium} />
            <AlertMessage
                type="information"
                text=" * Would you like to choose a specific date for your Direct Debit payment? You can easily set this up in your account after joining"
            />
        </>
    );

export const PaymentSummary = ({
    todayTileDescription,
    monthlyTileDescription,
    membershipStartDate,
    branchStatus,
    jjAdvisoryAlertMessageEnabled = false,
    hasJJAdvisoryAlertMessageVariationKey = false,
    showPayPalPayIn3 = false,
    ...payments
}: PaymentSummaryProperties) => {
    const { monthly, today, steppedKicker } = payments;
    const displayTodayPayments = today && today.items.length > 0;
    const displayMonthlyPayments = monthly && monthly.items.length > 0;
    const steppedKickerPaymentItems =
        steppedKicker && mapSteppedKickerForPaymentSummary(steppedKicker);
    const discountDuration = getDiscountDuration(steppedKicker);
    const isOpeningSoon =
        branchStatus !== undefined && branchStatus === GymStatus.ComingSoon;

    return (
        <>
            {displayTodayPayments && (
                <PaymentTile
                    title="Today's Payment"
                    items={today.items}
                    description={todayTileDescription}
                    total={today.total}
                    gutterBottom={displayMonthlyPayments || !!steppedKicker}
                    showPayPalPayIn3={showPayPalPayIn3}
                />
            )}

            {displayMonthlyPayments && (
                <PaymentTile
                    title="Monthly Payments"
                    items={monthly.items}
                    description={
                        monthlyTileDescription ||
                        getMonthlyDescription(
                            isOpeningSoon,
                            jjAdvisoryAlertMessageEnabled,
                            hasJJAdvisoryAlertMessageVariationKey,
                        )
                    }
                    total={monthly.total}
                />
            )}

            {steppedKickerPaymentItems && (
                <PaymentTile
                    title="Monthly Payments"
                    steppedKickerPaymentItems={steppedKickerPaymentItems}
                    description={
                        <MonthlyPaymentTileDescription
                            isSteppedKickerPromo={!!steppedKicker}
                            discountDuration={discountDuration}
                            membershipStartDate={membershipStartDate}
                            isOpeningSoon={isOpeningSoon}
                        />
                    }
                />
            )}
            {getJjAdvisoryAlertMessage(
                jjAdvisoryAlertMessageEnabled,
                hasJJAdvisoryAlertMessageVariationKey,
            )}
        </>
    );
};

import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { Heading } from '../Heading';
import { ImageStandalone } from '../ImageStandalone';
import { Paragraph } from '../Paragraph';

export const StyledWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 0;
    overflow: hidden;
    border-radius: 0;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: calc(100% - 6rem);
        margin: 0 3rem;
        border-radius: 0 0 2rem 2rem;
    }
    ${({ theme }) => theme.breakpoints.up(1024)} {
        flex-direction: row;
        align-items: stretch;
        margin: 0 auto;
    }
    ${({ theme }) => theme.breakpoints.up('largeDesktop')} {
        max-width: 128rem;
    }
`;

export const StyledImageContainer = styled('div')`
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: auto;
    min-height: 20rem;
    overflow: hidden;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        min-height: 30rem;
    }
    ${({ theme }) => theme.breakpoints.up(1024)} {
        flex: 1 1 50%;
        width: 50%;
    }
`;

export const StyledImageStandalone = styled(ImageStandalone)`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const StyledTextContainer = styled('div')`
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    min-height: 22rem;
    padding: 2rem 4rem 3rem;
    background-color: ${palette.grey[50]};
    ${({ theme }) => theme.breakpoints.up(768)} {
        min-height: 22rem;
        padding-bottom: 3.5rem;
    }
    ${({ theme }) => theme.breakpoints.up(1024)} {
        width: 50%;
        padding: 5.95rem 4rem;
        margin: 0 auto;
    }
`;

export const StyledHeading = styled(Heading)`
    margin-bottom: 2rem;
    font-size: 2.5rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 3rem;
        font-size: 3.1rem;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    margin-bottom: 2rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 3rem;
    }
`;

export const StyledForm = styled('form')`
    width: 100%;
    .rejoinEmailComponentError {
        position: absolute;
    }
`;

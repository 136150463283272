import { NextRouter, useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';

import {
    CustomMarker,
    CustomMarkerOptions,
    GymInformation,
} from '@tgg/common-types';
import {
    AnalyticsErrorEvent,
    CtaFindMyLocationButtonClick,
    dispatchEvent,
    EventKey,
    getUserPosition,
    MapLocationSearch,
    TextLinkPayload,
} from '@tgg/services';
import { isQueryParameterValid } from '@tgg/util';

import { closeInfoWindow, handleBoundsChanged } from './GoogleMap.notest';
import { GymMarkerIcon, resultMarkerIcon } from './GoogleMap.styled';
import {
    GymClickHandler,
    GymInformationWithCoordinates,
    ReducedMarkerListWithInfo,
} from './GoogleMap.types';
import { GymInfoWindow } from './components';

const INITIAL_MAP_BOUNDS = {
    center: { lat: 55, lng: -4 },
};

const INITIAL_ZOOM = 13;

const CURRENT_LOCATION = 'Current location';

export const setZoom = (isDesktop: boolean) => (isDesktop ? 6 : 5);

export const usePrevious = <T>(value: T): T | undefined => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const reference = useRef<T>();
    // Store current value in ref
    useEffect(() => {
        reference.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return reference.current;
};

/**
 * Initialises the various Google Maps objects required to render the map.
 *
 * @param markerOptions Markers to be rendered on the map and their associated gym information.
 * @param onGymInfoClick Click handler passed to the Gym Info button
 * @param withSearch Whether the search functionality is enabled
 */
export function useGoogleMap(
    markerOptions: CustomMarkerOptions[],
    withSearch: boolean,
    isDesktop: boolean,
    draggable: boolean,
    disableControlZoom: boolean,
    draggableCursor?: 'pointer' | undefined,
    onGymInfoClick?: GymClickHandler,
    initialQuery?: string,
) {
    const router = useRouter();
    const [map, setMap] = useState<google.maps.Map>();
    const mapReference = useRef<HTMLDivElement>(null);
    const [loaded, setLoaded] = useState(false);

    const [markers, setMarkers] = useState<google.maps.Marker[]>([]);

    /**
     * Listener added to the InfoWindow that adds the required click handler to the button within.
     */
    const listener = useRef<google.maps.MapsEventListener | null>(null);

    /**
     * Listener added to the Gym Info `<button /> within InfoWindow
     */
    const buttonListener = useRef<(() => void) | null>(null);

    /**
     * Listener added to the Gym Name Link `<a /> within InfoWindow
     */
    const gymNameLinkListener = useRef<(() => void) | null>(null);

    /* istanbul ignore next - google map markers are unavailable on jsdom, selectGym difficult to test */
    const selectGym = (
        infoWindow: google.maps.InfoWindow,
        locationMarker: google.maps.Marker,
        gymInformation: GymInformationWithCoordinates,
    ) => {
        dispatchEvent(EventKey.MAP_PIN, {
            mapPinLocation: gymInformation.gymName,
        });

        if (!onGymInfoClick) {
            return;
        }
        // Deal with previous listeners if they exist
        if (listener.current && listener.current.remove) {
            listener.current.remove();
        }

        if (buttonListener.current) {
            document
                .querySelector('#gym-info-window-button')
                ?.removeEventListener('click', buttonListener.current);
        }

        if (gymNameLinkListener.current) {
            document
                .querySelector('#gym-info-window-link')
                ?.removeEventListener('click', gymNameLinkListener.current);
        }
        // End deal with

        // @ts-ignore
        // eslint-disable-next-line testing-library/render-result-naming-convention
        const tooltipContent = renderToString(GymInfoWindow(gymInformation));

        infoWindow.setContent(tooltipContent);
        infoWindow.open({
            anchor: locationMarker,
            map,

            /**
             * Setting to true causes the map to break when we try to close the window on zoom - need to revisit
             * to work out what the expected functionality is
             */
            shouldFocus: false,
        });

        const gymNameLinkHandler = () => {
            dispatchEvent(EventKey.MAP_PIN_NAME, {
                mapPinLocation: gymInformation.gymName,
            });
            router.push(gymInformation.gymPageURL).catch(() => {
                dispatchEvent<AnalyticsErrorEvent>(EventKey.ERROR, {
                    category: '3001',
                    details: {
                        title: 'Error while navigating to gym page',
                        description: `Failed to navigate to gym page url - ${gymInformation.gymPageURL}`,
                    },
                });
            });
        };

        listener.current = infoWindow.addListener('domready', () => {
            /* eslint-disable-next-line unicorn/consistent-function-scoping */
            const gymInfoClickHandler = () => {
                dispatchEvent(EventKey.MAP_PIN_CTA, {
                    mapPinLocation: gymInformation.gymName,
                    mapPinCTAClick: 'view gym',
                });
                onGymInfoClick({ branchId: gymInformation.branchId });
            };

            document
                .querySelector('#gym-info-window-button')
                ?.addEventListener('click', gymInfoClickHandler);
            buttonListener.current = gymInfoClickHandler;

            document
                .querySelector('#gym-info-window-link')
                ?.addEventListener('click', gymNameLinkHandler);
            gymNameLinkListener.current = gymNameLinkHandler;
        });

        infoWindow.addListener('closeclick', () => {
            listener?.current?.remove();
        });
    };

    /**
     * List of markers that are visible within the current map bounds. We need to persist this as its own list
     * because moving the map around does not trigger a rerender, so has to be updated using addListener.
     */
    const [visibleMarkers, setVisibleMarkers] = useState<GymInformation[]>([]);

    const [placesService, setPlacesService] =
        useState<google.maps.places.PlacesService>();
    const infoWindow = useRef(new google.maps.InfoWindow());

    const {
        search,
        searchTerm,
        haveSearched,
        onClearSearch,
        findMyLocation,
        searchInputReference,
        userPositionError,
        searchLocation,
    } = useMapSearch({
        map,
        markers,
        placesService,
        infoWindow: infoWindow.current,
        isDesktop,
        router,
    });

    const previousSearchTerm = usePrevious(searchTerm);

    useEffect(() => {
        if (mapReference.current && !map) {
            const newMap = new google.maps.Map(mapReference.current, {
                center: INITIAL_MAP_BOUNDS.center,
                zoom: setZoom(isDesktop),
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                gestureHandling: draggable ? undefined : 'none',
                zoomControl: !disableControlZoom,
                draggableCursor: draggableCursor || undefined,
                clickableIcons: false,

                styles: [
                    {
                        featureType: 'poi',
                        stylers: [{ visibility: 'off' }],
                    },
                    {
                        featureType: 'transit',
                        stylers: [{ visibility: 'off' }],
                    },
                ],
            });

            google.maps.event.addListenerOnce(newMap, 'tilesloaded', () => {
                setLoaded(true);
            });

            setMap(newMap);
            setPlacesService(new google.maps.places.PlacesService(newMap));
        }

        if (map) {
            const closeInfoWindowEvents = ['zoom_changed', 'dragstart'];

            map.setZoom(
                markerOptions.length === 1 ? INITIAL_ZOOM : setZoom(isDesktop),
            );
            if (markerOptions.length === 1 && markerOptions[0].position) {
                map.setCenter(markerOptions[0].position);
            }

            closeInfoWindowEvents.forEach(event => {
                /* istanbul ignore next */
                map.addListener(event, () => {
                    if (listener.current && listener.current.remove) {
                        listener.current.remove();
                    }
                    closeInfoWindow(infoWindow.current);
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapReference, map, markers.length, isDesktop]);

    useEffect(() => {
        let eventListener: google.maps.MapsEventListener | undefined;

        if (map && searchLocation) {
            handleBoundsChanged({
                map,
                markers,
                searchLocation,
                setVisibleMarkers,
            });
        }
        // eslint-disable-next-line unicorn/prefer-ternary
        if (searchTerm === previousSearchTerm) {
            /* istanbul ignore next */
            eventListener = map?.addListener('dragend', () => {
                handleBoundsChanged({
                    map,
                    markers,
                    searchLocation,
                    setVisibleMarkers,
                });
            });
        } else {
            // Probably some Major Optimisation to be done here
            // Clear this at some point? Or clear it every time (on cleanup) in case markers change?
            /* istanbul ignore next */
            eventListener = map?.addListener('zoom_changed', () => {
                handleBoundsChanged({
                    map,
                    markers,
                    searchLocation,
                    setVisibleMarkers,
                });
            });
        }

        return () =>
            eventListener && google.maps.event.removeListener(eventListener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchLocation, map]);

    useEffect(() => {
        if (markers.length === 0) {
            const { markerList, info } = markerOptions.reduce(
                (accumulator: ReducedMarkerListWithInfo, current) => {
                    const { position, gym } = current;
                    const locationMarker = new google.maps.Marker({
                        position,
                        icon: GymMarkerIcon,
                        title: gym?.gymName,
                    }) as CustomMarker;

                    const gymInformation = {
                        ...gym,
                        latitude: position?.lat as number,
                        longitude: position?.lng as number,
                    } as GymInformationWithCoordinates;

                    /* istanbul ignore next - google map markers are unavailable on jsdom, making difficult to click markers */
                    locationMarker.addListener('click', () => {
                        selectGym(
                            infoWindow.current,
                            locationMarker,
                            gymInformation,
                        );
                    });
                    locationMarker.gym = gymInformation;

                    accumulator.markerList.push(locationMarker);
                    accumulator.info.push(gymInformation);

                    return accumulator;
                },
                {
                    markerList: [],
                    info: [],
                },
            );

            setMarkers(markerList);
            setVisibleMarkers(info);
        }

        return () => {
            markers.forEach(marker => marker.setMap(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, markers.length, markerOptions]);

    /**
     * For the regional variant (fewer gyms & co-located), move the map viewport to a suitable center / zoom.
     * Currently, this works as withSearch === regional but this may not always be the case. Maybe once we
     * sort out performance we can always do this regardless of variant?
     */
    useEffect(() => {
        if (map && markers.length > 1 && !withSearch) {
            const positions = markers.map(marker => marker.getPosition());
            const bounds = getContainingBoundsFromPositions(
                positions as google.maps.LatLng[],
            );
            map?.setCenter(bounds.getCenter());
            map?.fitBounds(bounds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, markers.length, withSearch]);

    /**
     * Use a search query, taken from the parameters, at page start.
     * 'initialSearchUsed' flag is used to ensure this only happens once.
     * 'setQueryInProgress' flag is used to ensure we don't try to search again while the first search is in progress.
     * 'initialQuery' contains the search query from the URL parameters.
     */
    const [initialSearchUsed, setInitialSearchUsed] = useState<boolean>(false);
    const [queryInProgress, setQueryInProgress] = useState<boolean>(false);

    useEffect(() => {
        if (map && initialQuery && !queryInProgress && !initialSearchUsed) {
            setQueryInProgress(true);

            const applySearch = async () => {
                try {
                    initialQuery === CURRENT_LOCATION
                        ? await findMyLocation()
                        : await search(initialQuery);
                } finally {
                    setQueryInProgress(false);
                    setInitialSearchUsed(true);
                }
            };
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            applySearch();
        }
    }, [
        initialQuery,
        search,
        findMyLocation,
        map,
        queryInProgress,
        initialSearchUsed,
    ]);

    const onSearch = async (query: string) => {
        dispatchEvent<MapLocationSearch>(EventKey.MAP_LOCATION_SEARCH, {
            locationSearchTerm: query,
        });
        await search(query);
    };

    const onFindMyLocation = async () => {
        dispatchEvent<CtaFindMyLocationButtonClick>(EventKey.CTA_BUTTON_CLICK, {
            ctaName: 'find my location',
        });
        await findMyLocation();
    };

    return {
        loaded,
        map,
        markers,
        visibleMarkers,
        mapReference,
        placesService,
        onSearch,
        onFindMyLocation,
        searchTerm,
        haveSearched,
        onClearSearch,
        searchInputReference,
        userPositionError,
    };
}

export function useMapSearch({
    map,
    markers,
    placesService,
    infoWindow,
    isDesktop,
    router,
    draggable,
}: {
    map?: google.maps.Map;
    markers: google.maps.Marker[];
    placesService?: google.maps.places.PlacesService;
    infoWindow: google.maps.InfoWindow;
    isDesktop: boolean;
    router: NextRouter;
    draggable?: boolean;
}) {
    const searchInputReference = useRef<HTMLInputElement>(null);
    const autocompleteService = new google.maps.places.AutocompleteService();

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [userPositionError, setUserPositionError] = useState(false);
    const [searchLocation, setSearchLocation] = useState<any>(null);

    const haveSearched = !!searchTerm;

    const removeSearchResultMarker = () => {
        // I have no idea why this else needs to be ignored, but coverage complains otherwise
        /* istanbul ignore if */ /* istanbul ignore else */
        if (searchResultMarker.current) {
            searchResultMarker.current.setMap(null);
        }
    };

    /**
     * On clearing search, empty search input & return map to its initial bounds.
     */
    const onClearSearch = async (linkText: string) => {
        setSearchLocation(null);
        setSearchTerm('');

        removeSearchResultMarker();

        /**
         * Can we make this always transition smoothly? Current production site does but only for some searches - not clear to me how or why.
         */
        map?.setZoom(setZoom(isDesktop));
        map?.panTo(INITIAL_MAP_BOUNDS.center);

        dispatchEvent<TextLinkPayload>(EventKey.TEXT_LINK, {
            linkText,
        });

        await updateUrlQueryParameter(router);
    };

    const searchResultMarker = useRef(new google.maps.Marker());

    const boundsPadding = 50;

    const setBounds = (bounds: google.maps.LatLngBounds) => {
        map?.setCenter(bounds.getCenter());

        if (isDesktop && !haveSearched) {
            // to handle initial search with animating map height (as it gets too close, zooming out by 1 level and adjusting padding)
            map?.fitBounds(bounds, { top: boundsPadding * 2 });
            map?.setZoom((map.getZoom() || 6) - 1);
        } else {
            map?.fitBounds(bounds, { top: boundsPadding });
        }
    };

    /**
     * Calls Google Maps Places Autocomplete API with the search term to get place predictions; then calls the Places service with the first prediction.
     * Determines five closest markers to the result and moves the map bounds to display them.
     *
     * Before executing search, replaces the content of the text box with the top autocomplete result (which is what we're actually using to search).
     * This is especially useful for some unintuitive results - for example, "kent" will search for "Kentish Town".
     *
     * On error, does nothing and allows the user to try again (following live site behaviour)
     *
     * @param query Search term entered by the user
     */
    const search = async (query: string) => {
        /**
         * Istanbul ignoring error cases in this function because
         * a) google is a global namespace so doesn't play nicely with mocks
         * b) We aren't really doing any error handling, just returning
         */
        /* istanbul ignore if */
        if (!placesService || !map) return;

        // Close info window every time we do a new search
        infoWindow.close();

        searchInputReference.current?.blur();

        const autocompleteResults =
            await autocompleteService.getPlacePredictions({
                input: query,
                componentRestrictions: {
                    country: 'GB',
                },
            });

        // change query param in URL using search term
        await updateUrlQueryParameter(router, query);

        /* istanbul ignore if */
        if (autocompleteResults?.predictions?.length === 0) return;

        const [place] = autocompleteResults.predictions;

        setSearchTerm(place.description);

        placesService.getDetails(
            {
                placeId: place.place_id,
                fields: ['geometry.location'],
            },
            (
                result: google.maps.places.PlaceResult | null,
                status: google.maps.places.PlacesServiceStatus,
            ) => {
                /* istanbul ignore if */
                if (status !== google.maps.places.PlacesServiceStatus.OK) {
                    return;
                }

                /* istanbul ignore if */
                if (!result) {
                    return;
                }

                const { geometry } = result;

                /* istanbul ignore if */
                if (!geometry?.location) {
                    return;
                }
                const { location } = geometry;

                const bounds = getBoundsFromNearestFiveMarkers(
                    location,
                    markers,
                );

                setBounds(bounds);
                setSearchLocation(location);

                updateResultMarker(location);
            },
        );
    };

    /**
     * Calls getUserPosition service and sets the search term to "Current location"
     * Determines the closest markers to the user location and moves the map bounds to display them.
     */
    const findMyLocation = async () => {
        try {
            const location = await getUserPosition();
            const latLng = new google.maps.LatLng(location);
            setSearchTerm(CURRENT_LOCATION);
            const bounds = getBoundsFromNearestFiveMarkers(latLng, markers);
            /**
             * Istanbul ignoring error cases in this function because
             * a) google is a global namespace so doesn't play nicely with mocks
             * b) We aren't really doing any error handling, just returning
             */
            /* istanbul ignore if */
            if (!placesService || !map) return;

            setBounds(bounds);
            setSearchLocation(location);

            updateResultMarker(latLng);
            setUserPositionError(false);

            // change query param in URL using search term
            await updateUrlQueryParameter(router, CURRENT_LOCATION);
        } catch {
            setUserPositionError(true);
        }
    };

    function updateResultMarker(location: google.maps.LatLng) {
        removeSearchResultMarker();

        searchResultMarker.current = new google.maps.Marker({
            map,
            position: location,
            icon: resultMarkerIcon,
            animation: google.maps.Animation.DROP,
        });
    }

    return {
        search,
        findMyLocation,
        searchTerm,
        haveSearched,
        onClearSearch,
        searchInputReference,
        userPositionError,
        searchLocation,
    };
}
/**
 * Given a location and a set of markers, determine the closest five markers and return a `LatLngBounds` instance containing them.
 *
 * @param location Location relative to which the marker distance is calculated
 * @param markers Markers from which to pick the closest 5
 */
function getBoundsFromNearestFiveMarkers(
    location: google.maps.LatLng,
    markers: google.maps.Marker[],
): google.maps.LatLngBounds {
    const orderedMarkers = markers
        .map(marker => ({
            position: marker.getPosition(),
            distance:
                google.maps.geometry.spherical.computeDistanceBetween(
                    location,
                    marker.getPosition() as google.maps.LatLng,
                ) / 1609, // convert meters to miles
        }))
        .sort((a, b) => a.distance - b.distance);

    // We want to display nearest five markers to the searched location (give or take)
    const [first, second, third, fourth, fifth] = orderedMarkers;
    const closestMarkers = [first, second, third, fourth, fifth];

    const maxGymDistance = 25; // in miles

    // filter 5 closest markers and keep those closer than maxGymDistance (in miles)
    let filteredMarkers = closestMarkers.filter(
        m => m.distance < maxGymDistance,
    );

    // if there are less than 2 gyms within maxGymDistance, then just accept original 5 closest
    filteredMarkers =
        filteredMarkers.length >= 2 ? filteredMarkers : closestMarkers;

    return getContainingBoundsFromPositions(
        filteredMarkers.map(marker => marker.position) as google.maps.LatLng[],
    );
}

function getContainingBoundsFromPositions(
    positions: google.maps.LatLng[],
): google.maps.LatLngBounds {
    const latitudes = positions.map(position => position.lat());
    const longitudes = positions.map(position => position.lng());

    const southWestCorner = {
        lat: Math.min(...latitudes),
        lng: Math.min(...longitudes),
    };

    const northEastCorner = {
        lat: Math.max(...latitudes),
        lng: Math.max(...longitudes),
    };

    return new google.maps.LatLngBounds(southWestCorner, northEastCorner);
}

async function updateUrlQueryParameter(
    router: NextRouter,
    searchQuery?: string,
) {
    let updatedQuery;

    if (!searchQuery || !isQueryParameterValid(searchQuery)) {
        // remove the value from the query params
        const { s, ...restOfQuery } = router.query;
        updatedQuery = restOfQuery;
    } else {
        // update the pareter
        updatedQuery = { ...router.query, s: searchQuery };
    }

    // eslint-disable-next-line compat/compat
    const searchParameters = new URLSearchParams(
        updatedQuery as Record<string, string>,
    );
    window.history.pushState(null, '', `?${searchParameters.toString()}`);
}

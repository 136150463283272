import { styled } from '@mui/material/styles';

import { palette } from '../../theme';

export const StyledButtonLink = styled('button')`
    padding: 0;
    margin: 0;
    font-family: thegymgroupsans-Bold;
    font-size: inherit;
    line-height: inherit;
    color: ${palette.common.blue};
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
    border: 0;
`;

import { OptimizelyFeatureFlags } from '@tgg/common-types';

import { type TggTraceCorrelationParameters, type TggLogger } from '../logger';

export type FeatureFlagsRequestOptions = {
    loggerMetaData: TggTraceCorrelationParameters;
    logger: TggLogger;
};

export type TggFlag = { enabled: boolean; variationKey?: string | null };

export type BaseFlagVariations = 'off';
export type OptimizelyFlagVariations<T extends string> = T | BaseFlagVariations;

export type OptimizelyFlag<
    Variation extends string | null = BaseFlagVariations,
    Variables extends Record<string, unknown> | null = null,
> = TggFlag & {
    variationKey?: Variation;
    variables?: Variables;
    ruleKey?: string | null;
};

interface FlagTypeMap {
    [OptimizelyFeatureFlags.JJ_ADVISORY_MESSAGE_ENABLED]: JjAdvisoryMessageEnabledFlag;
    [OptimizelyFeatureFlags.MEMBERSHIP_TERMS_VISIBILITY]: MembershipTermsVisibilityFlag;
    [OptimizelyFeatureFlags.SHORTEN_MBT_PRICE_DRAW_TEST]: ShortenMBTPriceDrawTestFlag;
    [OptimizelyFeatureFlags.SAVINGS_VISIBILITY_VARIATIONS]: SavingsVisibilityVariationsFlag;
    [OptimizelyFeatureFlags.YOUR_PAYMENTS_BUTTON_VARIATIONS]: YourPaymentsButtonVariationsFlag;
    [OptimizelyFeatureFlags.AA_TEST]: AATestFlag;
    [OptimizelyFeatureFlags.API_VERSION_3_ENABLED]: ApiVersion3EnabledFlag;
    [OptimizelyFeatureFlags.USE_OPTIMIZELY_REACT_SDK]: UseOptimizelyReactSdkFlag;
    [OptimizelyFeatureFlags.PAYPAL_PAY_IN_3_PRODUCT_OPTIONS]: ProductOptionsPaypalPayIn3EnabledFlag;
    [OptimizelyFeatureFlags.PAYPAL_PAY_IN_3_TABLE_IFRAME]: ProductTableIframePaypalPayIn3EnabledFlag;
    [OptimizelyFeatureFlags.PRODUCT_TABLE_FREE_FREEZE_NOTICE]: ProductTableFreeFreezeNoticeEnabledFlag;
    [OptimizelyFeatureFlags.GUEST_PASS_ENABLED]: GuestPassEnabledFlag;
    [OptimizelyFeatureFlags.GUEST_PASS_SLOTS_USAGE_ENABLED]: GuestPassSlotsUsageEnabledFlag;
    [OptimizelyFeatureFlags.PAYPAL_PAY_IN_3_GYM_BAG_IFRAME]: PaypalPayIn3GymBagIframeEnabledFlag;
    [OptimizelyFeatureFlags.PINO_TABLE_MBT_OPTIMIZATION]: PinoTableMBTOptimizationEnabledFlag;
    [OptimizelyFeatureFlags.PAYPAL_PAY_IN_3_JOIN_JOURNEY_ENABLED]: PaypalPayIn3JoinJourneyEnabledFlag;
    [OptimizelyFeatureFlags.PAYPAL_RENEWAL_ENABLED]: PaypalRenewalEnabledFlag;
    [OptimizelyFeatureFlags.PAYPAL_PAY_IN_3_RENEWAL_ENABLED]: PaypalPayIn3RenewalEnabledFlag;
    [OptimizelyFeatureFlags.MEMBER_API_VERSION_3_ENABLED]: MemberApiVersion3EnabledFlag;
}

export type FlagObject<T extends OptimizelyFeatureFlags> =
    T extends keyof FlagTypeMap ? FlagTypeMap[T] : OptimizelyFlag;

export type TggFlagsObject = {
    [key in OptimizelyFeatureFlags]: FlagObject<key>;
};

// Flags with A/B support
export type SavingsVisibilityVariations = OptimizelyFlagVariations<
    'v1' | 'v2' | 'v3'
>;
export type SavingsVisibilityVariationsFlag =
    OptimizelyFlag<SavingsVisibilityVariations>;

export type YourPaymentsButtonVariations = OptimizelyFlagVariations<
    | 'control_padlock_make_payment'
    | 'v1_padlock_pay_securely'
    | 'v2_badge_make_payment'
    | 'v3_badge_pay_securely'
>;
export type YourPaymentsButtonVariationsFlag =
    OptimizelyFlag<YourPaymentsButtonVariations>;

export type JjAdvisoryMessageEnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'control' | 'v1_advisory_message'>
>;

export type MembershipTermsVisibilityFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'Control' | 'V1'>
>;

export type PinoTableMBTOptimizationEnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'control' | 'v1'>
>;

export type ShortenMBTPriceDrawTestFlag = OptimizelyFlag<
    OptimizelyFlagVariations<
        | 'control_stdmbt'
        | 'v1_stdmbt_opd'
        | 'v2_shortmbt_cpd'
        | 'v3_shortmbt_opd'
    >
>;

export type AATestFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'FXAA_control' | 'FXAA_v1' | 'FXAA_v2'>,
    { enableDataLayer: boolean }
>;

export type ApiVersion3EnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'on'>
>;

export type UseOptimizelyReactSdkFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'client' | 'proxy' | 'ssr'>
>;

// Tracking

export enum OptimizelyVisitEvents {
    'VISIT_YOUR_MEMBERSHIP' = 'your_membership_visit',
    'VISIT_YOUR_PAYMENTS' = 'your_payments_visit',
    'VISIT_GYM_PAGE' = 'gym_page_visit',
    'VISIT_ABOUT_YOU' = 'about_you_visit',
    'VISIT_CHOOSE_MEMBERSHIP' = 'choose_membership_visit',
    'VISIT_CHOOSE_YOUR_ADDONS' = 'choose_your_addons_visit',
    'VISIT_PAYMENT_MONTHLY' = 'payment_monthly_visit',
    'VISIT_YOUR_EMAIL' = 'your_email_visit',
    'VISIT_FIND_YOUR_GYM' = 'find_your_gym_visit',
    'VISIT_FIND_A_GYM' = 'find_a_gym_visit',
}

export enum OptimizelyGlobalEvents {
    'START_JOIN_JOURNEY' = 'jj_start',
    'PURCHASE' = 'purchase',
}

export enum FlagsConstants {
    UNRESOLVED = 'unresolved',
    ROOT = 'root',
    DEBUG = 'debug',
    TGG_USER_AGENT = 'TGG Sys Call',
}

export const OptimizelyEvents = {
    ...OptimizelyVisitEvents,
    ...OptimizelyGlobalEvents,
};

export type OptimizelyEventKey = keyof typeof OptimizelyEvents;
export type OptimizelyEvent = (typeof OptimizelyEvents)[OptimizelyEventKey];

export type OptimizelyDecisionEvent = {
    'optimizely-flagKey': OptimizelyFeatureFlags;
    'optimizely-ruleKey': string;
    'optimizely-variationKey': string;
    'optimizely-userId': string;
    'optimizely-resolvedPath': string;
};

export type ProductOptionsPaypalPayIn3EnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'on'>
>;

export type ProductTableIframePaypalPayIn3EnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'on'>
>;

export type ProductTableFreeFreezeNoticeEnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'on'>
>;

export type GuestPassEnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'on'>
>;

export type GuestPassSlotsUsageEnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'on'>
>;

export type PaypalPayIn3GymBagIframeEnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'on'>
>;

export type PaypalPayIn3JoinJourneyEnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'on'>
>;

export type PaypalRenewalEnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'on'>
>;

export type PaypalPayIn3RenewalEnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'on'>
>;
export type MemberApiVersion3EnabledFlag = OptimizelyFlag<
    OptimizelyFlagVariations<'on'>
>;

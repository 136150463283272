import { Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { ImageStandalone } from '../ImageStandalone';
import { RichTextContent } from '../RichTextContent';

import { StoryBoxSize, StoryBoxVariant } from './AddOnBox.types';

export const StyledWrapper = styled('article')<{
    $variant: StoryBoxVariant;
}>`
    display: flex;
    flex: 1 1 30%;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
    }
    flex-direction: column;
    height: 100%;
    margin-bottom: 3rem;
    background-color: ${palette.common.white};
    border: ${({ $variant }) =>
        $variant === 'light blue'
            ? `none`
            : `0.2rem solid ${palette.common.lightGrey}`};
    border-radius: 1rem;
`;

export const StyledContent = styled('div')<{
    $vertical?: boolean;
}>`
    position: relative;
    display: flow;
    flex-grow: 1;
    flex-direction: row;

    padding: 2rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: flex;
        padding: 4rem;
    }
`;

export const StyledTagsList = styled('ul')`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 1rem;
    list-style: none;
`;

export const StyledTagItem = styled('li')`
    font-size: 1.4rem;

    &:nth-of-type(n + 1)::after {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        color: ${palette.primary.main};
        content: '+';
    }

    &:last-of-type::after {
        display: none;
    }
`;

export const StyledTagLink = styled('a')`
    color: ${palette.common.blue};
    text-decoration: none;
    text-transform: lowercase;
`;

export const StyledImageStandalone = styled(ImageStandalone, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<{
    $size: StoryBoxSize;
}>`
    display: flex;
    width: auto;
    height: 11rem;
    border-radius: 1rem 1rem 0 0;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        height: ${({ $size }) => ($size === 'large' ? '36.4rem' : '23.9rem')};
    }
`;

export const StyledHeading = styled(Heading)`
    font-size: 2.3rem;
    line-height: 2.8rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 2.6rem;
        line-height: 3rem;
        letter-spacing: -0.15rem;
    }
`;

export const StyledButton = styled(ButtonBase)`
    width: 100%;
    padding: 0.2rem 1rem;
    white-space: nowrap;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: auto;
    }
`;

export const StyledPill = styled('div')<{
    $vertical?: boolean;
}>`
    position: absolute;
    top: 0;
    left: 3rem;
    padding: 0.3rem 1rem;
    font-family: thegymgroupsans-headline;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: ${palette.common.blue};
    text-align: center;
    letter-spacing: -0.2%;
    background-color: ${palette.secondary.main};
    border-radius: 2rem;
    transform: translateY(-50%);

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        left: -27rem;
        font-size: 1.8rem;
        line-height: 2.5rem;
    }
`;

export const StyledRichTextContent = styled(RichTextContent)`
    padding: 0;
    margin: 0;

    p {
        font-size: 1.4rem;
        line-height: 2rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            font-size: 1.6rem;
            line-height: 2.2rem;
        }
    }

    li {
        list-style-type: disc;

        strong: {
            font-family: thegymgroupsans-headline;
        }
    }
`;

export const StyledButtonContainer = styled('div')`
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: auto;

    /* stylelint-disable-next-line no-descending-specificity */
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('mobile')} {
        grid-template-columns: repeat(auto-fill, 14.2rem);

        &:has(a:only-child) {
            grid-template-columns: max-content;
        }
    }
`;

export const StyledAdditionalContent = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ToggleButton = styled((properties: SwitchProps) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...properties}
    />
))(({ theme }) => ({
    marginLeft: 'auto',
    alignSelf: 'center',
    width: 70,
    height: 40,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 4,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(31px)',
            color: `${theme.palette.common.white}`,
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: theme.palette.primary.main,
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.palette.primary.main,
            border: `6px solid ${theme.palette.common.white}`,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.common.white,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 32,
        height: 32,
    },
    '& .MuiSwitch-track': {
        borderRadius: 40,
        backgroundColor: theme.palette.grey[900],
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.grey[900],
        }),
    },
}));

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import getConfig from 'next/config';

import { ApplicationIdentifier } from '@tgg/common-types';

import { type FlagsConstants } from '../featureFlags';

import { logClientMessage } from './clientLogger';
import { TggLogger, TggTraceCorrelationParameters } from './logger.types';
import { createWinstonLogger } from './serverLogger/winstonLoggerInstance';
import {
    BaseLogger,
    createLogMessageCaller,
    getLoggerType,
} from './utils/loggerUtils';

export const logMessage = <T extends BaseLogger>(input: {
    applicationIdentifier: ApplicationIdentifier | FlagsConstants.UNRESOLVED;
    severityLevel: SeverityLevel | 'debug';
    message: string;
    parameters: TggTraceCorrelationParameters;
    serverLogger: T;
}) => {
    const {
        applicationIdentifier,
        message,
        parameters,
        severityLevel,
        serverLogger,
    } = input;

    const logServerMessage = createLogMessageCaller(serverLogger);
    const logMessageMethod =
        getLoggerType() === 'CLIENT' ? logClientMessage : logServerMessage;

    logMessageMethod({
        applicationIdentifier,
        message,
        parameters,
        severityLevel,
    });
};

export const createAppLogger = (
    applicationIdentifier: ApplicationIdentifier | FlagsConstants.UNRESOLVED,
    options: {
        NODE_ENV: string;
        APP_SERVER_LOGGING_LEVEL: string;
        APP_LOGGING_LEVEL: string;
        APP_ENVIRONMENT: string;
    },
) => {
    const {
        NODE_ENV,
        APP_SERVER_LOGGING_LEVEL,
        APP_LOGGING_LEVEL,
        APP_ENVIRONMENT,
    } = options;

    /* istanbul ignore next */
    const env = NODE_ENV || APP_ENVIRONMENT;
    /* istanbul ignore next */
    const loggingLevel = APP_LOGGING_LEVEL || APP_SERVER_LOGGING_LEVEL;

    const winstonLogger = createWinstonLogger(env, loggingLevel);

    const appLogger: TggLogger = {
        out(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                severityLevel: SeverityLevel.Verbose,
                serverLogger: winstonLogger,
            });
        },
        error(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                severityLevel: SeverityLevel.Error,
                serverLogger: winstonLogger,
            });
        },
        critical(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                severityLevel: SeverityLevel.Critical,
                serverLogger: winstonLogger,
            });
        },
        warn(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                severityLevel: SeverityLevel.Warning,
                serverLogger: winstonLogger,
            });
        },
        info(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                severityLevel: SeverityLevel.Information,
                serverLogger: winstonLogger,
            });
        },
        debug(message, parameters) {
            logMessage({
                applicationIdentifier,
                message,
                parameters,
                severityLevel: 'debug',
                serverLogger: winstonLogger,
            });
        },
        // compatibility method
        log(message, parameters) {
            this.out(message, parameters);
        },
    };

    return appLogger;
};

export const getAppLogger = (options: {
    applicationIdentifier: ApplicationIdentifier | FlagsConstants.UNRESOLVED;
}) => {
    const { applicationIdentifier } = options;
    /* istanbul ignore else */
    if (getConfig && typeof getConfig === 'function') {
        const {
            serverRuntimeConfig: { NODE_ENV, APP_SERVER_LOGGING_LEVEL },
            publicRuntimeConfig: { APP_LOGGING_LEVEL, APP_ENVIRONMENT },
        } = getConfig();

        return createAppLogger(applicationIdentifier, {
            NODE_ENV,
            APP_SERVER_LOGGING_LEVEL,
            APP_LOGGING_LEVEL,
            APP_ENVIRONMENT,
        });
    }

    /* istanbul ignore next */
    return createAppLogger(applicationIdentifier, {
        NODE_ENV: process.env.NODE_ENV,
        APP_SERVER_LOGGING_LEVEL: 'debug',
        APP_LOGGING_LEVEL: 'debug',
        APP_ENVIRONMENT: process.env.NODE_ENV,
    });
};

import { styled } from '@mui/material/styles';

import { palette, Theme } from '../../theme';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const StyledBody = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: ${({ theme }) => theme.spacing(24)};
    padding: 2.4rem 3rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: grid;
        padding: ${({ theme }) => theme.spacing(40)};
    }
`;

export const StyledHeading = styled(Heading)`
    padding-left: 0;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    text-align: left;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 3.1rem;
        text-align: center;
    }
`;

export const StyledItem = styled('article')`
    display: flex;
    flex-direction: row;
    padding-right: ${({ theme }) => theme.spacing(30)};
`;

export const StyledOpeningHoursItem = styled('article')`
    display: flex;
    flex-direction: row;
    grid-row: 1/3;
    grid-column: 2;
`;

export const StyledContent = styled('div')``;

export const StyledContentHeading = styled(Heading)`
    font-size: 1.9rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-top: ${({ theme }) => theme.spacing(4)};
        font-size: 2.1rem;
    }
`;

export const StyledDescription = styled('p')`
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-bottom: 0;
`;

export const StyledAddress = styled('address')`
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-bottom: 0;
    font-style: unset;
`;

export const StyledIcon = styled('div')`
    display: flex;
    align-items: center;
    width: 4rem;
    height: 4rem;
    padding: ${({ theme }) => theme.spacing(8)};
    margin-right: ${({ theme }) => theme.spacing(15)};
    border: 0.25rem solid ${palette.grey[900]};
    border-radius: 100%;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 6rem;
        height: 6rem;
        padding: ${({ theme }) => theme.spacing(12)};
    }
`;

export const StyledOpeningTimeAndDayWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
`;

export const StyledOpeningTimesDay = styled(Paragraph)`
    grid-column-start: 2;
    grid-column-end: 3;
    padding-right: 2rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-right: 4rem;
    }
`;

export const StyledOpeningTimesTime = styled(Paragraph)`
    grid-column-start: 3;
    grid-column-end: 4;
`;

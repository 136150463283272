import { AddOnTileItemProperties } from '@tgg/common-types';
import { decimalToPoundsAndPence, getTestId } from '@tgg/util';

import { ButtonBase } from '../Button';
import { ContentTile } from '../ContentTile';

import {
    StyledContent,
    StyledDescription,
    StyledAddOnTileItem,
    StyledPrice,
    StyledDuration,
    StyledDiscountedPrice,
    StyledDiscountMessage,
    StyledPriceContainer,
} from './AddOnTile.styled';
import { AddOnTileProperties } from './AddOnTile.types';

export function AddOnTileItem({
    price,
    discountedPrice,
    discountMessage,
    duration,
    onAddClick,
    onRemoveClick,
    isSelected,
}: AddOnTileItemProperties) {
    const buttonText = isSelected ? 'Remove' : 'Add';
    const durationText = duration === 'Monthly' ? 'per month' : 'one off';
    const onClickHandler = isSelected ? onRemoveClick : onAddClick;
    const testId = isSelected ? 'remove' : 'add';

    return (
        <StyledAddOnTileItem>
            <StyledPriceContainer>
                <div>
                    <StyledPrice>
                        {discountedPrice && (
                            <>
                                <StyledDiscountedPrice>
                                    {decimalToPoundsAndPence(discountedPrice)}
                                </StyledDiscountedPrice>{' '}
                            </>
                        )}
                        {decimalToPoundsAndPence(price)}
                    </StyledPrice>
                    {duration && (
                        <StyledDuration>{durationText}</StyledDuration>
                    )}
                </div>
                {discountMessage && (
                    <StyledDiscountMessage>
                        {discountMessage}
                    </StyledDiscountMessage>
                )}
            </StyledPriceContainer>

            <ButtonBase
                fullWidth={false}
                text={buttonText}
                onClick={onClickHandler}
                data-testid={getTestId(`${testId}-yanga-button`)}
            />
        </StyledAddOnTileItem>
    );
}

export function AddOnTile({
    logo,
    description,
    children,
    ...otherProperties
}: AddOnTileProperties) {
    return (
        <ContentTile {...otherProperties}>
            <StyledContent>
                {logo}
                <StyledDescription gutterBottom={false}>
                    {description}
                </StyledDescription>
                {children}
            </StyledContent>
        </ContentTile>
    );
}

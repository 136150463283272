import { styled } from '@mui/material/styles';

export const StyledOl = styled('ol')`
    padding-left: 1.8rem;

    li {
        padding-left: 0.8rem;
        margin-bottom: 2rem;

        p {
            display: inline-block;
            vertical-align: top;
        }
    }
`;

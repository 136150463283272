import { styled } from '@mui/material/styles';

import { lightGreyBoxShadow, palette } from '../../../theme';

export const StyledContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 26rem;
    height: 100%;
    font-size: 1.4rem;
    font-weight: normal;
    border-radius: 0.8rem;
    box-shadow: ${lightGreyBoxShadow};
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 41rem;
        font-size: 1.6rem;
    }
`;

export const StyledCustomerInfoContainer = styled('div')`
    display: flex;
    align-items: center;
    padding: 3rem;
    background-color: ${palette.grey[50]};
    border-radius: 0 0 0.8rem 0.8rem;
`;

export const StyledQuoteContainer = styled('div')`
    padding: 3rem;
    background-color: ${palette.common.white};
    border-radius: 0.8rem 0.8rem 0 0;
`;

export const StyledIconContainer = styled('div')`
    margin-bottom: 1.6rem;
`;

export const StyledName = styled('div')`
    font-size: 1.4rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;

export const StyledCustomerLabel = styled('div')`
    font-size: 1rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
    }
`;

export const StyledImageContainer = styled('div')`
    img {
        width: 8.2rem;
        height: 8.2rem;
        margin-right: 2rem;
        object-fit: cover;
        ${({ theme }) => theme.breakpoints.up('desktop')} {
            margin-left: 1.2rem;
        }
        border: 0.2rem solid ${palette.primary.main};
        border-radius: 100%;
    }
`;

/* eslint-disable react/require-default-props */

import { Nullable } from '@tgg/common-types';
import { getViewFormattedDate } from '@tgg/util';

interface OneOffPaymentDescriptionArguments {
    date?: Nullable<string>;
    updateJourneyName?: string;
}

export const OneOffPaymentDescription = ({
    date,
    updateJourneyName,
}: OneOffPaymentDescriptionArguments) => {
    if (date) {
        const formattedDate = getViewFormattedDate(date);
        return updateJourneyName === 'paymentArrears' ? (
            <>
                One off payment for the failed payment on <b>{formattedDate}</b>
                . Admin fee has been waived due to early repayment.
            </>
        ) : (
            <>
                One off payment for the period up until <b>{formattedDate}</b>.
            </>
        );
    }
    return null;
};

import { utcToZonedTime } from 'date-fns-tz';
import { stringify } from 'qs';

import { axiosInstance } from '@tgg/micro-services/axios';
import { getFormattedDate } from '@tgg/util';

import { JoinResponseBody } from '../options.types';

type ShoppingBasketCookieData = {
    url: string;
    branchId: string;
    accountTemplateIds: string[];
    membershipStartDate?: string;
    promoCode?: string | null;
    appIdentifier?: string;
};

export const getMembershipOptionData = ({
    url,
    branchId,
    accountTemplateIds,
    membershipStartDate,
    promoCode,
}: ShoppingBasketCookieData) => {
    const now = new Date();
    const ukTime = utcToZonedTime(now, 'Europe/London');
    const formattedDateNow = getFormattedDate(ukTime, 'yyyy-MM-dd');

    return axiosInstance.get<JoinResponseBody>(url, {
        params: {
            BranchId: branchId,
            AccountTemplateIds: accountTemplateIds,
            MembershipStartDate: membershipStartDate || formattedDateNow,
            DiscountCode: promoCode,
        },
        paramsSerializer(parameters) {
            return stringify(parameters, {
                arrayFormat: 'repeat',
            });
        },
    });
};

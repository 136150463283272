import { type OptimizelyDecision } from '@optimizely/optimizely-sdk';

import { type OptimizelyFeatureFlags } from '@tgg/common-types';

import { AnyContext } from '../../cookies/cookies.types';
import { getFullUrlFromRequest, parseNextCachePathname } from '../../url';
import { defaultFlags } from '../defaultFlags';
import { FlagsConstants, type TggFlagsObject } from '../featureFlags.types';

export const mapOptimizelyFlag = (flag: OptimizelyDecision) => {
    return {
        enabled:
            flag.enabled ??
            /* istanbul ignore next */
            defaultFlags[flag.flagKey as OptimizelyFeatureFlags].enabled,
        variationKey:
            flag.variationKey ??
            /* istanbul ignore next */
            defaultFlags[flag.flagKey as OptimizelyFeatureFlags]?.variationKey,
        variables:
            flag.variables ??
            /* istanbul ignore next */
            defaultFlags[flag.flagKey as OptimizelyFeatureFlags]?.variables,
        ruleKey: flag.ruleKey,
    };
};

export const mapOptimizelyFlags = (
    flagsObject: Record<OptimizelyFeatureFlags, OptimizelyDecision>,
) =>
    Object.entries(flagsObject).reduce((accumulator, entry) => {
        const newAccumulator = { ...accumulator };
        // @ts-ignore
        newAccumulator[entry[0] as OptimizelyFeatureFlags] = mapOptimizelyFlag(
            entry[1],
        );
        return newAccumulator;
    }, {} as TggFlagsObject);

export const getFeatureFlagRequestOptions = (context: AnyContext) => {
    const { req } = context;
    if (!req) {
        return {
            pathname: FlagsConstants.UNRESOLVED,
        };
    }

    const pathnameFromRequest = req.url
        ? getFullUrlFromRequest(req).pathname
        : FlagsConstants.UNRESOLVED;

    const pathname = pathnameFromRequest.includes('_next/data')
        ? parseNextCachePathname(pathnameFromRequest)
        : pathnameFromRequest;

    return {
        pathname,
    };
};

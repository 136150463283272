import { PaymentMethod } from '@tgg/common-types';
import { getTestId } from '@tgg/util';

import {
    StyledCreditCardButton,
    StyledOptionsWrapper,
    StyledWayOfPaymentContainer,
    StyledHeading,
} from './PaymentMethodSelector.styled';
import { PaymentMethodSelectorProperties } from './PaymentMethodSelector.types';

export function PaymentMethodSelector({
    selectedPaymentMethod,
    onSelect,
    showPayPalPayIn3,
}: PaymentMethodSelectorProperties) {
    return (
        <StyledWayOfPaymentContainer>
            <StyledHeading variant="h3" gutterBottom={false} component="h3">
                Select your payment type
            </StyledHeading>
            <StyledOptionsWrapper>
                <StyledCreditCardButton
                    text="Credit/Debit Card"
                    fullWidth
                    buttonStyle={
                        selectedPaymentMethod === PaymentMethod.Card
                            ? 'primary'
                            : 'secondary'
                    }
                    onClick={() => onSelect(PaymentMethod.Card)}
                />
                <StyledCreditCardButton
                    text="PayPal"
                    fullWidth
                    buttonStyle={
                        selectedPaymentMethod === PaymentMethod.Paypal
                            ? 'primary'
                            : 'secondary'
                    }
                    onClick={() => onSelect(PaymentMethod.Paypal)}
                    data-testid={getTestId('pay-with-paypal')}
                />
                {showPayPalPayIn3 && (
                    <StyledCreditCardButton
                        text="PayPal Pay in 3"
                        fullWidth
                        buttonStyle={
                            selectedPaymentMethod === PaymentMethod.PaypalPayIn3
                                ? 'primary'
                                : 'secondary'
                        }
                        onClick={() => onSelect(PaymentMethod.PaypalPayIn3)}
                        data-testid={getTestId('paypal-pay-in-3')}
                    />
                )}
            </StyledOptionsWrapper>
        </StyledWayOfPaymentContainer>
    );
}

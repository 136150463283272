import {
    IncludedBenefits,
    PinoProductTitle,
    BenefitsHeadings,
} from '@tgg/common-types';

import { theme } from '../../theme';

import { PinoProductWithDetails } from './PinoTable.types';
import { StyledAnchorButton, StyledTableCell } from './pino-table.styled';

const GYM_LOCATION_ANCHOR = '#gym_times_location_section';

export interface StandardAccessCellProperties {
    cell: {
        id: string;
        title: PinoProductTitle;
        heading: string;
        row: PinoProductWithDetails;
    };
    state: {
        selectedProduct: PinoProductTitle;
        hoveredProduct: PinoProductTitle | null | undefined;
        includedBenefits: IncludedBenefits;
        isProductDisabled: boolean;
        onCommerceGymPage: boolean;
    };
    actions: {
        handleMouseEnter: (title: PinoProductTitle) => void;
        handleMouseLeave: (title: PinoProductTitle) => void;
        setSelectedProduct: (title: PinoProductTitle) => void;
        selectProduct: (product: PinoProductWithDetails) => void;
        scrollToRelatedSection: (href: string) => void;
    };
}

export const StandardAccessCell = ({
    cell,
    state,
    actions,
}: StandardAccessCellProperties) => {
    const { id, title, heading, row } = cell;
    const {
        selectedProduct,
        hoveredProduct,
        includedBenefits,
        isProductDisabled,
        onCommerceGymPage,
    } = state;
    const {
        handleMouseEnter,
        handleMouseLeave,
        scrollToRelatedSection,
        selectProduct,
        setSelectedProduct,
    } = actions;

    return (
        <StyledTableCell
            theme={theme}
            $selectedProduct={title === selectedProduct ? title : undefined}
            $hoveredProduct={
                title === hoveredProduct && title !== selectedProduct
                    ? title
                    : undefined
            }
            onMouseEnter={() => handleMouseEnter(title)}
            onMouseLeave={() => handleMouseLeave(title)}
            onClick={() => {
                selectProduct(row);
                setSelectedProduct(title);
            }}
            key={id}
            align="center"
            rowSpan={includedBenefits[heading as BenefitsHeadings].rowSpan}
            disabled={isProductDisabled}
        >
            {onCommerceGymPage ? (
                <StyledAnchorButton
                    type="button"
                    aria-label="standard product does not have 24/7 access to Free classes and Gym Access"
                    onClick={() => scrollToRelatedSection(GYM_LOCATION_ANCHOR)}
                >
                    <div>all hours</div>
                    <div>access</div>
                </StyledAnchorButton>
            ) : (
                <span aria-label="standard product does not have 24/7 access to Free classes and Gym Access">
                    <div>all hours</div>
                    <div>access</div>
                </span>
            )}
        </StyledTableCell>
    );
};

import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';
import { Heading } from '../Heading';

export const DownloadOurAppSection = styled('section')`
    display: flex;
    flex-direction: column;
    max-width: 51.9rem;
    margin: 1rem auto;
    border: 0.2rem solid ${palette.grey[50]};
    border-radius: 0.8rem;

    img {
        border-radius: 0.8rem 0.8rem 0 0;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin: 2rem auto;
        text-align: center;
    }
`;

export const StyledContent = styled('div')`
    padding: 0 1.5rem 1.5rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 0 7rem 1.5rem;
    }
`;

export const StyledHeading = styled(Heading)`
    margin-top: 2rem;
`;

export const StyledCheckList = styled('ul')`
    padding: 0;
    margin: 0 0 2rem;
    font-weight: normal;
    text-align: left;
    li {
        svg {
            margin-right: 1.1rem;
        }

        display: flex;
        margin-bottom: 1rem;
        list-style: none;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: inline-flex;
        flex-direction: column;
        margin: 0 auto 1rem auto;
    }
`;

export const StyledAppStoreBadgesContainer = styled('div')`
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: -1rem;
    a {
        display: flex;
    }
`;

import { EventKey } from '@tgg/services';

import { palette } from '../../../theme';
import { ButtonBase } from '../../Button';
import { Icon } from '../../Icon';

import { StyledSubmitButtonWrapper } from './PaymentCta.styled';
import { PaymentCtaProperties } from './PaymentCta.types';

export function PaymentCta({
    eventType,
    eventPayload,
    submitText = 'Make a Payment',
    submitButtonIconName,
    disabled = true,
    onCancel,
}: PaymentCtaProperties) {
    return (
        <StyledSubmitButtonWrapper>
            <ButtonBase
                type="submit"
                text={submitText}
                disabled={disabled}
                startIcon={
                    submitButtonIconName && (
                        <Icon
                            name={submitButtonIconName}
                            color={palette.common.white}
                            size={24}
                        />
                    )
                }
                data-analytics-event={EventKey.CTA_BUTTON_CLICK}
                data-analytics-payload={JSON.stringify({
                    event: eventType,
                    paymentType: 'card',
                    ...eventPayload,
                })}
                data-testid="payment-cta-submit-btn"
            />
            {onCancel && (
                <ButtonBase
                    onClick={onCancel}
                    buttonStyle="secondary"
                    text="cancel"
                    data-testid="payment-cta-cancel-btn"
                />
            )}
        </StyledSubmitButtonWrapper>
    );
}

import { Controller } from 'react-hook-form';

import { Asterisk } from '../../Asterisk';
import { TextInput } from '../../Forms/TextInput';
import { StyledSelectLabel } from '../ControlledInputs.styled';

import {
    ControlledSelectInputProperties,
    CustomChangeEventType,
} from './ControlledSelectInput.types';

export function ControlledSelectInput<T = any>({
    control,
    name,
    rules,
    label,
    labelInOneLine = false,
    defaultOptionText = '- select from dropdown -',
    isRequired,
    id,
    items,
    onCustomChange,
}: ControlledSelectInputProperties<T>) {
    const selectItems = defaultOptionText
        ? [{ key: 'default', value: defaultOptionText }, ...items]
        : items;
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
                field: { value, onChange, onBlur },
                fieldState: { invalid, isDirty, error },
            }) => {
                const disabled = items.length === 0;

                return (
                    <>
                        <StyledSelectLabel
                            $hasPaddingTop
                            $disabled={disabled}
                            $isLabelInOneLine={labelInOneLine}
                            htmlFor={id}
                            data-testid={`${id}-label`}
                        >
                            {label}
                            {isRequired && <Asterisk spaceBefore />}
                        </StyledSelectLabel>
                        <TextInput
                            select
                            value={value}
                            errorMessage={error?.message}
                            isInvalid={invalid}
                            id={id}
                            onChange={async event => {
                                onChange(event);
                                onCustomChange &&
                                    (await onCustomChange(
                                        event as CustomChangeEventType<T>,
                                    ));
                            }}
                            onBlur={onBlur}
                            iconElementRight={{
                                name: !invalid && isDirty ? 'tick' : 'blank',
                            }}
                            disabled={disabled}
                        >
                            {selectItems.map(
                                (option: { key: string; value: string }) => {
                                    if (option.key === 'default') {
                                        return (
                                            <option
                                                key={option.key}
                                                value=""
                                                disabled
                                            >
                                                {option.value}
                                            </option>
                                        );
                                    }
                                    return (
                                        <option
                                            key={option.key}
                                            value={option.key}
                                        >
                                            {option.value}
                                        </option>
                                    );
                                },
                            )}
                        </TextInput>
                    </>
                );
            }}
        />
    );
}

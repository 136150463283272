import { ImageStandalone } from '../ImageStandalone';

import {
    GridContainer,
    StyledImageWrapper,
} from './MultipleImageComponent.styled';
import { MultipleImageComponentProperties } from './MultipleImageComponent.types';

export const MultipleImageComponent = ({
    images: [billboard, secondPack, thirdPack],
    height,
}: MultipleImageComponentProperties) => {
    return (
        <GridContainer $height={height}>
            <StyledImageWrapper>
                <ImageStandalone height="auto" width="auto" {...billboard} />
            </StyledImageWrapper>
            {secondPack ? (
                <StyledImageWrapper>
                    <ImageStandalone
                        height="auto"
                        width="auto"
                        {...secondPack}
                    />
                </StyledImageWrapper>
            ) : null}
            {thirdPack ? (
                <StyledImageWrapper>
                    <ImageStandalone
                        height="auto"
                        width="auto"
                        {...thirdPack}
                    />
                </StyledImageWrapper>
            ) : null}
        </GridContainer>
    );
};

import { useMediaQuery } from '@mui/material';
import Rating from '@mui/material/Rating';

import { theme } from '../../theme';
import { StyledStarDescription } from '../GymHeader/GymHeader.styled';

import {
    StyledGoogleReviewsContainer,
    StyledGoogleReviewsLink,
    StyledIcon,
    StyledRatingStars,
} from './GooglePlaceRating.styled';
import { GooglePlaceRatingProperties } from './GooglePlaceRating.types';

export function GooglePlaceRating({
    rating,
    url,
    userRatingsTotal,
}: GooglePlaceRatingProperties) {
    const iconSize = useMediaQuery(theme.breakpoints.up('desktop')) ? 20 : 15;

    return (
        <StyledGoogleReviewsContainer>
            <StyledIcon
                name="googleReview"
                size={iconSize}
                viewBox="0 0 32 32"
            />
            <StyledGoogleReviewsLink href={url} target="_blank">
                <StyledRatingStars>
                    <Rating
                        value={rating}
                        precision={0.1}
                        size="small"
                        readOnly
                    />
                </StyledRatingStars>
            </StyledGoogleReviewsLink>
            <StyledStarDescription>
                <StyledGoogleReviewsLink href={url} target="_blank" $decorate>
                    {rating} •{' '}
                    {new Intl.NumberFormat().format(userRatingsTotal)} Reviews
                </StyledGoogleReviewsLink>
            </StyledStarDescription>
        </StyledGoogleReviewsContainer>
    );
}

export default GooglePlaceRating;

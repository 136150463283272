import { useMediaQuery } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { getAbsoluteURL, isMemberArea } from '@tgg/util';

import { theme } from '../../theme';
import { ButtonBase } from '../Button';
import { Paragraph } from '../Paragraph';

import {
    StyledButtonsContainer,
    StyledContainer,
    StyledContent,
    StyledHeading,
    StyledImage,
    StyledImageContainer,
    StyledMainHeading,
    StyledWrapper,
} from './GenericErrorMessage.styled';
import { GenericErrorMessageProperties } from './GenericErrorMessage.types';

export function GenericErrorMessage({
    mainHeading,
    heading,
    message = '',
    primaryButtonText = '',
    secondaryButtonText = '',
    contactLink = '/contact-us/',
    primaryLink,
    secondaryLink,
    imageSourceDesktop,
    imageSourceMobile,
}: GenericErrorMessageProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const router = useRouter();
    const isInMemberArea = isMemberArea(router.basePath);

    const imageSource = isDesktop ? imageSourceDesktop : imageSourceMobile;
    let defaultMessage;

    if (!message) {
        defaultMessage = (
            <>
                We’re not quite sure where things went wrong. Please try again
                later or{' '}
                <Link
                    href={
                        isInMemberArea
                            ? getAbsoluteURL(contactLink)
                            : contactLink
                    }
                >
                    contact us
                </Link>
                .
            </>
        );
    }
    return (
        <StyledWrapper>
            <StyledContainer>
                <StyledMainHeading>{mainHeading}</StyledMainHeading>
                <StyledImageContainer>
                    <StyledImage role="img" src={imageSource} layout="fill" />
                </StyledImageContainer>
                <StyledContent>
                    <StyledHeading variant="h2">{heading}</StyledHeading>
                    <Paragraph>{message || defaultMessage}</Paragraph>
                    <StyledButtonsContainer>
                        {primaryLink && (
                            <Link href={primaryLink} legacyBehavior>
                                <ButtonBase
                                    href={primaryLink}
                                    text={primaryButtonText}
                                />
                            </Link>
                        )}
                        {secondaryLink && (
                            <Link href={secondaryLink} legacyBehavior>
                                <ButtonBase
                                    href={secondaryLink}
                                    text={secondaryButtonText}
                                    buttonStyle="secondary"
                                />
                            </Link>
                        )}
                    </StyledButtonsContainer>
                </StyledContent>
            </StyledContainer>
        </StyledWrapper>
    );
}

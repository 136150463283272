import router from 'next/router';

import { ErrorCodes, PaymentError } from '@tgg/common-types';
import { EventKey, PaymentsType, dispatchEvent } from '@tgg/services';
import {
    getApiErrorResponseMessage,
    getCurrentTimestamp,
    relativeUrlWithBasePath,
} from '@tgg/util';

import {
    CreateOrderParameters,
    HandleApproveParameters,
} from './paypalHelpers.types';

export const handleCreateOrder = async ({
    wref,
    updatedPaymentToken,
    updatePaymentReference,
    logger,
    loggerMetaData,
    payPalPaymentPayload,
    paypalCreateOrder,
    returnUrl,
    setUpdatedPaymentToken,
}: CreateOrderParameters) => {
    void dispatchEvent<PaymentsType>(EventKey.CTA_BUTTON_CLICK, {
        event: 'your_payments_type',
        paymentType: 'paypal',
    });

    if (!wref && updatePaymentReference) {
        const paymentToken = await updatePaymentReference(
            updatedPaymentToken,
        ).catch(error => {
            logger.info(
                'Payment failed - The attempt to get payment reference for this transaction failed.',
                loggerMetaData,
            );
            const errorMessage = getApiErrorResponseMessage(error);
            throw new PaymentError(errorMessage, '2002', {
                redirect: true,
            });
        });
        setUpdatedPaymentToken(paymentToken);
    }

    if (!payPalPaymentPayload) {
        throw new Error(
            'No cookie was found when attempting payment with Paypal',
        );
    }

    const { branchId, basketItems, membershipStartDate, payments } =
        payPalPaymentPayload;
    const orderReference = `${wref as string}${getCurrentTimestamp()}`;

    const response = await paypalCreateOrder({
        branchId: branchId || '',
        wref: orderReference,
        productIds: [
            basketItems?.product?.id as string,
            ...(basketItems?.addOns || []).map(({ id }: { id: string }) => id),
        ],
        membershipStartDate,
        promoCode: payments?.promoCode || '',
        totalAuthorised: payments?.today?.total || 0,
        returnUrl: `${window.location.origin}${relativeUrlWithBasePath(returnUrl)}`,
        cancelUrl: `${window.location.origin}${relativeUrlWithBasePath(returnUrl)}`,
    });

    return { orderId: response.id, orderReference };
};

export const handleApprove = async ({
    data,
    orderReference,
    paypalCaptureOrder,
    paymentInfo,
    logger,
    loggerMetaData,
    confirmationUrl,
}: HandleApproveParameters) => {
    const result = await paypalCaptureOrder({
        orderId: data.orderID,
        requestId: orderReference,
        paymentInformation: paymentInfo,
    });

    if (result.success) {
        logger.info(
            `${confirmationUrl}: Paypal payment successful`,
            loggerMetaData,
        );
        await router.push(confirmationUrl);
    }
};

export const handleError = (
    error: any,
    logger: any,
    loggerMetaData: any,
    onErrorCallback: (code: ErrorCodes, fullMessage?: string | Error) => void,
) => {
    const errorMessage =
        (error instanceof Error && error.message) ||
        'Unknown error occurred when attempting payment with Paypal';
    logger.error(errorMessage, loggerMetaData);
    onErrorCallback('2002');
};

import { useEffect, useState } from 'react';

import { PaymentMethod } from '@tgg/common-types';

import { AgreementState, AgreementTypes } from '../MembershipAgreement.types';

interface UseMembershipAgreementParameters {
    agreementTypes: AgreementTypes[];
    selectedPaymentMethod: PaymentMethod;
    onTermsAndConditionsAccepted: (value: boolean) => void;
}

export const useMembershipAgreement = ({
    agreementTypes,
    selectedPaymentMethod,
    onTermsAndConditionsAccepted,
}: UseMembershipAgreementParameters) => {
    const [agreementCheckBoxes, setAgreementCheckBoxes] =
        useState<AgreementState>(createInitialAgreementState(agreementTypes));

    const acceptedTermsAndConditions = Object.values(agreementCheckBoxes).every(
        item => !!item,
    );

    useEffect(() => {
        setAgreementCheckBoxes(createInitialAgreementState(agreementTypes));
    }, [selectedPaymentMethod]);

    useEffect(() => {
        onTermsAndConditionsAccepted(acceptedTermsAndConditions);
    }, [acceptedTermsAndConditions]);

    return {
        agreementCheckBoxes,
        setAgreementCheckBoxes,
        acceptedTermsAndConditions,
    };
};

const createInitialAgreementState = (agreementTypes: AgreementTypes[]) =>
    agreementTypes.reduce(
        (accumulator: AgreementState, current: AgreementTypes) => {
            accumulator[current] = false;
            return accumulator;
        },
        {} as AgreementState,
    );

import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { calculateScrollPadding } from '../../utils';

export const useStickyScrollPadding = (): void => {
    const router = useRouter();

    useEffect(() => {
        const stickyElementsHeight = calculateScrollPadding();

        document.documentElement.style.scrollPaddingTop = `${stickyElementsHeight}px`;

        router.events.on('routeChangeComplete', calculateScrollPadding);

        window.addEventListener('resize', calculateScrollPadding);

        return () => {
            router.events.off('routeChangeComplete', calculateScrollPadding);
            window.removeEventListener('resize', calculateScrollPadding);
        };
    }, [router.events]);
};

export default useStickyScrollPadding;

import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../../theme';
import { ButtonBase } from '../../Button';
import { Heading } from '../../Heading';

export const StyledButton = styled(ButtonBase)`
    justify-content: left;
    width: auto;
    padding: 0;
    margin: 2rem 0 0 auto;
    font-family: thegymgroupsans-bold;
    font-size: 1.4rem;
    color: ${palette.common.blue};
    text-decoration: underline;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin: 1rem 0 0 auto;
        font-size: 1.6rem;
    }
    &:hover {
        color: ${palette.common.blue};
        text-decoration: underline;
        background-color: transparent;
    }
`;

export const StyledHeading = styled(Heading)`
    padding-left: 0;
    font-size: 2.1rem;
    text-align: left;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 2.7rem;
        text-align: center;
    }
`;

export const StyledAddressWrapper = styled('div')``;

export const StyledSearchAddress = styled('div')`
    &.hidden {
        display: none;
    }
`;

export const StyledManualAddress = styled('div')`
    &.hidden {
        display: none;
    }
`;

export const StyledAddressLabel = styled('label')`
    display: block;
    padding-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
    font-weight: normal;
    text-transform: lowercase;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-top: 2rem;
        font-size: 1.6rem;
    }
`;

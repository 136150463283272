import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';

export const StyledContainer = styled('div')`
    flex-grow: 1;
    padding: 0 2rem;
`;

export const StyledParagraph = styled(Paragraph)`
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 1rem;
    }
`;

export const StyledFindAGymLink = styled('a')`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin: 2.8rem 0;
    font-family: 'thegymgroupsans-Headline';
    color: ${palette.common.white};
    text-decoration: none;
    background-color: #2e94ff;
    border-radius: 1rem;

    &:hover {
        background-color: #2a87e8;
    }

    &:focus {
        background-color: #2e94ff;
        outline: solid 0.2rem #2e94ff;
        outline-offset: 0.2rem;
    }

    &:active {
        background-color: #2576cc;
        outline: none;
    }
`;

export const StyledPaymentIcons = styled('ul')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;

    li {
        list-style: none;
    }

    svg {
        height: 2rem;
    }

    rect {
        stroke: none;
    }
`;

export const StyledNavigationList = styled('ul')`
    padding-left: 0;
    margin-bottom: 3.5rem;

    li {
        list-style: none;
    }

    li + li {
        margin-top: 0.8rem;
    }
`;

export const StyledNavigationLink = styled('a')`
    display: block;
    padding: 1.3rem 2rem;
    font-family: 'thegymgroupsans-Headline';
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${palette.common.blue};
    text-decoration: none;
    background-color: ${palette.grey['900']};
    border-radius: 1rem;

    &:hover {
        background-color: #bacfe3;
    }

    &:focus {
        background-color: ${palette.grey['900']};
        outline: solid 0.2rem ${palette.common.blue};
        outline-offset: 0.2rem;
    }

    &:active {
        background-color: #a3b6c8;
        outline: none;
    }
`;

export const StyledFindAGymIcon = styled(Icon)`
    margin-bottom: 0.4rem;
`;

export const StyledHeading = styled(Heading)`
    && {
        padding-left: 0;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        line-height: 2rem;
        color: ${palette.common.blue};

        ${({ theme }) => theme.breakpoints.up('desktop')} {
            font-size: 1.6rem;
        }
    }
`;

export const StyledBenefits = styled('ul')`
    display: flex;
    gap: 1.2rem;
    padding-left: 0;
    margin-bottom: 2.8rem;
`;

export const StyledBenefitItem = styled('li')`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    font-size: 1.4rem;
    text-align: center;
    list-style: none;
`;

export const StyledTotalCost = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2rem 0 2rem;
    background-color: ${palette.grey[100]};
`;

export const StyledTotalCostText = styled('div')`
    font-family: thegymgroupsans-Headline;
    font-size: 1.6rem;
    font-weight: 800;
    line-height: 2.4rem;
    color: ${palette.common.blue};
`;

export const StyledNavigationButtonWrapper = styled('div')`
    > div {
        padding: 2rem;
        a {
            font-size: 1.6rem;
            font-weight: 800;
        }
    }
`;

export const StyledPayPalWrapper = styled('div')`
    > div {
        padding: 2rem 2rem 0 2rem;
        margin: 0;
        background-color: ${palette.grey[100]};
    }
`;

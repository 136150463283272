import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { BillingAddressFields } from '@tgg/common-types';
import {
    addressLineRequiredValidator,
    addressLineValidator,
    cityTownValidator,
    postcodeValidator,
} from '@tgg/form-validation';
import { getTestId } from '@tgg/util';

import { ButtonBase } from '../../Button';
import {
    ControlledSelectInput,
    ControlledTextInput,
} from '../../ControlledInputs';
import { Overlay } from '../../Overlay';
import {
    iso3166CountriesList,
    iso3166UsStatesList,
} from '../BillingAddress.constants';

import {
    StyledButtonsContainer,
    StyledForm,
    StyledSaveButton,
    StyledHeading,
    StyledParagraph,
} from './BillingAddressOverlay.styled';
import { BillingAddressOverlayProperties } from './BillingAddressOverlay.types';

const countryList = Object.entries(iso3166CountriesList).map(
    ([code, name]) => ({
        key: code,
        value: name,
    }),
);

const stateList = Object.entries(iso3166UsStatesList).map(([code, name]) => ({
    key: code,
    value: name,
}));

/**
 * An overlay which prompts the user to enter and submit their bulling address details.
 */
export const BillingAddressOverlay = ({
    isOpen,
    onSubmit,
    onClose,
}: BillingAddressOverlayProperties) => {
    const { handleSubmit, control, formState, setValue, watch } =
        useForm<BillingAddressFields>({
            mode: 'onChange',
            defaultValues: {
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                country: 'GB',
                postCode: '',
                state: '',
            },
        });
    const { isValid } = formState;
    const watchCountry = watch('country');

    useEffect(() => {
        if (watchCountry === 'US') {
            setValue('state', 'AL');
        } else {
            setValue('state', '');
        }
    }, [watchCountry, setValue]);

    return (
        <Overlay
            title="Change billing address"
            open={isOpen}
            handleClose={onClose}
            centerTitleOnDesktop
        >
            <StyledHeading>Billing Address</StyledHeading>
            <StyledParagraph gutterBottom={false}>
                Please make sure your credit/debit card billing address is as it
                appears on your card statement.
            </StyledParagraph>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <ControlledSelectInput
                    name="country"
                    label="Country"
                    id="country"
                    isRequired
                    items={countryList}
                    control={control}
                />

                <ControlledTextInput
                    rules={{
                        validate: {
                            validator: addressLineRequiredValidator,
                        },
                    }}
                    isRequired
                    control={control}
                    id="addressLine1"
                    name="addressLine1"
                    label="Address Line 1"
                />

                <ControlledTextInput
                    rules={{
                        validate: {
                            validator: addressLineValidator,
                        },
                    }}
                    control={control}
                    id="addressLine2"
                    name="addressLine2"
                    label="Address Line 2"
                />

                <ControlledTextInput
                    rules={{
                        validate: {
                            validator: addressLineValidator,
                        },
                    }}
                    control={control}
                    id="addressLine3"
                    name="addressLine3"
                    label="Address Line 3"
                />

                <ControlledTextInput
                    rules={{
                        validate: {
                            validator: cityTownValidator,
                        },
                    }}
                    control={control}
                    isRequired
                    id="city"
                    name="city"
                    label="City / Town"
                />

                {watchCountry === 'US' && (
                    <ControlledSelectInput
                        name="state"
                        label="state"
                        id="state"
                        isRequired
                        items={stateList}
                        control={control}
                    />
                )}

                <ControlledTextInput
                    rules={{
                        validate: {
                            validator: postcodeValidator,
                        },
                    }}
                    control={control}
                    isRequired
                    id="postCode"
                    name="postCode"
                    label="Postcode / Zip Code"
                />

                <StyledButtonsContainer>
                    <ButtonBase
                        text="Cancel"
                        onClick={onClose}
                        buttonStyle="secondary"
                    />

                    <StyledSaveButton
                        type="submit"
                        text="Save"
                        disabled={!isValid}
                        data-testid={getTestId('save-button')}
                    />
                </StyledButtonsContainer>
            </StyledForm>
        </Overlay>
    );
};

BillingAddressOverlay.displayName = 'BillingAddressOverlay';

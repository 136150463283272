import { ButtonCms } from '../../Button';
import {
    StyledContainerButton,
    StyledWrapperButton,
} from '../base/ButtonStandalone.styled';

import { ButtonStandaloneCmsProperties } from './ButtonStandaloneCms.types';

export const ButtonStandaloneCms = ({
    id,
    text,
    href,
    buttonStyle = 'primary',
    fullWidth = false,
    gymName,
    ctaPosition,
}: ButtonStandaloneCmsProperties) => (
    <StyledContainerButton
        $fullWidth={fullWidth}
        id={id}
        data-testid="standaloneButtonStyledWrapper"
    >
        <StyledWrapperButton>
            <ButtonCms
                text={text}
                href={href}
                buttonStyle={buttonStyle}
                gymName={gymName}
                ctaPosition={ctaPosition}
            />
        </StyledWrapperButton>
    </StyledContainerButton>
);

ButtonStandaloneCms.displayName = 'ButtonStandalone';

export default ButtonStandaloneCms;
